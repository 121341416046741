import React from 'react';
import {
  Flex,
  Heading,
  FormControl,
  VStack,
  FormHelperText,
  Text,
  Link,
  RadioGroup
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { ButtonRadioGroup, RadioButton } from '../../../general/ButtonRadio';
import { appFields } from '../../../../pages/ProductEntry';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { FabricAttributes as fa, flattenObject } from 'product-validator';
import {
  getNestedValidationError,
  isTouched
} from '../../../../utils/validationUtils';
import {
  Card,
  ErrorMessage,
  WrappedInputGroup,
  OtherTextInput
} from '../../../general';
import { PRICE_LOOKUP } from '../../../../utils/pricing/fabric/priceLookup';

// Retrieve the 3 categories of thread types ("Light", "Standard", "Heavy")
const KNIT_WEAVE_TYPE_CATEGORIES = Object.keys(fa.KnitWeaveType.Knit);

const getKnitWeaves = (knitOrWeave: fa.KnitOrWoven, fibre: string) => {
  try {
    for (const fibres of Object.values(PRICE_LOOKUP)) {
      if (fibre in fibres) return Object.keys(fibres[fibre][knitOrWeave]);
    }
    throw new Error('Fibre is defined but not in ContentSpecific.');
  } catch (err) {
    // In the case when the fibre is given as "Other" then return all
    // corresponding knitWeaveTypes
    return flattenObject(
      knitOrWeave === fa.KnitOrWoven.Knit
        ? fa.KnitWeaveType.Knit
        : fa.KnitWeaveType.Woven
    );
  }
};

// Default values are given to ensure values are defined
const getKnitWeaveOptions = (
  fibre = '',
  knitOrWeave = fa.KnitOrWoven.Woven
) => {
  const knitWeaveTypeByContent = getKnitWeaves(knitOrWeave, fibre);
  const knitWeaveTypeCategories = Object.values(fa.KnitWeaveType[knitOrWeave]);
  return knitWeaveTypeCategories.map((knitWeaveTypeByCategory) =>
    knitWeaveTypeByContent.filter(
      (knitWeaveType) => knitWeaveType in knitWeaveTypeByCategory
    )
  );
};

export const KnitWeaveTypeField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors
}) => {
  let title = 'Add a type of weave?';
  if (currentValue.knitOrWoven === fa.KnitOrWoven.Knit)
    title = 'Add a type of knit?';

  const errMsgs = getNestedValidationError('knitWeaveType', validationErrors);
  const touched = isTouched(currentValue.knitWeaveType);
  const primaryFibre =
    currentValue.content?.estimatedSpecificContent?.primary ||
    currentValue.content?.knownContent?.[0]?.content ||
    currentValue.content?.knownContentOther?.[0]?.content ||
    currentValue.content?.estimatedGeneralContent ||
    currentValue.content?.estimatedGeneralContentOther;
  const requiredFields = !!currentValue.knitOrWoven && !!primaryFibre;

  let requiredErrorMsg;
  if (!currentValue.knitOrWoven) {
    requiredErrorMsg = (
      <Text fontSize="xs" color="red.500">
        <Link as={RouterLink} to={appFields['Knit or Woven'].path}>
          Please select if the fabric is Knit or Woven
        </Link>
      </Text>
    );
  } else if (!primaryFibre) {
    requiredErrorMsg = (
      <Text fontSize="xs" color="red.500">
        <Link as={RouterLink} to={appFields['Fiber Content'].path}>
          Please select a fabric content type
        </Link>
      </Text>
    );
  }

  const categoryOptions = getKnitWeaveOptions(
    primaryFibre,
    currentValue.knitOrWoven
  );

  const knitWeaveType = (
    <FormControl as="fieldset" isInvalid={errMsgs.length > 0 && touched}>
      <VStack spacing={3}>
        {categoryOptions.every((category) => category.length === 0) ? (
          <Text>
            No {currentValue.knitOrWoven} types exists for {primaryFibre}
          </Text>
        ) : (
          <RadioGroup
            name={'ThreadType'}
            value={currentValue.knitWeaveType}
            onChange={(v) => setFormField({ knitWeaveType: v as string })}
          >
            <Flex
              wrap="wrap"
              justifyContent="center"
              sx={{ gap: '0.5rem' }}
              maxW="100%"
            >
              {KNIT_WEAVE_TYPE_CATEGORIES.map((category, index) => {
                return categoryOptions[index].length > 0 ? (
                  <Card key={category} title={category}>
                    <WrappedInputGroup numColumns={2}>
                      {categoryOptions[index].map((v) => (
                        <RadioButton
                          key={v}
                          value={v}
                          size="sm"
                          isDisabled={!!currentValue.knitWeaveTypeOther}
                        >
                          {v}
                        </RadioButton>
                      ))}
                    </WrappedInputGroup>
                  </Card>
                ) : undefined;
              })}
            </Flex>
          </RadioGroup>
        )}
        <OtherTextInput
          onChange={(e) =>
            setFormField({
              knitWeaveTypeOther: e.target.value
            })
          }
          value={currentValue.knitWeaveTypeOther ?? ''}
        />
      </VStack>
      <ErrorMessage errMsgs={errMsgs} />
      <FormHelperText>Select one.</FormHelperText>
    </FormControl>
  );

  return (
    <>
      <Flex
        my="1rem"
        dir="row"
        gridGap="3em"
        wrap="wrap"
        justifyContent="center"
      >
        <Heading size="md">{title}</Heading>
        <ButtonRadioGroup
          name="knitWeaveTypeExists"
          isDisabled={!requiredFields}
          buttonProps={{
            w: '7em'
          }}
          value={currentValue.knitWeaveTypeExists ?? 'No'}
          options={['Yes', 'No']}
          onChange={(v) => {
            setFormField({ knitWeaveTypeExists: v });
            if (v === 'No') setFormField({ knitWeaveType: undefined });
          }}
        />
      </Flex>
      {!requiredFields ? requiredErrorMsg : undefined}
      {currentValue.knitWeaveTypeExists === 'Yes' && !!requiredFields
        ? knitWeaveType
        : undefined}
    </>
  );
};

import React, { useState, useEffect, useCallback } from 'react';
import { Switch } from 'react-router';
import { useRouteMatch, Route } from 'react-router-dom';
import {
  NextFieldNavigator,
  NoPageFound,
  PageLayout,
  SideBar
} from '../components/general';
import { validateLength } from '../utils/validationUtils';
import {
  SummaryPage,
  LengthField,
  SearchField
} from '../components/rollToBundle';
import { SimpleProduct } from '../types/rollToBundleTypes';

export const rollToBundleRoutes = {
  search: {
    label: 'Search Product',
    to: '/roll_to_bundle/search',
    component: SearchField
  },
  updateLength: {
    label: 'Update Length',
    to: '/roll_to_bundle/length',
    component: LengthField
  },
  upload: {
    label: 'Upload',
    to: '/roll_to_bundle/upload',
    hideNavButtons: true,
    component: SummaryPage
  }
};

const routeList = Object.values(rollToBundleRoutes);

const linkGenerator = (currentPath: string) => {
  let prevPath = '';
  let nextPath = '';
  const idx = routeList.findIndex(
    (e) => e.to === `/roll_to_bundle/${currentPath}`
  );

  if (idx > 0) prevPath = routeList[idx - 1].to;
  if (idx < routeList.length - 1) nextPath = routeList[idx + 1].to;

  return { prevPath, nextPath };
};

export const RollToBundle = () => {
  const [mainProduct, setMainProduct] = useState<SimpleProduct>();
  const [length, setLength] = useState<string>();
  const [lengthErrors, setLengthErrors] = useState<string>();
  const [sku, setSku] = useState<string>();
  const [skuErrors, setSkuErrors] = useState<string>();
  const match = useRouteMatch();

  const resetForm = useCallback(() => {
    setSku(undefined);
    setSkuErrors(undefined);
    setMainProduct(undefined);
    setLength(undefined);
    setLengthErrors(undefined);
  }, []);

  useEffect(() => {
    (async () => {
      const errors = await validateLength(length);
      setLengthErrors(errors);
    })();
  }, [length]);

  useEffect(() => {
    (async () => {
      try {
        if (!sku) throw new Error('SKU must be defined.');
        if (!Number.isInteger(parseFloat(sku)))
          throw new Error('Must be a whole number.');
        setSkuErrors(undefined);
      } catch (err) {
        setSkuErrors(err.message);
      }
    })();
  }, [sku]);

  return (
    <PageLayout
      sidebar={<SideBar links={routeList} />}
      main={
        <Switch>
          <Route path={rollToBundleRoutes.search.to}>
            <NextFieldNavigator linkGenerator={linkGenerator}>
              {(toNextPage) => (
                <SearchField
                  sku={sku}
                  setSku={setSku}
                  skuErrors={skuErrors}
                  mainProduct={mainProduct}
                  toNextPage={toNextPage}
                  setMainProduct={setMainProduct}
                />
              )}
            </NextFieldNavigator>
          </Route>
          <Route path={rollToBundleRoutes.updateLength.to}>
            <NextFieldNavigator linkGenerator={linkGenerator}>
              <LengthField
                length={length}
                setLength={setLength}
                lengthErrors={lengthErrors}
              />
            </NextFieldNavigator>
          </Route>
          <Route path={rollToBundleRoutes.upload.to}>
            <SummaryPage
              name={mainProduct?.title}
              sku={mainProduct?.sku}
              skuErrors={skuErrors}
              length={length}
              lengthErrors={lengthErrors}
              resetForm={resetForm}
            />
          </Route>
          <Route path={`${match.path}`}>
            <NoPageFound />
          </Route>
        </Switch>
      }
    />
  );
};

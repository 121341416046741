import {
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  VStack
} from '@chakra-ui/react';
import { ProductTypes } from 'product-validator';
import React from 'react';
import {
  getNestedValidationError,
  isTouched
} from '../../../utils/validationUtils';
import { NumberInput } from '../../general';
import { IndexedAppFieldComponentProps } from '../../../types/appFieldTypes';
import update from 'immutability-helper';

export const WeightField: React.FC<IndexedAppFieldComponentProps> = ({
  setFormField,
  currentValue,
  validationErrors,
  idx = 0
}) => {
  let otherField = '';
  let weightGErrParam = '';
  let weightGMsg = '';
  let sideText = '';
  let weightGTouched = false;

  switch (currentValue.productType) {
    case ProductTypes['Other Supply']:
      weightGTouched = isTouched(currentValue.weightG);
      otherField = 'weightG';
      weightGErrParam = 'weightG';
      weightGMsg = `Weight of the product in grams`;
      sideText = 'Grams (G)';
      break;
  }

  const weightGErr = getNestedValidationError(
    weightGErrParam,
    validationErrors
  );

  const getCurrentInput = (): string => {
    if (currentValue.productType === ProductTypes['Other Supply'])
      return (currentValue.weightG ?? '').toString();
    return '';
  };

  const numberInputProps = {
    size: 'lg',
    stackProps: { w: '80%' },
    w: '80%'
  };

  return (
    <FormControl
      as="fieldset"
      isInvalid={weightGErr.length > 0 && weightGTouched}
    >
      <FormLabel>
        <Heading>{weightGMsg}</Heading>
        <FormHelperText>Weigh the product in gram units</FormHelperText>
      </FormLabel>
      <VStack spacing="3" alignItems="left">
        <NumberInput
          id="weightG"
          onChange={(s) => {
            setFormField((oldForm) => {
              return update(oldForm, { [otherField]: { $set: s } });
            });
          }}
          value={getCurrentInput()}
          text={sideText}
          {...numberInputProps}
        />
      </VStack>
    </FormControl>
  );
};

import { MergedProduct } from '../../../types/formTypes';
import { ThreadAttributes as ta } from 'product-validator';

const getThreadPrice = (
  threadType: string,
  spoolSize: string,
  content: string,
  indusWeight?: ta.IndustrialWeight
): number => {
  let prices = [0, 0, 0, 0, 0];

  switch (threadType) {
    case ta.ThreadType['All Purpose']:
      if (content === ta.Content.Wool) prices = [1.5, 3, 4, 4.5, 5];
      else prices = [1, 2.5, 3.5, 4, 4.5];
      break;
    case ta.ThreadType.Unknown:
      if (content === ta.Content.Wool || content === ta.Content.Silk)
        prices = [1.5, 3, 4, 4.5, 5];
      else prices = [1, 2.5, 3.5, 4, 4.5];
      break;
    case ta.ThreadType['Wooly Nylon']:
    default:
      prices = [1.5, 3, 4, 4.5, 5];
      break;
    case ta.ThreadType['Embroidery Floss']:
      return 0.5;
    case ta.ThreadType.Invisible:
    case ta.ThreadType.Elastic:
      prices = [1, 2.5, 3.5, 4, 4.5];
      break;
    case ta.ThreadType.Embroidery:
    case ta.ThreadType.Upholstery:
      if (content === ta.Content.Silk) prices = [2.5, 4, 5, 5.5, 6];
      prices = [2, 3.5, 4.5, 5, 5.5];
      break;
    case ta.ThreadType.Yarn:
      prices = [4, 6, 8, 9, 10];
      break;
    case ta.ThreadType.Silk:
      prices = [5, 7, 10, 12, 14];
      break;
    case ta.ThreadType.Metallic:
      prices = [2.5, 4.5, 7.5, 9.5, 11.5];
      break;
  }
  if (spoolSize === ta.SpoolSize['Home Sewing']) return prices[0];
  else if (spoolSize === ta.SpoolSize.Serger) return prices[1];
  else if (spoolSize === ta.SpoolSize.Industrial) {
    if (indusWeight === ta.IndustrialWeight.Light) return prices[2];
    else if (indusWeight === ta.IndustrialWeight.Medium) return prices[3];
    else if (indusWeight === ta.IndustrialWeight.Heavy) return prices[4];
  }
  return prices[0];
};

export const threadPriceSuggestion = (
  sellingUnit: ta.SellingUnit,
  threadType?: string,
  spoolSize?: string,
  content?: string,
  threadOnSpool?: ta.ThreadOnSpool,
  indusWeight?: ta.IndustrialWeight,
  imperfections?: string[],
  packSize?: number
): number => {
  let price = 0;

  if (threadType && spoolSize && content)
    price = getThreadPrice(threadType, spoolSize, content, indusWeight);

  if (threadOnSpool === ta.ThreadOnSpool['Less Than Full']) price *= 0.6;
  else if (threadOnSpool === ta.ThreadOnSpool.Various) price *= 0.85;

  if (sellingUnit === ta.SellingUnit['Pack'] && packSize)
    price = 0.8 * price * packSize;

  if (imperfections) {
    if (imperfections.includes(ta.Imperfections.Weak)) price *= 0.5;
    else price *= 0.8;
  }

  // Round to nearest 5 cents
  price = Math.ceil(price * 20) / 20;

  return price;
};

export const threadPriceSuggestionFromForm = (thread: MergedProduct) => {
  if (thread.sellingUnit) {
    let packSize = Number(thread.packSize);

    return threadPriceSuggestion(
      thread.sellingUnit as ta.SellingUnit,
      thread.threadType,
      thread.spoolSize as ta.SpoolSize,
      thread.material,
      thread.threadOnSpool as ta.ThreadOnSpool,
      thread.indusWeight as ta.IndustrialWeight,
      thread.imperfections?.[0],
      isNaN(packSize) ? undefined : packSize
    );
  }

  return undefined;
};

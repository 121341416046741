import axios from 'axios';
import { FabProduct } from 'product-validator';
import { ProductRequest, ResponseError } from './ResponseError';
import {
  StagedUploadTargetGenerateUploadResource,
  StagedUploadHttpMethodType,
  MimeTypeMedia,
  StagedUploadInput,
  MediaContentType,
  CreateMediaInput
} from './uploadProductTypes';

export type UploadProductResponseType = {
  productId: string;
  title: string;
  skus: string[];
};
export const productUpload = async (
  product: FabProduct
): Promise<UploadProductResponseType> => {
  try {
    const response = await axios.post('/product/product_upload', product);
    return response.data as {
      productId: string;
      title: string;
      skus: string[];
    };
  } catch (err) {
    // Throw custom response to be handled in UploadProduct.tsx
    throw new ResponseError(err, ProductRequest.PRODUCT);
  }
};

export const mediaUpload = async (sku: string, images: string[]) => {
  const medias: StagedUploadInput[] = images.map((img, index) => {
    return {
      filename: `${sku}_${index}.png`,
      resource: StagedUploadTargetGenerateUploadResource.Image,
      mimeType: MimeTypeMedia.png,
      httpMethod: StagedUploadHttpMethodType.Put
    };
  });
  try {
    const response = await axios.post('/product/media_upload', {
      medias,
      images
    });

    return response;
  } catch (err) {
    throw new ResponseError(err, ProductRequest.UPLOAD);
  }
};

export const attachMedia = async (
  id: string,
  title: string,
  imagesData: any
) => {
  const medias: CreateMediaInput[] = imagesData.map((img: any) => {
    return {
      originalSource: img.resourceUrl,
      alt: title,
      mediaContentType: MediaContentType.Image
    };
  });
  try {
    const response = await axios.post('/product/attach_media', { id, medias });

    return response;
  } catch (err) {
    throw new ResponseError(err, ProductRequest.ATTACH);
  }
};

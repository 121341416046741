import { VStack } from '@chakra-ui/react';
import React from 'react';
import { OtherSupplyAttributes as osa } from 'product-validator';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { OtherSupplyWeight } from './OtherSupplyWeight';
import { WeightField } from '../WeightField';
import { PoundsInUnitField } from './PoundsInUnitField';
import { OtherSupplySizeField } from './OtherSupplySizeField';

const RESPONSIVE_WIDTH = { md: '25rem', lg: '36rem' };

export const OtherSupplyWeights: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  return (
    <VStack spacing="7" alignItems="left" minW={RESPONSIVE_WIDTH}>
      <OtherSupplyWeight
        currentValue={currentValue}
        setFormField={setFormField}
        validationErrors={validationErrors}
        toNextPage={toNextPage}
      />
      {currentValue.sellingUnit === osa.SellingUnit.Individual ||
      currentValue.sellingUnit === osa.SellingUnit.Pack ? (
        <OtherSupplySizeField
          currentValue={currentValue}
          setFormField={setFormField}
          validationErrors={validationErrors}
          toNextPage={toNextPage}
        />
      ) : (
        <></>
      )}
      {currentValue.sellingUnit === osa.SellingUnit['By Weight'] ? (
        <PoundsInUnitField
          currentValue={currentValue}
          setFormField={setFormField}
          validationErrors={validationErrors}
          toNextPage={toNextPage}
        />
      ) : (
        <></>
      )}
      {currentValue.sellingUnit === osa.SellingUnit['By Weight'] ? (
        <></>
      ) : (
        <WeightField
          currentValue={currentValue}
          setFormField={setFormField}
          validationErrors={validationErrors}
          toNextPage={toNextPage}
        />
      )}
    </VStack>
  );
};

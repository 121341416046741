import {
  FormControl,
  FormLabel,
  FormHelperText,
  Heading,
  Stack,
  Flex
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { ProductTypes, FabricAttributes } from 'product-validator';
import { ButtonRadioGroup } from '../../general/ButtonRadio';
import { AppFieldComponent } from '../../../types/appFieldTypes';
import {
  getNestedValidationError,
  isTouched
} from '../../../utils/validationUtils';
import { OtherTextInput, SwitchInput, ErrorMessage } from '../../general';

export const StretchField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors
}) => {
  const errMsgs = getNestedValidationError('stretch', validationErrors);
  const touched = isTouched(currentValue.stretch, currentValue.stretchOther);
  const [options, setOptions] = useState<string[]>([]);

  const onRadioChange = async (val: string) => {
    setFormField({
      stretch: val
    });
  };

  const updateText = async (text: string) => {
    setFormField({
      stretchOther: text
    });
  };

  useEffect(() => {
    let keys = Object.keys(FabricAttributes.Stretch);
    if (
      currentValue.knitOrWoven === FabricAttributes.KnitOrWoven.Woven ||
      (currentValue.productType &&
        currentValue.productType === ProductTypes.Ribbing)
    )
      keys = keys.filter((v) => v !== FabricAttributes.Stretch['4-way']);
    setOptions(keys);
  }, [currentValue.knitOrWoven, currentValue.productType]);

  return (
    <FormControl as="fieldset" isInvalid={errMsgs.length > 0 && touched}>
      <FormLabel as="legend">
        <Heading>Stretch</Heading>
        Does it have stretch?
      </FormLabel>
      <Stack spacing="3">
        <ButtonRadioGroup
          name="stretch"
          value={currentValue.stretch}
          isDisabled={!!currentValue.stretchOther}
          options={options}
          onChange={onRadioChange}
        />
        <Flex
          w="100%"
          flexDir="row"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <OtherTextInput
            onChange={(e) => updateText(e.target.value)}
            value={currentValue.stretchOther ?? ''}
          />
          {currentValue.productType !== ProductTypes.Ribbing && (
            <SwitchInput
              label="slight?"
              pl={3}
              isDisabled={currentValue.stretch === 'None'}
              isChecked={currentValue.slightStretch ?? false}
              onChange={() =>
                setFormField((oldForm) => {
                  const newForm = { ...oldForm };
                  newForm.slightStretch = !(newForm.slightStretch ?? false);
                  return newForm;
                })
              }
            />
          )}
        </Flex>
      </Stack>
      <ErrorMessage errMsgs={errMsgs} />
      <FormHelperText>Choose one or type in a value.</FormHelperText>
    </FormControl>
  );
};

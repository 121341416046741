import { Table, Tbody, Th, Thead, Tr, Td, Link } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { inventorySuppliesRoutes } from '../../pages/InventorySupplies';

interface InventorySupplyTableProps {
  inventorySupplyObject: any;
}

export const InventorySupplyTable: React.FC<InventorySupplyTableProps> = ({
  inventorySupplyObject
}) => {
  return (
    <Table size="lg" variant="simple" m="4">
      <Thead>
        <Tr>
          <Th>Field</Th>
          <Th>Value</Th>
          <Th>Errors</Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td>
            <Link as={RouterLink} to={inventorySuppliesRoutes.startOrUpdate.to}>
              New Box?
            </Link>
          </Td>
          <Td>{inventorySupplyObject.isNewBox}</Td>
          <Td>-</Td>
        </Tr>
        <Tr>
          <Td>
            <Link as={RouterLink} to={inventorySuppliesRoutes.startOrUpdate.to}>
              Box ID
            </Link>
          </Td>
          <Td>{inventorySupplyObject.boxID}</Td>
          <Td>-</Td>
        </Tr>
        <Tr>
          <Td>
            <Link
              as={RouterLink}
              to={inventorySuppliesRoutes.supplyTypeBoxSize.to}
            >
              Supply Type
            </Link>
          </Td>
          <Td>{inventorySupplyObject.supplyType}</Td>
          <Td>-</Td>
        </Tr>
        <Tr>
          <Td>
            <Link
              as={RouterLink}
              to={inventorySuppliesRoutes.supplyTypeBoxSize.to}
            >
              Box Size
            </Link>
          </Td>
          <Td>{inventorySupplyObject.boxSize}</Td>
          <Td>-</Td>
        </Tr>
        <Tr>
          <Td>
            <Link as={RouterLink} to={inventorySuppliesRoutes.trimType.to}>
              Trim Type
            </Link>
          </Td>
          <Td>{inventorySupplyObject.trimType}</Td>
          <Td>-</Td>
        </Tr>
        <Tr>
          <Td>
            <Link
              as={RouterLink}
              to={inventorySuppliesRoutes.everythingElseCategory.to}
            >
              Everything Else Category
            </Link>
          </Td>
          <Td>{inventorySupplyObject.everythingElseCategory}</Td>
          <Td>-</Td>
        </Tr>
        <Tr>
          <Td>
            <Link
              as={RouterLink}
              to={inventorySuppliesRoutes.sewingCategory.to}
            >
              Sewing Category
            </Link>
          </Td>
          <Td>{inventorySupplyObject.sewingCategory}</Td>
          <Td>-</Td>
        </Tr>
        <Tr>
          <Td>
            <Link as={RouterLink} to={inventorySuppliesRoutes.craftCategory.to}>
              Craft Category
            </Link>
          </Td>
          <Td>{inventorySupplyObject.craftCategory}</Td>
          <Td>-</Td>
        </Tr>
        <Tr>
          <Td>
            <Link as={RouterLink} to={inventorySuppliesRoutes.otherCategory.to}>
              Other Category
            </Link>
          </Td>
          <Td>{inventorySupplyObject.otherCategory}</Td>
          <Td>-</Td>
        </Tr>
      </Tbody>
    </Table>
  );
};

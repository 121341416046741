import { Box, VStack, FormControl, FormLabel, Heading } from '@chakra-ui/react';
import React from 'react';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { ButtonRadioGroup, SwitchInput } from '../../../general';
import { ThreadAttributes as ta } from 'product-validator';
import { ThreadBrandField } from './ThreadBrandField';

export const ThreadSizeField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  const noMixedThreadOnSpool = Object.values(ta.ThreadOnSpool).filter(
    (option) => option !== ta.ThreadOnSpool.Various
  );

  const bulkySwitch = (
    <SwitchInput
      paddingTop="3"
      justifyContent="left"
      label="bulky?"
      isChecked={currentValue.bulky ?? false}
      onChange={() => {
        setFormField((oldForm) => {
          const newForm = { ...oldForm };
          newForm.bulky = !(newForm.bulky ?? false);
          return newForm;
        });
      }}
    />
  );

  return (
    <FormControl as="fieldset">
      <VStack spacing="12" alignItems="left" maxW="40rem">
        <Box maxW="30rem">
          <FormLabel as="legend">
            <Heading>Thread on Spool</Heading>
          </FormLabel>
          <ButtonRadioGroup
            name="Thread on Spool"
            value={currentValue.threadOnSpool}
            options={
              currentValue.sellingUnit === ta.SellingUnit.Pack
                ? Object.values(ta.ThreadOnSpool)
                : noMixedThreadOnSpool
            }
            onChange={(v) => {
              setFormField({ threadOnSpool: v as ta.ThreadOnSpool });
            }}
          />
        </Box>
        {currentValue.threadType !== ta.ThreadType['Embroidery Floss'] && (
          <Box maxW="30rem">
            <FormLabel as="legend">
              <Heading>Spool Size</Heading>
            </FormLabel>
            <ButtonRadioGroup
              name="Spool Size"
              value={currentValue.spoolSize}
              options={Object.values(ta.SpoolSize).splice(0, 3)}
              onChange={(v) => {
                setFormField({ spoolSize: v as ta.SpoolSize });
              }}
            />
          </Box>
        )}
        {currentValue.spoolSize === ta.SpoolSize.Industrial && (
          <Box>
            <FormLabel as="legend">
              <Heading>Industrial Weight</Heading>
            </FormLabel>
            <ButtonRadioGroup
              name="Industrial Weight"
              value={currentValue.indusWeight}
              options={Object.values(ta.IndustrialWeight)}
              onChange={(v) =>
                setFormField({
                  indusWeight: v as ta.IndustrialWeight
                })
              }
            />
            {bulkySwitch}
          </Box>
        )}
        {currentValue.spoolSize === ta.SpoolSize['Home Sewing'] && (
          <Box>
            <ThreadBrandField
              setFormField={setFormField}
              currentValue={currentValue}
              validationErrors={validationErrors}
              toNextPage={toNextPage}
            />
          </Box>
        )}
      </VStack>
    </FormControl>
  );
};

import axios from 'axios';
import { SimpleProduct, LabelInfo } from '../types/rollToBundleTypes';
import { ProductRequest, ResponseError } from './ResponseError';

export const searchRoll = async (sku: string): Promise<SimpleProduct[]> => {
  try {
    const response = await axios.get(`/product/?sku=${sku}&include=roll`);
    return response.data as SimpleProduct[];
  } catch (err) {
    return err;
  }
};

export const convertToBundle = async (
  sku: string,
  length: number
): Promise<LabelInfo & { status: number }> => {
  try {
    const response = await axios.post('/product/roll_to_bundle', {
      sku,
      length
    });
    return { ...response.data, status: response.status };
  } catch (err) {
    throw new ResponseError(err, ProductRequest.ROLL_TO_BUNDLE);
  }
};

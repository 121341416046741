import { MergedProduct } from '../../../types/formTypes';
import { RibbingAttributes as ra } from 'product-validator';

// const getRibbingPrice = (
//   use: string,
//   patternExists: boolean,
//   width?: ra.Size
// ) => {
//   const useKey = use in ra.Uses ? use : 'Other';
//   let price = 0.0;

//   switch (useKey) {
//     case ra.Uses.Collars:
//     case ra.Uses.Cuffs:
//     case 'Other':
//       if (patternExists) price = 0.5;
//       else price = 0.35;
//       break;
//     case ra.Uses.Waistbands:
//       if (patternExists) price = 1;
//       else price = 0.75;
//       break;
//   }

//   if (width === ra.Size.Double) price *= 2;

//   return price;
// };

// export const ribbingPriceSuggestion = (
//   sellingUnit: ra.SellingUnit,
//   use: string,
//   width?: ra.Size,
//   pattern?: string,
//   imperfections?: string[],
//   packSize?: number
// ) => {
//   const patternExists = pattern && pattern !== '' ? true : false;
//   const imperfection = imperfections && imperfections.length > 0 ? 0.05 : 0;
//   let price = getRibbingPrice(use, patternExists, width);

//   switch (sellingUnit) {
//     case ra.SellingUnit.Individual:
//       price -= imperfection;
//       break;
//     case ra.SellingUnit['Pack']:
//       price = (price - imperfection) * (packSize ?? 1) * 0.8;
//       price = Math.round(2 * price) / 2;
//       break;
//     case ra.SellingUnit['By Meter']:
//       patternExists ? (price = 1.0) : (price = 1.25);
//       price -= imperfection * 2;
//   }

//   return price;
// };

// export const ribbingPriceSuggestionFromForm = (ribbing: MergedProduct) => {
//   if (ribbing.sellingUnit) {
//     let packSize = Number(ribbing.packSize);

//     return ribbingPriceSuggestion(
//       ribbing.sellingUnit as ra.SellingUnit,
//       ribbing.use ?? '',
//       ribbing.size,
//       ribbing.pattern,
//       ribbing.imperfections?.[0],
//       isNaN(packSize) ? undefined : packSize
//     );
//   }

//   return undefined;
// };

const getRibbingPrice = (
  uses: string[],
  patternExists: boolean,
  width?: ra.Size
) => {
  let currentPrice = 0.0;
  for (let i = 0; i < uses.length; i++) {
    const useKey = uses[i] in ra.Uses ? uses[i] : 'Other';
    let price = 0.0;

    switch (useKey) {
      case ra.Uses.Collars:
      case ra.Uses.Cuffs:
      case 'Other':
        if (patternExists) price = 0.5;
        else price = 0.35;
        break;
      case ra.Uses.Waistbands:
        if (patternExists) price = 1;
        else price = 0.75;
        break;
    }
    if (price > currentPrice) {
      currentPrice = price;
    }
  }

  if (width === ra.Size.Double) currentPrice *= 2;

  return currentPrice;
};

export const ribbingPriceSuggestion = (
  sellingUnit: ra.SellingUnit,
  uses: string[],
  width?: ra.Size,
  pattern?: string,
  imperfections?: string[],
  packSize?: number
) => {
  const patternExists = pattern && pattern !== '' ? true : false;
  const imperfection = imperfections && imperfections.length > 0 ? 0.05 : 0;
  let price = getRibbingPrice(uses, patternExists, width);

  switch (sellingUnit) {
    case ra.SellingUnit.Individual:
      price -= imperfection;
      break;
    case ra.SellingUnit['Pack']:
      price = (price - imperfection) * (packSize ?? 1) * 0.8;
      price = Math.round(2 * price) / 2;
      break;
    case ra.SellingUnit['By Meter']:
      patternExists ? (price = 1.0) : (price = 1.25);
      price -= imperfection * 2;
  }

  return price;
};

export const ribbingPriceSuggestionFromForm = (ribbing: MergedProduct) => {
  if (ribbing.sellingUnit) {
    let packSize = Number(ribbing.packSize);

    return ribbingPriceSuggestion(
      ribbing.sellingUnit as ra.SellingUnit,
      ribbing.uses ?? [],
      ribbing.size,
      ribbing.pattern,
      ribbing.imperfections?.[0],
      isNaN(packSize) ? undefined : packSize
    );
  }

  return undefined;
};

import {
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  VStack,
  HStack,
  RadioGroup,
  Flex
} from '@chakra-ui/react';
import React from 'react';
import {
  getNestedValidationError,
  isTouched
} from '../../../utils/validationUtils';
import { ErrorMessage, OtherTextInput, WrappedInputGroup } from '../../general';
import { IndexedAppFieldComponentProps } from '../../../types/appFieldTypes';
import { RadioButton } from '../../general/ButtonRadio';
import { ProductTypes, toFraction } from 'product-validator';
import update from 'immutability-helper';

const WIDTH_OPTIONS = [
  { inches: 0.25, mm: '6' },
  { inches: 0.375, mm: '9' },
  { inches: 0.5, mm: '12' },
  { inches: 0.625, mm: '15' },
  { inches: 0.75, mm: '19' },
  { inches: 1, mm: '25' },
  { inches: 1.25, mm: '31' },
  { inches: 1.5, mm: '38' },
  { inches: 2, mm: '50' },
  { inches: 2.25, mm: '57' },
  { inches: 2.5, mm: '63' },
  { inches: 3, mm: '76' },
  { inches: 4, mm: '101' },
  { inches: 5, mm: '127' }
];

const splitFractionToNumbers = (val?: string): number[] => {
  const fraction = val ?? '';
  const fractions = fraction.split('/');
  let num = parseInt(fractions[0]);
  let denom = parseInt(fractions[1]);
  num = isNaN(num) ? 0 : num;
  denom = isNaN(denom) ? 1 : denom;

  return [num, denom];
};

// e.g. convert '2 1/2' into 2.5
const convertFractionStringToDecimal = (val: string): number => {
  const vals = val.split(' ');
  let decimal = 0;

  if (vals.length > 1) {
    let whole = parseInt(vals[0]);
    whole = isNaN(whole) ? 0 : whole;

    const fractions = splitFractionToNumbers(vals[1]);
    const num = fractions[0];
    const denom = fractions[1];

    decimal = whole + num / denom;
  } else if (vals[0].includes('/')) {
    const fractions = splitFractionToNumbers(vals[0]);
    const num = fractions[0];
    const denom = fractions[1];

    decimal = num / denom;
  } else {
    let whole = parseInt(vals[0]);
    decimal = isNaN(whole) ? 0 : whole;
  }

  return decimal;
};

const displayWidthPreview = (inches: string): string => {
  const inchesNum = parseFloat(inches);
  if (isNaN(inchesNum)) return '" (mm)';
  let mm = inchesNum * 25.4;
  if (1 - (mm % 1) < 0.1) mm = Math.round(mm);
  else mm = Math.floor(mm);

  const fraction = toFraction(inchesNum, true);
  return `${fraction}" (${mm}mm)`;
};

const getRadioButtonText = (
  unit: string,
  option: { inches: number; mm: string }
): string => {
  if (unit === 'Metric') return `${option.mm}mm`;
  else return `${toFraction(option.inches, true)}"`;
};

const convertInputUnit = (unit: string, input?: string): string => {
  if (!input) return '';
  if (unit === 'Metric') {
    let mm = convertFractionStringToDecimal(input) * 25.4;
    if (1 - (mm % 1) < 0.1) mm = Math.round(mm);
    else mm = Math.floor(mm);
    return mm.toString();
  } else {
    let val = parseFloat(input);
    val = isNaN(val) ? 0 : val / 25.4;
    return toFraction(val);
  }
};

export const WidthMixedField: React.FC<IndexedAppFieldComponentProps> = ({
  setFormField,
  currentValue,
  validationErrors,
  idx = 0
}) => {
  let widthErr = getNestedValidationError(`widthIn`, validationErrors);
  let widthTouched = isTouched(
    currentValue.widthRadio[idx],
    currentValue.widthOther[idx]
  );

  const unitMsg =
    currentValue.productType === ProductTypes.Trim ||
    currentValue.productType === ProductTypes['Bias Tape']
      ? 'width (in or mm)'
      : 'width (in)';

  const updateWidthOther = (val: string) => {
    let inches = 0;
    let strInches = '';
    if (currentValue.widthMixedUnit === 'Imperial') {
      if (val !== '') {
        inches = convertFractionStringToDecimal(val);
        strInches = inches.toFixed(6);
      }
    } else {
      inches = parseFloat(val);
      if (!isNaN(inches)) {
        inches = inches / 25.4;
        strInches = inches.toFixed(6);
      }
    }

    setFormField((oldForm) =>
      update(oldForm, {
        widthIn: {
          $set: strInches
        },
        widthOther: {
          [idx]: {
            $set: val
          }
        }
      })
    );
  };

  // Custom radio group: for specific button width
  const widthMixedUnitRadio = (
    <VStack>
      <FormLabel>Select a measurement unit.</FormLabel>
      <RadioGroup
        value={currentValue.widthMixedUnit ?? ''}
        onChange={(v) =>
          setFormField((oldForm) =>
            update(oldForm, {
              widthMixedUnit: {
                $set: v as string
              },
              widthOther: {
                [idx]: {
                  $set: convertInputUnit(v as string, oldForm.widthOther?.[idx])
                }
              }
            })
          )
        }
      >
        <WrappedInputGroup sx={{ gap: '0.25rem' }}>
          {['Imperial', 'Metric'].map((option) => (
            <RadioButton
              key={option}
              value={option}
              minW="7rem"
              fontSize="1rem"
            >
              {option}
            </RadioButton>
          ))}
        </WrappedInputGroup>
      </RadioGroup>
    </VStack>
  );

  // Custom radio group: for complex option mapping
  const WidthMixedRadio = (
    <RadioGroup
      value={currentValue.widthRadio[idx]}
      onChange={(v) =>
        setFormField((oldForm) =>
          update(oldForm, {
            widthRadio: {
              [idx]: { $set: v.toString() }
            }
          })
        )
      }
    >
      <WrappedInputGroup numColumns={3} sx={{ gap: '0.25rem' }}>
        {WIDTH_OPTIONS.map((option) => (
          <RadioButton
            key={option.mm}
            value={option.inches.toString()}
            isDisabled={!!currentValue.widthOther?.[idx]}
            minW="7rem"
          >
            {getRadioButtonText(currentValue.widthMixedUnit ?? '', option)}
          </RadioButton>
        ))}
      </WrappedInputGroup>
    </RadioGroup>
  );

  const WidthMixedOther = (
    <HStack>
      <OtherTextInput
        onChange={(e) => updateWidthOther(e.target.value)}
        value={currentValue.widthOther?.[idx] ?? ''}
        placeholder="e.g. 1 1/2"
      />
      <Flex minW="10rem">
        {`
        ${
          currentValue.widthIn && currentValue.widthOther?.[idx] !== ''
            ? displayWidthPreview(currentValue.widthIn)
            : unitMsg
        }`}
      </Flex>
    </HStack>
  );

  return (
    <FormControl as="fieldset" isInvalid={widthErr.length > 0 && widthTouched}>
      <FormLabel as="legend">
        <Heading>What's the width?</Heading>
      </FormLabel>
      <VStack flexDir="column" alignItems="flex-start" spacing="6" maxW="45rem">
        {(currentValue.productType === ProductTypes.Trim &&
          widthMixedUnitRadio) ||
          (currentValue.productType === ProductTypes['Bias Tape'] &&
            widthMixedUnitRadio) ||
          (currentValue.productType === ProductTypes.Elastic &&
            widthMixedUnitRadio)}
        {WidthMixedRadio}
        {WidthMixedOther}
      </VStack>
      <ErrorMessage errMsgs={widthErr} />
      {currentValue.widthMixedUnit === 'Imperial' ? (
        <FormHelperText>
          Choose one or type in a fraction. Only fractions are accepted.
        </FormHelperText>
      ) : (
        <FormHelperText>Choose one or type in a value.</FormHelperText>
      )}
    </FormControl>
  );
};

import {
  isFieldVarianted,
  ZipperAttributes as za,
  FabricAttributes as fa,
  ThreadAttributes as ta,
  BiasTapeAttributes as bta,
  ElasticAttributes as ea,
  TrimAttributes as tra,
  OtherSupplyAttributes as osa,
  ProductTypes
} from 'product-validator';
import { appFields } from '../pages/ProductEntry';
import { FormFieldState } from '../types/formTypes';
import { AppField } from '../types/appFieldTypes';

const fabricIsVarianted = (formFieldState: FormFieldState) =>
  isFieldVarianted('widthCM', formFieldState.variantedFields) ||
  isFieldVarianted('lengthM', formFieldState.variantedFields);

export const canNavigate = (formFields: FormFieldState, field: string) => {
  if (!(field in appFields)) return false;
  if (formFields.productType === ProductTypes.Fabric) {
    switch (field) {
      case 'Bundle or Roll':
      case 'Stretch':
      case 'Knit or Woven':
      case 'Opacity, Weight, and Drape':
      case 'Feel':
      case 'Fiber Content':
      case 'Name':
      case 'Quality':
      case 'Common Uses':
      case 'Colours':
      case 'Camera':
        return true;
      case 'Stretch Percentage':
        return (
          (formFields.stretch === fa.Stretch['2-way'] ||
            formFields.stretch === fa.Stretch['4-way'] ||
            formFields.stretchOther === fa.Stretch['4-way'] ||
            formFields.stretchOther === fa.Stretch['2-way']) &&
          !formFields.slightStretch
        );
      case 'Has Variants':
        return formFields.bundleOrRoll === fa.BundleOrRoll.Bundle;
      case 'Dimensions':
        return (
          formFields.bundleOrRoll === fa.BundleOrRoll.Roll ||
          (formFields.bundleOrRoll === fa.BundleOrRoll.Bundle &&
            !fabricIsVarianted(formFields))
        );
      case 'Pattern + Imperfections':
      case 'Price':
        return !fabricIsVarianted(formFields);
      case 'Pattern':
      case 'Variant Dimensions':
      case 'Variant Price':
        return fabricIsVarianted(formFields);
      case 'Knit or Weave Type':
        if (
          formFields.content?.contentType === fa.ContentType.EstimatedSpecific
        ) {
          return !!formFields.content?.estimatedSpecificContent?.primary;
        } else if (
          formFields.content?.contentType === fa.ContentType.EstimatedGeneral
        ) {
          return (
            !!formFields.content.estimatedGeneralContent ||
            !!formFields.content.estimatedGeneralContentOther
          );
        } else if (formFields.content?.contentType === fa.ContentType.Known) {
          return (
            !!formFields.content?.knownContent?.[0]?.content ||
            !!formFields.content?.knownContentOther?.[0]?.content
          );
        } else {
          return false;
        }
      default:
        return false;
    }
  } else if (formFields.productType === ProductTypes.Zippers) {
    switch (field) {
      // case 'Zipper Dimensions':
      //   return formFields.sellingUnit !== za.SellingUnit['Tape By The Meter'];
      case 'Zipper Dimensions':
        return (
          formFields.sellingUnit === za.SellingUnit.Individual ||
          formFields.sellingUnit === za.SellingUnit.Pack
        );
      case 'Size':
        return (
          formFields.sellingUnit === za.SellingUnit.Hardware &&
          formFields.zipperHardwareType === za.ZipperHardwareType.Slider
        );
      case 'Hardware Type':
      case 'Hardware Unit':
      case 'Hardware Material':
        return formFields.sellingUnit === za.SellingUnit.Hardware;
      case 'Supplies':
      case 'Selling Unit':
      case 'Additional Information':
      case 'Quantity':
      case 'Colours':
      case 'Price':
      case 'Camera':
        return true;
      case 'Zipper Type + Closure':
      case 'Common Uses':
        return (
          formFields.sellingUnit === za.SellingUnit.Individual ||
          formFields.sellingUnit === za.SellingUnit['Pack']
        );
      case 'Brand, Imperfections, and Quality':
        return (
          formFields.sellingUnit === za.SellingUnit.Individual ||
          formFields.sellingUnit === za.SellingUnit['Pack'] ||
          formFields.sellingUnit === za.SellingUnit.Hardware
        );
      case 'Zipper Type':
      case 'Imperfections + Quality':
        return formFields.sellingUnit === za.SellingUnit['Tape By The Meter'];
      default:
        return false;
    }
  } else if (formFields.productType === ProductTypes.Ribbing) {
    switch (field) {
      case 'Supplies':
      case 'Selling Unit':
      case 'Quantity':
      case 'Dimensions':
      case 'Material and Stretch':
      case 'Ribbing Common Use':
      case 'Pattern + Imperfections':
      case 'Price':
      case 'Colours':
      case 'Camera':
        return true;
      default:
        return false;
    }
  } else if (formFields.productType === ProductTypes['Sewing Patterns']) {
    switch (field) {
      case 'Supplies':
      case 'Sewing Pattern Type':
      case 'Sewing Pattern Brand Number':
      case 'Quantity':
      case 'Price':
      case 'Camera':
        return true;
      default:
        return false;
    }
  } else if (formFields.productType === ProductTypes.Buttons) {
    switch (field) {
      case 'Supplies':
      case 'Selling Unit':
      case 'Button Size':
      case 'Quantity':
      case 'Button Type':
      case 'Button Material':
      case 'Button Design':
      case 'Common Uses':
      case 'Price':
      case 'Colours':
      case 'Camera':
        return true;
      default:
        return false;
    }
  } else if (formFields.productType === ProductTypes.Thread) {
    switch (field) {
      case 'Supplies':
      case 'Selling Unit':
      case 'Thread Type':
      case 'Thread Size':
      case 'Uses + Imperfections':
      case 'Quantity':
      case 'Price':
      case 'Colours':
      case 'Camera':
        return true;
      case 'Thread Material':
        return formFields.threadType !== ta.ThreadType.Silk &&
          formFields.threadType !== ta.ThreadType['Wooly Nylon']
          ? true
          : formFields.threadTypeOther &&
              formFields.threadTypeOther !== '' &&
              formFields.threadTypeOther !== ta.ThreadType.Silk &&
              formFields.threadTypeOther !== ta.ThreadType['Wooly Nylon'];
      default:
        return false;
    }
  } else if (formFields.productType === ProductTypes.Trim) {
    switch (field) {
      case 'Dimensions + Quantity':
        return formFields.sellingUnit !== tra.SellingUnit.Pack;
      case 'Dimensions':
        return formFields.sellingUnit === tra.SellingUnit.Pack;
      case 'Quantity':
        return formFields.sellingUnit === tra.SellingUnit.Pack;
      case 'Supplies':
      case 'Selling Unit':
      case 'Camera':
      case 'Type + Weave':
      case 'Design + Shape':
      case 'Trim Qualities':
      case 'Trim Content':
      case 'Colours':
      case 'Uses + Imperfections':
      case 'Price':
        return true;
      default:
        return false;
    }
  } else if (formFields.productType === ProductTypes.Elastic) {
    switch (field) {
      case 'Dimensions + Quantity':
        return formFields.sellingUnit !== ea.SellingUnit['Pack'];
      case 'Dimensions':
        return formFields.sellingUnit === ea.SellingUnit['Pack'];
      case 'Supplies':
      case 'Selling Unit':
      case 'Camera':
      case 'Elastic Type':
      case 'Qualities + Imperfections':
      case 'Colours':
      case 'Price':
        return true;
      case 'Quantity':
        return formFields.sellingUnit === ea.SellingUnit['Pack'];
      default:
        return false;
    }
  } else if (formFields.productType === ProductTypes['Bias Tape']) {
    switch (field) {
      case 'Supplies':
      case 'Selling Unit':
      case 'Camera':
      case 'Dimensions + Quantity':
      case 'Material + Type':
      case 'Qualities + Imperfections':
      case 'Colours':
      case 'Price':
        return true;
      case 'Brand + Additional Info':
        return formFields.sellingUnit === bta.SellingUnit.Package;
      default:
        return false;
    }
  } else if (formFields.productType === ProductTypes['Other Supply']) {
    switch (field) {
      case 'Supplies':
      case 'Supply Type':
      case 'Name':
      case 'Dimensions':
      case 'Imperfections':
      case 'Additional Info':
      case 'Colours':
      case 'Price':
      case 'Camera':
        return true;
      case 'Quantity':
        return formFields.sellingUnit !== osa.SellingUnit['By Meter'];
      case 'Weight':
        return (
          formFields.sellingUnit === osa.SellingUnit['By Meter'] ||
          formFields.sellingUnit === osa.SellingUnit['By Weight']
        );
      case 'Weight + Size':
        return (
          formFields.sellingUnit === osa.SellingUnit.Individual ||
          formFields.sellingUnit === osa.SellingUnit.Pack
        );
      case 'Selling Unit':
        return formFields.otherSupplyType !==
          osa.otherSupplyType['Sewing Tool'] &&
          formFields.otherSupplyType !== osa.otherSupplyType.Books &&
          formFields.otherSupplyType !== osa.otherSupplyType.Sequins
          ? true
          : formFields.otherSupplyTypeOther &&
              formFields.otherSupplyTypeOther !== '' &&
              formFields.otherSupplyTypeOther !==
                osa.otherSupplyType['Sewing Tool'] &&
              formFields.otherSupplyTypeOther !== osa.otherSupplyType.Sequins &&
              formFields.otherSupplyTypeOther !== osa.otherSupplyType.Books;
      default:
        return false;
    }
  } else {
    switch (field) {
      case 'Supplies':
      case 'Colours':
      case 'Camera':
        return true;
      default:
        return false;
    }
  }
};

/**
 * This function uses "function currying" to create a link generator function.
 * The currying is used to enclose the state of the form with the generator.
 * The link generator takes in the current url and returns the next and previous url.
 * @param formFields current state of the form
 * @returns a link generator function
 */
export const linkGeneratorWrapper = (
  formFields: FormFieldState,
  fieldList: AppField[]
) => {
  return (currentPath: string) => {
    // Get the index of the current field
    const currentPathIndex = fieldList.findIndex((e) => e.path === currentPath);
    let prevPath = '';
    let nextPath = '';

    // Get a valid next path
    for (let i = currentPathIndex + 1; i < fieldList.length; i++) {
      if (canNavigate(formFields, fieldList[i].name)) {
        nextPath = fieldList[i].path;
        break;
      }
    }

    // This is for the edge case for the image editor page
    // because it's the last element in appFields.
    if (!nextPath) nextPath = `upload_product`;

    // Get next valid prev path
    for (let i = currentPathIndex - 1; i >= 0; i--) {
      if (canNavigate(formFields, fieldList[i].name)) {
        prevPath = fieldList[i].path;
        break;
      }
    }
    return { prevPath, nextPath };
  };
};

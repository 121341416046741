import {
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  VStack
} from '@chakra-ui/react';
import { ProductTypes } from 'product-validator';
import React from 'react';
import {
  getNestedValidationError,
  isTouched
} from '../../../utils/validationUtils';
import { ErrorMessage, NumberInput } from '../../general';
import { IndexedAppFieldComponentProps } from '../../../types/appFieldTypes';
import update from 'immutability-helper';

export const HeightField: React.FC<IndexedAppFieldComponentProps> = ({
  setFormField,
  currentValue,
  validationErrors,
  idx = 0
}) => {
  let otherField = '';
  let heightErrParam = '';
  let heightMsg = '';
  let sideText = '';
  let heightTouched = false;

  switch (currentValue.productType) {
    case ProductTypes['Other Supply']:
      heightTouched = isTouched(currentValue.heightCM);
      otherField = 'heightCM';
      heightErrParam = 'heightCM';
      heightMsg = `What's the height in centimeters?`;
      sideText = 'height (CM)';
      break;
  }

  const heightErr = getNestedValidationError(heightErrParam, validationErrors);

  const getCurrentInput = (): string => {
    if (currentValue.productType === ProductTypes['Other Supply'])
      return (currentValue.heightCM ?? '').toString();
    return '';
  };

  const numberInputProps = {
    size: 'lg',
    stackProps: { w: '80%' },
    w: '80%'
  };

  return (
    <FormControl
      as="fieldset"
      isInvalid={heightErr.length > 0 && heightTouched}
    >
      <FormLabel as="legend">
        <Heading>{heightMsg}</Heading>
        {currentValue.productType === ProductTypes['Other Supply'] ? (
          <FormHelperText>(Optional)</FormHelperText>
        ) : (
          <></>
        )}
      </FormLabel>
      <VStack spacing="3" alignItems="left">
        <NumberInput
          id="heightCM"
          onChange={(s) => {
            setFormField((oldForm) => {
              return update(oldForm, { [otherField]: { $set: s } });
            });
          }}
          value={getCurrentInput()}
          text={sideText}
          {...numberInputProps}
        />
      </VStack>
      <ErrorMessage errMsgs={heightErr} />
      <FormHelperText>Enter numbers.</FormHelperText>
    </FormControl>
  );
};

import {
  KnownContent,
  ContentMix,
  ValidationError,
  Fabric,
  FabricSchema,
  FabricAttributes,
  getSchema,
  FabProduct,
  ProductTypes
} from 'product-validator';
import { FormFieldState, ValidationErrors } from '../types/formTypes';
import { formToProduct } from './formUtils';

/**
 * This function check if a user has interacted with a set of fields.
 * @param fields fields to check if they have been touched
 * @returns true if field has been touched
 */
export const isTouched = (
  ...fields: (
    | FormFieldState[keyof FormFieldState]
    | KnownContent[]
    | ContentMix
    | string[]
    | string
    | number
    | undefined
    | null
  )[]
) => {
  return fields.some((val) => val !== undefined);
};

/**
 * This function converts a yup Validation error into an keyable error
 * object.
 * @param err A validation error object from the yup library
 * @returns an object where the keys correspond to form fields
 */
export const yupErrToValidationErrors = (err: ValidationError) => {
  const newErr: ValidationErrors = {};
  err.inner.forEach((v) => {
    if (v.path) {
      // For paths like "feel[0]" and "content.knownContent"
      // this code adds a lookup key using root field.
      // ex "feel[0]" adds key for "feel" and "feel[0]"
      let path = v.path;
      while (path) {
        newErr[path] = (newErr[path] ?? []).concat(v.errors);
        const lastIdxOfDelimiter = Math.max(
          path.lastIndexOf('['),
          path.lastIndexOf('.'),
          0
        );
        path = path.slice(0, lastIdxOfDelimiter);
      }
    } else {
      newErr['noPathErr'] = (newErr['noPathErr'] ?? []).concat(v.errors);
    }
  });
  return newErr;
};

export const getNestedValidationError = (
  fields: string | string[],
  errors?: ValidationErrors,
  concatKey = false // concatenate key -> e.g. Uses is not defined%uses = 'Uses is not defined', 'uses'
): string[] => {
  if (errors) {
    if (typeof fields === 'string') {
      return errors[fields] ?? [];
    } else {
      const errMsgs: string[] = [];
      for (const field of fields) {
        let error = errors[field]?.[0];
        if (error) {
          if (concatKey) error = `${error}%${field}`;
          errMsgs.push(error);
        }
      }
      return errMsgs;
    }
  }
  return [];
};

/**
 * This method is for manual validation of form properties that do not get
 * validated by a corresponding product schema, and should not be added to
 * said schema.
 *
 * @param formState
 * @returns ValidationErrors instance
 */
const validateClientOnlyProps = (
  formState: FormFieldState
): ValidationErrors => {
  let validationErrors: ValidationErrors = {};

  // Validate fabric unboxing date
  if (formState.unboxingDateExists === 'Yes' && !formState.unboxingDate)
    validationErrors = {
      ...validationErrors,
      unboxingDate: ['No unboxing date was selected']
    };

  return validationErrors;
};

interface validateFormType {
  (formState: FormFieldState): Promise<{
    product: FabProduct;
    validationErrors: ValidationErrors;
  }>;
}

export const validateForm: validateFormType = async (
  formState: FormFieldState
) => {
  const fabProduct = formToProduct(formState);
  // Needs to use a schema even at the beginning of the form
  const fabProductSchema = getSchema(
    formState.productType ?? ProductTypes.Fabric
  );

  const castProduct = (await fabProductSchema.cast(fabProduct, {
    assert: false,
    stripUnknown: true,
    context: { product: fabProduct }
  })) as FabProduct;

  let validationErrors: ValidationErrors = validateClientOnlyProps(formState);

  // Validate fabric object
  try {
    await fabProductSchema.validate(fabProduct, {
      abortEarly: false,
      context: { product: fabProduct }
    });
    return { product: castProduct, validationErrors: validationErrors };
  } catch (err) {
    validationErrors = {
      ...validationErrors,
      ...yupErrToValidationErrors(err)
    };
    if (err.name === 'ValidationError') {
      return {
        product: castProduct,
        validationErrors: validationErrors
      };
    }
  }
  throw new Error('Validation Failed');
};

/**
 * This method is specfically for validating the length of a fabric.
 * The particular use case is when converting a roll to a bundle.
 *
 * @param length represents the length value of a fabric
 * @returns an error message
 */
export const validateLength = async (length?: string) => {
  try {
    const fabric: Partial<Fabric> = {
      bundleOrRoll: FabricAttributes.BundleOrRoll.Bundle,
      lengthM: length ? [parseFloat(length)] : undefined
    };

    await FabricSchema.validateAt('lengthM', fabric, {
      context: { product: fabric }
    });
    return undefined;
  } catch (err) {
    if (err.name === 'ValidationError') return err.message;
  }
};

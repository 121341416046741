import { VStack } from '@chakra-ui/react';
import React from 'react';
import { Divider } from '../../../general/Divider';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { LengthField } from '../LengthField';
import { TeethSizeField } from './TeethSizeField';
import { ZipperAttributes as za } from 'product-validator';

export const ZipperDimensionsField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  return (
    <VStack spacing="4">
      {(currentValue.sellingUnit === za.SellingUnit.Individual ||
        currentValue.sellingUnit === za.SellingUnit['Pack']) && (
        <>
          <LengthField
            currentValue={currentValue}
            setFormField={setFormField}
            validationErrors={validationErrors}
            toNextPage={toNextPage}
          />
          <Divider />
        </>
      )}
      <TeethSizeField
        currentValue={currentValue}
        setFormField={setFormField}
        validationErrors={validationErrors}
        toNextPage={toNextPage}
      />
    </VStack>
  );
};

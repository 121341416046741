import { useHistory } from 'react-router-dom';
import {
  Heading,
  VStack,
  Text,
  Button,
  FormControl,
  Input,
  HStack,
  Img,
  useToast,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  FormHelperText
} from '@chakra-ui/react';
import { OtherTextInput } from '../general';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import base from '../../api/airtable';
import { FieldSet, Records } from 'airtable';

interface UpdateBoxStatusProps {
  inventorySupplyObject: any;
  setInventorySupplyObject: (inventorySupplyObject: object) => void;
  inventorySupplies: any;
  setInventorySupplies: (inventorySupplies: Records<FieldSet>) => void;
  selectedInventorySupply: any;
  setSelectedInventorySupply: (selectedInventorySupply: any) => void;
}

export const UpdateBoxStatus: React.FC<UpdateBoxStatusProps> = ({
  inventorySupplyObject,
  setInventorySupplyObject,
  inventorySupplies,
  setInventorySupplies,
  selectedInventorySupply,
  setSelectedInventorySupply
}) => {
  const history = useHistory();
  const [images, setImages] = useState<any>([]);
  const [imageURLs, setImageURLs] = useState<any>([]);
  const [uploadSuccess, setUploadSuccess] = useState<any>(0);
  const [uploadingState, setUploadingState] = useState<any>(0);

  const toast = useToast();

  useEffect(() => {
    setSelectedInventorySupply(
      inventorySupplies.find((inventorySupply: any) => {
        return inventorySupply.fields['Box ID'] === inventorySupplyObject.boxID;
      })
    );
  }, [inventorySupplyObject.boxID]);

  useEffect(() => {
    if (images.length < 1) return;
    let newImageUrls: string[] = [];
    images.forEach((image: any) =>
      newImageUrls.push(URL.createObjectURL(image))
    );
    setImageURLs(newImageUrls);
  }, [images]);

  const onImageChange = (e: any) => {
    setImages([...images, ...e.target.files]);
  };

  const updateStatus = async (e: any) => {
    e.preventDefault();

    setUploadingState(1);

    let oldStatus = selectedInventorySupply?.fields.Status;
    let newStatus = '';

    switch (oldStatus) {
      case 'New Box':
        newStatus = 'General Sort';
        break;
      case 'General Sort':
        newStatus = 'Measured';
        break;
      case 'Measured':
        newStatus = 'Input';
        break;
      default:
        newStatus = oldStatus;
        break;
    }

    let imageDestinationAttribute = 'General Sort Photo';
    let noteDestinationAttribute = 'General Sort Notes';

    if (newStatus === 'Measured') {
      imageDestinationAttribute = 'Measured Photo';
      noteDestinationAttribute = 'Measured Notes';
    }

    let cloudinaryURLs: string[] = [];

    for (const image of images) {
      const formData = new FormData();
      formData.append('file', image);
      formData.append('upload_preset', 'unsignedPreset');

      if (images === undefined) {
        base('Supplies - General').update(
          [
            {
              id: selectedInventorySupply?.id,
              fields: {
                Status: newStatus,
                [noteDestinationAttribute]: selectedInventorySupply?.Notes
              }
            }
          ],
          (err: any, record: any) => {
            if (err) {
              setUploadingState(0);
              console.error(err);
              return;
            } else {
              setUploadingState(0);
              setUploadSuccess(1);
              return;
            }
          }
        );
      } else {
        // send image to cloudinary then have airtable grab that image and put it into the appropriate attribute(column)
        await axios
          .post(
            'https://api.cloudinary.com/v1_1/dex9qk2pk/image/upload',
            formData
          )
          .then((response) => {
            cloudinaryURLs.push(response.data.url);
          });
      }
    }
    let airtableAttachmentObject = cloudinaryURLs.map((cloudinaryURL) => {
      return { url: cloudinaryURL };
    });

    base('Supplies - General').update(
      [
        {
          id: selectedInventorySupply?.id,
          fields: {
            Status: newStatus,
            [noteDestinationAttribute]: selectedInventorySupply?.Notes,
            [imageDestinationAttribute]: airtableAttachmentObject as any
          }
        }
      ],
      (err: any, record: any) => {
        if (err) {
          setUploadingState(0);
          console.error(err);
          toast({
            title: `error occured`,
            status: 'error',
            isClosable: true
          });
          return;
        } else {
          toast({
            title: 'Updated Successfully',
            status: 'success',
            isClosable: true
          });
          setUploadingState(0);
          setUploadSuccess(1);
          return;
        }
      }
    );
  };

  // There is a duplicate in SummaryPage.tsx in inventorySupplies components folder
  // selectedInventorySupply is also set to null here
  const resetInventorySupply = () => {
    let newInventorySupplyPath = '/inventory_supplies/start_or_update';
    history.push(newInventorySupplyPath);
    setInventorySupplyObject({
      isNewBox: 'no',
      boxID: '',
      supplyType: '',
      boxSize: '',
      trimType: '',
      everythingElseCategory: '',
      sewingCategory: '',
      craftCategory: '',
      otherCategory: ''
    });
    setSelectedInventorySupply(null);
    base('Supplies - General')
      .select({ view: 'Full List' })
      .eachPage((records, fetchNextPage) => {
        setInventorySupplies(records);
        fetchNextPage();
      });
  };

  return (
    <FormControl as="fieldset">
      <VStack spacing="8" alignItems="center">
        <Heading>Update Box Status</Heading>
        <Text fontSize="lg">
          Selected Box ID: {selectedInventorySupply?.fields['Box ID']}
        </Text>
        <Text fontSize="lg">
          Selected Box Status: {selectedInventorySupply?.fields.Status}
        </Text>
        <HStack>
          <Heading size="md">Note:</Heading>
          <OtherTextInput
            placeholder="Enter a note"
            value={selectedInventorySupply?.Notes}
            onChange={(e) => {
              const updatedSelectedInventorySupply = {
                ...selectedInventorySupply,
                Notes: e.target.value
              };
              setSelectedInventorySupply(updatedSelectedInventorySupply);
            }}
          />
        </HStack>
        <HStack>
          <Input
            type="file"
            name="image"
            multiple
            accept="image/*"
            onChange={onImageChange}
          ></Input>
        </HStack>
        <FormHelperText>Optional</FormHelperText>
        {images ? (
          imageURLs.map((imageSrc: any, index: any) => (
            <Img key={index} src={imageSrc} alt={`Supply ${index}`} />
          ))
        ) : (
          <></>
        )}
        {uploadingState === 1 ? (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        ) : (
          <></>
        )}
        {uploadSuccess === 1 ? (
          <Alert
            status="success"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Box Updated
            </AlertTitle>
          </Alert>
        ) : (
          <></>
        )}
        <Button onClick={updateStatus}>Update</Button>
        {uploadSuccess === 1 ? (
          <Button onClick={resetInventorySupply}>New Supply Entry</Button>
        ) : (
          <></>
        )}
      </VStack>
    </FormControl>
  );
};

import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  VStack,
  Link
} from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  RibbingAttributes as ra,
  ButtonProductAttributes as ba,
  TrimAttributes as tra,
  BiasTapeAttributes as bta,
  ElasticAttributes as ea,
  ProductTypes
} from 'product-validator';
import {
  getNestedValidationError,
  isTouched
} from '../../../../utils/validationUtils';
import { ButtonRadioGroup, ErrorMessage, NumberInput } from '../../../general';
import {
  AppFieldComponent,
  fieldOptions,
  FormFieldState
} from '../../../../types';
import { FieldRowEntries } from '../../productTable/FieldRowEntries';
import { getPath } from '../../productTable/ProductTable';
import { appFields } from '../../../../pages/ProductEntry';
import { AppField } from '../../../../types/appFieldTypes';
import { getPageText } from './getPageText';

const generatePriceSuggestions = (currentValue: FormFieldState): string[] => {
  let steps = [];
  let suggestedPrice = currentValue.priceBase ?? 0;
  let lessThanStep = 2;

  switch (currentValue.productType) {
    case ProductTypes.Fabric:
      const greaterThanStep = 2;
      if (suggestedPrice <= 4) lessThanStep = 1;
      steps = [
        lessThanStep * -2,
        lessThanStep * -1,
        0,
        greaterThanStep,
        greaterThanStep * 2
      ];
      break;
    case ProductTypes.Ribbing:
      if (
        (currentValue.sellingUnit as ra.SellingUnit) === ra.SellingUnit['Pack']
      )
        lessThanStep = 0.25;
      else lessThanStep = 0.1;
      steps = [lessThanStep * -1, 0, lessThanStep];
      break;
    case ProductTypes['Sewing Patterns']:
      lessThanStep = 2;
      steps = [lessThanStep * -1, 0, lessThanStep];
      break;
    case ProductTypes.Buttons:
      if (currentValue.uniqueShape === true) lessThanStep = 0.25;
      else
        switch (currentValue.material) {
          case ba.Material.Plastic:
          case ba.Material.Wood:
          case ba.Material.Paper:
          default:
            lessThanStep = 0.05;
            break;
          case ba.Material['Fabric Covered']:
          case ba.Material['Leather Covered']:
            lessThanStep = 0.1;
            break;
          case ba.Material.Metal:
          case ba.Material.Brass:
          case ba.Material.Shell:
            lessThanStep = 0.25;
            break;
        }
      steps = [lessThanStep * -1, 0, lessThanStep];
      break;
    case ProductTypes.Thread:
      if (!currentValue.priceBase) lessThanStep = 0.1;
      else if (currentValue.priceBase < 1.5) lessThanStep = 0.25;
      else lessThanStep = 0.5;
      steps = [lessThanStep * -1, 0, lessThanStep];
      break;
    case ProductTypes.Trim:
      if (!currentValue.priceBase) lessThanStep = 0.1;
      else if (currentValue.sellingUnit === tra.SellingUnit['By Meter']) {
        if (currentValue.priceBase < 2) lessThanStep = 0.25;
        else lessThanStep = 0.5;
      } else if (currentValue.sellingUnit === tra.SellingUnit.Bundle) {
        if (currentValue.priceBase <= 4) lessThanStep = 0.5;
        else if (currentValue.priceBase <= 10) lessThanStep = 1;
        else lessThanStep = 2;
      } else if (currentValue.sellingUnit === tra.SellingUnit['Roll']) {
        if (currentValue.priceBase <= 10) lessThanStep = 1;
        else if (currentValue.priceBase <= 20) lessThanStep = 2;
        else lessThanStep = 4;
      } else if (currentValue.sellingUnit === tra.SellingUnit.Pack) {
        if (currentValue.priceBase <= 4) lessThanStep = 0.5;
        else if (currentValue.priceBase <= 10) lessThanStep = 1;
        else lessThanStep = 2;
      }
      steps = [lessThanStep * -1, 0, lessThanStep];
      break;
    case ProductTypes.Elastic:
      if (currentValue.sellingUnit === ea.SellingUnit.Roll) {
        // 15% discount to elastic rolls
        suggestedPrice = suggestedPrice * 0.85;
        if (suggestedPrice > 20) {
          lessThanStep = 4;
        } else if (suggestedPrice > 10 && suggestedPrice <= 20) {
          lessThanStep = 2;
        } else {
          lessThanStep = 1;
        }
      } else if (currentValue.sellingUnit === ea.SellingUnit.Bundle) {
        lessThanStep = 0.25;
        if (currentValue.priceBase && currentValue.priceBase <= 0.25)
          lessThanStep = 0.05;
      } else if (currentValue.sellingUnit === ea.SellingUnit['Pack']) {
        if (suggestedPrice > 0.5) {
          lessThanStep = 0.25;
        } else {
          lessThanStep = 0.1;
        }
      } else {
        lessThanStep = 0.1;
      }

      steps = [lessThanStep * -1, 0, lessThanStep];
      break;
    case ProductTypes['Bias Tape']:
      if (currentValue.sellingUnit === bta.SellingUnit.Roll) {
        if (suggestedPrice > 20) {
          lessThanStep = 4;
        } else if (suggestedPrice > 10 && suggestedPrice <= 20) {
          lessThanStep = 2;
        } else {
          lessThanStep = 1;
        }
      } else if (
        currentValue.sellingUnit === bta.SellingUnit.Bundle ||
        currentValue.sellingUnit === bta.SellingUnit.Package
      ) {
        if (suggestedPrice >= 6.5) {
          lessThanStep = 1.0;
        } else if (suggestedPrice > 2.5 && suggestedPrice < 6.5) {
          lessThanStep = 0.5;
        } else {
          lessThanStep = 0.25;
        }
      } else {
        lessThanStep = 0.1;
      }

      steps = [lessThanStep * -1, 0, lessThanStep];
      break;
    default:
      lessThanStep = 0.1;
      steps = [lessThanStep * -1, 0, lessThanStep];
  }

  // Round to 2 decimal place
  const newPrices = steps.map((step) =>
    (Math.round((step + suggestedPrice) * 100) / 100).toString()
  );

  return newPrices;
};

const getMissingFieldLink = (
  currentValue: FormFieldState,
  fieldKey: string
): string => {
  let link = '';

  const errField = Object.values(FieldRowEntries).find(
    (field) => fieldKey === field.valueKey
  );
  if (!errField) return '';

  const linkAppFields: AppField[] = [];
  errField.appFieldKeys.forEach((k) => {
    linkAppFields.push(appFields[k]);
  });

  link = getPath(currentValue, linkAppFields);

  return link;
};

export const PriceField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  product
}) => {
  const priceErr = getNestedValidationError('price', validationErrors);
  const priceTouched = isTouched(
    currentValue.priceRadio,
    currentValue.priceOther
  );

  let requiredFields: string[] = [];
  if (!currentValue.productType) requiredFields = ['productType'];
  else
    requiredFields = fieldOptions[currentValue.productType].priceRequiredFields;

  const { title, sideText, helperText } = getPageText(currentValue, product);

  const numberInputProps = {
    size: 'lg',
    stackProps: { w: '80%' },
    w: '17.5rem'
  };

  const missingFields = getNestedValidationError(
    requiredFields,
    validationErrors,
    true // concatenate key -> e.g. Uses is not defined%uses = 'Uses is not defined', 'uses'
  );

  let errorMsgs: string = '';
  let fieldKeys: string = '';

  if (missingFields.length > 0)
    [errorMsgs, fieldKeys] = missingFields[0].split('%');

  const requiredErrorMsg = (
    <Link
      as={RouterLink}
      to={getMissingFieldLink(currentValue, fieldKeys)}
      mt={3}
      fontSize="sm"
      color="red.500"
    >
      {errorMsgs}
    </Link>
  );

  return (
    <Box>
      <FormControl
        as="fieldset"
        isInvalid={priceErr.length > 0 && priceTouched}
      >
        <FormLabel as="legend">
          <Heading>{title}</Heading>
          <FormHelperText>{helperText}</FormHelperText>
        </FormLabel>
        {missingFields.length > 0 ? (
          requiredErrorMsg
        ) : (
          <>
            {currentValue.productType === ProductTypes['Other Supply'] ? (
              <></>
            ) : (
              <VStack
                flexDir="column"
                alignItems="flex-start"
                spacing="3"
                my="1.5rem"
              >
                <ButtonRadioGroup
                  name="price"
                  value={currentValue.priceRadio}
                  options={generatePriceSuggestions(currentValue)}
                  onChange={(v) => setFormField({ priceRadio: v })}
                  isDisabled={!!currentValue.priceOther}
                >
                  {(value) => `$${parseFloat(value as string).toFixed(2)}`}
                </ButtonRadioGroup>
              </VStack>
            )}

            <NumberInput
              id="price"
              onChange={(s) => {
                setFormField({
                  priceOther: s
                });
              }}
              defaultValue={currentValue.priceOther}
              text={sideText ?? ''}
              {...numberInputProps}
            />
            <ErrorMessage errMsgs={priceErr} />
            <FormHelperText>
              {currentValue.productType === ProductTypes['Other Supply'] ? (
                <>Type in a value</>
              ) : (
                <>Choose one or type in a value.</>
              )}
            </FormHelperText>
          </>
        )}
      </FormControl>
    </Box>
  );
};

import React, { useState } from 'react';
import { searchRoll } from '../../api/rollToBundle';
import {
  Text,
  Flex,
  Heading,
  FormControl,
  FormHelperText,
  FormLabel
} from '@chakra-ui/react';
import { ErrorMessage, OtherTextInput } from '../general';
import { AutoComplete } from './AutoComplete';
import { SimpleProduct } from '../../types/rollToBundleTypes';
import { toNextPage } from '../../components/general/NextFieldNavigator';
import { isTouched } from '../../utils/validationUtils';

const RESPONSIVE_WIDTH = { md: '25rem', lg: '30rem' };

interface SearchFieldProps {
  sku?: string;
  setSku: (sku: string) => void;
  skuErrors?: string;
  mainProduct?: SimpleProduct;
  setMainProduct: (product: SimpleProduct) => void;
  toNextPage: toNextPage;
}

export const SearchField: React.FC<SearchFieldProps> = ({
  sku,
  setSku,
  skuErrors,
  mainProduct,
  setMainProduct,
  toNextPage
}) => {
  const [products, setProducts] = useState<SimpleProduct[]>();

  const getProducts = async (sku: string) => {
    let products: SimpleProduct[] = [];
    if (sku.length > 0 && !skuErrors) products = await searchRoll(sku);
    setSku(sku);
    setProducts(products);
  };

  const setProduct = (product: SimpleProduct) => {
    setMainProduct(product);
    toNextPage();
  };

  const errors = skuErrors ? [skuErrors] : undefined;
  const touched = isTouched(sku);

  return (
    <Flex minW={RESPONSIVE_WIDTH}>
      <FormControl as="fieldset" isInvalid={!!errors && touched}>
        <FormLabel mb={5}>
          <Heading>Search Product</Heading>
          {mainProduct && (
            <Text textColor="gray.500" fontSize="sm">
              Current SKU selected: {mainProduct.sku}
            </Text>
          )}
        </FormLabel>
        <OtherTextInput
          type="number"
          placeholder="Enter SKU"
          autoComplete="off"
          value={sku ?? ''}
          onChange={(e) => {
            getProducts(e.target.value.toString());
          }}
        />
        {!errors && products && (
          <AutoComplete products={products} setMainProduct={setProduct} />
        )}
        <ErrorMessage errMsgs={errors} />
        <FormHelperText>Enter numbers.</FormHelperText>
      </FormControl>
    </Flex>
  );
};

import {
  FabricAttributes,
  isFieldVarianted,
  getNumVariants,
  KnownContent,
  VARIANT_FIELDS,
  ProductTypes,
  ThreadAttributes as ta,
  OtherSupplyAttributes as osa,
  ZipperAttributes as za
} from 'product-validator';
import { FormFieldState, FormVariantableField } from '../types/formTypes';
import {
  priceSuggestionFromForm,
  imperfectionsDiscount
} from './pricing/fabric/price';
import { zipperPriceSuggestionFromForm } from './pricing/supplies/zippers';
import { ribbingPriceSuggestionFromForm } from './pricing/supplies/ribbing';
import { buttonProductPriceSuggestionFromForm } from './pricing/supplies/buttonProduct';
import { threadPriceSuggestionFromForm } from './pricing/supplies/thread';
import { trimPriceSuggestionFromForm } from './pricing/supplies/trim/trim';
import { elasticPriceSuggestionFromForm } from './pricing/supplies/elastic';
import { biasTapePriceSuggestionFromForm } from './pricing/supplies/biasTape';

/**
 * Use this const to return 2 values from checkbox.
 * ex: The checkbox labelled "Lining" has value "Finishing%%Lining"
 */
export const USES_DELINEATOR = '%%';

export const mergeRadio = (radioField?: string, otherField?: string) => {
  if (!!otherField) return otherField;
  return radioField;
};

const mergeCheckbox = (checkboxField?: string[], otherField?: string) => {
  const filteredOther =
    otherField?.split(',').filter((v) => v.length > 0) ?? [];
  return (checkboxField ?? []).concat(filteredOther);
};

/**
 * This is a helper function for merging variantable fields
 * @param a a list whose elements are the first arg of the merge function
 * @param b a list whose elements are the second arg of the merge function
 * @param mergeFn the merge function that will be called on each pair
 * @returns the list of merged values
 */
const mergeVariantedField = (
  a: any[],
  b: any[],
  mergeFn: (a: any, b: any) => any
) => {
  const numElements = Math.max(a.length, b.length);
  const output = [];
  for (let i = 0; i < numElements; i++) output.push(mergeFn(a[i], b[i]));
  return output;
};

export const stringToKnownContent = (text?: string) => {
  const knownContent: KnownContent[] = [];
  if (!text) return knownContent;
  for (const match of Array.from(
    text.matchAll(/(?<amount>\d+)% (?<name>[^,]+)/g)
  )) {
    knownContent.push({ content: match[2], amount: parseInt(match[1]) });
  }
  return knownContent;
};

const setNewPriceBase = (
  form: FormFieldState,
  newPriceBase?: number,
  oldPrice?: number
) => {
  if (newPriceBase && (!form.priceBase || newPriceBase !== oldPrice)) {
    // Only set new default price if base price is changed
    form.priceBase = newPriceBase;
    form.priceRadio = newPriceBase.toString();
  }
};

/**
 * Creates a product object from form. Does not mutate form object
 */
export const formToProduct = (form: Readonly<FormFieldState>) => {
  const product = { ...form };
  product.stretch = mergeRadio(form.stretch, form.stretchOther);
  if (
    form.slightStretch &&
    form.stretch &&
    form.stretch !== FabricAttributes.Stretch.None
  )
    product.stretch = `Slight ${form.stretch}`;
  product.feel = mergeCheckbox(form.feel, form.feelOther);
  product.colour = mergeCheckbox(form.colour, form.colourOther);
  product.pattern = mergeRadio(form.pattern, form.patternOther);

  product.imperfections = mergeVariantedField(
    form.imperfections,
    form.imperfectionOther,
    mergeCheckbox
  );

  product.widthCM = mergeVariantedField(
    form.widthRadio,
    form.widthOther,
    mergeRadio
  );

  product.lengthM = mergeVariantedField(
    form.lengthRadio,
    form.lengthMOther,
    mergeRadio
  );

  product.quantity = mergeVariantedField(
    form.quantityRadio,
    form.quantityOther,
    mergeRadio
  );

  product.material = mergeRadio(form.material, form.materialOther);
  // product.use = mergeRadio(form.use, form.useOther);

  if (product.widthCM.length > 0) product.widthMM = product.widthCM[0];

  product.buttonType = mergeRadio(form.buttonType, form.buttonTypeOther);

  product.design = mergeRadio(form.design, form.designOther);

  product.threadType = mergeRadio(form.threadType, form.threadTypeOther);

  // Trim width: form.widthIn !== product.widthIn
  // form.widthIn is form.widthOther[0] converted to decimal inches
  if (form.widthIn && form.widthIn !== '') product.widthIn = form.widthIn;
  else if (form.widthRadio[0]) product.widthIn = form.widthRadio[0];

  product.trimType = mergeRadio(form.trimType, form.trimTypeOther);

  product.qualities = mergeCheckbox(form.qualities, form.qualityOther);

  product.trimContent = mergeCheckbox(form.trimContent, form.trimContentOther);

  product.elasticType = mergeRadio(form.elasticType, form.elasticTypeOther);

  product.otherSupplyType = mergeRadio(
    form.otherSupplyType,
    form.otherSupplyTypeOther
  );

  product.weightLB = mergeRadio(form.weightRadio, form.weightLB);

  if (form.content) {
    // Deep copy
    product.content = { ...form.content };
    if (
      form.content.estimatedGeneralContent ||
      form.content.estimatedGeneralContentOther
    ) {
      product.content.estimatedGeneralContent = mergeRadio(
        form.content.estimatedGeneralContent,
        form.content.estimatedGeneralContentOther
      );
    }
    if (
      form.content.estimatedSpecificContent ||
      form.content.estimatedSpecificContentOther
    ) {
      product.content.estimatedSpecificContent = {
        ...form.content.estimatedSpecificContent
      };
      product.content.estimatedSpecificContent.primary = mergeRadio(
        form.content.estimatedSpecificContent?.primary,
        form.content.estimatedSpecificContentOther?.primary
      );
      product.content.estimatedSpecificContent.secondary = mergeRadio(
        form.content.estimatedSpecificContent?.secondary,
        form.content.estimatedSpecificContentOther?.secondary
      );
      if (form.content.estimatedSpecificContentBlend) {
        product.content.estimatedSpecificContent.secondary = 'Blend';
      }
    }
    if (form.content.knownContent || form.content.knownContentOther) {
      let otherToMerge: KnownContent[] = [];
      const otherKnown: KnownContent[] = form.content.knownContentOther ?? [];
      if (
        otherKnown[0] &&
        otherKnown[0].amount > 0 &&
        otherKnown[0].content !== ''
      )
        otherToMerge.push(otherKnown[0]);
      if (
        otherKnown[1] &&
        otherKnown[1].amount > 0 &&
        otherKnown[1].content !== ''
      )
        otherToMerge.push(otherKnown[1]);
      product.content.knownContent = (form.content.knownContent ?? []).concat(
        otherToMerge
      );
    }
  }

  product.knitWeaveType = mergeRadio(
    form.knitWeaveType,
    form.knitWeaveTypeOther
  );
  product.quality = mergeRadio(form.quality, form.qualityOther);
  product.brand = mergeRadio(form.brand, form.brandOther);
  //product.lengthM = [...form.lengthMOther];
  //product.quantity = [...form.quantityOther];
  if (form.lengthInOther) product.lengthIn = parseFloat(form.lengthInOther);
  product.packSize = mergeRadio(form.packSize, form.packSizeOther);

  if (form.usesConcatenated) {
    // Get rid of duplicates
    const uses = new Set<string>([]);
    form.usesConcatenated.forEach((use) => {
      const [taggedUse, unTaggedUse] = use.split(USES_DELINEATOR);
      uses.add(taggedUse);
      if (unTaggedUse) uses.add(unTaggedUse);
    });
    product.uses = mergeCheckbox(Array.from(uses), form.usesOther);
  } else if (form.usesOther) {
    product.uses = mergeCheckbox([], form.usesOther);
  }

  const price = mergeRadio(form.priceRadio, form.priceOther);
  // If price is varianted, prices are generated
  if (
    isFieldVarianted('price', form.variantedFields) &&
    product.bundleOrRoll === 'Roll' &&
    product.variantedFields &&
    product.variantedFields.length > 0
  ) {
    product.price = [...form.price];
    product.price[0] = price;
  } else if (isFieldVarianted('price', form.variantedFields)) {
    product.price = [...form.price];
  } else if (price) product.price = [price];

  // Remove other varianted fields
  product.variantedFields = form.variantedFields?.filter((field) =>
    VARIANT_FIELDS.has(field)
  );

  return product;
};

/**
 * This function fills in the automatic fields of a new form.
 * @param oldForm the old state of the form
 * @param newForm the new state of the form to mutate
 */
export const fillAutoFields = (
  oldForm: Readonly<FormFieldState>,
  newForm: FormFieldState
) => {
  switch (oldForm.productType) {
    case ProductTypes.Fabric: {
      // If roll is selected, can set variants
      if (
        newForm.bundleOrRoll === FabricAttributes.BundleOrRoll.Roll &&
        oldForm.quantity.length === 0
      ) {
        newForm.quantity = ['1'];
        newForm.quantityOther = ['1'];
      } else if (
        newForm.bundleOrRoll === FabricAttributes.BundleOrRoll.Bundle &&
        oldForm.quantity.length === 0
      ) {
        newForm.quantity = ['1'];
        newForm.quantityOther = ['1'];
      }

      const allVariantableFields: FormVariantableField[] = [
        'lengthM',
        'lengthMOther',
        'lengthRadio',
        'widthCM',
        'widthOther',
        'widthRadio',
        'imperfectionOther',
        'imperfections',
        'price',
        'quantityOther',
        'quantity',
        'quantityRadio'
      ];
      // Ensure all varianted fields are the appropriate length
      const numVariants = getNumVariants(newForm) ?? 0;
      allVariantableFields.forEach((field) => {
        if (!isFieldVarianted(field, newForm.variantedFields)) {
          newForm[field]?.splice(1);
        } else if (newForm[field].length < numVariants) {
          newForm[field].push(
            ...Array<undefined>(numVariants - newForm[field].length).fill(
              undefined
            )
          );
        }
      });

      // If the stretch is none/slight then make width and length stretch percentages undefined
      if (
        newForm.stretch === FabricAttributes.Stretch.None ||
        newForm.slightStretch
      ) {
        newForm.widthStretch = undefined;
        newForm.lengthStretch = undefined;
      }
      // Update base price suggestion
      const product = formToProduct(newForm);
      const newPriceBase = priceSuggestionFromForm(product);
      if (
        newPriceBase &&
        (!newForm.priceBase || newPriceBase !== oldForm.priceBase)
      ) {
        // Only set new default price if base price is changed
        newForm.priceBase = newPriceBase;
        newForm.priceRadio = newPriceBase.toString();
      }

      // If price is varianted, automatically set price
      if (isFieldVarianted('price', newForm.variantedFields)) {
        const numVariants = getNumVariants(newForm) ?? 0;
        const pricePerMeter = parseFloat(
          mergeRadio(newForm.priceRadio, newForm.priceOther) ?? '0'
        );
        const oldProduct = formToProduct(oldForm);
        const oldPricePerMeter = parseFloat(
          mergeRadio(oldForm.priceRadio, oldForm.priceOther) ?? '0'
        );

        for (let i = 0; i < numVariants; i++) {
          // If relevant fields haven't changed, don't set price
          if (
            pricePerMeter === oldPricePerMeter &&
            product.imperfections?.[i]?.[0] ===
              oldProduct.imperfections?.[i]?.[0] &&
            product.lengthM?.[i] === oldProduct.lengthM?.[i]
          )
            continue;

          let newPrice = pricePerMeter;

          // Apply imperfections to individual variants
          const imperfections = product.imperfections?.[i];
          if (
            isFieldVarianted('imperfections', newForm.variantedFields) &&
            imperfections
          )
            newPrice = imperfectionsDiscount(newPrice, imperfections);

          //if doing Roll Variants, multiply every variant price by the length except the first one
          if (
            product.bundleOrRoll === 'Roll' &&
            product.variantedFields &&
            product.variantedFields.length > 0 &&
            i !== 0
          ) {
            // Multiply prices by length
            let lengthM: string | number | undefined = product.lengthM?.[i];
            if (typeof lengthM === 'string') lengthM = parseFloat(lengthM);
            if (typeof lengthM === 'number') newPrice = newPrice * lengthM;
          } else if (
            product.bundleOrRoll === 'Bundle' &&
            product.variantedFields &&
            product.variantedFields.length > 0
          ) {
            // Multiply prices by length
            let lengthM: string | number | undefined = product.lengthM?.[i];
            if (typeof lengthM === 'string') lengthM = parseFloat(lengthM);
            if (typeof lengthM === 'number') newPrice = newPrice * lengthM;
          }

          newPrice = Math.round(newPrice * 100) / 100;
          newForm.price[i] = newPrice.toString();
        }
      }
      break;
    }
    case ProductTypes.Zippers: {
      const product = formToProduct(newForm);

      // packSize must be 1 or greater so we set packSize to be 1 and simply not display it
      // when the zipper hardware selling unit is not pack
      if (oldForm.sellingUnit === za.SellingUnit.Hardware) {
        if (oldForm.zipperHardwareSU !== za.ZipperHardwareSU.Pack) {
          newForm.packSize = '1';
        }
      }

      const newPriceBase = zipperPriceSuggestionFromForm(product);
      setNewPriceBase(newForm, newPriceBase, oldForm.priceBase);
      break;
    }
    case ProductTypes.Ribbing: {
      const product = formToProduct(newForm);
      const newPriceBase = ribbingPriceSuggestionFromForm(product);
      setNewPriceBase(newForm, newPriceBase, oldForm.priceBase);
      break;
    }
    case ProductTypes['Sewing Patterns']: {
      // Set default value of quantity to be 1
      if (oldForm.quantity.length === 0) {
        newForm.quantity = ['1'];
        newForm.quantityOther = ['1'];
      }

      const newPriceBase = 3.5;
      setNewPriceBase(newForm, newPriceBase, oldForm.priceBase);
      break;
    }
    case ProductTypes.Buttons: {
      // Width is required for Button Packs but Button Packs can have mixed width buttons
      // When mixed width is selected as an option then set the default width to be 1 and not display it
      // Change this behaviour into one where when mixed width is true, widthMM is not a required field
      if (newForm.mixedWidth) {
        newForm.widthMM = '1';
      }

      const product = formToProduct(newForm);
      const newPriceBase = buttonProductPriceSuggestionFromForm(product);
      setNewPriceBase(newForm, newPriceBase, oldForm.priceBase);
      break;
    }
    case ProductTypes.Thread: {
      if (!oldForm.threadOnSpool) newForm.threadOnSpool = ta.ThreadOnSpool.Full;

      const oldThreadType = mergeRadio(
        oldForm.threadType,
        oldForm.threadTypeOther
      );
      const newThreadType = mergeRadio(
        newForm.threadType,
        newForm.threadTypeOther
      );
      if (oldThreadType !== newThreadType) {
        newForm.material = undefined;
        newForm.materialOther = undefined;
      }

      // "Deselecting" buttons when text box is filled
      if (newForm.threadTypeOther && newForm.threadType) {
        newForm.threadType = undefined;
      }

      if (newForm.materialOther && newForm.material) {
        newForm.material = undefined;
      }

      if (newThreadType === ta.ThreadType.Silk) {
        newForm.material = ta.Content.Silk;
        newForm.materialOther = undefined;
      } else if (newThreadType === ta.ThreadType['Wooly Nylon']) {
        newForm.material = ta.Content.Nylon;
        newForm.materialOther = undefined;
      } else if (newThreadType === ta.ThreadType['Embroidery Floss'])
        newForm.spoolSize = ta.SpoolSize.Skein;

      const product = formToProduct(newForm);
      const newPriceBase = threadPriceSuggestionFromForm(product);
      setNewPriceBase(newForm, newPriceBase, oldForm.priceBase);
      break;
    }
    case ProductTypes['Other Supply']: {
      // const oldOtherSupplyType = mergeRadio(
      //   oldForm.otherSupplyType,
      //   oldForm.otherSupplyTypeOther
      // );

      const newOtherSupplyType = mergeRadio(
        newForm.otherSupplyType,
        newForm.otherSupplyTypeOther
      );

      if (newForm.otherSupplyTypeOther && newForm.otherSupplyType) {
        newForm.otherSupplyType = undefined;
      }

      if (newOtherSupplyType === osa.otherSupplyType['Sewing Tool']) {
        newForm.sellingUnit = osa.SellingUnit.Individual;
      } else if (newOtherSupplyType === osa.otherSupplyType.Sequins) {
        newForm.sellingUnit = osa.SellingUnit.Pack;
      } else if (newOtherSupplyType === osa.otherSupplyType.Books) {
        newForm.sellingUnit = osa.SellingUnit.Individual;
      }

      break;
    }
    case ProductTypes.Trim: {
      if (!oldForm.widthMixedUnit) newForm.widthMixedUnit = 'Imperial';

      // Set default value of quantity to be 1
      if (oldForm.quantity.length === 0) {
        newForm.quantity = ['1'];
        newForm.quantityOther = ['1'];
      }

      const product = formToProduct(newForm);
      const newPriceBase = trimPriceSuggestionFromForm(product);
      setNewPriceBase(newForm, newPriceBase, oldForm.priceBase);
      break;
    }
    case ProductTypes.Elastic: {
      if (!oldForm.widthMixedUnit) newForm.widthMixedUnit = 'Imperial';

      // Set default value of quantity to be 1
      if (oldForm.quantity.length === 0) {
        newForm.quantity = ['1'];
        newForm.quantityOther = ['1'];
      }

      const product = formToProduct(newForm);
      const newPriceBase = elasticPriceSuggestionFromForm(product);
      setNewPriceBase(newForm, newPriceBase, oldForm.priceBase);
      break;
    }
    case ProductTypes['Bias Tape']: {
      if (!oldForm.widthMixedUnit) newForm.widthMixedUnit = 'Imperial';

      const product = formToProduct(newForm);
      const newPriceBase = biasTapePriceSuggestionFromForm(product);
      setNewPriceBase(newForm, newPriceBase, oldForm.priceBase);
      break;
    }
  }
};

import React from 'react';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Heading,
  Text,
  CheckboxGroup
} from '@chakra-ui/react';
import { CheckboxButton, WrappedInputGroup } from '../../../general';

const SIZES = Array.from(Array(10).keys()).map((i) => (i + 1).toString());

export const TeethSizeField: AppFieldComponent = ({
  setFormField,
  currentValue,
  toNextPage
}) => {
  return (
    <FormControl as="fieldset">
      <FormLabel as="legend" mb="1.5rem">
        <Heading>What's the size?</Heading>
        <Text size="sm">(optional)</Text>
      </FormLabel>
      <CheckboxGroup
        value={[currentValue.widthMM ?? '']}
        onChange={(v: string[]) => setFormField({ widthMM: v[v.length - 1] })}
      >
        <WrappedInputGroup>
          {SIZES.map((v) => (
            <CheckboxButton minW="3em" key={v} value={v}>
              {v}
            </CheckboxButton>
          ))}
        </WrappedInputGroup>
      </CheckboxGroup>
      <FormHelperText>Number on the back of slider</FormHelperText>
    </FormControl>
  );
};

import { FormControl, FormLabel, Heading, Box } from '@chakra-ui/react';
import React from 'react';
import { OtherSupplyAttributes as osa } from 'product-validator';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { ButtonRadioGroup } from '../../../general';

export const OtherSupplyDimensionDescriptionField: AppFieldComponent = ({
  setFormField,
  currentValue,
  toNextPage
}) => {
  return (
    <FormControl as="fieldset">
      <FormLabel as="legend" mb="1.5rem">
        <Heading>What do the dimensions describe?</Heading>
      </FormLabel>
      <Box maxW={'50rem'}>
        <ButtonRadioGroup
          name="Dimension Description"
          options={Object.values(osa.DimensionDescription)}
          value={currentValue.dimensionDescription}
          onChange={(v) => {
            setFormField({
              dimensionDescription: v as osa.DimensionDescription
            });
            toNextPage({ navOnNextFormUpdate: true });
          }}
        />
      </Box>
    </FormControl>
  );
};

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  productUpload,
  mediaUpload,
  attachMedia,
  ResponseError,
  ProductRequest,
  UploadProductResponseType
} from '../../api';
import { Heading, VStack, Box } from '@chakra-ui/react';
import { FabProduct, ProductTypes, printLabel } from 'product-validator';
import { ProductTable } from './productTable/ProductTable';
import { appFields, getAppFieldList } from '../../pages/ProductEntry';
import { clearAuth } from '../../utils/authSession';
import { ValidationErrors, FormFieldState } from '../../types/formTypes';
import {
  UploadButtonConsole,
  UploadStatus
} from '../general/UploadButtonConsole';

interface UploadProductProps {
  product?: FabProduct;
  images?: string[];
  imageErrors?: string;
  validationErrors: ValidationErrors;
  currentValue: FormFieldState;
  resetFormProduct: () => void;
}

export const UploadProduct = ({
  product,
  validationErrors,
  currentValue,
  images,
  imageErrors,
  resetFormProduct
}: UploadProductProps) => {
  const history = useHistory();
  const [skus, setSku] = useState<string[]>();
  const [
    uploadResponse,
    setUploadResponse
  ] = useState<UploadProductResponseType>();
  const formIsInvalid = Object.values(validationErrors).length > 0;
  const [uploadStatus, setUploadStatus] = useState(
    formIsInvalid ? UploadStatus.FORM_IS_INVALID : UploadStatus.READY_TO_UPLOAD
  );
  const [errorResponse, setErrorResponse] = useState('');

  const onNewProductClick = () => {
    let newProductPath = appFields.Supplies.path;
    if (currentValue.productType === ProductTypes.Fabric)
      newProductPath = appFields['Bundle or Roll'].path;
    else if (!!currentValue.productType) {
      const paths = getAppFieldList(currentValue.productType);
      newProductPath = paths[1]?.path;
    }
    history.push(newProductPath);

    resetFormProduct();
  };

  const onNewSupplyClick = () => {
    let newSupplyPath = appFields.Supplies.path;
    history.push(newSupplyPath);
    resetFormProduct();
  };

  const submitProductForm = async () => {
    if (product) {
      setUploadStatus(UploadStatus.UPLOADING);
      try {
        let productResponse = uploadResponse;
        let responseSkus = skus;

        // If product has already been uploaded, don't re-upload
        if (!productResponse) {
          productResponse = await productUpload(product);
          responseSkus = productResponse.skus;
          setSku(responseSkus);
          setUploadResponse(productResponse);
        }
        setUploadStatus(UploadStatus.UPLOADING_MEDIA);
        if (images && images.length > 0) {
          if (!responseSkus) responseSkus = ['sku not set'];
          const mediaResponse = await mediaUpload(responseSkus[0], images);
          await attachMedia(
            productResponse.productId,
            productResponse.title,
            mediaResponse.data
          );
        }

        setUploadStatus(UploadStatus.UPLOAD_SUCCESS);
      } catch (err) {
        if (err instanceof ResponseError) {
          // Check if requests were authenticated
          if (err.status === 401) {
            clearAuth();
            history.push({
              pathname: '/login',
              state: { errorMsg: err.message }
            });
            return;
          }
          if (
            err.name === ProductRequest.ATTACH ||
            err.name === ProductRequest.UPLOAD
          )
            setUploadStatus(UploadStatus.UPLOAD_PARTIALLY_FAILED);
          else setUploadStatus(UploadStatus.UPLOAD_FAILED);

          setErrorResponse(err.message);
        }
      }
    }
  };

  return (
    <Box>
      <VStack>
        <Heading>Upload Product!</Heading>
        <ProductTable
          imageErrors={imageErrors}
          images={images}
          validationErrors={validationErrors}
          product={product}
          currentValue={currentValue}
        />
        <UploadButtonConsole
          currentValue={currentValue}
          startNewProduct={onNewProductClick}
          startNewSupply={onNewSupplyClick}
          printLabel={() => {
            if (product && skus) printLabel(product, skus);
            else console.log("can't print label without product and sku");
          }}
          submitProductForm={submitProductForm}
          skusMessage={skus?.join(', ') ?? 'not set'}
          uploadStatus={uploadStatus}
          errorMessage={errorResponse}
        />
      </VStack>
    </Box>
  );
};

import { AxiosError } from 'axios';

export enum ProductRequest {
  PRODUCT = 'Product',
  UPLOAD = 'Upload',
  ATTACH = 'Attach',
  ROLL_TO_BUNDLE = 'Roll to Bundle'
}

const clientMsgTemplate = (action: string) =>
  `${action} was denied. Something's wrong with the submission. Please try refreshing app and clearing cache.`;

const CLIENT_ERROR_MSGS: Record<ProductRequest, string> = {
  [ProductRequest.PRODUCT]: clientMsgTemplate('Upload product'),
  [ProductRequest.UPLOAD]: clientMsgTemplate('Upload media'),
  [ProductRequest.ATTACH]: clientMsgTemplate('Attach media'),
  [ProductRequest.ROLL_TO_BUNDLE]: clientMsgTemplate(
    'Roll to Bundle conversion'
  )
};

const serverMsgTemplate = (action: string) =>
  `${action} failed. Something's wrong with the server. Please retry or Contact administrator`;

const SERVER_ERROR_MSGS: Record<ProductRequest, string> = {
  [ProductRequest.PRODUCT]: serverMsgTemplate('Upload product'),
  [ProductRequest.UPLOAD]: serverMsgTemplate('Upload media'),
  [ProductRequest.ATTACH]: serverMsgTemplate('Attach media'),
  [ProductRequest.ROLL_TO_BUNDLE]: serverMsgTemplate(
    'Roll to Bundle conversion'
  )
};

export class ResponseError extends Error {
  // eslint-disable-next-line
  readonly obj: any;
  public name: ProductRequest;
  public status?: number;
  // eslint-disable-next-line
  constructor(axiosErr: AxiosError, name: ProductRequest, ...obj: any) {
    super(obj);
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    this.obj = obj;
    this.name = name;
    this.status = axiosErr.response?.status;
    this.message = axiosErr.message;

    if (this.status === 400) {
      this.message = axiosErr.response?.data ?? CLIENT_ERROR_MSGS[name];
    } else if (this.status === 500) {
      this.message = SERVER_ERROR_MSGS[name];
    } else if (this.status === 401) {
      this.message = 'Please login again.';
    }
  }
}

import React, { useState, useEffect } from 'react';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
const MAX_MOBILE_WIDTH = 450;

interface PageLayoutProps {
  topbar?: React.ReactNode;
  sidebar: React.ReactNode;
  main: React.ReactNode;
}
export const PageLayout: React.FC<PageLayoutProps> = (props) => {
  const [isOpen, setOpen] = useState(true);
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    const isMobile = window.innerWidth < MAX_MOBILE_WIDTH;
    // If the app is used on mobile then collapse the sidebar
    if (isMobile) setOpen(false);
    setMobile(isMobile);
  }, []);

  const sidebar = (
    <Box
      minW={{ sm: '15rem', lg: '18rem' }}
      w={isMobile ? '100%' : undefined}
      maxH="100%"
      overflowY="auto"
    >
      {props.sidebar}
    </Box>
  );

  const openOnMobile = isMobile && isOpen;

  return (
    <Flex flexDir="row" w="100%" h="100%" maxW="100%" maxH="100%">
      {isOpen && sidebar}
      <Flex
        flexGrow={1}
        flexDir="column"
        pt="3"
        px="2"
        justifyContent="flex-start"
        alignItems="flex-start"
        overflow="hidden"
      >
        <Flex
          flexGrow={0}
          marginBottom={1}
          marginRight={3}
          w="100%"
          flexDir="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <IconButton
            aria-label="Open Navigation Menu"
            isRound
            onClick={() => setOpen((v) => !v)}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          />
          {!openOnMobile && <Box>{props.topbar}</Box>}
        </Flex>
        {!openOnMobile && (
          /** Had an issue with <table/>'s getting clipped when using <Center/>, 
            this way of centering seems to work better */
          <Box flexGrow={1} minW="0" w="100%" h="100%" overflow="auto">
            <Flex h="100%" flexDir="column" justifyContent="center">
              <Box minH="0">
                <Flex flexDir="row" justifyContent="center">
                  <Box minW="0">{props.main}</Box>
                </Flex>
              </Box>
            </Flex>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

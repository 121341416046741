import { FormControl, Heading, VStack, FormHelperText } from '@chakra-ui/react';
import React from 'react';
import { ButtonRadioGroup } from '../general';

export enum SupplyInventoryCraftCategories {
  Feathers = 'Feathers',
  Sequins = 'Sequins',
  Beads = 'Beads',
  Felt = 'Felt',
  'Yarn & Yarn Tools' = 'Yarn and Yarn Tools',
  Other = 'Other'
}

interface CraftCategoryProps {
  inventorySupplyObject: any;
  setInventorySupplyObject: (inventorySupplyObject: object) => void;
}

export const CraftCategory: React.FC<CraftCategoryProps> = ({
  inventorySupplyObject,
  setInventorySupplyObject
}) => {
  return (
    <FormControl as="fieldset">
      <VStack spacing="8" alignItems="left">
        <Heading>Craft Category</Heading>
        <ButtonRadioGroup
          name="craftCategory"
          options={Object.values(SupplyInventoryCraftCategories)}
          value={inventorySupplyObject.craftCategory}
          buttonProps={{ minW: '12rem' }}
          isDisabled={
            inventorySupplyObject.everythingElseCategory === 'Craft' || null
              ? false
              : true
          }
          onChange={(v) => {
            setInventorySupplyObject({
              ...inventorySupplyObject,
              craftCategory: v
            });
          }}
        />
        {inventorySupplyObject.everythingElseCategory === 'Craft' || null ? (
          <></>
        ) : (
          <FormHelperText style={{ color: 'red' }}>
            Change Category to Craft to select a Craft Category Option
          </FormHelperText>
        )}
        <FormHelperText>Choose One</FormHelperText>
      </VStack>
    </FormControl>
  );
};

import React, { CSSProperties, FC } from 'react';
import { XYCoord } from 'react-dnd';
import { DragLayer } from 'react-dnd';

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%'
};

function getItemStyles(props: CustomDragLayerProps): React.CSSProperties {
  const { currentOffset } = props;
  if (!currentOffset) {
    return {
      display: 'none'
    };
  }

  let { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    textAlign: 'left',
    transform,
    WebkitTransform: transform
  };
}

export interface CustomDragLayerProps {
  item?: { name: string };
  currentOffset?: XYCoord | null;
  isDragging?: boolean;
}

const CustomDragLayer: FC<CustomDragLayerProps> = (props) => {
  const { item, isDragging } = props;

  if (!isDragging) {
    return null;
  }
  return (
    <div style={layerStyles}>
      <div style={getItemStyles(props)}>
        <p>{item?.name}</p>
      </div>
    </div>
  );
};

export default DragLayer((monitor) => ({
  item: monitor.getItem(),
  currentOffset: monitor.getClientOffset(),
  isDragging: monitor.isDragging()
}))(CustomDragLayer);

import {
  FormControl,
  FormLabel,
  FormHelperText,
  VStack,
  Heading,
  Flex,
  RadioGroup
} from '@chakra-ui/react';
import React from 'react';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { RadioButton } from '../../../general/ButtonRadio';
import {
  OtherTextInput,
  ErrorMessage,
  WrappedInputGroup,
  Card
} from '../../../general';
import {
  getNestedValidationError,
  isTouched
} from '../../../../utils/validationUtils';
import { OtherSupplyAttributes as osa } from 'product-validator';
import { otherSupplyType } from 'product-validator/lib/attributes/otherSupplyAttributes';

export const OtherSupplyType: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  const otherSupplyTypeErrMsgs = getNestedValidationError(
    'otherSupplyType',
    validationErrors
  );

  const otherSupplyTypeTouched = isTouched(
    currentValue.otherSupplyType,
    currentValue.otherSupplyTypeOther
  );

  const OTHER_SUPPLY_TYPE_CATEGORIES = Object.keys(osa.otherSupplyTypeOrdered);

  const categoryOptions = Object.values(osa.otherSupplyTypeOrdered);

  let typesArr: osa.otherSupplyType[][] = [];

  for (let i = 0; i < OTHER_SUPPLY_TYPE_CATEGORIES.length; i++) {
    typesArr.push(Object.values(categoryOptions[i]));
  }

  return (
    <FormControl
      as="fieldset"
      isInvalid={otherSupplyTypeErrMsgs.length > 0 && otherSupplyTypeTouched}
    >
      <VStack spacing={3}>
        <FormLabel as="legend">
          <Heading>Supply</Heading>
        </FormLabel>
        <RadioGroup
          name={'otherSupplyType'}
          value={currentValue.otherSupplyType}
          onChange={(v) => {
            setFormField({ otherSupplyType: v as string });
            toNextPage({ navOnNextFormUpdate: true });
          }}
        >
          <Flex
            wrap="wrap"
            justifyContent="center"
            sx={{ gap: '0.5rem' }}
            maxW="100%"
          >
            {OTHER_SUPPLY_TYPE_CATEGORIES.map((category, index) => {
              return typesArr[index].length > 0 ? (
                <Card key={category} title={category}>
                  <VStack maxW={'10rem'}>
                    <WrappedInputGroup numColumns={2}>
                      {typesArr[index].map((v: otherSupplyType) => (
                        <RadioButton
                          key={v}
                          value={v}
                          size="sm"
                          isDisabled={!!currentValue.otherSupplyTypeOther}
                        >
                          {v}
                        </RadioButton>
                      ))}
                    </WrappedInputGroup>
                  </VStack>
                </Card>
              ) : undefined;
            })}
          </Flex>
        </RadioGroup>
        <OtherTextInput
          onChange={(e) =>
            setFormField({
              otherSupplyTypeOther: e.target.value
            })
          }
          value={currentValue.otherSupplyTypeOther ?? ''}
        />
      </VStack>
      <ErrorMessage errMsgs={otherSupplyTypeErrMsgs} />
      <FormHelperText>Select one.</FormHelperText>
    </FormControl>
  );
};

import {
  Flex,
  FormControl,
  FormLabel,
  FormHelperText,
  Heading
} from '@chakra-ui/react';
import React from 'react';
import { ProductTypes } from 'product-validator';
import { ButtonRadioGroup } from '../../../general';
import { OtherSupplyAttributes as osa } from 'product-validator';
import { AppFieldComponent } from '../../../../types/appFieldTypes';

export const OtherSupplySizeField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  return (
    <Flex
      flexDirection="row"
      wrap="wrap"
      gridGap="1rem"
      alignItems="flex-start"
    >
      <FormControl as="fieldset">
        <FormLabel as="legend">
          <Heading>Size</Heading>
          {currentValue.productType === ProductTypes['Other Supply'] ? (
            <FormHelperText>(Optional)</FormHelperText>
          ) : (
            <></>
          )}
        </FormLabel>
        <>
          <ButtonRadioGroup
            name="Size"
            value={currentValue?.osSize}
            options={Object.values(osa.osSize)}
            onChange={(v) => setFormField({ osSize: v as osa.osSize })}
          />
        </>
      </FormControl>
    </Flex>
  );
};

import {
  FormControl,
  FormLabel,
  FormHelperText,
  Heading,
  Box,
  Flex,
  Stack,
  CheckboxGroup,
  VStack
} from '@chakra-ui/react';
import React from 'react';
import {
  FabricAttributes as fa,
  ProductTypes,
  RibbingAttributes as ra
} from 'product-validator';
import { AppFieldComponent } from '../../../types/appFieldTypes';
import {
  getNestedValidationError,
  isTouched
} from '../../../utils/validationUtils';
import { USES_DELINEATOR } from '../../../utils/formUtils';
import {
  CheckboxButton,
  OtherTextInput,
  ErrorMessage,
  Card
} from '../../general';
import { fieldOptions } from '../../../types/fieldOptionsTypes';
import { getTrimUseOptions } from '../../../utils/supplyUtils';

interface CommonUsesOptionsProps {
  title?: string;
  useCategory?: string;
  options: string[];
}

const CommonUsesOptions: React.FC<CommonUsesOptionsProps> = ({
  title,
  useCategory,
  options
}) => {
  return (
    <Card title={title}>
      <VStack>
        {options.map((use, idx) => (
          <CheckboxButton
            key={idx}
            size="sm"
            value={
              useCategory && useCategory !== 'Other'
                ? `${useCategory}${USES_DELINEATOR}${use}`
                : use
            }
          >
            {use}
          </CheckboxButton>
        ))}
      </VStack>
    </Card>
  );
};

export const CommonUsesField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors
}) => {
  const onTextChange = async (text: string) => {
    setFormField({
      usesOther: text
    });
  };

  const errMsgs: string[] = getNestedValidationError('uses', validationErrors);
  const touched = isTouched(
    currentValue.usesConcatenated,
    currentValue.usesOther
  );

  const showOtherField =
    currentValue.productType !== ProductTypes.Buttons &&
    currentValue.productType !== ProductTypes.Thread;

  let checkboxOptions;
  if (currentValue.productType === ProductTypes.Fabric) {
    const categoryOptions = Object.entries(
      fieldOptions[currentValue.productType].commonUses
    );
    checkboxOptions = categoryOptions
      .map(([useCategory, useCategoryItems]) => (
        <CommonUsesOptions
          key={useCategory}
          useCategory={useCategory}
          title={useCategory}
          options={Object.values(useCategoryItems)}
        />
      ))
      .concat(
        <CommonUsesOptions
          key="Other"
          title="Other"
          options={[
            fa.UsesTagged.Swimwear,
            fa.UsesTagged.Costumes,
            fa.UsesTagged.Accessories
          ]}
        />
      );
  } else if (currentValue.productType === ProductTypes.Trim) {
    checkboxOptions = (
      <CommonUsesOptions options={getTrimUseOptions(currentValue.trimType)} />
    );
  } else if (currentValue.productType === ProductTypes.Ribbing) {
    checkboxOptions = <CommonUsesOptions options={Object.values(ra.Uses)} />;
  } else {
    checkboxOptions = (
      <CommonUsesOptions
        options={Object.values(
          fieldOptions[currentValue.productType ?? ProductTypes.Zippers]
            .commonUses
        )}
      />
    );
  }

  return (
    <Box>
      <FormControl as="fieldset" isInvalid={errMsgs.length > 0 && touched}>
        <FormLabel as="legend">
          <Heading>Uses</Heading>
          What would you use this product for?
        </FormLabel>
        <Stack spacing="3">
          <CheckboxGroup
            value={currentValue.usesConcatenated}
            onChange={(usesArr: string[]) => {
              setFormField({
                usesConcatenated: usesArr
              });
            }}
          >
            <Flex
              flexDirection="row"
              wrap="wrap"
              maxW="60rem"
              sx={{ gap: '0.25rem' }}
            >
              {checkboxOptions}
            </Flex>
          </CheckboxGroup>
          {showOtherField && (
            <OtherTextInput
              onChange={(e) => onTextChange(e.target.value)}
              value={currentValue.usesOther ?? ''}
            />
          )}
        </Stack>
        <ErrorMessage errMsgs={errMsgs} />
        {showOtherField ? (
          <FormHelperText>Choose one.</FormHelperText>
        ) : (
          <FormHelperText>Choose all that apply or enter other.</FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

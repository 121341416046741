import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import {
  getNestedValidationError,
  isTouched
} from '../../../utils/validationUtils';
import { ProductTypes, FabricAttributes as fa } from 'product-validator';
import { AppFieldComponent } from '../../../types/appFieldTypes';
import { IndexedAppFieldComponentProps } from '../../../types/appFieldTypes';
import { VariantsTable } from './VariantsTable';
import { VariantsRollTable } from './VariantsRollTable';
import { ImperfectionField } from '../fields/ImperfectionField';
import { LengthField } from '../fields/LengthField';
import { WidthField } from '../fields/WidthField';
import { QuantityField } from '../fields/QuantityField';
import update from 'immutability-helper';
import { ErrorMessage } from '../../general/ErrorMessage';
import { isFieldVarianted } from 'product-validator';
import { FormVariantableField } from '../../../types/formTypes';

export type TableColumnField =
  | 'lengthM'
  | 'widthCM'
  | 'imperfections'
  | 'quantity';
const modalFields: {
  [k in TableColumnField]: React.FC<IndexedAppFieldComponentProps>;
} = {
  lengthM: LengthField,
  widthCM: WidthField,
  imperfections: ImperfectionField,
  quantity: QuantityField
};

const fieldsForDimensionVariants: FormVariantableField[] = [
  'lengthM',
  'lengthMOther',
  'lengthRadio',
  'widthCM',
  'widthOther',
  'widthRadio',
  'imperfectionOther',
  'imperfections',
  'quantity',
  'quantityOther',
  'quantityRadio'
];

const fieldsForValidationErrors: string[] = [
  'duplicateVariants',
  'lengthM',
  'lengthMOther',
  'lengthRadio',
  'widthCM',
  'widthOther',
  'widthRadio',
  'imperfectionOther',
  'imperfections',
  'quantity',
  'quantityOther',
  'quantityRadio'
];

export const VariantDimensionsField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeIdxState, setActiveIdx] = useState<number>(0);
  const [activeFieldState, setActiveField] = useState<TableColumnField>();

  const clickCell = (cellField: TableColumnField, idx: number) => {
    if (isFieldVarianted(cellField, currentValue.variantedFields))
      setActiveIdx(idx);
    else setActiveIdx(0);
    setActiveField(cellField);
    onOpen();
  };

  const applyAllClick = useCallback(
    (
      column: Extract<
        TableColumnField,
        'widthCM' | 'imperfections' | 'quantity'
      >
    ) => {
      let relatedFields: FormVariantableField[] = [];
      if (column === 'widthCM')
        relatedFields.push('widthCM', 'widthOther', 'widthRadio');
      else if (column === 'quantity')
        relatedFields.push('quantity', 'quantityOther');
      else relatedFields.push('imperfections', 'imperfectionOther');

      setFormField((oldForm) =>
        update(oldForm, {
          variantedFields: (
            variantedFields: FormVariantableField[] | undefined
          ) => {
            if (variantedFields?.includes(column))
              return variantedFields?.filter(
                (field) => !relatedFields.includes(field)
              );
            else return variantedFields?.concat(...relatedFields);
          }
        })
      );
    },
    [setFormField]
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      // Prevent 'Enter' from navigating to next page
      event.stopPropagation();
    }
  };

  const addRow = useCallback(() => {
    setFormField((oldForm) => {
      let newForm = oldForm;
      oldForm.variantedFields?.forEach((field) => {
        if (field === 'quantityOther')
          newForm = update(newForm, {
            [field]: { $push: ['1'] }
          });
        else if (Array.isArray(newForm[field]))
          newForm = update(newForm, {
            [field]: { $push: [undefined] }
          });
        else
          newForm = update(newForm, {
            [field]: { $set: [undefined] }
          });
      });
      return newForm;
    });
  }, [setFormField]);

  const removeRow = useCallback(
    (idxToRemove) => {
      setFormField((oldForm) => {
        let newForm = oldForm;
        oldForm.variantedFields?.forEach((field) => {
          newForm = update(newForm, {
            [field]: { $splice: [[idxToRemove, 1]] }
          });
        });
        return newForm;
      });
    },
    [setFormField]
  );

  const errMsgs = getNestedValidationError(
    fieldsForValidationErrors,
    validationErrors
  );

  const touched = isTouched(
    ...fieldsForDimensionVariants.map((k) => currentValue[k])
  );

  const isRollVariants =
    currentValue.productType === ProductTypes.Fabric &&
    currentValue.bundleOrRoll === fa.BundleOrRoll.Roll;

  return (
    <FormControl isInvalid={errMsgs.length > 0 && touched}>
      {isRollVariants ? (
        <Stack spacing="3">
          <FormLabel>
            <Heading>Roll</Heading>
          </FormLabel>
          <VariantsRollTable
            currentValue={currentValue}
            setFormField={setFormField}
            removeRow={removeRow}
            clickCell={clickCell}
            applyAllClick={applyAllClick}
          />
          <Button onClick={addRow} size="md">
            + Add Variant
          </Button>
          <Modal isOpen={isOpen} onClose={onClose} size="4xl">
            <ModalOverlay />
            <ModalContent onKeyDown={handleKeyDown} padding="6">
              <ModalCloseButton />
              <ModalBody>
                {activeFieldState &&
                  React.createElement(modalFields[activeFieldState], {
                    currentValue: currentValue,
                    setFormField: setFormField,
                    validationErrors: validationErrors,
                    idx: activeIdxState,
                    toNextPage: () => {}
                  })}
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="orange" mr={3} onClick={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Stack>
      ) : (
        <Stack>
          <FormLabel>
            <Heading>Bundle variants</Heading>
            <FormHelperText>Click on values to edit.</FormHelperText>
          </FormLabel>
          <Stack spacing="3">
            <VariantsTable
              currentValue={currentValue}
              setFormField={setFormField}
              removeRow={removeRow}
              clickCell={clickCell}
              applyAllClick={applyAllClick}
            />
            <Button onClick={addRow} size="md">
              + Add Variant
            </Button>
          </Stack>
          <ErrorMessage errMsgs={errMsgs} />
          <Modal isOpen={isOpen} onClose={onClose} size="4xl">
            <ModalOverlay />
            <ModalContent onKeyDown={handleKeyDown} padding="6">
              <ModalCloseButton />
              <ModalBody>
                {activeFieldState &&
                  React.createElement(modalFields[activeFieldState], {
                    currentValue: currentValue,
                    setFormField: setFormField,
                    validationErrors: validationErrors,
                    idx: activeIdxState,
                    toNextPage: () => {}
                  })}
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="orange" mr={3} onClick={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Stack>
      )}
    </FormControl>
  );
};

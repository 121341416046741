import { FabricAttributes as fa } from 'product-validator';
export const UPHOLSTERY_PRICING = {
  [fa.Weight.Light]: 4,
  [fa.Weight.Medium]: 8,
  [fa.Weight.Heavy]: 10
};

const IMPERFECTION_PRICING = {
  [fa.Imperfections.Discoloration]: 0.3,
  [fa.Imperfections['Scented - Vintage']]: 0.2,
  [fa.Imperfections['Scented - Washed']]: 0.2,
  [fa.Imperfections['Small Holes']]: 0.2,
  [fa.Imperfections.Run]: 0.2
};

export const maxImperfectionDiscount = (imperfections: string[]): number => {
  const imperfectionDiscounts: number[] = [];
  let imperfectionPrice = 0;
  for (const imperfection of imperfections) {
    imperfectionPrice = IMPERFECTION_PRICING[imperfection];
    if (imperfectionPrice) imperfectionDiscounts.push(imperfectionPrice);
    // In the case an "Other" imperfection is given then 0.2
    else imperfectionDiscounts.push(0.2);
  }

  return Math.max(...imperfectionDiscounts);
};

import { Button, HStack, Text } from '@chakra-ui/react';
import React from 'react';

const addTo = (val: number, quantityOther?: string): string => {
  let q = parseInt(quantityOther ?? '1');
  q = isNaN(q) ? 0 : q + val;
  if (q < 0) q = 0;
  return q.toString();
};

interface VariantQuantityProps {
  quantityText: string;
  quantityOther?: string;
  onChange: (val: string) => void;
}

export const VariantQuantity: React.FC<VariantQuantityProps> = ({
  quantityText,
  quantityOther,
  onChange
}: VariantQuantityProps) => (
  <HStack>
    <Button
      id="decrement"
      onClick={(e) => {
        e.stopPropagation();
        onChange(addTo(-1, quantityOther));
      }}
    >
      -
    </Button>
    <Text w="2rem">{quantityText}</Text>
    <Button
      id="increment"
      onClick={(e) => {
        e.stopPropagation();
        onChange(addTo(1, quantityOther));
      }}
    >
      +
    </Button>
  </HStack>
);

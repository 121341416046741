import React from 'react';
import { FormState } from '../../FormStateContainer';
import { FormFieldState } from '../../../../types/formTypes';
import { Card, SwitchInput } from '../../../general';
import { FabricAttributes as fa } from 'product-validator';
import {
  FormControl,
  Box,
  FormHelperText,
  FormLabel,
  HStack
} from '@chakra-ui/react';
import { WrappedDatePicker } from '../../../general';

export interface MediaTagFieldComponentProps {
  setFormField: FormState['setFormField'];
  currentValue: FormFieldState;
}

export const MediaTagField: React.FC<MediaTagFieldComponentProps> = ({
  setFormField,
  currentValue
}) => {
  return (
    <FormControl>
      <Card title="Media Tags" maxW="13rem">
        <SwitchInput
          label="Unboxing"
          isChecked={
            currentValue.mediaTag
              ? currentValue.mediaTag === fa.MediaTag.Unboxing
              : false
          }
          onChange={() => {
            setFormField((oldForm) => {
              const newForm = { ...oldForm };
              if (oldForm.mediaTag === fa.MediaTag.Unboxing) {
                newForm.unboxingDateExists = 'No';
                newForm.unboxingDate = undefined;
                newForm.mediaTag = fa.MediaTag.None;
              } else {
                newForm.unboxingDateExists = 'Yes';
                newForm.mediaTag = fa.MediaTag.Unboxing;
              }
              return newForm;
            });
          }}
        />
        <SwitchInput
          label="Daily Finds"
          isChecked={
            currentValue.mediaTag
              ? currentValue.mediaTag === fa.MediaTag['Daily Finds']
              : false
          }
          onChange={() => {
            setFormField((oldForm) => {
              const newForm = { ...oldForm };
              if (oldForm.mediaTag === fa.MediaTag['Daily Finds']) {
                newForm.mediaTag = fa.MediaTag.None;
              } else {
                newForm.mediaTag = fa.MediaTag['Daily Finds'];
                newForm.unboxingDateExists = 'No';
                newForm.unboxingDate = undefined;
              }
              return newForm;
            });
          }}
        />
        <SwitchInput
          label="Name the Fabric"
          isChecked={
            currentValue.mediaTag
              ? currentValue.mediaTag === fa.MediaTag['Name the Fabric']
              : false
          }
          onChange={() => {
            setFormField((oldForm) => {
              const newForm = { ...oldForm };
              if (oldForm.mediaTag === fa.MediaTag['Name the Fabric']) {
                newForm.mediaTag = fa.MediaTag.None;
              } else {
                newForm.mediaTag = fa.MediaTag['Name the Fabric'];
                newForm.unboxingDateExists = 'No';
                newForm.unboxingDate = undefined;
              }
              return newForm;
            });
          }}
        />
      </Card>
      {currentValue.mediaTag === fa.MediaTag.Unboxing ? (
        <Box>
          <HStack>
            <FormLabel>Date:</FormLabel>
            <WrappedDatePicker
              date={currentValue.unboxingDate}
              onChange={(date) => {
                setFormField({ unboxingDate: date as Date });
              }}
            />
          </HStack>
          <FormHelperText>Select a date.</FormHelperText>
        </Box>
      ) : (
        <></>
      )}
    </FormControl>
  );
};

import { Flex, Box, Text, BoxProps } from '@chakra-ui/react';
import React from 'react';

interface LooksDescriptorProps extends BoxProps {
  text: string;
  isVertical?: boolean;
}

export const LooksDescriptor = ({
  text,
  isVertical,
  children,
  ...rest
}: LooksDescriptorProps) => {
  const size = isVertical ? '4rem' : '1.6rem';

  return (
    <Flex
      w="100%"
      justifyContent="flex-start"
      {...(isVertical
        ? {
            flexDir: 'column',
            alignItems: 'center'
          }
        : { flexDir: 'row' })}
    >
      <Box
        minW={size}
        minH={size}
        maxW={size}
        maxH={size}
        style={{ borderRadius: '0.2rem' }}
        {...rest}
      >
        {children}
      </Box>
      <Text
        {...(isVertical
          ? {
              paddingTop: '12px'
            }
          : {
              paddingLeft: '12px'
            })}
      >
        {text}
      </Text>
    </Flex>
  );
};

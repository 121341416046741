import { FormControl, Heading, VStack, FormHelperText } from '@chakra-ui/react';
import React from 'react';
import { ButtonRadioGroup } from '../general';

export enum SupplyInventoryOtherCategories {
  Tools = 'Tools',
  Books = 'Books',
  'Bridal Wear' = 'Bridal Wear',
  Notions = 'Notions',
  'Costume Jewelry' = 'Costume Jewelry',
  Clothing = 'Clothing',
  Applique = 'Applique',
  Accessories = 'Accessories',
  Other = 'Other'
}

interface OtherCategoryProps {
  inventorySupplyObject: any;
  setInventorySupplyObject: (inventorySupplyObject: object) => void;
}

export const OtherCategory: React.FC<OtherCategoryProps> = ({
  inventorySupplyObject,
  setInventorySupplyObject
}) => {
  return (
    <FormControl as="fieldset">
      <VStack spacing="8" alignItems="left">
        <Heading>Other Category</Heading>
        <ButtonRadioGroup
          name="otherCategory"
          options={Object.values(SupplyInventoryOtherCategories)}
          value={inventorySupplyObject.otherCategory}
          buttonProps={{ minW: '12rem' }}
          isDisabled={
            inventorySupplyObject.everythingElseCategory === 'Other' || null
              ? false
              : true
          }
          onChange={(v) => {
            setInventorySupplyObject({
              ...inventorySupplyObject,
              otherCategory: v
            });
          }}
        />
        {inventorySupplyObject.everythingElseCategory === 'Other' || null ? (
          <></>
        ) : (
          <FormHelperText style={{ color: 'red' }}>
            Change Category to Other to select an Other Category Option
          </FormHelperText>
        )}
      </VStack>
    </FormControl>
  );
};

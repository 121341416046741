import { useHistory } from 'react-router-dom';
import {
  VStack,
  HStack,
  Heading,
  Button,
  FormHelperText,
  FormControl,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  Spinner
} from '@chakra-ui/react';
import React, { useState } from 'react';
import axios from 'axios';
import base from '../../api/airtable';
import { jsPDF } from 'jspdf';
import { InventorySupplyTable } from './InventorySupplyTable';
import { FieldSet, Records } from 'airtable';

interface SummaryPageProps {
  inventorySupplyObject: any;
  setInventorySupplyObject: (inventorySupplyObject: object) => void;
  inventorySupplies: any;
  setInventorySupplies: (inventorySupplies: Records<FieldSet>) => void;
}

export const SummaryPage: React.FC<SummaryPageProps> = ({
  inventorySupplyObject,
  setInventorySupplyObject,
  inventorySupplies,
  setInventorySupplies
}) => {
  const history = useHistory();
  const [uploadSuccess, setUploadSuccess] = useState<any>(0);
  const [uploadingState, setUploadingState] = useState<any>(0);
  const toast = useToast();
  const getSubA = async (
    supplyType: string,
    trimType: string,
    category: string
  ) => {
    let subA = '';
    if (supplyType === 'Trim') {
      subA = trimType;
    } else if (supplyType === 'Everything Else') {
      subA = category;
    }
    return subA;
  };

  const generateInventorySupplySKU = async (
    supplyType: keyof typeof supplyTypeNumbers,
    trimType: keyof typeof trimTypeLetters,
    category: keyof typeof everythingElseCategoryLetters,
    categoryOption: any
  ) => {
    // Assign numbers to supply types
    const supplyTypeNumbers = {
      Zippers: '1',
      Buttons: '2',
      Elastic: '3',
      'Sewing Patterns': '4',
      Thread: '5',
      Trim: '6',
      Ribbing: '7',
      'Bias Tape': '8',
      'Everything Else': '9'
    };

    // Assign letters to trim types
    const trimTypeLetters = {
      Fringe: 'A',
      Ribbon: 'B',
      Lace: 'C',
      Tape: 'D',
      Cording: 'E',
      Webbing: 'F',
      Piping: 'G',
      'Faux Fur': 'H',
      Braided: 'I',
      Ricrac: 'J'
    };

    // Assign letters to categories for Everything Else
    const everythingElseCategoryLetters = {
      Sewing: 'A',
      Craft: 'B',
      Other: 'C'
    };

    // Get the corresponding number for the supply type
    const supplyTypeNumber = supplyTypeNumbers[supplyType] || '';

    // If the supply type is Trim, add the corresponding letter for the trim type
    const trimTypeLetter =
      supplyType === 'Trim' ? trimTypeLetters[trimType] || '' : '';

    // If the supply type is Everything Else, add the corresponding letter and number based on the category and category option
    let everythingElseLetter = '';
    let categoryOptionNumber = '';

    if (supplyType === 'Everything Else') {
      everythingElseLetter = everythingElseCategoryLetters[category] || '';

      switch (category) {
        case 'Sewing':
          // Add your sewing category options here
          switch (categoryOption) {
            case 'Velcro':
              categoryOptionNumber = '1';
              break;
            case 'Sewing Tool':
              categoryOptionNumber = '2';
              break;
            case 'Storage':
              categoryOptionNumber = '3';
              break;
            case 'Interfacing':
              categoryOptionNumber = '4';
              break;
            case 'Other':
              categoryOptionNumber = '5';
              break;
            default:
              break;
          }
          break;

        case 'Craft':
          // Add your craft category options here
          switch (categoryOption) {
            case 'Feathers':
              categoryOptionNumber = '6';
              break;
            case 'Sequins':
              categoryOptionNumber = '7';
              break;
            case 'Beads':
              categoryOptionNumber = '8';
              break;
            case 'Felt':
              categoryOptionNumber = '9';
              break;
            case 'Yarn and Yarn Tools':
              categoryOptionNumber = '10';
              break;
            case 'Other':
              categoryOptionNumber = '11';
              break;
            default:
              break;
          }
          break;

        case 'Other':
          // Add your other category options here
          switch (categoryOption) {
            case 'Costume Jewellery':
              categoryOptionNumber = '12';
              break;
            case 'Bridal Wear':
              categoryOptionNumber = '13';
              break;
            case 'Clothing':
              categoryOptionNumber = '14';
              break;
            case 'Books':
              categoryOptionNumber = '15';
              break;
            case 'Tools':
              categoryOptionNumber = '16';
              break;
            case 'Notions':
              categoryOptionNumber = '17';
              break;
            case 'Applique':
              categoryOptionNumber = '18';
              break;
            case 'Accessories':
              categoryOptionNumber = '19';
              break;
            case 'Other':
              categoryOptionNumber = '20';
              break;
            default:
              break;
          }
          break;

        default:
          break;
      }
    }

    // Combine the parts to form the SKU string
    const boxID = `${supplyTypeNumber}${trimTypeLetter}${everythingElseLetter}${categoryOptionNumber}`;

    const response = await axios.post('/product/box_count', { boxID });

    const boxCount = response.data;

    const finalBoxID = `${boxID}-${boxCount}`;

    return finalBoxID;
  };

  const addInventorySupply = async (event: any) => {
    event.preventDefault();

    setUploadingState(1);

    let categoryOption = '';

    if (inventorySupplyObject.sewingCategory !== '') {
      categoryOption = inventorySupplyObject.sewingCategory;
    } else if (inventorySupplyObject.craftCategory !== '') {
      categoryOption = inventorySupplyObject.craftCategory;
    } else if (inventorySupplyObject.otherCategory !== '') {
      categoryOption = inventorySupplyObject.otherCategory;
    }

    const boxID = inventorySupplyObject.boxID
      ? inventorySupplyObject.boxID
      : await generateInventorySupplySKU(
          inventorySupplyObject.supplyType,
          inventorySupplyObject.trimType,
          inventorySupplyObject.everythingElseCategory,
          categoryOption
        );
    setInventorySupplyObject({ ...inventorySupplyObject, boxID });
    const productType = inventorySupplyObject.supplyType;
    const subA = await getSubA(
      inventorySupplyObject.supplyType,
      inventorySupplyObject.trimType,
      inventorySupplyObject.everythingElseCategory
    );
    const subB = categoryOption;
    const boxSize = inventorySupplyObject.boxSize;
    const status = 'New Box';

    base('Supplies - General').create(
      {
        'Box ID': boxID,
        'Product Type': productType,
        'Sub A': subA,
        'Sub B': subB,
        'Size of Box': boxSize,
        Status: status
      },
      (err: any, record: any) => {
        if (err) {
          console.error(err);
          toast({
            title: `error occured`,
            status: 'error',
            isClosable: true
          });
          setUploadingState(0);
          return;
        } else {
          setUploadSuccess(1);
          setUploadingState(0);
          toast({
            title: 'Upload Success',
            status: 'success',
            isClosable: true
          });
        }
      }
    );
  };

  const createInventorySupplyLabel = async (event: any) => {
    event.preventDefault();
    var doc = new jsPDF('p', 'in', 'letter');
    doc.setFontSize(180);
    doc.text(
      inventorySupplyObject.boxID,
      doc.internal.pageSize.getWidth() / 2,
      3,
      { align: 'center' }
    );
    doc.setFontSize(30);
    doc.text(
      `${inventorySupplyObject.supplyType} ${
        inventorySupplyObject.trimType ?? ''
      } ${inventorySupplyObject.everythingElseCategory ?? ''} ${
        inventorySupplyObject.sewingCategory ?? ''
      } ${inventorySupplyObject.craftCategory ?? ''} ${
        inventorySupplyObject.otherCategory ?? ''
      }`,
      doc.internal.pageSize.getWidth() / 2,
      4,
      { align: 'center' }
    );
    doc.setFontSize(180);
    doc.text(
      inventorySupplyObject.boxID,
      doc.internal.pageSize.getWidth() / 2,
      9,
      { align: 'center' }
    );
    doc.setFontSize(30);
    doc.text(
      `${inventorySupplyObject.supplyType} ${
        inventorySupplyObject.trimType ?? ''
      } ${inventorySupplyObject.everythingElseCategory ?? ''} ${
        inventorySupplyObject.sewingCategory ?? ''
      } ${inventorySupplyObject.craftCategory ?? ''} ${
        inventorySupplyObject.otherCategory ?? ''
      }`,
      doc.internal.pageSize.getWidth() / 2,
      10,
      { align: 'center' }
    );
    doc.autoPrint({ variant: 'non-conform' });
    doc.save(`label-${inventorySupplyObject.boxID}`);

    await axios.post('/product/inventory_supply_label_email', {
      inventorySupplyObject
    });
  };

  const resetInventorySupply = () => {
    let newInventorySupplyPath = '/inventory_supplies/start_or_update';
    history.push(newInventorySupplyPath);
    setInventorySupplyObject({
      isNewBox: 'no',
      boxID: '',
      supplyType: '',
      boxSize: '',
      trimType: '',
      everythingElseCategory: '',
      sewingCategory: '',
      craftCategory: '',
      otherCategory: ''
    });
    base('Supplies - General')
      .select({ view: 'Full List' })
      .eachPage((records, fetchNextPage) => {
        setInventorySupplies(records);
        fetchNextPage();
      });
  };
  return (
    <VStack>
      <Heading>Upload Inventory Supply</Heading>
      <InventorySupplyTable inventorySupplyObject={inventorySupplyObject} />
      <FormControl as="fieldset">
        <VStack>
          {uploadingState === 1 ? (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          ) : (
            <></>
          )}
          {uploadSuccess === 1 ? (
            <Alert
              status="success"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                New Box Created
              </AlertTitle>
            </Alert>
          ) : (
            <></>
          )}
          <Button
            isDisabled={inventorySupplyObject.isNewBox === 'yes' ? false : true}
            onClick={addInventorySupply}
          >
            Submit
          </Button>
          {inventorySupplyObject.isNewBox === 'yes' ? (
            <></>
          ) : (
            <FormHelperText style={{ color: 'red' }}>
              New Box? must be yes to submit a new box
            </FormHelperText>
          )}
          {uploadSuccess === 1 ? (
            <VStack>
              <HStack>
                <Button onClick={createInventorySupplyLabel}>
                  Create Label
                </Button>
                <Button onClick={resetInventorySupply}>New Supply Entry</Button>
              </HStack>
            </VStack>
          ) : (
            <></>
          )}
        </VStack>
      </FormControl>
    </VStack>
  );
};

import {
  FormControl,
  FormLabel,
  FormHelperText,
  VStack,
  Heading
} from '@chakra-ui/react';
import React from 'react';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import {
  OtherTextInput,
  ErrorMessage,
  ImageRadioGroup,
  ImageRadioButton
} from '../../../general';
import {
  getNestedValidationError,
  isTouched
} from '../../../../utils/validationUtils';
import { ButtonProductAttributes as ba } from 'product-validator';
import * as IMAGES from '../../../../images/buttons';

const buttonSrc: { [k in keyof typeof ba.ButtonType]: string } = {
  'Sew-on Snap': IMAGES.sew_on_snap,
  'Spring Snap': IMAGES.spring_button,
  Grommet: IMAGES.grommet,
  '2 Hole': IMAGES.two_hole_button,
  '4 Hole': IMAGES.four_hole_button,
  Toggle: IMAGES.toggle,
  Shank: IMAGES.shank
};

export const ButtonTypeField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors
}) => {
  const buttonTypeErrMsgs = getNestedValidationError(
    'buttonType',
    validationErrors
  );

  const buttonTypeTouched = isTouched(
    currentValue.buttonType,
    currentValue.buttonTypeOther
  );

  return (
    <VStack spacing="6" alignItems="left">
      <FormControl
        as="fieldset"
        isInvalid={buttonTypeErrMsgs.length > 0 && buttonTypeTouched}
      >
        <FormLabel as="legend">
          <Heading>Type</Heading>
        </FormLabel>
        <VStack spacing="3" alignItems="left" maxW="40rem">
          <ImageRadioGroup
            name="Button Type"
            value={currentValue.buttonType}
            onChange={(v) => {
              setFormField({ buttonType: v as ba.ButtonType });
            }}
          >
            {Object.values(ba.ButtonType).map((v) => (
              <ImageRadioButton
                value={v}
                key={v}
                image={buttonSrc[v as ba.ButtonType]}
                isDisabled={!!currentValue.buttonTypeOther}
              />
            ))}
          </ImageRadioGroup>
          <OtherTextInput
            onChange={(e) => {
              setFormField({
                buttonTypeOther: e.target.value
              });
            }}
            value={currentValue.buttonTypeOther ?? ''}
          />
          <FormHelperText>Select one or enter in another.</FormHelperText>
          <ErrorMessage errMsgs={buttonTypeErrMsgs} />
        </VStack>
      </FormControl>
    </VStack>
  );
};

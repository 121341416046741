import {
  VStack,
  FormControl,
  FormLabel,
  FormHelperText,
  Heading
} from '@chakra-ui/react';
import React from 'react';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import {
  ButtonRadioGroup,
  OtherTextInput,
  ErrorMessage
} from '../../../general';
import { SewingPatternAttributes as spa } from 'product-validator';
import {
  getNestedValidationError,
  isTouched
} from '../../../../utils/validationUtils';

export const SewingPatternBrandNumberField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  const patternBrandErrMsgs = getNestedValidationError(
    'brand',
    validationErrors
  );
  const patternBrandTouched = isTouched(
    currentValue.brand,
    currentValue.brandOther
  );

  const patternNumberErrMsgs = getNestedValidationError(
    'patternNumber',
    validationErrors
  );
  const patternNumberTouched = isTouched(currentValue.patternNumber);

  return (
    <VStack spacing="6" alignItems="left">
      <FormControl
        as="fieldset"
        isInvalid={patternBrandErrMsgs.length > 0 && patternBrandTouched}
      >
        <FormLabel as="legend">
          <Heading>Brand</Heading>
        </FormLabel>
        <VStack spacing="3" alignItems="left">
          <ButtonRadioGroup
            name="Pattern Brand"
            value={currentValue.brand}
            isDisabled={!!currentValue.brandOther}
            options={Object.values(spa.Brand)}
            onChange={(v) => {
              setFormField({ brand: v as spa.Brand });
            }}
          />
          <OtherTextInput
            onChange={(e) => {
              setFormField({
                brandOther: e.target.value
              });
            }}
            value={currentValue.brandOther ?? ''}
          />
        </VStack>
        <FormHelperText>Select one or enter in another.</FormHelperText>
        <ErrorMessage errMsgs={patternBrandErrMsgs} />
      </FormControl>

      <FormControl
        as="fieldset"
        isInvalid={patternNumberErrMsgs.length > 0 && patternNumberTouched}
      >
        <FormLabel as="legend">
          <Heading>Pattern Number</Heading>
        </FormLabel>
        <OtherTextInput
          onChange={(e) => {
            setFormField({
              patternNumber: e.target.value
            });
          }}
          value={currentValue.patternNumber ?? ''}
        />
        <FormHelperText>Type in a number.</FormHelperText>
        <ErrorMessage errMsgs={patternNumberErrMsgs} />
      </FormControl>
    </VStack>
  );
};

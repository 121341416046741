import { Box } from '@chakra-ui/react';
import React from 'react';
import { AppFieldComponent } from '../../../types/appFieldTypes';
import { YesNo } from '../../../types/formTypes';
import { YesNoQuestion } from '../../general';
import { strigifyProductType } from '../../../utils/utils';

import { ImperfectionField } from './ImperfectionField';
import { PatternField } from './PatternField';

export const PatternImperfectionField: AppFieldComponent = (props) => {
  const question = `Does the ${strigifyProductType(
    props.currentValue.productType
  )} have any imperfections?`;
  return (
    <Box>
      <PatternField {...props} />
      <YesNoQuestion
        name="imperfections"
        question={question}
        value={props.currentValue.imperfectionExists as YesNo}
        onChange={(v) => {
          props.setFormField({ imperfectionExists: v });
          if (v === 'No') props.setFormField({ imperfections: [[]] });
        }}
      >
        <ImperfectionField {...props} />
      </YesNoQuestion>
    </Box>
  );
};

import React from 'react';
import {
  Flex,
  Heading,
  FormControl,
  FormHelperText,
  FormLabel
} from '@chakra-ui/react';
import { ErrorMessage, OtherTextInput } from '../general';
import { isTouched } from '../../utils/validationUtils';

const RESPONSIVE_WIDTH = { md: '25rem', lg: '30rem' };

interface LengthFieldProps {
  length?: string;
  setLength: (val?: string) => void;
  lengthErrors?: string;
}

export const LengthField: React.FC<LengthFieldProps> = ({
  length,
  setLength,
  lengthErrors
}) => {
  const errors = lengthErrors ? [lengthErrors] : undefined;
  const touched = isTouched(length);
  return (
    <Flex minW={RESPONSIVE_WIDTH}>
      <FormControl as="fieldset" isInvalid={!!lengthErrors && touched}>
        <FormLabel mb={5}>
          <Heading>Length</Heading>
        </FormLabel>
        <OtherTextInput
          type="number"
          placeholder="Length (M)"
          autoComplete="off"
          value={length ?? ''}
          onChange={(e) => setLength(e.target.value)}
        />
        <ErrorMessage errMsgs={errors} />
        <FormHelperText>Enter numbers.</FormHelperText>
      </FormControl>
    </Flex>
  );
};

import { Tr, Table, Thead, Th, Tbody, TableCaption } from '@chakra-ui/react';
import {
  Fabric,
  getNumVariants,
  FabProduct,
  ProductTypes,
  getUnboxingDate,
  VariantableValue,
  FabricAttributes as fa
} from 'product-validator';
import React from 'react';
import {
  FormFieldState,
  ValidationErrors,
  variantedField
} from '../../../types/formTypes';
import { AppField } from '../../../types/appFieldTypes';
import { appFields } from '../../../pages/ProductEntry';
import { canNavigate } from '../../../utils/navigationUtils';
import { FieldRow } from './FieldRow';
import { FieldRowEntries, getFieldRowEntries } from './FieldRowEntries';
import { getContent } from '../../../utils/utils';

interface FabricTableProps {
  product?: FabProduct;
  validationErrors: ValidationErrors;
  currentValue: FormFieldState;
  images?: string[];
  imageErrors?: string;
}

export const getPath = (
  currentValue: FormFieldState,
  appFields: AppField[]
): string => {
  let path = '';
  appFields.forEach((appField) => {
    if (canNavigate(currentValue, appField.name)) path = appField.path;
  });
  return path;
};

export const ProductTable = ({
  product,
  validationErrors,
  currentValue,
  images,
  imageErrors
}: FabricTableProps) => {
  const numVariants =
    product?.productType === 'Fabric' && getNumVariants(product as Fabric);

  return (
    <Table size="sm" variant="simple" m="4">
      {product?.productType === ProductTypes.Fabric && (
        <TableCaption>There are {numVariants ?? 'no'} variants</TableCaption>
      )}
      <Thead>
        <Tr>
          <Th>Field (click to go to page)</Th>
          <Th>Is variant field?</Th>
          <Th>Value</Th>
          <Th>Errors</Th>
        </Tr>
      </Thead>
      <Tbody>
        {getFieldRowEntries(product?.productType ?? ProductTypes.Fabric).map(
          (rowKey) => {
            const {
              valueKey,
              appFieldKeys,
              showRowFn,
              isVariantedFn
            } = FieldRowEntries[rowKey];

            let rowAppFields: AppField[] = [];
            appFieldKeys.forEach((k) => {
              rowAppFields.push(appFields[k as string]);
            });

            let value;
            if (rowKey === 'Images') {
              value = images?.length.toString();
            } else if (rowKey === 'Fabric Content') {
              value = getContent(product?.content);
            } else if (rowKey === 'Unboxing') {
              value = currentValue.unboxingDateExists
                ? getUnboxingDate(currentValue.unboxingDate)
                : '';
            } else if (
              rowKey === 'Width (cm)' &&
              currentValue.quality === fa.Quality.Aesthetic.Tubular
            ) {
              value = `${product?.widthCM} ${
                (product?.widthCM as number) * 2
                  ? `(${(product?.widthCM as number) * 2}CM)`
                  : ''
              }`;
            } else {
              value = product?.[valueKey as keyof FabProduct] as
                | string[]
                | string
                | number
                | boolean
                | Fabric
                | variantedField<string>
                | variantedField<string[]>
                | VariantableValue<number>;
            }

            const errMsgs = validationErrors[valueKey];
            const toPath = getPath(currentValue, rowAppFields);
            const showRow =
              toPath !== '' &&
              ((showRowFn ? showRowFn(currentValue) : undefined) ?? true);
            const isVarianted =
              (isVariantedFn ? isVariantedFn(currentValue) : undefined) ??
              false;

            if (!showRow) return undefined;
            return (
              <FieldRow
                key={rowKey}
                fieldName={rowKey as string}
                value={value}
                errMsgs={errMsgs}
                toPath={toPath}
                isVarianted={isVarianted}
              />
            );
          }
        )}
      </Tbody>
    </Table>
  );
};

import React from 'react';
import { FormState } from '../FormStateContainer';
import { FormFieldState } from '../../../types/formTypes';
import { Card, SwitchInput } from '../../general';
import { FabricAttributes as fa } from 'product-validator';

export interface ColourToneFieldComponentProps {
  setFormField: FormState['setFormField'];
  currentValue: FormFieldState;
}

export const ColourToneField: React.FC<ColourToneFieldComponentProps> = ({
  setFormField,
  currentValue
}) => {
  return (
    <Card title="Optional" maxW="10rem">
      <SwitchInput
        label="Light"
        isChecked={
          currentValue.colourTone
            ? currentValue.colourTone === fa.ColourTone.Light
            : false
        }
        onChange={() => {
          setFormField((oldForm) => {
            const newForm = { ...oldForm };
            if (oldForm.colourTone === fa.ColourTone.Light)
              newForm.colourTone = fa.ColourTone.None;
            else newForm.colourTone = fa.ColourTone.Light;
            return newForm;
          });
        }}
      />
      <SwitchInput
        label="Dark"
        isChecked={
          currentValue.colourTone
            ? currentValue.colourTone === fa.ColourTone.Dark
            : false
        }
        onChange={() => {
          setFormField((oldForm) => {
            const newForm = { ...oldForm };
            if (oldForm.colourTone === fa.ColourTone.Dark)
              newForm.colourTone = fa.ColourTone.None;
            else newForm.colourTone = fa.ColourTone.Dark;
            return newForm;
          });
        }}
      />
    </Card>
  );
};

import React from 'react';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { FormControl, FormHelperText, Stack, Heading } from '@chakra-ui/react';
//import { YesNo } from '../../../../types/formTypes';
import { OtherTextInput, ErrorMessage } from '../../../general';
import {
  getNestedValidationError,
  isTouched
} from '../../../../utils/validationUtils';

export const BiasTapeBrandField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  const errMsgs = getNestedValidationError('brand', validationErrors);
  const touched = isTouched(currentValue.brand, currentValue.brandOther);
  return (
    <FormControl as="fieldset" isInvalid={errMsgs.length > 0 && touched}>
      <Stack spacing={3}>
        <Heading>Brand</Heading>
        <FormHelperText>Optional</FormHelperText>
        <OtherTextInput
          value={currentValue.brandOther ?? ''}
          onChange={(e) => setFormField({ brandOther: e.target.value })}
        />
        <FormHelperText>Type in a brand.</FormHelperText>
      </Stack>
      <ErrorMessage errMsgs={errMsgs} />
    </FormControl>
  );
};

import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Button,
  Center,
  Heading,
  VStack,
  InputGroup,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputRightElement
} from '@chakra-ui/react';
import { authLogin } from '../api/auth';
import { checkAuth } from '../utils/authSession';

export const Login = (props: RouteComponentProps) => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  // Display error message when token has expired
  useEffect(() => {
    if (props.location.state) {
      const error = (props.location.state as { errorMsg: string }).errorMsg;
      setErrorMsg(error);
    }
  }, [setErrorMsg, props.location.state]);

  const handleClick = () => setShow(!show);

  const changeEmailHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const changePasswordHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  };

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();

    // Make API call
    const response = await authLogin(email, password);

    if (response.status === 200) {
      // No errors, redirect to beginning of product page
      props.history.push('/');
    } else if (response.status === 401) {
      setErrorMsg(response.data);
    } else {
      setErrorMsg('Server error, please try again later');
    }
  };

  if (checkAuth()) props.history.push('/product_entry');

  return (
    <Center w="100%" h="70%">
      <VStack>
        <Heading paddingBottom="5rem">FABCYCLE</Heading>
        <form onSubmit={submitHandler}>
          <FormControl isRequired isInvalid={!!errorMsg}>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              variant="filled"
              pr="4.5rem"
              borderRadius={0}
              onChange={changeEmailHandler}
            />
          </FormControl>
          <FormControl isRequired isInvalid={!!errorMsg}>
            <FormLabel paddingTop={2}>Password</FormLabel>
            <InputGroup size="md">
              <Input
                variant="filled"
                pr="4.5rem"
                onChange={changePasswordHandler}
                type={show ? 'text' : 'password'}
                borderRadius={0}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                  {show ? 'Hide' : 'Show'}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errorMsg}</FormErrorMessage>
          </FormControl>
          <Button
            type="submit"
            borderRadius={0}
            width="full"
            marginTop="2rem"
            _hover={{ bg: 'gray.800' }}
            color="white"
            backgroundColor="black"
            size="lg"
          >
            Log in to FABCYCLE
          </Button>
        </form>
      </VStack>
    </Center>
  );
};

import React from 'react';
import {
  ProductTypes,
  ZipperAttributes as za,
  RibbingAttributes as ra,
  ButtonProductAttributes as ba,
  ThreadAttributes as ta,
  TrimAttributes as tra,
  ElasticAttributes as ea,
  BiasTapeAttributes as bta,
  OtherSupplyAttributes as osa
} from 'product-validator';
import { AppFieldComponent } from '../../../types/appFieldTypes';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Heading,
  Box
} from '@chakra-ui/react';
import { ButtonRadioGroup } from '../../general/ButtonRadio';
import { getOtherSupplySellingUnitOptions } from '../../../utils/supplyUtils';

export const SellingUnitField: AppFieldComponent = ({
  setFormField,
  currentValue,
  toNextPage
}) => {
  let unitOptions: string[] = [];
  let boxWidth = '';

  switch (currentValue.productType) {
    case ProductTypes.Zippers:
      unitOptions = Object.values(za.SellingUnit);
      boxWidth = '30rem';
      break;
    case ProductTypes.Ribbing:
      unitOptions = Object.values(ra.SellingUnit);
      boxWidth = '60rem';
      break;
    case ProductTypes.Buttons:
      unitOptions = Object.values(ba.SellingUnit);
      boxWidth = '30rem';
      break;
    case ProductTypes.Thread:
      unitOptions = Object.values(ta.SellingUnit);
      boxWidth = '30rem';
      break;
    case ProductTypes.Trim:
      unitOptions = Object.values(tra.SellingUnit);
      boxWidth = '30rem';
      break;
    case ProductTypes.Elastic:
      unitOptions = Object.values(ea.SellingUnit);
      boxWidth = '30rem';
      break;
    case ProductTypes['Bias Tape']:
      unitOptions = Object.values(bta.SellingUnit);
      boxWidth = '30rem';
      break;
    case ProductTypes['Other Supply']:
      unitOptions = getOtherSupplySellingUnitOptions(
        currentValue.otherSupplyType as string
      );
      // 3 options is a row, 4 options is a 2x2 box
      if (unitOptions.length < 4) {
        boxWidth = '40rem';
      } else {
        boxWidth = '30rem';
      }
      break;
  }

  return (
    <FormControl as="fieldset">
      <FormLabel as="legend" mb="1.5rem">
        <Heading>Selling Unit</Heading>
      </FormLabel>
      <Box maxW={boxWidth}>
        <ButtonRadioGroup
          name="Selling Unit"
          options={unitOptions}
          value={currentValue.sellingUnit}
          onChange={(v) => {
            setFormField({
              sellingUnit: v as
                | za.SellingUnit
                | ra.SellingUnit
                | ba.SellingUnit
                | ta.SellingUnit
                | ea.SellingUnit
                | bta.SellingUnit
                | osa.SellingUnit
            });
            toNextPage({ navOnNextFormUpdate: true });
          }}
        />
      </Box>
      <FormHelperText>Choose one.</FormHelperText>
    </FormControl>
  );
};

import {
  FormControl,
  FormLabel,
  Heading,
  Box,
  HStack,
  Stack
} from '@chakra-ui/react';
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark
} from '@chakra-ui/react';
import React from 'react';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { Divider } from '../../../general/Divider';
import { ErrorMessage } from '../../../general';
import { FabricAttributes as fa } from 'product-validator';
import { getNestedValidationError } from '../../../../utils/validationUtils';

export const StretchPercentageField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  const widthStretchErrMsgs = getNestedValidationError(
    'widthStretch',
    validationErrors
  );
  const lengthStretchErrMsgs = getNestedValidationError(
    'lengthStretch',
    validationErrors
  );
  return (
    <Box>
      <FormControl
        as="fieldset"
        isInvalid={
          widthStretchErrMsgs.length > 0 || lengthStretchErrMsgs.length > 0
        }
      >
        <FormLabel as="legend" mb="3rem">
          <Heading>What is the stretch percentage?</Heading>
        </FormLabel>
        <Stack spacing={8}>
          <HStack spacing={5}>
            <Heading size={'md'}>Width:</Heading>
            <Slider
              defaultValue={0}
              min={0}
              max={100}
              step={10}
              onChange={(v) => {
                setFormField({
                  widthStretch: v
                });
              }}
              isDisabled={
                currentValue.stretch === fa.Stretch['2-way'] &&
                !!currentValue.lengthStretch &&
                currentValue.lengthStretch >= 10
              }
            >
              <SliderMark value={0} mt="1" ml="-2.5" fontSize="sm">
                0%
              </SliderMark>
              <SliderMark value={10} mt="1" ml="-2.5" fontSize="sm">
                10%
              </SliderMark>
              <SliderMark value={20} mt="1" ml="-2.5" fontSize="sm">
                20%
              </SliderMark>
              <SliderMark value={30} mt="1" ml="-2.5" fontSize="sm">
                30%
              </SliderMark>
              <SliderMark value={40} mt="1" ml="-2.5" fontSize="sm">
                40%
              </SliderMark>
              <SliderMark value={50} mt="1" ml="-2.5" fontSize="sm">
                50%
              </SliderMark>
              <SliderMark value={60} mt="1" ml="-2.5" fontSize="sm">
                60%
              </SliderMark>
              <SliderMark value={70} mt="1" ml="-2.5" fontSize="sm">
                70%
              </SliderMark>
              <SliderMark value={80} mt="1" ml="-2.5" fontSize="sm">
                80%
              </SliderMark>
              <SliderMark value={90} mt="1" ml="-2.5" fontSize="sm">
                90%
              </SliderMark>
              <SliderMark value={100} mt="1" ml="-2.5" fontSize="sm">
                100%
              </SliderMark>
              <SliderMark
                value={currentValue.widthStretch ?? 0}
                textAlign="center"
                bg="purple.500"
                color="white"
                mt="-10"
                ml="-5"
                w="12"
              >
                {currentValue.widthStretch}%
              </SliderMark>
              <SliderTrack>
                <SliderFilledTrack bg={'purple.500'} />
              </SliderTrack>
              <SliderThumb bg="purple.500" />
            </Slider>
          </HStack>

          <Divider />
          <HStack spacing={4}>
            <Heading size="md">Length:</Heading>
            <Slider
              defaultValue={0}
              min={0}
              max={100}
              step={10}
              onChange={(v) => {
                setFormField({
                  lengthStretch: v
                });
              }}
              isDisabled={
                currentValue.stretch === fa.Stretch['2-way'] &&
                !!currentValue.widthStretch &&
                currentValue.widthStretch >= 10
              }
            >
              <SliderMark value={0} mt="1" ml="-2.5" fontSize="sm">
                0%
              </SliderMark>
              <SliderMark value={10} mt="1" ml="-2.5" fontSize="sm">
                10%
              </SliderMark>
              <SliderMark value={20} mt="1" ml="-2.5" fontSize="sm">
                20%
              </SliderMark>
              <SliderMark value={30} mt="1" ml="-2.5" fontSize="sm">
                30%
              </SliderMark>
              <SliderMark value={40} mt="1" ml="-2.5" fontSize="sm">
                40%
              </SliderMark>
              <SliderMark value={50} mt="1" ml="-2.5" fontSize="sm">
                50%
              </SliderMark>
              <SliderMark value={60} mt="1" ml="-2.5" fontSize="sm">
                60%
              </SliderMark>
              <SliderMark value={70} mt="1" ml="-2.5" fontSize="sm">
                70%
              </SliderMark>
              <SliderMark value={80} mt="1" ml="-2.5" fontSize="sm">
                80%
              </SliderMark>
              <SliderMark value={90} mt="1" ml="-2.5" fontSize="sm">
                90%
              </SliderMark>
              <SliderMark value={100} mt="1" ml="-2.5" fontSize="sm">
                100%
              </SliderMark>
              <SliderMark
                value={currentValue.lengthStretch ?? 0}
                textAlign="center"
                bg="purple.500"
                color="white"
                mt="-10"
                ml="-5"
                w="12"
              >
                {currentValue.lengthStretch}%
              </SliderMark>
              <SliderTrack>
                <SliderFilledTrack bg={'purple.500'} />
              </SliderTrack>
              <SliderThumb bg="purple.500" />
            </Slider>
          </HStack>
        </Stack>
        <ErrorMessage errMsgs={lengthStretchErrMsgs} />
        <ErrorMessage errMsgs={widthStretchErrMsgs} />
      </FormControl>
    </Box>
  );
};

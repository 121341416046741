import { FabricAttributes as fa } from 'product-validator';

export const ESTIMATED_GENERAL_PRICING = {
  [fa.ContentGeneral.Natural]: 10,
  [fa.ContentGeneral['Natural Blend']]: 8,
  [fa.ContentGeneral.Synthetic]: 8,
  [fa.ContentGeneral['Synthetic Blend']]: 6
};
export enum FibreType {
  Natural = 'Natural',
  Synthetic = 'Synthetic',
  SemiSynthetic = 'SemiSynthetic'
}

export const NaturalFibres = {
  [fa.ContentSpecific.Cashmere]: fa.ContentSpecific.Cashmere,
  [fa.ContentSpecific.Cotton]: fa.ContentSpecific.Cotton,
  [fa.ContentSpecific.Hemp]: fa.ContentSpecific.Hemp,
  [fa.ContentSpecific.Linen]: fa.ContentSpecific.Linen,
  [fa.ContentSpecific['Organic Cotton']]: fa.ContentSpecific['Organic Cotton'],
  [fa.ContentSpecific.Silk]: fa.ContentSpecific.Silk,
  [fa.ContentSpecific.Wool]: fa.ContentSpecific.Wool
};

export const SemiSyntheticFibres = {
  [fa.ContentSpecific.Lyocell]: fa.ContentSpecific.Lyocell,
  [fa.ContentSpecific.Modal]: fa.ContentSpecific.Modal,
  [fa.ContentSpecific.Rayon]: fa.ContentSpecific.Rayon,
  [fa.ContentSpecific['Rayon From Bamboo']]:
    fa.ContentSpecific['Rayon From Bamboo'],
  [fa.ContentSpecific.Tencel]: fa.ContentSpecific.Tencel
};

export const SyntheticFibres = {
  [fa.ContentSpecific.Acetate]: fa.ContentSpecific.Acetate,
  [fa.ContentSpecific.Acrylic]: fa.ContentSpecific.Acrylic,
  [fa.ContentSpecific.Nylon]: fa.ContentSpecific.Nylon,
  [fa.ContentSpecific.PU]: fa.ContentSpecific.PU,
  [fa.ContentSpecific.PVC]: fa.ContentSpecific.PVC,
  [fa.ContentSpecific.Polyester]: fa.ContentSpecific.Polyester
};

export type FibreTypeMix = {
  primary?: FibreType;
  secondary?: FibreType;
};

/**
 * @primary: Natural
 * @secondary: Semi-Synthetic
 */
export const FIBRE_TYPE_MIX_NHS: FibreTypeMix = {
  primary: FibreType.Natural,
  secondary: FibreType.SemiSynthetic
};

/**
 * @primary: Synthetic
 * @secondary: Natural
 */
export const FIBRE_TYPE_MIX_SN: FibreTypeMix = {
  primary: FibreType.Synthetic,
  secondary: FibreType.Natural
};

/**
 * @primary: Natural
 * @secondary: Synthetic
 */
const FIBRE_TYPE_MIX_NS: FibreTypeMix = {
  primary: FibreType.Natural,
  secondary: FibreType.Synthetic
};

/**
 * @primary: Synthetic
 * @secondary: Semi-Synthetic
 */
const FIBRE_TYPE_MIX_SHS: FibreTypeMix = {
  primary: FibreType.Synthetic,
  secondary: FibreType.SemiSynthetic
};

/**
 * @primary: Semi-Synthetic
 * @primary: Synthetic
 */
const FIBRE_TYPE_MIX_HSS: FibreTypeMix = {
  primary: FibreType.SemiSynthetic,
  secondary: FibreType.Synthetic
};

/**
 * FibreTypeMix combinations that deduct $2 from the price
 */
export const FIBRE_TYPE_MIX_DEDUCT2: FibreTypeMix[] = [
  FIBRE_TYPE_MIX_NS,
  FIBRE_TYPE_MIX_SHS,
  FIBRE_TYPE_MIX_HSS
];

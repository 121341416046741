import { FabProduct, ProductTypes } from 'product-validator';
import React, { useCallback, useEffect, useState } from 'react';
import { FormFieldState, ValidationErrors } from '../../types/formTypes';
import { fillAutoFields } from '../../utils/formUtils';
import { useRefreshPersistantState } from '../../utils/hooks';
import { validateForm } from '../../utils/validationUtils';

export interface FormState {
  setFormField: (
    newField:
      | Partial<FormFieldState>
      | ((oldForm: FormFieldState) => FormFieldState)
  ) => void;
  currentValue: FormFieldState;
  validationErrors: ValidationErrors;
  images: string[] | undefined;
  imageErrors: string | undefined;
  setImages: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  product?: FabProduct;
  resetFormProduct: () => void;
}

export const createInitFormState = (
  initState: Partial<FormFieldState>
): FormFieldState => {
  return {
    widthRadio: [],
    widthCM: [],
    widthOther: [],
    lengthRadio: [],
    lengthM: [],
    lengthMOther: [],
    imperfections: [],
    imperfectionOther: [],
    price: [],
    quantity: [],
    quantityRadio: [],
    quantityOther: [],
    productType: initState.productType ?? undefined,
    unboxingDate: initState.unboxingDate,
    unboxingDateExists: initState.unboxingDateExists
  };
};

const FORM_INIT_STATE: Pick<
  FormState,
  'validationErrors' | 'images' | 'imageErrors' | 'product'
> = {
  validationErrors: {},
  images: undefined,
  imageErrors: undefined,
  product: undefined
};

type props = {
  children: (form: FormState) => JSX.Element;
  isSupplies: boolean;
};
export const FormStateContainer: React.FC<props> = ({
  children,
  isSupplies
}) => {
  const [validationErrors, setValidationErrors] = useState(
    FORM_INIT_STATE.validationErrors
  );
  const [currentValue, setFormFieldState] = useRefreshPersistantState(
    createInitFormState({
      productType: isSupplies ? undefined : ProductTypes.Fabric
    }),
    'productForm'
  );
  const [product, setProduct] = useState(FORM_INIT_STATE.product);
  const [images, setImages] = useState(FORM_INIT_STATE.images);
  const [imageErrors, setImageErrors] = useState(FORM_INIT_STATE.imageErrors);

  const resetFormFields = useCallback(() => {
    setFormFieldState(
      createInitFormState({
        productType: currentValue.productType,
        unboxingDate: currentValue.unboxingDate,
        unboxingDateExists: currentValue.unboxingDateExists
        // Form fields carried over to next product
      })
    );
  }, [
    currentValue.productType,
    currentValue.unboxingDate,
    currentValue.unboxingDateExists,
    setFormFieldState
  ]);

  const resetFormProduct = useCallback(() => {
    resetFormFields();
    setValidationErrors(FORM_INIT_STATE.validationErrors);
    setImages(FORM_INIT_STATE.images);
    setImageErrors(FORM_INIT_STATE.imageErrors);
    setProduct(FORM_INIT_STATE.product);
  }, [resetFormFields]);

  const setFormField: FormState['setFormField'] = useCallback(
    async (newField) => {
      setFormFieldState((oldForm: FormFieldState) => {
        let newForm: FormFieldState;
        if (newField instanceof Function) newForm = newField(oldForm);
        else newForm = { ...oldForm, ...newField };
        fillAutoFields(oldForm, newForm);

        return newForm;
      });
    },
    [setFormFieldState]
  );

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      const localStorageForm = localStorage.getItem('devFabricForm');
      if (localStorageForm) setFormFieldState(JSON.parse(localStorageForm));
    }
  }, [setFormFieldState]);

  useEffect(() => {
    (async () => {
      const { product, validationErrors } = await validateForm(currentValue);
      setProduct(product);
      setValidationErrors(validationErrors);
    })();
  }, [currentValue]);

  useEffect(() => {
    (async () => {
      // Validate images array
      try {
        if (images === undefined || images.length < 3)
          throw new Error('Must have at least 3 images');
        else if (images?.length > 7)
          throw new Error('Maximum of 7 images can be uploaded');

        setImageErrors('');
      } catch (err) {
        // setImageErrors(err.message);
        setImageErrors('');
      }
    })();
  }, [images]);

  return (
    <>
      {typeof children === 'function' &&
        children({
          setFormField,
          currentValue,
          validationErrors,
          images,
          imageErrors,
          setImages,
          product,
          resetFormProduct
        } as FormState)}
    </>
  );
};

import {
  FormControl,
  Stack,
  FormHelperText,
  Flex,
  CheckboxGroup
} from '@chakra-ui/react';
import React from 'react';
import { FabricAttributes as fa, MixType } from 'product-validator';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import {
  getNestedValidationError,
  isTouched
} from '../../../../utils/validationUtils';
import {
  OtherTextInput,
  ErrorMessage,
  Card,
  ButtonRadioGroup,
  SwitchInput,
  CheckboxButton,
  WrappedInputGroup
} from '../../../general';
import { FormFieldState } from '../../../../types/formTypes';

const RESPONSIVE_WIDTH = { md: '23rem', lg: '34rem' };

const SECONDARY_FIBRES = Object.values(fa.ContentSpecific);
// Sorting: https://stackoverflow.com/a/33811507

const ContentSpecificPrimaryOrder = [
  fa.ContentSpecific.Cotton,
  fa.ContentSpecific.Polyester,
  fa.ContentSpecific['Rayon From Bamboo'],
  fa.ContentSpecific.Wool,
  fa.ContentSpecific.Nylon,
  fa.ContentSpecific.Rayon,
  fa.ContentSpecific.Linen,
  fa.ContentSpecific.Acrylic,
  fa.ContentSpecific.Tencel,
  fa.ContentSpecific['Organic Cotton'],
  fa.ContentSpecific.Acetate,
  fa.ContentSpecific.Silk,
  fa.ContentSpecific.PVC,
  fa.ContentSpecific.Modal,
  fa.ContentSpecific.Hemp,
  fa.ContentSpecific.PU,
  fa.ContentSpecific.Lyocell,
  fa.ContentSpecific.Cashmere
];

const PRIMARY_FIBRES = [...SECONDARY_FIBRES].sort((a, b) => {
  return (
    ContentSpecificPrimaryOrder.indexOf(a) -
    ContentSpecificPrimaryOrder.indexOf(b)
  );
});
PRIMARY_FIBRES.splice(PRIMARY_FIBRES.indexOf('Spandex'), 1);

interface FibreBankProps {
  content: FormFieldState['content'];
  helpText: string;
  title: string;
  field: MixType;
  errMsgs: string[];
  touched: boolean;
  isCheckBox: boolean;
  onTextChange: (field: MixType, text: string) => void;
  switchComponent?: React.ReactNode;
  children: React.ReactNode;
}

interface FibreOptionsProps {
  content: FormFieldState['content'];
  options: string[];
  name: string;
  field: MixType;
  onRadioChange: (field: MixType, radioValue: string) => void;
  isDisabled?: boolean;
}

const PrimaryFibreOptions = ({
  content,
  options,
  name,
  field,
  onRadioChange,
  isDisabled
}: FibreOptionsProps) => (
  <ButtonRadioGroup
    name={name}
    isDisabled={isDisabled}
    value={content?.estimatedSpecificContent?.[field]}
    options={options}
    buttonProps={{
      size: 'sm'
    }}
    onChange={(v) => {
      return onRadioChange(field, v);
    }}
  />
);

const SecondaryFibreOptions = ({
  content,
  options,
  field,
  onRadioChange,
  isDisabled
}: FibreOptionsProps) => (
  <CheckboxGroup
    value={[content?.estimatedSpecificContent?.[field] ?? '']}
    onChange={(v: string[]) => {
      return onRadioChange(field, v[v.length - 1]);
    }}
  >
    <WrappedInputGroup>
      {options.map((value) => (
        <CheckboxButton
          isDisabled={isDisabled}
          size="sm"
          key={value}
          value={value}
        >
          {value}
        </CheckboxButton>
      ))}
    </WrappedInputGroup>
  </CheckboxGroup>
);

const FibreBank = ({
  content,
  helpText,
  title,
  field,
  errMsgs,
  touched,
  onTextChange,
  switchComponent,
  children
}: FibreBankProps) => (
  <FormControl
    as="fieldset"
    isInvalid={errMsgs.length > 0 && touched}
    maxW={RESPONSIVE_WIDTH}
  >
    <FormHelperText>{helpText}</FormHelperText>
    <Stack spacing="3">
      <Card title={title}>
        {children}
        {switchComponent}
      </Card>
      <OtherTextInput
        onChange={(e) => onTextChange(field, e.target.value)}
        value={content?.estimatedSpecificContentOther?.[field] ?? ''}
      />
    </Stack>
    <ErrorMessage errMsgs={errMsgs} />
  </FormControl>
);

export const SpecificContentField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors
}) => {
  const onTextChange = async (field: MixType, text: string) => {
    setFormField({
      content: {
        ...currentValue.content,
        contentType: fa.ContentType.EstimatedSpecific,
        estimatedSpecificContent:
          currentValue.content?.estimatedSpecificContent,
        estimatedSpecificContentOther: {
          ...currentValue.content?.estimatedSpecificContentOther,
          [field]: text
        }
      }
    });
  };

  const onRadioChange = (field: MixType, radioValue: string) => {
    setFormField({
      content: {
        ...currentValue.content,
        contentType: fa.ContentType.EstimatedSpecific,
        estimatedSpecificContent: {
          ...currentValue.content?.estimatedSpecificContent,
          [field]: radioValue
        },
        estimatedSpecificContentOther:
          currentValue.content?.estimatedSpecificContentOther
      }
    });
  };

  const primaryErrMsgs = getNestedValidationError(
    'content.estimatedSpecificContent.primary',
    validationErrors
  );

  const secondaryErrMsgs = getNestedValidationError(
    'content.estimatedSpecificContent.secondary',
    validationErrors
  );

  const touched = isTouched(
    currentValue.content?.estimatedSpecificContent?.primary,
    currentValue.content?.estimatedSpecificContentOther?.primary,
    currentValue.content?.estimatedSpecificContent?.secondary,
    currentValue.content?.estimatedSpecificContentOther?.secondary
  );

  return (
    <Flex
      flexDirection="row"
      wrap="wrap"
      gridGap="1rem"
      justifyContent="center"
    >
      <FibreBank
        content={currentValue?.content}
        errMsgs={primaryErrMsgs}
        field="primary"
        helpText="Select one"
        title="Primary"
        touched={touched}
        isCheckBox={false}
        onTextChange={onTextChange}
      >
        <PrimaryFibreOptions
          content={currentValue?.content}
          options={PRIMARY_FIBRES}
          name="estimatedPrimary"
          field="primary"
          isDisabled={
            !!currentValue?.content?.estimatedSpecificContentOther?.primary
          }
          onRadioChange={onRadioChange}
        />
      </FibreBank>
      <FibreBank
        content={currentValue?.content}
        errMsgs={secondaryErrMsgs}
        field="secondary"
        helpText="*Optional"
        title="Secondary"
        touched={touched}
        isCheckBox={true}
        onTextChange={onTextChange}
        switchComponent={
          <SwitchInput
            paddingTop="3"
            label="Or choose Blend"
            isChecked={
              currentValue.content?.estimatedSpecificContentBlend ?? false
            }
            onChange={() =>
              setFormField((oldForm) => {
                const newForm = { ...oldForm };
                const oldContent = { ...newForm.content };
                newForm.content = {
                  ...oldContent,
                  contentType: fa.ContentType.EstimatedSpecific,
                  estimatedSpecificContentBlend:
                    !newForm.content?.estimatedSpecificContentBlend ?? true
                };
                return newForm;
              })
            }
          />
        }
      >
        <SecondaryFibreOptions
          content={currentValue?.content}
          options={SECONDARY_FIBRES}
          name="estimatedSecondary"
          field="secondary"
          onRadioChange={onRadioChange}
          isDisabled={
            (currentValue.content?.estimatedSpecificContentBlend ||
              !!currentValue.content?.estimatedSpecificContentOther
                ?.secondary) ??
            false
          }
        />
      </FibreBank>
    </Flex>
  );
};

import React from 'react';
import { VStack } from '@chakra-ui/react';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { YesNo } from '../../../../types/formTypes';
import { YesNoQuestion } from '../../../general';
import { ImperfectionField } from '../ImperfectionField';

export const OtherSupplyImperfectionsField: AppFieldComponent = (props) => {
  return (
    <VStack spacing="8" alignItems="left">
      <YesNoQuestion
        name="imperfections"
        question={'Does the product have any imperfections?'}
        value={props.currentValue.imperfectionExists as YesNo}
        onChange={(v) => {
          props.setFormField({ imperfectionExists: v });
          if (v === 'No') props.setFormField({ imperfections: [[]] });
        }}
      >
        <ImperfectionField {...props} />
      </YesNoQuestion>
    </VStack>
  );
};

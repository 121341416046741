import { Center, Text, VStack } from '@chakra-ui/react';
import React from 'react';

export const NoPageFound = () => {
  return (
    <Center w="100%" h="100%">
      <VStack>
        <Text fontSize="xl">Page not found</Text>
      </VStack>
    </Center>
  );
};

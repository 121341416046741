import {
  FormControl,
  FormLabel,
  FormHelperText,
  Stack,
  Heading
} from '@chakra-ui/react';
import { FabricAttributes } from 'product-validator';
import React from 'react';
import {
  getNestedValidationError,
  isTouched
} from '../../../../utils/validationUtils';
import {
  ButtonRadioGroup,
  ErrorMessage,
  OtherTextInput,
  Card
} from '../../../general';
import { AppFieldComponent } from '../../../../types/appFieldTypes';

export const GeneralContentField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors
}) => {
  const onTextChange = async (text: string) => {
    setFormField({
      content: {
        ...currentValue.content,
        contentType: FabricAttributes.ContentType.EstimatedGeneral,
        estimatedGeneralContentOther: text
      }
    });
  };

  const errMsgs: string[] = getNestedValidationError(
    'content',
    validationErrors
  );
  const touched = isTouched(currentValue.content?.estimatedGeneralContentOther);

  return (
    <FormControl as="fieldset" isInvalid={errMsgs.length > 0 && touched}>
      <FormLabel as="legend">
        <Heading size="md">What is it generally made out of?</Heading>
      </FormLabel>
      <Stack spacing="3" alignItems="center">
        <Card maxWidth="12rem">
          <ButtonRadioGroup
            name="Estimated General"
            value={currentValue.content?.estimatedGeneralContent}
            isVertical={true}
            isDisabled={!!currentValue.content?.estimatedGeneralContentOther}
            options={Object.values(FabricAttributes.ContentGeneral).filter(
              (v) => v !== 'Other'
            )}
            onChange={(value) => {
              setFormField({
                content: {
                  ...currentValue.content,
                  contentType: FabricAttributes.ContentType.EstimatedGeneral,
                  estimatedGeneralContent: value
                }
              });
            }}
          />
        </Card>
        <OtherTextInput
          onChange={(e) => onTextChange(e.target.value)}
          value={currentValue.content?.estimatedGeneralContentOther ?? ''}
        />
      </Stack>
      <ErrorMessage errMsgs={errMsgs} />
      <FormHelperText>Choose one or enter other.</FormHelperText>
    </FormControl>
  );
};

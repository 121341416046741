import React from 'react';
import { Box } from '@chakra-ui/react';
import { YesNoQuestion } from '../../../general';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { ImperfectionField } from '../ImperfectionField';
import { QualityField } from '../QualityField';
import { YesNo } from '../../../../types/formTypes';
import { strigifyProductType } from '../../../../utils/utils';

export const ImperfectionQualityField: AppFieldComponent = (props) => {
  const question = `Does the ${strigifyProductType(
    props.currentValue.productType
  )} have any imperfections?`;
  return (
    <Box>
      <YesNoQuestion
        name="imperfections"
        question={question}
        value={props.currentValue.imperfectionExists as YesNo}
        onChange={(v) => {
          props.setFormField({ imperfectionExists: v });
          if (v === 'No') props.setFormField({ imperfections: [[]] });
        }}
      >
        <ImperfectionField {...props} />
      </YesNoQuestion>
      <QualityField {...props} />
    </Box>
  );
};

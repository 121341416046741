import React from 'react';
import { Box } from '@chakra-ui/react';
import { YesNoQuestion } from '../../../general';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { ImperfectionField } from '../ImperfectionField';
import { BrandField } from './BrandField';
import { QualityField } from '../QualityField';
import { YesNo } from '../../../../types/formTypes';

export const BrandImperfectionQualityField: AppFieldComponent = (props) => {
  return (
    <Box>
      <BrandField {...props} />
      <YesNoQuestion
        name="imperfections"
        question={
          props.currentValue.zipperHardwareType
            ? `Does the ${props.currentValue.zipperHardwareType} have any imperfections`
            : 'Does the zipper have any imperfections?'
        }
        value={props.currentValue.imperfectionExists as YesNo}
        onChange={(v) => {
          props.setFormField({ imperfectionExists: v });
          if (v === 'No') props.setFormField({ imperfections: [[]] });
        }}
      >
        <ImperfectionField {...props} />
      </YesNoQuestion>
      <QualityField {...props} />
    </Box>
  );
};

import {
  VStack,
  Heading,
  Box,
  FormControl,
  FormHelperText
} from '@chakra-ui/react';
import {
  ProductTypes,
  FabricAttributes as fa,
  RibbingAttributes as ra
} from 'product-validator';
import React from 'react';
import { Divider } from '../../general/Divider';
import { AppFieldComponent } from '../../../types/appFieldTypes';
import { WidthField } from './WidthField';
import { WidthMixedField } from './WidthMixedField';
import { LengthField } from './LengthField';
import { QuantityField } from './QuantityField';
import { ButtonRadioGroup } from '../../general';
import { HasVariantsField, LengthMixedField } from '..';

const RESPONSIVE_WIDTH = { md: '25rem', lg: '36rem' };

export const DimensionsField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  const useMixedWidth =
    currentValue.productType === ProductTypes.Trim ||
    currentValue.productType === ProductTypes.Elastic ||
    currentValue.productType === ProductTypes['Bias Tape'];

  const useMixedLength =
    currentValue.productType === ProductTypes['Bias Tape'] ||
    currentValue.productType === ProductTypes.Elastic ||
    currentValue.productType === ProductTypes.Trim;

  // All 'By Meter' selling units are equal
  const showLength = currentValue.sellingUnit !== ra.SellingUnit['By Meter'];

  const showQuantity =
    currentValue.bundleOrRoll === fa.BundleOrRoll.Bundle || useMixedWidth;

  const enablePackQuantity =
    (currentValue.productType === ProductTypes.Elastic &&
      currentValue.sellingUnit === 'Pack') ||
    (currentValue.productType === ProductTypes.Trim &&
      currentValue.sellingUnit === 'Pack');

  const showRibbingSize =
    currentValue.productType === ProductTypes.Ribbing &&
    currentValue.sellingUnit !== ra.SellingUnit['By Meter'];

  const showRollVariantsOption =
    currentValue.productType === ProductTypes.Fabric &&
    currentValue.bundleOrRoll === fa.BundleOrRoll.Roll;

  return (
    <VStack spacing="4" alignItems="left" minW={RESPONSIVE_WIDTH}>
      {useMixedWidth ? (
        <WidthMixedField
          currentValue={currentValue}
          setFormField={setFormField}
          validationErrors={validationErrors}
          toNextPage={toNextPage}
        />
      ) : (
        <WidthField
          currentValue={currentValue}
          setFormField={setFormField}
          validationErrors={validationErrors}
          toNextPage={toNextPage}
        />
      )}

      {showLength &&
        (useMixedLength ? (
          <Box>
            <Divider />
            <LengthMixedField
              currentValue={currentValue}
              setFormField={setFormField}
              validationErrors={validationErrors}
              toNextPage={toNextPage}
            />
          </Box>
        ) : (
          <Box>
            <Divider />
            <LengthField
              currentValue={currentValue}
              setFormField={setFormField}
              validationErrors={validationErrors}
              toNextPage={toNextPage}
            />
          </Box>
        ))}
      {showQuantity &&
        (enablePackQuantity ? (
          <></>
        ) : (
          <>
            <Divider />
            <QuantityField
              currentValue={currentValue}
              setFormField={setFormField}
              validationErrors={validationErrors}
              toNextPage={toNextPage}
            />
          </>
        ))}
      {showRibbingSize && (
        <FormControl as="fieldset">
          <VStack spacing="4" alignItems="left">
            <Divider />
            <Heading>What's the size?</Heading>
            <ButtonRadioGroup
              name="ribbingWidth"
              value={currentValue.size}
              options={Object.values(ra.Size)}
              onChange={(v) => setFormField({ size: v as ra.Size })}
            />
            <FormHelperText>Select one.</FormHelperText>
          </VStack>
        </FormControl>
      )}
      {showRollVariantsOption ? (
        <FormControl as="fieldset">
          <VStack spacing="4" alignItems="left">
            <Divider />
            <HasVariantsField
              currentValue={currentValue}
              setFormField={setFormField}
              validationErrors={validationErrors}
              toNextPage={toNextPage}
            />
          </VStack>
        </FormControl>
      ) : (
        <></>
      )}
    </VStack>
  );
};

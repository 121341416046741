import {
  HStack,
  NumberInput as ChakraNumberInput,
  NumberInputField,
  NumberInputProps as ChakraNumberInputProps,
  StackProps,
  Text
} from '@chakra-ui/react';
import React from 'react';

interface NumberInputProps extends ChakraNumberInputProps {
  text: string;
  stackProps?: StackProps;
  textOnLeft?: boolean;
  onChange?: (value: string) => void;
}

export const NumberInput: React.FC<NumberInputProps> = ({
  text,
  stackProps,
  textOnLeft,
  onChange,
  ...rest
}) => {
  const textComponent = <Text>{text}</Text>;
  return (
    <HStack {...stackProps}>
      {textOnLeft && textComponent}
      <ChakraNumberInput
        w="5rem"
        size="sm"
        keepWithinRange={true}
        clampValueOnBlur={false}
        onChange={(s) => onChange && onChange(s)}
        {...rest}
      >
        <NumberInputField />
      </ChakraNumberInput>
      {!textOnLeft && textComponent}
    </HStack>
  );
};

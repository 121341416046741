import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router';
import { useRouteMatch, Route } from 'react-router-dom';
import {
  NextFieldNavigator,
  PageLayout,
  SideBar,
  NoPageFound
} from '../components/general';
import {
  StartOrUpdateBox,
  SupplyTypeBoxSize,
  TrimType,
  EverythingElseCategory,
  SewingCategory,
  CraftCategory,
  OtherCategory,
  SummaryPage,
  UpdateBoxStatus
} from '../components/inventorySupplies';
import base from '../api/airtable';

export const inventorySuppliesRoutes = {
  startOrUpdate: {
    label: 'Start Or Update Box',
    to: '/inventory_supplies/start_or_update',
    component: StartOrUpdateBox
  },
  updateBoxStatus: {
    label: 'Update Box Status',
    to: '/inventory_supplies/update_box_status',
    compoenent: UpdateBoxStatus
  },
  supplyTypeBoxSize: {
    label: 'Supply Type + Box Size',
    to: '/inventory_supplies/supply_type_box_size',
    component: SupplyTypeBoxSize
  },
  trimType: {
    label: 'Trim Type',
    to: '/inventory_supplies/trim_type',
    component: TrimType
  },
  everythingElseCategory: {
    label: 'Everything Else Category',
    to: '/inventory_supplies/everything_else_category',
    component: EverythingElseCategory
  },
  sewingCategory: {
    label: `\xa0 \xa0 \xa0 \xa0 - Sewing Category`,
    to: '/inventory_supplies/sewing_category',
    component: SewingCategory
  },
  craftCategory: {
    label: `\xa0 \xa0 \xa0 \xa0 - Craft Category`,
    to: '/inventory_supplies/craft_category',
    component: CraftCategory
  },
  otherCategory: {
    label: `\xa0 \xa0 \xa0\xa0 \xa0- Other Category`,
    to: '/inventory_supplies/other_category',
    component: OtherCategory
  },
  summaryPage: {
    label: 'Summary',
    to: '/inventory_supplies/summary_page',
    component: SummaryPage
  }
};

export const inventorySuppliesRoutesUpdateBox = {
  startOrUpdate: {
    label: 'Start Or Update Box',
    to: '/inventory_supplies/start_or_update',
    component: StartOrUpdateBox
  },
  updateBoxStatus: {
    label: 'Update Box Status',
    to: '/inventory_supplies/update_box_status',
    compoenent: UpdateBoxStatus
  }
};

export const inventorySuppliesRoutesStandard = {
  startOrUpdate: {
    label: 'Start Or Update Box',
    to: '/inventory_supplies/start_or_update',
    component: StartOrUpdateBox
  },
  supplyTypeBoxSize: {
    label: 'Supply Type + Box Size',
    to: '/inventory_supplies/supply_type_box_size',
    component: SupplyTypeBoxSize
  },
  summaryPage: {
    label: 'Summary',
    to: '/inventory_supplies/summary_page',
    component: SummaryPage
  }
};

export const inventorySuppliesRoutesTrimSupply = {
  startOrUpdate: {
    label: 'Start Or Update Box',
    to: '/inventory_supplies/start_or_update',
    component: StartOrUpdateBox
  },
  supplyTypeBoxSize: {
    label: 'Supply Type + Box Size',
    to: '/inventory_supplies/supply_type_box_size',
    component: SupplyTypeBoxSize
  },
  trimType: {
    label: 'Trim Type',
    to: '/inventory_supplies/trim_type',
    component: TrimType
  },
  summaryPage: {
    label: 'Summary',
    to: '/inventory_supplies/summary_page',
    component: SummaryPage
  }
};

export const inventorySuppliesRoutesEverythingElse = {
  startOrUpdate: {
    label: 'Start Or Update Box',
    to: '/inventory_supplies/start_or_update',
    component: StartOrUpdateBox
  },
  supplyTypeBoxSize: {
    label: 'Supply Type + Box Size',
    to: '/inventory_supplies/supply_type_box_size',
    component: SupplyTypeBoxSize
  },
  everythingElseCategory: {
    label: 'Everything Else Category',
    to: '/inventory_supplies/everything_else_category',
    component: EverythingElseCategory
  },
  summaryPage: {
    label: 'Summary',
    to: '/inventory_supplies/summary_page',
    component: SummaryPage
  }
};

export const inventorySuppliesRoutesEverythingElseSewing = {
  startOrUpdate: {
    label: 'Start Or Update Box',
    to: '/inventory_supplies/start_or_update',
    component: StartOrUpdateBox
  },
  supplyTypeBoxSize: {
    label: 'Supply Type + Box Size',
    to: '/inventory_supplies/supply_type_box_size',
    component: SupplyTypeBoxSize
  },
  everythingElseCategory: {
    label: 'Everything Else Category',
    to: '/inventory_supplies/everything_else_category',
    component: EverythingElseCategory
  },
  sewingCategory: {
    label: `\xa0 \xa0 \xa0 \xa0 - Sewing Category`,
    to: '/inventory_supplies/sewing_category',
    component: SewingCategory
  },
  summaryPage: {
    label: 'Summary',
    to: '/inventory_supplies/summary_page',
    component: SummaryPage
  }
};

export const inventorySuppliesRoutesEverythingElseCraft = {
  startOrUpdate: {
    label: 'Start Or Update Box',
    to: '/inventory_supplies/start_or_update',
    component: StartOrUpdateBox
  },
  supplyTypeBoxSize: {
    label: 'Supply Type + Box Size',
    to: '/inventory_supplies/supply_type_box_size',
    component: SupplyTypeBoxSize
  },
  everythingElseCategory: {
    label: 'Everything Else Category',
    to: '/inventory_supplies/everything_else_category',
    component: EverythingElseCategory
  },
  craftCategory: {
    label: `\xa0 \xa0 \xa0 \xa0 - Craft Category`,
    to: '/inventory_supplies/craft_category',
    component: CraftCategory
  },
  summaryPage: {
    label: 'Summary',
    to: '/inventory_supplies/summary_page',
    component: SummaryPage
  }
};

export const inventorySuppliesRoutesEverythingElseOther = {
  startOrUpdate: {
    label: 'Start Or Update Box',
    to: '/inventory_supplies/start_or_update',
    component: StartOrUpdateBox
  },
  supplyTypeBoxSize: {
    label: 'Supply Type + Box Size',
    to: '/inventory_supplies/supply_type_box_size',
    component: SupplyTypeBoxSize
  },
  everythingElseCategory: {
    label: 'Everything Else Category',
    to: '/inventory_supplies/everything_else_category',
    component: EverythingElseCategory
  },
  otherCategory: {
    label: `\xa0 \xa0 \xa0\xa0 \xa0- Other Category`,
    to: '/inventory_supplies/other_category',
    component: OtherCategory
  },
  summaryPage: {
    label: 'Summary',
    to: '/inventory_supplies/summary_page',
    component: SummaryPage
  }
};

let routeList = Object.values(inventorySuppliesRoutes);

const linkGenerator = (currentPath: string) => {
  let prevPath = '';
  let nextPath = '';
  const idx = routeList.findIndex(
    (e) => e.to === `/inventory_supplies/${currentPath}`
  );

  if (idx > 0) prevPath = routeList[idx - 1].to;
  if (idx < routeList.length - 1) nextPath = routeList[idx + 1].to;

  return { prevPath, nextPath };
};

export const InventorySupplies = () => {
  const [inventorySupplyObject, setInventorySupplyObject] = useState<any>({
    isNewBox: 'no',
    boxID: '',
    supplyType: '',
    boxSize: '',
    trimType: '',
    everythingElseCategory: '',
    sewingCategory: '',
    craftCategory: '',
    otherCategory: ''
  });
  const [inventorySupplies, setInventorySupplies] = useState<any>([]);
  const [selectedInventorySupply, setSelectedInventorySupply] = useState<any>();
  const [
    inventorySuppliesSidebarRoutes,
    setInventorySuppliesSidebarRoutes
  ] = useState<any>(routeList);

  const match = useRouteMatch();

  function getInventorySupplies() {
    let holdingArray: any[] = [];
    base('Supplies - General')
      .select({ view: 'Full List' })
      .eachPage((records, fetchNextPage) => {
        for (let i = 0; i < records.length; i++) {
          holdingArray.push(records[i]);
        }
        fetchNextPage();
      });
    setInventorySupplies(holdingArray);
  }

  useEffect(() => {
    getInventorySupplies();
  }, []);

  useEffect(() => {
    if (inventorySupplyObject.isNewBox === 'no') {
      routeList = Object.values(inventorySuppliesRoutesUpdateBox);
      setInventorySuppliesSidebarRoutes(routeList);
    } else if (
      inventorySupplyObject.supplyType !== 'Trim' &&
      inventorySupplyObject.supplyType !== 'Everything Else'
    ) {
      routeList = Object.values(inventorySuppliesRoutesStandard);
      setInventorySuppliesSidebarRoutes(routeList);
    } else if (inventorySupplyObject.supplyType === 'Trim') {
      routeList = Object.values(inventorySuppliesRoutesTrimSupply);
      setInventorySuppliesSidebarRoutes(routeList);
    } else if (inventorySupplyObject.supplyType === 'Everything Else') {
      routeList = Object.values(inventorySuppliesRoutesEverythingElse);
      setInventorySuppliesSidebarRoutes(routeList);
      if (inventorySupplyObject.everythingElseCategory === 'Sewing') {
        routeList = Object.values(inventorySuppliesRoutesEverythingElseSewing);
        setInventorySuppliesSidebarRoutes(routeList);
      } else if (inventorySupplyObject.everythingElseCategory === 'Craft') {
        routeList = Object.values(inventorySuppliesRoutesEverythingElseCraft);
        setInventorySuppliesSidebarRoutes(routeList);
      } else if (inventorySupplyObject.everythingElseCategory === 'Other') {
        routeList = Object.values(inventorySuppliesRoutesEverythingElseOther);
        setInventorySuppliesSidebarRoutes(routeList);
      }
    }
  }, [inventorySupplyObject]);

  return (
    <PageLayout
      sidebar={<SideBar links={inventorySuppliesSidebarRoutes} />}
      main={
        <Switch>
          <Route path={inventorySuppliesRoutes.startOrUpdate.to}>
            <NextFieldNavigator linkGenerator={linkGenerator}>
              {(toNextPage) => (
                <StartOrUpdateBox
                  inventorySupplyObject={inventorySupplyObject}
                  setInventorySupplyObject={setInventorySupplyObject}
                />
              )}
            </NextFieldNavigator>
          </Route>
          <Route path={inventorySuppliesRoutes.updateBoxStatus.to}>
            <UpdateBoxStatus
              inventorySupplyObject={inventorySupplyObject}
              setInventorySupplyObject={setInventorySupplyObject}
              inventorySupplies={inventorySupplies}
              setInventorySupplies={setInventorySupplies}
              selectedInventorySupply={selectedInventorySupply}
              setSelectedInventorySupply={setSelectedInventorySupply}
            />
          </Route>
          <Route path={inventorySuppliesRoutes.supplyTypeBoxSize.to}>
            <NextFieldNavigator linkGenerator={linkGenerator}>
              <SupplyTypeBoxSize
                inventorySupplyObject={inventorySupplyObject}
                setInventorySupplyObject={setInventorySupplyObject}
              />
            </NextFieldNavigator>
          </Route>
          <Route path={inventorySuppliesRoutes.trimType.to}>
            <NextFieldNavigator linkGenerator={linkGenerator}>
              <TrimType
                inventorySupplyObject={inventorySupplyObject}
                setInventorySupplyObject={setInventorySupplyObject}
              />
            </NextFieldNavigator>
          </Route>
          <Route path={inventorySuppliesRoutes.everythingElseCategory.to}>
            <NextFieldNavigator linkGenerator={linkGenerator}>
              <EverythingElseCategory
                inventorySupplyObject={inventorySupplyObject}
                setInventorySupplyObject={setInventorySupplyObject}
              />
            </NextFieldNavigator>
          </Route>
          <Route path={inventorySuppliesRoutes.sewingCategory.to}>
            <NextFieldNavigator linkGenerator={linkGenerator}>
              <SewingCategory
                inventorySupplyObject={inventorySupplyObject}
                setInventorySupplyObject={setInventorySupplyObject}
              />
            </NextFieldNavigator>
          </Route>
          <Route path={inventorySuppliesRoutes.craftCategory.to}>
            <NextFieldNavigator linkGenerator={linkGenerator}>
              <CraftCategory
                inventorySupplyObject={inventorySupplyObject}
                setInventorySupplyObject={setInventorySupplyObject}
              />
            </NextFieldNavigator>
          </Route>
          <Route path={inventorySuppliesRoutes.otherCategory.to}>
            <NextFieldNavigator linkGenerator={linkGenerator}>
              <OtherCategory
                inventorySupplyObject={inventorySupplyObject}
                setInventorySupplyObject={setInventorySupplyObject}
              />
            </NextFieldNavigator>
          </Route>
          <Route path={inventorySuppliesRoutes.summaryPage.to}>
            <SummaryPage
              inventorySupplyObject={inventorySupplyObject}
              setInventorySupplyObject={setInventorySupplyObject}
              inventorySupplies={inventorySupplies}
              setInventorySupplies={setInventorySupplies}
            />
          </Route>
          <Route path={`${match.path}`}>
            <NoPageFound />
          </Route>
        </Switch>
      }
    />
  );
};

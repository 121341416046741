import { TrimAttributes as tra } from 'product-validator';

export const TRIM_PRICE_LOOKUP: {
  [trimType in tra.TrimType | 'Other']: {
    [quality in tra.Qualities | 'Other' | 'None']?: number;
  };
} = {
  [tra.TrimType.Ribbon]: {
    None: 0.25,
    Other: 0.35,
    [tra.Qualities.Metallic]: 0.35,
    [tra.Qualities.Velvet]: 0.35,
    [tra.Qualities['Latex Grip']]: 0.35,
    [tra.Qualities.Glitter]: 0.35,
    [tra.Qualities.Sequins]: 0.35,
    [tra.Qualities.Rhinestones]: 1.0,
    [tra.Qualities.Beaded]: 0.5,
    [tra.Qualities.Cording]: 0.5,
    [tra.Qualities.Embroidered]: 0.5,
    [tra.Qualities.Vintage]: 0.35
  },
  [tra.TrimType.Lace]: {
    None: 1.25,
    Other: 1.5,
    [tra.Qualities.Metallic]: 1.5,
    [tra.Qualities.Velvet]: 1.5,
    [tra.Qualities['Latex Grip']]: 1.5,
    [tra.Qualities.Glitter]: 1.5,
    [tra.Qualities.Sequins]: 1.5,
    [tra.Qualities.Rhinestones]: 3.0,
    [tra.Qualities.Beaded]: 2.5,
    [tra.Qualities.Cording]: 2.5,
    [tra.Qualities.Embroidered]: 2.5,
    [tra.Qualities.Vintage]: 1.75,
    [tra.Qualities['Hand Made']]: 2.75
  },
  [tra.TrimType.Tape]: {
    None: 1.0,
    Other: 1.25,
    [tra.Qualities.Metallic]: 1.25,
    [tra.Qualities.Velvet]: 1.25,
    [tra.Qualities['Latex Grip']]: 1.25,
    [tra.Qualities.Glitter]: 1.25,
    [tra.Qualities.Sequins]: 1.25,
    [tra.Qualities.Rhinestones]: 2.0,
    [tra.Qualities.Beaded]: 1.75,
    [tra.Qualities.Cording]: 1.75,
    [tra.Qualities.Embroidered]: 1.75,
    [tra.Qualities.Vintage]: 1.5,
    [tra.Qualities.Grommet]: 1.5,
    [tra.Qualities.Snap]: 1.5,
    [tra.Qualities['Hook and Eye']]: 1.5
  },
  [tra.TrimType.Cording]: {
    None: 1.25,
    Other: 1.5,
    [tra.Qualities.Metallic]: 1.5,
    [tra.Qualities.Velvet]: 1.5,
    [tra.Qualities['Latex Grip']]: 1.5,
    [tra.Qualities.Glitter]: 1.5,
    [tra.Qualities.Sequins]: 1.5,
    [tra.Qualities.Rhinestones]: 2.25,
    [tra.Qualities.Beaded]: 2.0,
    [tra.Qualities.Cording]: 2.0,
    [tra.Qualities.Embroidered]: 2.0,
    [tra.Qualities.Vintage]: 1.75
  },
  [tra.TrimType.Webbing]: {
    None: 1.5,
    Other: 1.75,
    [tra.Qualities.Metallic]: 1.75,
    [tra.Qualities.Velvet]: 1.75,
    [tra.Qualities['Latex Grip']]: 1.75,
    [tra.Qualities.Glitter]: 1.75,
    [tra.Qualities.Sequins]: 1.75,
    [tra.Qualities.Rhinestones]: 2.5,
    [tra.Qualities.Beaded]: 2.0,
    [tra.Qualities.Cording]: 2.0,
    [tra.Qualities.Embroidered]: 2.0,
    [tra.Qualities.Vintage]: 1.5
  },
  [tra.TrimType.Piping]: {
    None: 0.5,
    Other: 0.75,
    [tra.Qualities.Metallic]: 0.75,
    [tra.Qualities.Velvet]: 0.75,
    [tra.Qualities['Latex Grip']]: 0.75,
    [tra.Qualities.Glitter]: 0.75,
    [tra.Qualities.Sequins]: 0.75,
    [tra.Qualities.Rhinestones]: 1.5,
    [tra.Qualities.Beaded]: 1.0,
    [tra.Qualities.Cording]: 1.0,
    [tra.Qualities.Embroidered]: 1.0,
    [tra.Qualities.Vintage]: 0.75
  },
  [tra.TrimType['Faux Fur']]: {
    None: 1.5,
    Other: 2,
    [tra.Qualities.Metallic]: 1.75,
    [tra.Qualities.Velvet]: 1.75,
    [tra.Qualities['Latex Grip']]: 1.75,
    [tra.Qualities.Glitter]: 1.75,
    [tra.Qualities.Sequins]: 1.75,
    [tra.Qualities.Rhinestones]: 2.25,
    [tra.Qualities.Beaded]: 2.0,
    [tra.Qualities.Cording]: 2.0,
    [tra.Qualities.Embroidered]: 2.0,
    [tra.Qualities.Vintage]: 2.0,
    [tra.Qualities['Long Hair']]: 2.5,
    [tra.Qualities['Medium Hair']]: 2.0,
    [tra.Qualities['Short Hair']]: 1.5
  },
  [tra.TrimType.Braided]: {
    None: 1.25,
    Other: 1.5,
    [tra.Qualities.Metallic]: 1.5,
    [tra.Qualities.Velvet]: 1.5,
    [tra.Qualities['Latex Grip']]: 1.5,
    [tra.Qualities.Glitter]: 1.5,
    [tra.Qualities.Sequins]: 1.5,
    [tra.Qualities.Rhinestones]: 2.5,
    [tra.Qualities.Beaded]: 2.0,
    [tra.Qualities.Cording]: 2.0,
    [tra.Qualities.Embroidered]: 2.0,
    [tra.Qualities.Vintage]: 1.75
  },
  [tra.TrimType.Ricrac]: {
    None: 0.25,
    Other: 0.35,
    [tra.Qualities.Metallic]: 0.35,
    [tra.Qualities.Velvet]: 0.35,
    [tra.Qualities['Latex Grip']]: 0.35,
    [tra.Qualities.Glitter]: 0.35,
    [tra.Qualities.Sequins]: 0.35,
    [tra.Qualities.Rhinestones]: 1.0,
    [tra.Qualities.Beaded]: 0.5,
    [tra.Qualities.Cording]: 0.5,
    [tra.Qualities.Embroidered]: 0.5,
    [tra.Qualities.Vintage]: 0.35
  },
  Other: {
    None: 1.5,
    Other: 1.75,
    [tra.Qualities.Metallic]: 1.75,
    [tra.Qualities.Velvet]: 1.75,
    [tra.Qualities['Latex Grip']]: 1.75,
    [tra.Qualities.Glitter]: 1.75,
    [tra.Qualities.Sequins]: 1.75,
    [tra.Qualities.Rhinestones]: 2.5,
    [tra.Qualities.Beaded]: 2.0,
    [tra.Qualities.Cording]: 2.0,
    [tra.Qualities.Embroidered]: 2.0,
    [tra.Qualities.Vintage]: 1.5
  }
};

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Home } from './pages/Home';
import { Login } from './pages/Login';
import { ProductEntry } from './pages/ProductEntry';
import { ProtectedRoute } from './components/general/ProtectedRoute';
import { NoPageFound } from './components/general/NoPageFound';
import { RollToBundle } from './pages/RollToBundle';
import { InventorySupplies } from './pages/InventorySupplies';

export const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login}></Route>
        <ProtectedRoute path="/product_entry" component={ProductEntry} />
        <ProtectedRoute path="/supplies">
          <ProductEntry isSupplies={true} />
        </ProtectedRoute>
        <ProtectedRoute path="/roll_to_bundle" component={RollToBundle} />
        <ProtectedRoute
          path="/inventory_supplies"
          component={InventorySupplies}
        />
        <ProtectedRoute exact path="/" component={Home} />
        <Route path="*" component={NoPageFound} />
      </Switch>
    </Router>
  );
};

import React from 'react';
import { VStack } from '@chakra-ui/react';
import { AppFieldComponent } from '../../../types/appFieldTypes';
import { YesNo } from '../../../types/formTypes';
import { YesNoQuestion } from '../../general';
import { ImperfectionField } from './ImperfectionField';
import { CommonUsesField } from './CommonUsesField';
import { ProductTypes } from 'product-validator';

export const UsesImperfectionsField: AppFieldComponent = (props) => {
  return (
    <VStack spacing="8" alignItems="left">
      <YesNoQuestion
        name="imperfections"
        question={
          props.currentValue.productType === 'Bias Tape'
            ? `Does the bias tape have any imperfections?`
            : props.currentValue.productType === 'Elastic'
            ? `Does the elastic have any imperfections?`
            : props.currentValue.productType === 'Trim'
            ? `Does the trim have any imperfections`
            : `Does the thread have any imperfections?`
        }
        value={props.currentValue.imperfectionExists as YesNo}
        onChange={(v) => {
          props.setFormField({ imperfectionExists: v });
          if (v === 'No') props.setFormField({ imperfections: [[]] });
        }}
      >
        <ImperfectionField {...props} />
      </YesNoQuestion>
      {(props.currentValue.productType === ProductTypes.Trim ||
        props.currentValue.productType === ProductTypes.Thread) && (
        <CommonUsesField {...props} />
      )}
    </VStack>
  );
};

import React, { useCallback } from 'react';
import { Flex } from '@chakra-ui/react';
import { FormState } from '../FormStateContainer';
import { ImageGridItem } from './ImageGridItem';
import { getValidImage } from '../../../utils/utils';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import ImageDragLayer from './ImageDragLayer';

interface ImageGalleryProps {
  images?: string[];
  mainImgIdx?: number;
  setImages?: FormState['setImages'];
  setMainImg?: (img: string) => void;
  setMainImgIdx?: (val: number) => void;
}

export const ImageGallery: React.FC<ImageGalleryProps> = ({
  images,
  setMainImg,
  mainImgIdx,
  setMainImgIdx,
  setImages
}) => {
  const deleteHandler = useCallback(
    (imgId: number) => {
      if (setImages)
        setImages((prevImgs) => prevImgs?.filter((_, i) => i !== imgId));
      if (setMainImgIdx && setMainImg) {
        setMainImgIdx(0);
        setMainImg(getValidImage(images));
      }
    },
    [images, setImages, setMainImg, setMainImgIdx]
  );

  const moveImage = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (images) {
        const dragImg = images[dragIndex];
        if (setImages) {
          setImages((prevImages) =>
            update(prevImages, {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragImg]
              ]
            })
          );
        }
      }
    },
    [images, setImages]
  );

  const viewImage = useCallback(
    (index: number) => {
      if (setMainImgIdx && setMainImg) {
        setMainImgIdx(index);
        setMainImg(getValidImage(images, index));
      }
    },
    [images, setMainImg, setMainImgIdx]
  );

  // https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
  const imageList = images?.map((imgSrc, i) => (
    <ImageGridItem
      moveImage={moveImage}
      viewImage={viewImage}
      mainImgIdx={mainImgIdx}
      id={Math.random().toString(36).substring(7)}
      key={i}
      index={i}
      deleteHandler={deleteHandler}
      imgSrc={imgSrc}
    />
  ));

  return (
    <Flex
      w="100%"
      padding="1em"
      sx={{ gap: '8px' }}
      flexDirection="row"
      wrap="wrap"
      justifyContent="center"
      maxH="100%"
      overflowY="auto"
    >
      <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
        <ImageDragLayer />
        {imageList}
      </DndProvider>
    </Flex>
  );
};

import React from 'react';
import { VStack } from '@chakra-ui/react';
import { Divider } from '../../general/Divider';
import { YesNo } from '../../../types/formTypes';
import { YesNoQuestion } from '../../general';
import { AppFieldComponent } from '../../../types/appFieldTypes';
import { QualitiesField } from './QualitiesField';
import { UsesImperfectionsField } from './UsesImperfectionsField';

export const QualitiesImperfectionsField: AppFieldComponent = (props) => {
  return (
    <VStack spacing={3} alignItems="flex-start">
      <YesNoQuestion
        name="qualities"
        question={
          props.currentValue.productType === 'Bias Tape'
            ? `Does the bias tape have any qualities?`
            : `Does the elastic have any qualities?`
        }
        value={props.currentValue.qualitiesExist as YesNo}
        onChange={(v) => {
          props.setFormField({ qualitiesExist: v });
          if (v === 'No') props.setFormField({ qualities: [] });
        }}
      >
        <QualitiesField {...props} />
      </YesNoQuestion>
      <Divider />
      <UsesImperfectionsField {...props} />
    </VStack>
  );
};

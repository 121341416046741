import React from 'react';
import { ButtonRadioGroup, FormFieldTemplate } from '../../../general';
import { ZipperAttributes as za } from 'product-validator';
import { AppFieldComponent } from '../../../../types/appFieldTypes';

export const MaterialField: AppFieldComponent = ({
  currentValue,
  setFormField,
  toNextPage
}) => {
  return (
    <FormFieldTemplate title="Material">
      <ButtonRadioGroup
        name="material"
        isVertical={true}
        options={Object.values(za.Material)}
        value={currentValue.material}
        onChange={(v) => {
          setFormField({ material: v as za.Material });
          toNextPage({ navOnNextFormUpdate: true });
        }}
      />
    </FormFieldTemplate>
  );
};

import React, { useState } from 'react';
import {
  FabProduct,
  FabricAttributes as fa,
  ProductTypes,
  printLabel
} from 'product-validator';
import { useHistory } from 'react-router';
import { Heading, VStack } from '@chakra-ui/react';
import { convertToBundle } from '../../api/rollToBundle';
import { BundleTable } from './BundleTable';
import { UploadButtonConsole, UploadStatus } from '../general';
import { rollToBundleRoutes } from '../../pages/RollToBundle';
import { LabelInfo } from '../../types/rollToBundleTypes';
import { clearAuth } from '../../utils/authSession';
import { ResponseError } from '../../api';

interface SummaryPageProps {
  name?: string;
  sku?: string;
  skuErrors?: string;
  length?: string;
  lengthErrors?: string;
  resetForm: () => void;
}
export const SummaryPage: React.FC<SummaryPageProps> = ({
  name,
  sku,
  skuErrors,
  length,
  lengthErrors,
  resetForm
}) => {
  const [label, setLabel] = useState<LabelInfo>();
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(
    !!skuErrors || !!lengthErrors
      ? UploadStatus.FORM_IS_INVALID
      : UploadStatus.READY_TO_UPLOAD
  );
  const [errorResponse, setErrorResponse] = useState('');

  const history = useHistory();
  const onNewProductClick = () => {
    resetForm();
    history.push(rollToBundleRoutes.search.to);
  };

  const onNewSupplyClick = () => {
    resetForm();
    history.push(rollToBundleRoutes.search.to);
  };

  const updateRollToBundle = async () => {
    setUploadStatus(UploadStatus.UPLOADING);
    try {
      if (sku && length) {
        const lengthNum = parseFloat(length);
        if (isNaN(lengthNum)) throw new Error('Length is not a number.');

        const res = await convertToBundle(sku, lengthNum);

        // Set label information
        setLabel(res);
        setUploadStatus(UploadStatus.UPLOAD_SUCCESS);
      }
    } catch (err) {
      if (err instanceof ResponseError) {
        if (err.status === 401) {
          clearAuth();
          history.push({
            pathname: '/login',
            state: { errorMsg: err.message }
          });
          return;
        }
        setErrorResponse(err.message);
        setUploadStatus(UploadStatus.UPLOAD_FAILED);
      }
    }
  };

  return (
    <VStack>
      <Heading>Upload Bundle!</Heading>
      <BundleTable
        name={name}
        sku={sku}
        skuErrors={skuErrors}
        length={length}
        lengthErrors={lengthErrors}
      />
      <UploadButtonConsole
        printLabel={() => {
          if (label && sku) {
            const fabric = {
              productType: ProductTypes.Fabric,
              bundleOrRoll: fa.BundleOrRoll.Bundle,
              lengthM: [label.length],
              price: [label.price],
              name: label.name,
              widthCM: [label.width]
            } as FabProduct;
            printLabel(fabric, [sku], label.content);
          } else console.log("can't print label without fabric and sku");
        }}
        currentValue={undefined}
        skusMessage={sku}
        startNewProduct={onNewProductClick}
        startNewSupply={onNewSupplyClick}
        submitProductForm={updateRollToBundle}
        uploadStatus={uploadStatus}
        errorMessage={errorResponse}
      />
    </VStack>
  );
};

import {
  Box,
  ButtonProps,
  RadioGroup,
  useRadio,
  useRadioGroupContext,
  UseRadioGroupProps,
  VStack
} from '@chakra-ui/react';
import React from 'react';
import { WrappedInputGroup } from './WrappedInputGroup';
import { InputButton } from './InputButton';

interface RadioButtonProps extends ButtonProps {
  value: string;
  isDisabled?: boolean;
  children: React.ReactNode;
}

export const RadioButton = ({
  value,
  isDisabled,
  ...rest
}: RadioButtonProps) => {
  const group = useRadioGroupContext();

  const radioProps = useRadio({
    value: value,
    onChange: group?.onChange,
    isChecked: group && group.value === value,
    isDisabled: isDisabled,
    name: group?.name
  });
  return InputButton({
    getCheckboxProps: radioProps.getCheckboxProps,
    getInputProps: radioProps.getInputProps,
    isDisabled: isDisabled,
    ...rest
  });
};

interface ButtonRadioGroupProps extends UseRadioGroupProps {
  options: string[];
  name: string;
  value?: string;
  isVertical?: boolean;
  isDisabled?: boolean;
  onChange?: (e: string) => void;
  buttonProps?: ButtonProps;
  children?: (value?: string) => React.ReactNode;
}

/**
 * A Helper Component for basic radio groups.
 * More complicated layouts should be created using RadioGroup and RadioButton.
 */
export const ButtonRadioGroup = (props: ButtonRadioGroupProps) => {
  let inner = (
    <>
      {props.options.map((option) => (
        <RadioButton
          key={option}
          isDisabled={props.isDisabled}
          {...props.buttonProps}
          value={option}
        >
          {props.children ? props.children(option) : option}
        </RadioButton>
      ))}
    </>
  );

  if (props.isVertical) inner = <VStack>{inner}</VStack>;
  else inner = <WrappedInputGroup>{inner}</WrappedInputGroup>;

  return (
    <Box>
      <RadioGroup value={props.value} onChange={props.onChange}>
        {inner}
      </RadioGroup>
    </Box>
  );
};

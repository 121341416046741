// correct base: appd74jISzmL4GU8e
// test base: app0pegvm2oOgQcmc

import Airtable from 'airtable';

const base = new Airtable({
  apiKey:
    'pat0WjpDCKs2Q8znu.679a364db77c83d3f7eb8410f113aa204d9c4ebdb09019e6daf19f5c44168f99'
}).base('appd74jISzmL4GU8e');

export default base;

import React from 'react';
import { VStack } from '@chakra-ui/react';
import { Divider } from '../../../general/Divider';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { BiasTapeTypeField } from './BiasTapeTypeField';
import { BiasTapeMaterialField } from './BiasTapeMaterialField';

export const MaterialTypeField: AppFieldComponent = (props) => {
  return (
    <VStack spacing={3}>
      <BiasTapeMaterialField {...props} />
      <Divider />
      <BiasTapeTypeField {...props} />
    </VStack>
  );
};

import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

interface WrappedInputGroupProps extends FlexProps {
  numColumns?: number;
  children: React.ReactNode;
}
const RESPONSIVE_WIDTH = ['10rem', '20.25rem', '30.50rem', '40.75rem'];
export const WrappedInputGroup: React.FC<WrappedInputGroupProps> = ({
  children,
  numColumns,
  ...rest
}) => {
  return (
    <Flex
      dir="row"
      justifyContent="flex-start"
      wrap="wrap"
      sx={{ gap: '0.25rem' }}
      maxW={RESPONSIVE_WIDTH.slice(0, numColumns ?? 3)}
      minW="0"
      {...rest}
    >
      {children}
    </Flex>
  );
};

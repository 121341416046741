import { MergedProduct } from '../../../types/formTypes';
import { ZipperAttributes as za } from 'product-validator';
import {
  SellingUnit,
  ZipperHardwareMaterial,
  ZipperHardwareSU
} from 'product-validator/lib/attributes/zipperAttributes';

const LENGTH_RANGES = [
  { start: 3, end: 9 },
  { start: 10, end: 18 },
  { start: 19, end: 24 },
  { start: 25, end: 30 }
];

const individualPriceLookup: { [k in za.ZipperType]: number } = {
  [za.ZipperType.Coil]: 1,
  [za.ZipperType['Molded Plastic']]: 1.25,
  [za.ZipperType.Invisible]: 1.25,
  [za.ZipperType.Metal]: 1.5
};

const baseZipperPrice = (
  zipperType: za.ZipperType,
  quality?: string,
  addOnVal = 0.5
) => {
  let price = individualPriceLookup[zipperType];

  if (
    quality &&
    (quality === za.Quality.Designer || quality === za.Quality['Heavy Duty'])
  )
    price += addOnVal;

  return price;
};

const zipperIndividualPrice = (
  lengthIn: number,
  zipperType: za.ZipperType,
  quality?: string,
  imperfections?: string[]
) => {
  let price = 0;
  const basePriceIncrement = 0.5;
  const basePrice = baseZipperPrice(zipperType, quality);

  for (let i = 0; i < LENGTH_RANGES.length; i++) {
    if (
      lengthIn >= LENGTH_RANGES[i].start &&
      lengthIn <= LENGTH_RANGES[i].end
    ) {
      price = basePrice + i * basePriceIncrement;
      break;
    }
  }

  if (price === 0) price = basePrice + 4 * basePriceIncrement;

  // Round to two decimal places
  if (imperfections && imperfections.length > 0)
    price = Math.round(0.85 * price * 100) / 100;

  return price;
};

/**
 * Calculates the price of a zipper depending on sellingUnit and certain zipper
 * fields.
 * @param sellingUnit different pricing schemes for different sellingUnits
 * @param lengthIn the length of zipper (matters for individual and pack of zippers)
 * @param zipperType type of teeth on zipper
 * @param material material of zipper slider
 * @param quality any special qualities that zipper may have (may or may not affect price)
 * @param imperfections discounts the price by 15% if one exists of individual
 * @param packSize only matters in zipper packs
 * @returns
 */
export const zipperPriceSuggestion = (
  sellingUnit: za.SellingUnit,
  lengthIn?: number,
  zipperType?: za.ZipperType,
  zipperHardwareType?: za.ZipperHardwareType,
  zipperHardwareMaterial?: za.ZipperHardwareMaterial,
  zipperHardwareSU?: za.ZipperHardwareSU,
  quality?: string,
  imperfections?: string[],
  packSize?: number
) => {
  switch (sellingUnit) {
    case za.SellingUnit.Individual:
    case za.SellingUnit['Pack']:
      let price = 0;
      if (lengthIn && zipperType) {
        price = zipperIndividualPrice(
          lengthIn,
          zipperType,
          quality,
          imperfections
        );

        if (sellingUnit === za.SellingUnit['Pack'] && packSize) {
          price *= packSize;
          // Round up to nearest $0.50
          price = Math.ceil(0.8 * price * 2) / 2;
        }
        return price;
      }
      return undefined;
    case za.SellingUnit.Hardware:
      let hardwarePrice = 0;
      if (zipperHardwareType === za.ZipperHardwareType.Slider) {
        if (zipperHardwareMaterial === za.ZipperHardwareMaterial.Plastic)
          hardwarePrice = 0.25;
        else if (zipperHardwareMaterial === za.ZipperHardwareMaterial.Metal)
          hardwarePrice = 0.5;
      } else if (zipperHardwareType === za.ZipperHardwareType.Pull) {
        if (zipperHardwareMaterial === za.ZipperHardwareMaterial.Plastic)
          hardwarePrice = 0.1;
        else if (zipperHardwareMaterial === za.ZipperHardwareMaterial.Metal)
          hardwarePrice = 0.15;
        else if (zipperHardwareMaterial === za.ZipperHardwareMaterial.Fabric)
          hardwarePrice = 0.05;
      } else if (zipperHardwareType === za.ZipperHardwareType.Ender) {
        if (zipperHardwareMaterial === za.ZipperHardwareMaterial.Plastic)
          hardwarePrice = 0.1;
        else if (zipperHardwareMaterial === za.ZipperHardwareMaterial.Metal)
          hardwarePrice = 0.1;
      } else if (zipperHardwareType === za.ZipperHardwareType.Stopper) {
        if (zipperHardwareMaterial === za.ZipperHardwareMaterial.Plastic)
          hardwarePrice = 0.1;
        else if (zipperHardwareMaterial === za.ZipperHardwareMaterial.Metal)
          hardwarePrice = 0.1;
      }

      if (zipperHardwareSU === za.ZipperHardwareSU.Pack && packSize) {
        hardwarePrice = hardwarePrice * packSize * 0.9;
      }

      return hardwarePrice;
    case za.SellingUnit['Tape By The Meter']:
      if (zipperType) return baseZipperPrice(zipperType, quality, 0.25);
      return undefined;
  }
};

export const zipperPriceSuggestionFromForm = (zipper: MergedProduct) => {
  if (zipper.sellingUnit) {
    let packSize = Number(zipper.packSize);

    return zipperPriceSuggestion(
      zipper.sellingUnit as SellingUnit,
      zipper.lengthIn,
      zipper.zipperType,
      zipper.zipperHardwareType,
      zipper.zipperHardwareMaterial as ZipperHardwareMaterial,
      zipper.zipperHardwareSU as ZipperHardwareSU,
      zipper.quality,
      zipper.imperfections?.[0],
      isNaN(packSize) ? undefined : packSize
    );
  }
  return undefined;
};

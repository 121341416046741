import { FormControl, VStack, Heading, FormHelperText } from '@chakra-ui/react';
import { ButtonRadioGroup } from '../general';
import React from 'react';

export enum SupplyTypes {
  Zippers = 'Zippers',
  Buttons = 'Buttons',
  Elastic = 'Elastic',
  'Sewing Patterns' = 'Sewing Patterns',
  Thread = 'Thread',
  Trim = 'Trim',
  Ribbing = 'Ribbing',
  'Bias Tape' = 'Bias Tape',
  'Everything Else' = 'Everything Else'
}

export enum BoxSize {
  'Banker Box' = 'Banker Box',
  'Large Bin' = 'Large Bin',
  'Box-small' = 'Box-small',
  'Small Bin' = 'Small Bin',
  'Blue Bin' = 'Blue Bin'
}

interface SupplyTypeBoxSizeProps {
  inventorySupplyObject: any;
  setInventorySupplyObject: (inventorySuppliesObject: object) => void;
}

export const SupplyTypeBoxSize: React.FC<SupplyTypeBoxSizeProps> = ({
  inventorySupplyObject,
  setInventorySupplyObject
}) => {
  return (
    <FormControl as="fieldset">
      <VStack spacing="8" alignItems="left">
        <Heading>Supply Type</Heading>
        <ButtonRadioGroup
          name="Inventory Supply Type"
          options={Object.values(SupplyTypes)}
          value={inventorySupplyObject.supplyType}
          buttonProps={{ minW: '10rem' }}
          onChange={(v) => {
            const updatedInventorySupplyObject = {
              ...inventorySupplyObject,
              supplyType: v
            };
            setInventorySupplyObject(updatedInventorySupplyObject);
          }}
        />
        <FormHelperText>Choose one</FormHelperText>
        <Heading>Size of Box</Heading>
        <ButtonRadioGroup
          name="Box Size"
          value={inventorySupplyObject.boxSize}
          options={Object.values(BoxSize)}
          buttonProps={{ minW: '10rem' }}
          onChange={(v) => {
            const updatedInventorySupplyObject = {
              ...inventorySupplyObject,
              boxSize: v
            };
            setInventorySupplyObject(updatedInventorySupplyObject);
          }}
        />
        <FormHelperText>Choose one</FormHelperText>
      </VStack>
    </FormControl>
  );
};

import React from 'react';
import { VStack } from '@chakra-ui/react';
import { Divider } from '../../../general/Divider';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { BiasTapeBrandField } from './BiasTapeBrandField';
import { BiasTapeAdditionalInfoField } from './BiasTapeAdditionalInfoField';

const RESPONSIVE_WIDTH = { md: '25rem', lg: '36rem' };

export const BrandAdditionalInfoField: AppFieldComponent = (props) => {
  return (
    <VStack spacing="0.1" alignItems="left" minW={RESPONSIVE_WIDTH}>
      <BiasTapeBrandField {...props} />
      <Divider />
      <BiasTapeAdditionalInfoField {...props} />
    </VStack>
  );
};

import React from 'react';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { Box, FormControl, FormHelperText } from '@chakra-ui/react';
import { YesNo } from '../../../../types/formTypes';
import { YesNoQuestion, WrappedDatePicker } from '../../../general';

export const UnboxingField: AppFieldComponent = ({
  setFormField,
  currentValue,
  toNextPage
}) => {
  const question = `Is the fabric for the Unboxing Collection?`;
  return (
    <FormControl as="fieldset">
      <YesNoQuestion
        name="unboxing"
        question={question}
        value={currentValue.unboxingDateExists as YesNo}
        onChange={(v) => {
          setFormField({ unboxingDateExists: v });
          if (v === 'No') setFormField({ unboxingDate: undefined });
        }}
      >
        <Box>
          <WrappedDatePicker
            date={currentValue.unboxingDate}
            onChange={(date) => {
              setFormField({ unboxingDate: date as Date });
            }}
          />
          <FormHelperText>Select a date.</FormHelperText>
        </Box>
      </YesNoQuestion>
    </FormControl>
  );
};

import {
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  VStack
} from '@chakra-ui/react';
import React from 'react';
import { ProductTypes } from 'product-validator';
import {
  getNestedValidationError,
  isTouched
} from '../../../utils/validationUtils';
import {
  ButtonRadioGroup,
  ErrorMessage,
  NumberInput,
  SwitchInput
} from '../../general';
import { IndexedAppFieldComponentProps } from '../../../types/appFieldTypes';
import { OtherSupplyAttributes as osa } from 'product-validator';
import update from 'immutability-helper';

enum WidthUnit {
  CM = 'CM',
  MM = 'MM'
}

const WIDTH_OPTIONS = {
  [WidthUnit.CM]: {
    UnitLong: 'centimeters',
    Radios: ['88', '115', '136', '142', '148', '152', '156', '160', '300']
  },
  [WidthUnit.MM]: {
    UnitLong: 'millimeters',
    Radios: ['5', '10', '15', '20', '30', '50']
  }
};

export const WidthField: React.FC<IndexedAppFieldComponentProps> = ({
  setFormField,
  currentValue,
  validationErrors,
  idx = 0
}) => {
  let widthRadios: string[] = [];
  let widthErr: string[] = [];
  let widthTouched = false;
  let widthUnit: WidthUnit;

  const mixedWidthSwitch = (
    <SwitchInput
      paddingTop="3"
      label="various widths?"
      isChecked={currentValue.mixedWidth ?? false}
      onChange={() => {
        setFormField((oldForm) => {
          const newForm = { ...oldForm };
          newForm.mixedWidth = !(newForm.mixedWidth ?? false);
          return newForm;
        });
      }}
    />
  );

  switch (currentValue.productType) {
    case ProductTypes.Fabric: {
      widthUnit = WidthUnit.CM;
      widthRadios = WIDTH_OPTIONS.CM.Radios;
      widthErr = getNestedValidationError(`widthCM[${idx}]`, validationErrors);
      widthTouched = isTouched(
        currentValue.widthCM[idx],
        currentValue.widthRadio[idx],
        currentValue.widthOther[idx]
      );
      break;
    }
    case ProductTypes.Ribbing: {
      widthUnit = WidthUnit.CM;
      widthRadios = WIDTH_OPTIONS.CM.Radios;
      widthErr = getNestedValidationError(`widthCM`, validationErrors);
      widthTouched = isTouched(
        currentValue.widthCM[idx],
        currentValue.widthOther[idx]
      );
      break;
    }
    case ProductTypes['Other Supply']: {
      widthUnit = WidthUnit.CM;
      widthErr = getNestedValidationError(`widthCM`, validationErrors);
      widthTouched = isTouched(
        currentValue.widthCM[idx],
        currentValue.widthOther[idx]
      );
      break;
    }
    case ProductTypes.Buttons: {
      widthUnit = WidthUnit.MM;
      widthRadios = WIDTH_OPTIONS.MM.Radios;
      widthErr = getNestedValidationError(`widthMM`, validationErrors);
      widthTouched = isTouched(
        currentValue.widthMM,
        currentValue.widthRadio[idx],
        currentValue.widthOther[idx]
      );
      break;
    }
    default:
      widthUnit = WidthUnit.CM;
  }

  const numberInputProps = {
    size: 'lg',
    stackProps: { w: '80%' },
    w: '80%'
  };

  return (
    <FormControl as="fieldset" isInvalid={widthErr.length > 0 && widthTouched}>
      <FormLabel as="legend">
        <Heading>
          What's the width in {WIDTH_OPTIONS[widthUnit].UnitLong}?
        </Heading>
        {currentValue.productType === ProductTypes['Other Supply'] &&
        currentValue.sellingUnit === osa.SellingUnit['By Meter'] ? (
          <FormHelperText>(Required)</FormHelperText>
        ) : currentValue.productType === ProductTypes['Other Supply'] ? (
          <FormHelperText>(Optional)</FormHelperText>
        ) : (
          <></>
        )}
      </FormLabel>
      <VStack flexDir="column" alignItems="flex-start" spacing="3">
        {currentValue.productType === ProductTypes.Buttons &&
        currentValue.sellingUnit === 'Pack' ? (
          mixedWidthSwitch
        ) : (
          <></>
        )}
        {currentValue.productType !== ProductTypes.Ribbing && (
          <ButtonRadioGroup
            name="width"
            value={currentValue.widthRadio[idx]}
            options={widthRadios}
            onChange={(v) =>
              setFormField((oldForm) =>
                update(oldForm, {
                  widthRadio: {
                    [idx]: { $set: v }
                  }
                })
              )
            }
            isDisabled={
              !!currentValue.widthOther[idx] || !!currentValue.mixedWidth
            }
          />
        )}
        <NumberInput
          id="widthOther"
          onChange={(s) => {
            setFormField((oldForm) =>
              update(oldForm, {
                widthOther: {
                  [idx]: { $set: s }
                }
              })
            );
          }}
          defaultValue={currentValue.widthOther[idx]}
          text={`width (${widthUnit})`}
          {...numberInputProps}
          isDisabled={!!currentValue.mixedWidth}
        />
      </VStack>
      <ErrorMessage errMsgs={widthErr} />
      {currentValue.productType === ProductTypes['Other Supply'] ? (
        <FormHelperText>Type in a value</FormHelperText>
      ) : (
        <FormHelperText>
          Choose one or type in the uncut measurement of the width.
        </FormHelperText>
      )}
    </FormControl>
  );
};

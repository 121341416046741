import {
  FormControl,
  FormLabel,
  FormHelperText,
  VStack,
  Heading,
  CheckboxGroup
} from '@chakra-ui/react';
import React from 'react';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import {
  WrappedInputGroup,
  CheckboxButton,
  OtherTextInput,
  ErrorMessage
} from '../../../general';
import {
  getNestedValidationError,
  isTouched
} from '../../../../utils/validationUtils';
import { getTrimQualityOptions } from '../../../../utils/supplyUtils';
import { YesNo } from '../../../../types/formTypes';
import { YesNoQuestion } from '../../../general';

export const TrimQualitiesField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  const qualitiesErrMsgs = getNestedValidationError(
    'qualities',
    validationErrors
  );
  const qualitiesTouched = isTouched(
    currentValue.qualities,
    currentValue.qualityOther
  );

  const qualityOptions = getTrimQualityOptions(currentValue.trimType);

  return (
    <YesNoQuestion
      name="qualities"
      question={`Does the trim have any qualities?`}
      value={currentValue.qualitiesExist as YesNo}
      onChange={(v) => {
        setFormField({ qualitiesExist: v });
        if (v === 'No') setFormField({ qualities: [] });
      }}
    >
      <FormControl
        as="fieldset"
        isInvalid={qualitiesErrMsgs.length > 0 && qualitiesTouched}
      >
        <FormLabel as="legend">
          <Heading>Qualities</Heading>
        </FormLabel>
        <VStack spacing="3" alignItems="left" maxW="40rem">
          <FormHelperText>Optional, select up to 3</FormHelperText>
          <CheckboxGroup
            value={currentValue.qualities}
            onChange={(qualityArr: string[]) => {
              return setFormField({
                qualities: qualityArr
              });
            }}
          >
            <WrappedInputGroup maxW="43rem">
              {qualityOptions.map((value) => (
                <CheckboxButton
                  key={value}
                  isDisabled={
                    currentValue.qualities &&
                    (currentValue.qualities.length >= 3 ||
                      (currentValue.qualities.length >= 2 &&
                        !!currentValue.qualityOther)) &&
                    !currentValue.qualities.includes(value)
                  }
                  value={value}
                >
                  {value}
                </CheckboxButton>
              ))}
            </WrappedInputGroup>
          </CheckboxGroup>
          <OtherTextInput
            onChange={(e) => setFormField({ qualityOther: e.target.value })}
            isDisabled={
              currentValue.qualities && currentValue.qualities.length >= 3
            }
            value={currentValue.qualityOther ?? ''}
          />
          <FormHelperText>Select one or enter in another.</FormHelperText>

          <ErrorMessage errMsgs={qualitiesErrMsgs} />
        </VStack>
      </FormControl>
    </YesNoQuestion>
  );
};

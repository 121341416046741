/** Specifies the resource type to receive. */
export enum StagedUploadTargetGenerateUploadResource {
  /** A timeline event. */
  Timeline = 'TIMELINE',
  /** A product image. */
  ProductImage = 'PRODUCT_IMAGE',
  /** A collection image. */
  CollectionImage = 'COLLECTION_IMAGE',
  /** A shop image. */
  ShopImage = 'SHOP_IMAGE',
  /** Merchandising::Video resource representation. */
  Video = 'VIDEO',
  /** Merchandising::Model3d resource representation. */
  Model_3D = 'MODEL_3D',
  /** Merchandising::Image resource representation. */
  Image = 'IMAGE'
}

/** Possible HTTP method of a staged upload target. */
export enum StagedUploadHttpMethodType {
  /** The POST HTTP method. */
  Post = 'POST',
  /** The PUT HTTP method. */
  Put = 'PUT'
}

export enum MimeTypeMedia {
  mp4 = 'video/mp4',
  quicktime = 'video/quicktime',
  png = 'image/png',
  gif = 'image/gif',
  jpeg = 'image/jpeg',
  gltfBinary = 'model/gltf-binary'
}

/** Media to be uploaded. */
export type StagedUploadInput = {
  /** Media resource. */
  resource: StagedUploadTargetGenerateUploadResource;
  /** Media filename. */
  filename: string;
  /** Media MIME type. */
  mimeType: MimeTypeMedia;
  /** HTTP method to be used by the Staged Upload. */
  httpMethod?: StagedUploadHttpMethodType;
  /** Size of the file to upload, in bytes. This is required for VIDEO and MODEL_3D resources. */
  // uint664
  fileSize?: number;
};

/** The possible content types for a media object. */
export enum MediaContentType {
  /** A Shopify hosted video. */
  Video = 'VIDEO',
  /** An externally hosted video. */
  ExternalVideo = 'EXTERNAL_VIDEO',
  /** A 3d model. */
  Model_3D = 'MODEL_3D',
  /** A Shopify hosted image. */
  Image = 'IMAGE'
}

/** Specifies the input fields required to create a media object. */
export type CreateMediaInput = {
  /** The original source of the media object. May be an external URL or signed upload URL. */
  originalSource: string;
  /** The alt text associated to the media. */
  alt?: string;
  /** The media content type. */
  mediaContentType: MediaContentType;
};

import {
  FormControl,
  FormLabel,
  FormHelperText,
  Box,
  VStack,
  Heading,
  CheckboxGroup
} from '@chakra-ui/react';
import React from 'react';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import {
  ButtonRadioGroup,
  WrappedInputGroup,
  CheckboxButton,
  Card
} from '../../../general';
import { SewingPatternAttributes as spa } from 'product-validator';
import {
  getNestedValidationError,
  isTouched
} from '../../../../utils/validationUtils';

const HELP_TEXT = ['25 years or older', ''];

export const SewingPatternTypeField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  const patternTypeErrMsgs = getNestedValidationError(
    'patternType',
    validationErrors
  );

  const patternTypeTouched = isTouched(currentValue.patternType);

  return (
    <VStack spacing="6" alignItems="left">
      <FormControl
        as="fieldset"
        isInvalid={patternTypeErrMsgs.length > 0 && patternTypeTouched}
      >
        <FormLabel as="legend">
          <Heading>Pattern Type</Heading>
          <FormHelperText>
            Craft includes home decor
            <br />
            Accessories include wearable projects like hats and bag
          </FormHelperText>
        </FormLabel>
        <Box>
          <ButtonRadioGroup
            name="Pattern Type"
            value={currentValue.patternType}
            options={Object.values(spa.PatternType)}
            onChange={(v) => {
              setFormField({ patternType: v as spa.PatternType });
            }}
          />
        </Box>
        <FormHelperText>Choose one.</FormHelperText>
      </FormControl>

      <FormControl as="fieldset" mb="1.5rem">
        <FormLabel as="legend">
          <Heading>Qualities</Heading>
        </FormLabel>
        <Card title="Optional">
          <CheckboxGroup
            value={currentValue.qualities}
            onChange={(qualitiesArr: string[]) =>
              setFormField({ qualities: qualitiesArr })
            }
          >
            <WrappedInputGroup numColumns={2}>
              {Object.values(spa.Qualities).map((value, index) => (
                <Box key={value}>
                  <CheckboxButton value={value}>{value}</CheckboxButton>
                  <FormHelperText ml="2rem">{HELP_TEXT[index]}</FormHelperText>
                </Box>
              ))}
            </WrappedInputGroup>
          </CheckboxGroup>
        </Card>
      </FormControl>

      <FormControl as="fieldset" mb="1.5rem">
        <FormLabel as="legend">
          <Heading>Condition</Heading>
        </FormLabel>
        <ButtonRadioGroup
          name="Pattern Condition"
          value={currentValue.condition}
          options={Object.values(spa.Condition)}
          onChange={(v) => {
            setFormField({ condition: v as spa.Condition });
          }}
        />
      </FormControl>
    </VStack>
  );
};

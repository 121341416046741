import { FormControl, FormHelperText, Stack } from '@chakra-ui/react';
import React from 'react';
import { ProductTypes, FabricAttributes } from 'product-validator';
import { AppFieldComponent } from '../../../types/appFieldTypes';
import {
  getNestedValidationError,
  isTouched
} from '../../../utils/validationUtils';
import {
  Card,
  YesNoQuestion,
  OtherTextInput,
  ErrorMessage,
  ImageRadioGroup,
  ImageRadioButton,
  SwitchInput
} from '../../general';
import { fieldOptions } from '../../../types/fieldOptionsTypes';
import { YesNo } from '../../../types/formTypes';
import * as IMAGES from '../../../images/patterns';

const patternSrc: { [k in keyof typeof FabricAttributes.Pattern]: string } = {
  Plaid: IMAGES.plaid,
  'Polka Dots': IMAGES.polka_dots,
  Stripes: IMAGES.stripes,
  Pinstripe: IMAGES.pinstripe,
  Floral: IMAGES.floral,
  Geometric: IMAGES.geometric,
  Abstract: IMAGES.abstract,
  Paisley: IMAGES.paisley,
  Damask: IMAGES.damask,
  Chevron: IMAGES.chevron,
  ZigZag: IMAGES.zigzag,
  'Animal Print': IMAGES.animal_print
};

export const PatternField: AppFieldComponent = (props) => {
  const patternErrMsgs = getNestedValidationError(
    'pattern',
    props.validationErrors
  );
  const patternTouched = isTouched(
    props.currentValue.pattern,
    props.currentValue.patternOther
  );

  const patternOptions =
    fieldOptions[props.currentValue?.productType ?? ProductTypes.Fabric]
      .pattern;

  const patternField = (
    <FormControl
      as="fieldset"
      isInvalid={patternErrMsgs.length > 0 && patternTouched}
    >
      <Stack spacing="3">
        <Card title="Pattern" maxW="33.6rem">
          <ImageRadioGroup
            name="pattern"
            value={props.currentValue.pattern}
            onChange={(v) => props.setFormField({ pattern: v })}
          >
            {patternOptions.map((v) => (
              <ImageRadioButton
                value={v}
                key={v}
                image={patternSrc[v as keyof typeof FabricAttributes.Pattern]}
                isDisabled={!!props.currentValue.patternOther}
                minWidth="10rem"
              />
            ))}
          </ImageRadioGroup>
        </Card>
        <Card title="Border" maxW="10rem">
          <SwitchInput
            label="Border"
            isChecked={
              props.currentValue.border
                ? props.currentValue.border === FabricAttributes.Border.Border
                : false
            }
            onChange={() => {
              props.setFormField((oldForm) => {
                const newForm = { ...oldForm };
                if (oldForm.border === FabricAttributes.Border.Border)
                  newForm.border = FabricAttributes.Border.None;
                else newForm.border = FabricAttributes.Border.Border;
                return newForm;
              });
            }}
          />
        </Card>
        <OtherTextInput
          onChange={(e) =>
            props.setFormField({
              patternOther: e.target.value
            })
          }
          value={props.currentValue.patternOther ?? ''}
        />
      </Stack>
      <ErrorMessage errMsgs={patternErrMsgs} />
      <FormHelperText>Choose one or type in a value.</FormHelperText>
    </FormControl>
  );

  return (
    <YesNoQuestion
      name="pattern"
      question={`Does the ${
        props.currentValue.productType?.toLowerCase() ?? 'fabric'
      } have a pattern?`}
      value={props.currentValue.patternExists as YesNo}
      onChange={(v) => {
        props.setFormField({ patternExists: v });
        if (v === 'No') props.setFormField({ pattern: undefined });
      }}
    >
      {patternField}
    </YesNoQuestion>
  );
};

import { Tr, Td, Link } from '@chakra-ui/react';
import { Fabric, ContentMix, VariantableValue } from 'product-validator';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { variantedField } from '../../../types/formTypes';

const NONE = '-';

interface FieldRowProps {
  fieldName: string;
  value?:
    | string[]
    | string
    | number
    | boolean
    | Fabric
    | ContentMix
    | variantedField<string>
    | variantedField<string[]>
    | VariantableValue<number>
    | Date;
  toPath: string;
  errMsgs?: string[];
  isVarianted?: boolean;
}

export const FieldRow = (props: FieldRowProps) => {
  let valueToShow: string | number | boolean = NONE;
  const actualValue = props.value;
  if (actualValue) {
    if (typeof actualValue === 'string' || typeof actualValue === 'number')
      valueToShow = actualValue;
    else if (typeof actualValue === 'boolean')
      valueToShow = actualValue ? 'Yes' : 'No';
    else if (Array.isArray(actualValue)) valueToShow = actualValue.join(', ');
  }
  if (valueToShow.toString().length === 0) valueToShow = NONE;

  let errMsg = props.errMsgs ? props.errMsgs[0] : NONE;
  let isVarianted = props.isVarianted ? `yes` : NONE;

  // By default the row should show if that path can be navigated to
  return (
    <Tr backgroundColor={errMsg === NONE ? '' : 'orange.100'}>
      <Td>
        <Link as={RouterLink} to={props.toPath ?? ''}>
          {props.fieldName}
        </Link>
      </Td>
      <Td>{isVarianted}</Td>
      <Td>{valueToShow}</Td>
      <Td>{errMsg}</Td>
    </Tr>
  );
};

import React from 'react';
import {
  FormControl,
  FormHelperText,
  Stack,
  ButtonProps,
  RadioGroup,
  Flex
} from '@chakra-ui/react';
import { AppFieldComponent } from '../../../types/appFieldTypes';
import { ProductTypes } from 'product-validator';
import {
  getNestedValidationError,
  isTouched
} from '../../../utils/validationUtils';
import {
  Card,
  ErrorMessage,
  WrappedInputGroup,
  OtherTextInput,
  YesNoQuestion,
  RadioButton
} from '../../general';
import { fieldOptions } from '../../../types/fieldOptionsTypes';
import { YesNo } from '../../../types/formTypes';

interface QualityOptionsProps {
  title?: string;
  options: string[];
  isDisabled: boolean;
  isSupplies?: boolean;
  buttonProps?: ButtonProps;
}

const QualityOptions: React.FC<QualityOptionsProps> = ({
  title,
  options,
  isDisabled,
  isSupplies,
  buttonProps
}) => {
  return (
    <Card title={title}>
      <WrappedInputGroup numColumns={3}>
        {options.map((va) => (
          <RadioButton
            {...buttonProps}
            key={va}
            value={va}
            isDisabled={isDisabled}
          >
            {va}
          </RadioButton>
        ))}
      </WrappedInputGroup>
    </Card>
  );
};

export const QualityField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors
}) => {
  const errMsgs = getNestedValidationError('quality', validationErrors);
  const touched = isTouched(currentValue.quality, currentValue.qualityOther);
  const isSupplies = currentValue.productType !== ProductTypes.Fabric;

  let radioOptions;
  if (currentValue.productType === ProductTypes.Fabric) {
    const categoryOptions = Object.entries(
      fieldOptions[currentValue.productType].quality
    );
    radioOptions = (
      <Flex wrap="wrap" sx={{ gap: '1rem' }} maxW="100%">
        {categoryOptions.map(([qualityType, qualityOptions]) => (
          <QualityOptions
            key={qualityType}
            title={qualityType}
            isDisabled={!!currentValue.qualityOther}
            options={Object.values(qualityOptions)}
            buttonProps={{
              size: 'sm'
            }}
          />
        ))}
      </Flex>
    );
  } else {
    radioOptions = (
      <QualityOptions
        title="quality"
        isDisabled={!!currentValue.qualityOther}
        isSupplies={isSupplies}
        options={Object.values(
          fieldOptions[currentValue.productType ?? ProductTypes.Zippers].quality
        )}
      />
    );
  }

  const qualityOptions = (
    <FormControl isInvalid={errMsgs.length > 0 && touched}>
      <Stack spacing={3}>
        <RadioGroup
          name="quality"
          value={currentValue.quality}
          onChange={(v) => setFormField({ quality: v as string })}
        >
          {radioOptions}
        </RadioGroup>
        <OtherTextInput
          onChange={(e) => setFormField({ qualityOther: e.target.value })}
          value={currentValue.qualityOther ?? ''}
        />
        <ErrorMessage errMsgs={errMsgs} />
      </Stack>
      <FormHelperText>Choose one or type in a value.</FormHelperText>
    </FormControl>
  );

  return (
    <YesNoQuestion
      name="quality"
      question={
        currentValue.zipperHardwareType
          ? `Does the ${currentValue.zipperHardwareType} have any special qualities`
          : 'Does it have any special qualities?'
      }
      value={currentValue.qualityExists as YesNo}
      onChange={(v) => {
        setFormField({ qualityExists: v });
        if (v === 'No') setFormField({ quality: undefined });
      }}
    >
      {qualityOptions}
    </YesNoQuestion>
  );
};

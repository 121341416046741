import { Input, InputProps } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';

/**
 * This function will execute the fn callback only after the callback
 * isn't called for 'wait' milliseconds
 * @param fn function to run
 * @param wait ms duration to wait
 */
const throttle = (fn: Function, wait: number = 300) => {
  let lastFn: ReturnType<typeof setTimeout>;
  return (...args: any) => {
    clearTimeout(lastFn);
    lastFn = setTimeout(() => {
      fn(...args);
    }, wait);
  };
};

interface OtherTextInputProps extends InputProps {
  throttleValue?: number;
  placeholder?: string;
}

export const OtherTextInput = ({
  throttleValue,
  placeholder,
  value,
  onChange,
  ...rest
}: OtherTextInputProps) => {
  const [text, setText] = useState(value);

  useEffect(() => {
    setText(value);
  }, [value]);

  const throttledUpdate = useRef(
    throttle(
      (
        e: React.ChangeEvent<HTMLInputElement>,
        onChange?: React.ChangeEventHandler<HTMLInputElement>
      ) => {
        if (onChange) onChange(e);
      },
      throttleValue ?? 400
    )
  ).current;

  return (
    <Input
      maxW="25rem"
      placeholder={placeholder ?? 'Other'}
      borderColor="purple.500"
      _disabled={{ opacity: '0.1', cursor: 'not-allowed' }}
      {...rest}
      value={text}
      onChange={(e) => {
        setText(e.target.value);
        throttledUpdate(e, onChange);
      }}
    />
  );
};

import { Divider as ChakraDivider, Center } from '@chakra-ui/react';
import React from 'react';

export const Divider = () => {
  return (
    <Center w="100%" p="4">
      <ChakraDivider maxW="30rem" borderBottomWidth="2px" />
    </Center>
  );
};

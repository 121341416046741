import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { AppField } from '../types/appFieldTypes';
import { ProductTypes } from 'product-validator';
import {
  NoPageFound,
  PageLayout,
  SideBar,
  NextFieldNavigator
} from '../components/general';
import * as ProductFields from '../components/productEntry';
import { FormStateContainer, UploadProduct } from '../components/productEntry';
import { linkGeneratorWrapper, canNavigate } from '../utils/navigationUtils';
import { Badge } from '@chakra-ui/react';

export const appFields: { [key: string]: AppField } = {
  Supplies: {
    name: 'Supplies',
    path: 'supply_type',
    component: ProductFields.SupplyTypeField
  },
  'Selling Unit': {
    name: 'Selling Unit',
    path: 'selling_unit',
    component: ProductFields.SellingUnitField
  },
  Material: {
    name: 'Material',
    path: 'material',
    component: ProductFields.MaterialField
  },
  'Zipper Type': {
    name: 'Zipper Type',
    path: 'zipper_type',
    component: ProductFields.ZipperTypeField
  },
  'Hardware Type': {
    name: 'Hardware Type',
    path: 'hardware_type',
    component: ProductFields.ZipperHardwareTypeField
  },
  'Hardware Unit': {
    name: 'Hardware Unit',
    path: 'hardware_unit',
    component: ProductFields.ZipperHardwareSellingUnitField
  },
  'Hardware Material': {
    name: 'Hardware Material',
    path: 'hardware_material',
    component: ProductFields.ZipperHardwareMaterialField
  },
  'Zipper Type + Closure': {
    name: 'Zipper Type + Closure',
    path: 'zipper_type_closure',
    component: ProductFields.ZipperTypeClosureField
  },
  'Bundle or Roll': {
    name: 'Bundle or Roll',
    path: 'bundle_or_roll',
    component: ProductFields.BundleOrRollField
  },
  'Has Variants': {
    name: 'Has Variants',
    path: 'has_variants',
    component: ProductFields.HasVariantsField
  },
  Camera: {
    name: 'Camera',
    path: 'camera',
    //component: ProductFields.ImageField
    component: ProductFields.ImageUploadField
  },
  'Variant Dimensions': {
    name: 'Variant Dimensions',
    path: 'variants',
    component: ProductFields.VariantDimensionsField
  },
  Dimensions: {
    name: 'Dimensions',
    path: 'dimensions',
    component: ProductFields.DimensionsField
  },
  'Dimensions + Quantity': {
    name: 'Dimensions + Quantity',
    path: 'dimensions_quantity',
    component: ProductFields.DimensionsField
  },
  'Zipper Dimensions': {
    name: 'Zipper Dimensions',
    path: 'zipper_dimensions',
    component: ProductFields.ZipperDimensionsField
  },
  Size: {
    name: 'Size',
    path: 'zipper_size',
    component: ProductFields.ZipperDimensionsField
  },
  'Other Supply Dimensions': {
    name: 'Dimensions',
    path: 'other_supply_dimensions',
    component: ProductFields.OtherSupplyDimensionsField
  },
  'Supply Type': {
    name: 'Supply Type',
    path: 'other_supply_type',
    component: ProductFields.OtherSupplyType
  },
  Weight: {
    name: 'Weight',
    path: 'other_supply_weight',
    component: ProductFields.OtherSupplyWeights
  },
  'Weight + Size': {
    name: 'Weight + Size',
    path: 'weight_size',
    component: ProductFields.OtherSupplyWeights
  },
  'Other Supply Imperfections': {
    name: 'Imperfections',
    path: 'other_supply_imperfections',
    component: ProductFields.OtherSupplyImperfectionsField
  },
  'Additional Info': {
    name: 'Additional Info',
    path: 'other_supply_additional_info',
    component: ProductFields.OtherSupplyAdditionalInfoField
  },
  'Other Supply Quantity': {
    name: 'Quantity',
    path: 'other_supply_quantity',
    component: ProductFields.OtherSupplyQuantityField
  },
  'Knit or Woven': {
    name: 'Knit or Woven',
    path: 'knit_or_woven',
    component: ProductFields.KnitOrWovenField
  },
  'Fiber Content': {
    name: 'Fiber Content',
    path: 'content',
    component: ProductFields.ContentField
  },
  'Knit or Weave Type': {
    name: 'Knit or Weave Type',
    path: 'knit_weave_type',
    component: ProductFields.KnitWeaveTypeField
  },
  Quality: {
    name: 'Quality',
    path: 'quality',
    component: ProductFields.QualityField
  },
  Stretch: {
    name: 'Stretch',
    path: 'stretch',
    component: ProductFields.StretchField
  },
  'Stretch Percentage': {
    name: 'Stretch Percentage',
    path: 'stretch_percentage',
    component: ProductFields.StretchPercentageField
  },
  'Opacity, Weight, and Drape': {
    name: 'Opacity, Weight, and Drape',
    path: 'opacity_weight_drape',
    component: ProductFields.OpacityWeightDrapeField
  },
  Feel: {
    name: 'Feel',
    path: 'feel',
    component: ProductFields.FeelField
  },
  'Material and Stretch': {
    name: 'Material and Stretch',
    path: 'material_stretch',
    component: ProductFields.ContentStretchField
  },
  Colours: {
    name: 'Colours',
    path: 'colour',
    component: ProductFields.ColourField
  },
  'Brand, Imperfections, and Quality': {
    name: 'Brand, Imperfections, and Quality',
    path: 'brand_imperfection_quality',
    component: ProductFields.BrandImperfectionQualityField
  },
  'Imperfections + Quality': {
    name: 'Imperfections + Quality',
    path: 'imperfection_quality',
    component: ProductFields.ImperfectionQualityField
  },
  'Pattern + Imperfections': {
    name: 'Pattern + Imperfections',
    path: 'pattern_imperfections',
    component: ProductFields.PatternImperfectionField
  },
  Pattern: {
    name: 'Pattern',
    path: 'pattern',
    component: ProductFields.PatternField
  },
  'Common Uses': {
    name: 'Common Uses',
    path: 'common_uses',
    component: ProductFields.CommonUsesField
  },
  'Ribbing Common Use': {
    name: 'Ribbing Common Use',
    path: 'ribbing_common_use',
    //component: ProductFields.RibbingUseField
    component: ProductFields.CommonUsesField
  },
  Quantity: {
    name: 'Quantity',
    path: 'quantity',
    component: ProductFields.QuantityField
  },
  'Sewing Pattern Type': {
    name: 'Sewing Pattern Type',
    path: 'sewing_pattern_type',
    component: ProductFields.SewingPatternTypeField
  },
  'Sewing Pattern Brand Number': {
    name: 'Sewing Pattern Brand Number',
    path: 'sewing_pattern_brand_number',
    component: ProductFields.SewingPatternBrandNumberField
  },
  'Button Size': {
    name: 'Button Size',
    path: 'button_size',
    component: ProductFields.ButtonSizeField
  },
  'Button Type': {
    name: 'Button Type',
    path: 'button_type',
    component: ProductFields.ButtonTypeField
  },
  'Button Material': {
    name: 'Button Material',
    path: 'button_material',
    component: ProductFields.SupplyContentField
  },
  'Button Design': {
    name: 'Button Design',
    path: 'button_design',
    component: ProductFields.ButtonDesignField
  },
  'Thread Type': {
    name: 'Thread Type',
    path: 'thread_type',
    component: ProductFields.ThreadTypeField
  },
  'Thread Material': {
    name: 'Thread Material',
    path: 'thread_material',
    component: ProductFields.SupplyContentField
  },
  'Thread Size': {
    name: 'Thread Size',
    path: 'thread_size',
    component: ProductFields.ThreadSizeField
  },
  'Uses + Imperfections': {
    name: 'Uses + Imperfections',
    path: 'uses_imperfections',
    component: ProductFields.UsesImperfectionsField
  },
  'Type + Weave': {
    name: 'Type + Weave',
    path: 'type_weave',
    component: ProductFields.TrimTypeWeaveField
  },
  'Design + Shape': {
    name: 'Design + Shape',
    path: 'design_shape',
    component: ProductFields.DesignShapeField
  },
  Qualities: {
    name: 'Qualities',
    path: 'qualities',
    component: ProductFields.QualitiesField
  },
  'Trim Content': {
    name: 'Trim Content',
    path: 'trim_content',
    component: ProductFields.TrimContentField
  },
  'Trim Qualities': {
    name: 'Trim Qualities',
    path: 'trim_qualities',
    component: ProductFields.TrimQualitiesField
  },
  'Elastic Type': {
    name: 'Elastic Type',
    path: 'elastic_type',
    component: ProductFields.ElasticTypeField
  },
  'Bias Tape Type': {
    name: 'Bias Tape Type',
    path: 'bias_tape_type',
    component: ProductFields.BiasTapeTypeField
  },
  'Bias Tape Material': {
    name: 'Bias Tape Material',
    path: 'bias_tape_material',
    component: ProductFields.BiasTapeMaterialField
  },
  'Material + Type': {
    name: 'Material + Type',
    path: 'material_type',
    component: ProductFields.MaterialTypeField
  },
  'Bias Tape Brand': {
    name: 'Bias Tape Brand',
    path: 'bias_tape_brand',
    component: ProductFields.BiasTapeBrandField
  },
  'Brand + Additional Info': {
    name: 'Brand + Additional Info',
    path: 'brand_additionalinfo',
    component: ProductFields.BrandAdditionalInfoField
  },
  'Qualities + Imperfections': {
    name: 'Qualities + Imperfections',
    path: 'qualities_imperfections',
    component: ProductFields.QualitiesImperfectionsField
  },
  Imperfections: {
    name: 'Imperfections',
    path: 'imperfections',
    component: ProductFields.UsesImperfectionsField
  },
  Price: {
    name: 'Price',
    path: 'price',
    component: ProductFields.PriceField
  },
  'Variant Price': {
    name: 'Variant Price',
    path: 'variant_price',
    component: ProductFields.VariantPricingField
  },
  'Additional Information': {
    name: 'Additional Information',
    path: 'additional_information',
    component: ProductFields.AdditionalInfoField
  },
  Unboxing: {
    name: 'Unboxing',
    path: 'unboxing',
    component: ProductFields.UnboxingField
  },
  Name: {
    name: 'Name',
    path: 'name',
    component: ProductFields.NameField
  }
};

const appFieldsFabric: AppField[] = [
  appFields['Bundle or Roll'],
  appFields['Has Variants'],
  appFields['Camera'],
  appFields['Dimensions'],
  appFields['Variant Dimensions'],
  appFields['Knit or Woven'],
  appFields['Fiber Content'],
  appFields['Knit or Weave Type'],
  appFields['Quality'],
  appFields['Stretch'],
  appFields['Stretch Percentage'],
  appFields['Opacity, Weight, and Drape'],
  appFields['Feel'],
  appFields['Colours'],
  appFields['Pattern + Imperfections'],
  appFields['Pattern'],
  appFields['Common Uses'],
  appFields['Price'],
  appFields['Variant Price'],
  appFields['Name']
];

const appFieldsZipper: AppField[] = [
  appFields['Supplies'],
  appFields['Selling Unit'],
  appFields['Camera'],
  appFields['Hardware Type'],
  appFields['Hardware Unit'],
  appFields['Hardware Material'],
  appFields['Zipper Type'],
  appFields['Zipper Type + Closure'],
  appFields['Zipper Dimensions'],
  appFields['Size'],
  appFields['Colours'],
  appFields['Brand, Imperfections, and Quality'],
  appFields['Imperfections + Quality'],
  appFields['Common Uses'],
  appFields['Quantity'],
  appFields['Price'],
  appFields['Additional Information']
];

const appFieldsRibbing: AppField[] = [
  appFields['Supplies'],
  appFields['Selling Unit'],
  appFields['Camera'],
  appFields['Material and Stretch'],
  appFields['Colours'],
  appFields['Pattern + Imperfections'],
  appFields['Dimensions'],
  appFields['Ribbing Common Use'],
  appFields['Quantity'],
  appFields['Price']
];

const appFieldsSewingPattern: AppField[] = [
  appFields['Supplies'],
  appFields['Camera'],
  appFields['Sewing Pattern Type'],
  appFields['Sewing Pattern Brand Number'],
  appFields['Quantity'],
  appFields['Price']
];

const appFieldsButton: AppField[] = [
  appFields['Supplies'],
  appFields['Selling Unit'],
  appFields['Camera'],
  appFields['Button Size'],
  appFields['Quantity'],
  appFields['Button Type'],
  appFields['Button Material'],
  appFields['Colours'],
  appFields['Button Design'],
  appFields['Common Uses'],
  appFields['Price']
];

const appFieldsThread: AppField[] = [
  appFields['Supplies'],
  appFields['Selling Unit'],
  appFields['Camera'],
  appFields['Thread Type'],
  appFields['Thread Material'],
  appFields['Colours'],
  appFields['Thread Size'],
  appFields['Quantity'],
  appFields['Uses + Imperfections'],
  appFields['Price']
];

export const appFieldsTrim: AppField[] = [
  appFields['Supplies'],
  appFields['Selling Unit'],
  appFields['Camera'],
  appFields['Dimensions'],
  appFields['Quantity'],
  appFields['Dimensions + Quantity'],
  appFields['Type + Weave'],
  appFields['Design + Shape'],
  appFields['Trim Qualities'],
  appFields['Trim Content'],
  appFields['Colours'],
  appFields['Uses + Imperfections'],
  appFields['Price']
];

export const appFieldsElastic: AppField[] = [
  appFields['Supplies'],
  appFields['Selling Unit'],
  appFields['Camera'],
  appFields['Dimensions'],
  appFields['Quantity'],
  appFields['Dimensions + Quantity'],
  appFields['Elastic Type'],
  appFields['Qualities + Imperfections'],
  appFields['Colours'],
  appFields['Price']
];

export const appFieldsBiasTape: AppField[] = [
  appFields['Supplies'],
  appFields['Selling Unit'],
  appFields['Camera'],
  appFields['Dimensions + Quantity'],
  appFields['Material + Type'],
  appFields['Brand + Additional Info'],
  appFields['Qualities + Imperfections'],
  appFields['Colours'],
  appFields['Price']
];

export const appFieldsOtherSupply: AppField[] = [
  appFields['Supplies'],
  appFields['Supply Type'],
  appFields['Selling Unit'],
  appFields['Camera'],
  appFields['Name'],
  appFields['Other Supply Dimensions'],
  appFields['Weight'],
  appFields['Weight + Size'],
  appFields['Other Supply Quantity'],
  appFields['Other Supply Imperfections'],
  appFields['Additional Info'],
  appFields['Colours'],
  appFields['Price']
];

const appFieldLookUp: {
  [k in keyof typeof ProductTypes]: AppField[];
} = {
  Fabric: appFieldsFabric,
  Zippers: appFieldsZipper,
  Buttons: appFieldsButton,
  Elastic: appFieldsElastic,
  'Sewing Patterns': appFieldsSewingPattern,
  Thread: appFieldsThread,
  Trim: appFieldsTrim,
  Ribbing: appFieldsRibbing,
  'Bias Tape': appFieldsBiasTape,
  'Other Supply': appFieldsOtherSupply
};

export const getAppFieldList = (productType?: string): AppField[] => {
  return productType
    ? appFieldLookUp[productType as ProductTypes]
    : [appFields['Supplies']];
};

type props = {
  isSupplies?: boolean;
};
export const ProductEntry: React.FC<props> = ({ isSupplies = false }) => {
  const match = useRouteMatch();

  return (
    <FormStateContainer isSupplies={isSupplies}>
      {(form) => {
        // create link generator with form state
        const linkGenerator = linkGeneratorWrapper(
          form.currentValue,
          getAppFieldList(form.currentValue.productType)
        );
        return (
          <PageLayout
            topbar={
              <Badge
                textTransform="capitalize"
                colorScheme="purple"
                variant="solid"
                fontSize="1rem"
              >
                {form.currentValue.productType === ProductTypes['Other Supply']
                  ? form.currentValue.otherSupplyType
                    ? form.currentValue.otherSupplyType
                    : form.currentValue.productType
                  : form.currentValue.productType}
              </Badge>
            }
            sidebar={
              <SideBar
                links={getAppFieldList(form.currentValue.productType)
                  .map((appField) => {
                    return {
                      disabled: !canNavigate(form.currentValue, appField.name),
                      to: `${match.path}/${appField.path}`,
                      label: appField.name
                    };
                  })
                  .concat([
                    {
                      to: `${match.path}/upload_product`,
                      label: 'Upload Product',
                      disabled: false
                    }
                  ])}
              />
            }
            main={
              <Switch>
                {getAppFieldList(form.currentValue.productType).map((route) => {
                  return (
                    <Route
                      path={`${match.path}/${route.path}`}
                      key={route.path}
                    >
                      <NextFieldNavigator linkGenerator={linkGenerator}>
                        {(toNextPage) =>
                          React.createElement(route.component, {
                            ...form,
                            toNextPage
                          })
                        }
                      </NextFieldNavigator>
                    </Route>
                  );
                })}
                <Route path={`${match.path}/upload_product`}>
                  <UploadProduct {...form} />
                </Route>
                <Route path={`${match.path}`}>
                  <NoPageFound />
                </Route>
              </Switch>
            }
          />
        );
      }}
    </FormStateContainer>
  );
};

import { Flex } from '@chakra-ui/react';
import React from 'react';
import {
  ButtonRadioGroup,
  FormFieldTemplate,
  SwitchInput
} from '../../../general';
import { FabricAttributes } from 'product-validator';
import { AppFieldComponent } from '../../../../types/appFieldTypes';

export const OpacityWeightDrapeField: AppFieldComponent = ({
  setFormField,
  currentValue,
  toNextPage
}) => {
  const bulkySwitch = (
    <SwitchInput
      paddingTop="3"
      label="bulky?"
      isChecked={currentValue.bulky ?? false}
      onChange={() => {
        setFormField((oldForm) => {
          const newForm = { ...oldForm };
          newForm.bulky = !(newForm.bulky ?? false);
          return newForm;
        });
      }}
    />
  );

  return (
    <Flex
      flexDirection="row"
      wrap="wrap"
      gridGap="1rem"
      alignItems="flex-start"
    >
      <FormFieldTemplate title="Opacity">
        <ButtonRadioGroup
          name="opacity"
          value={currentValue?.opacity}
          isVertical={true}
          options={Object.values(FabricAttributes.Opacity)}
          onChange={(v) =>
            setFormField({ opacity: v as FabricAttributes.Opacity })
          }
        />
      </FormFieldTemplate>
      <FormFieldTemplate title="Weight">
        <>
          <ButtonRadioGroup
            name="Weight"
            value={currentValue?.weight}
            isVertical={true}
            options={Object.values(FabricAttributes.Weight)}
            onChange={(v) =>
              setFormField({ weight: v as FabricAttributes.Weight })
            }
          />
          {bulkySwitch}
        </>
      </FormFieldTemplate>
      <FormFieldTemplate title="Drape">
        <ButtonRadioGroup
          name="Drape"
          value={currentValue?.drape}
          isVertical={true}
          options={Object.values(FabricAttributes.Drape)}
          onChange={(v) => setFormField({ drape: v as FabricAttributes.Drape })}
        />
      </FormFieldTemplate>
    </Flex>
  );
};

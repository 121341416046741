export const checkAuth = () => {
  const expiry = localStorage.getItem('expiry');
  if (expiry && Date.now() < parseFloat(expiry)) {
    return true;
  } else {
    clearAuth();
    return false;
  }
};

export const setAuth = (val: string) => {
  localStorage.setItem('expiry', val);
};

export const clearAuth = () => {
  localStorage.removeItem('expiry');
};

export const authErrorMsg = () => {
  const expiry = localStorage.getItem('expiry');
  if (!expiry) return 'Please login.';
  else if (Date.now() > parseFloat(expiry))
    return 'Your session has expired. Please login again.';
  else return '';
};

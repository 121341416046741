import { MergedProduct } from '../../../types/formTypes';
import { BiasTapeAttributes as bta } from 'product-validator';

export const biasTapePriceSuggestion = (
  sellingUnit: bta.SellingUnit,
  widthIn: number,
  biasTapeType?: string,
  imperfections?: string[],
  lengthM?: number
): number => {
  let price = 0;

  switch (biasTapeType) {
    case bta.biasTapeType['Single Fold']:
    case bta.biasTapeType['Double Fold']:
      price = 0.35;
      break;
    case bta.biasTapeType.Continuous:
      price = 0.25;
      break;
    case bta.biasTapeType.Fusible:
      price = 0.3;
      break;
    case bta.biasTapeType.Blanket:
      price = 0.25;
      break;
    default:
      price = 0.25;
      break;
  }

  if (widthIn <= 1) price += 0;
  else if (widthIn <= 2) price += 0.1;
  else if (widthIn <= 3) price += 0.2;
  else price = 0.25;

  if (sellingUnit === bta.SellingUnit.Bundle && lengthM) price *= lengthM;
  if (sellingUnit === bta.SellingUnit.Package && lengthM) price *= lengthM;
  if (sellingUnit === bta.SellingUnit.Roll && lengthM) price *= lengthM;

  if (lengthM) {
    if (sellingUnit === bta.SellingUnit['Roll']) {
      if (lengthM >= 30) price *= 0.6;
      else if (lengthM >= 11) price *= 0.7;
      else price *= 0.8;
    }
  }

  if (!!imperfections && imperfections.length > 0) {
    price *= 0.9;
  }

  price = Math.ceil(price * 20) / 20;

  return price;
};

export const biasTapePriceSuggestionFromForm = (biasTape: MergedProduct) => {
  if (biasTape.sellingUnit) {
    let lengthM = Number(biasTape.lengthM);
    let widthIn = Number(biasTape.widthIn);

    return biasTapePriceSuggestion(
      biasTape.sellingUnit as bta.SellingUnit,
      isNaN(widthIn) ? 1 : widthIn,
      biasTape.biasTapeType,
      biasTape.imperfections?.[0],
      isNaN(lengthM) ? undefined : lengthM
    );
  }

  return undefined;
};

import {
  FormControl,
  FormLabel,
  FormHelperText,
  Heading,
  Box,
  Flex,
  Stack,
  CheckboxGroup,
  VStack
} from '@chakra-ui/react';
import React from 'react';
import { FabricAttributes } from 'product-validator';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { CheckboxButton } from '../../../general/ButtonCheckbox';
import {
  getNestedValidationError,
  isTouched
} from '../../../../utils/validationUtils';
import { OtherTextInput, ErrorMessage, Card } from '../../../general';

export const FeelField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors
}) => {
  const onTextChange = async (text: string) => {
    setFormField({
      feelOther: text
    });
  };

  const errMsgs: string[] = getNestedValidationError('feel', validationErrors);
  const touched = isTouched(currentValue.feel, currentValue.feelOther);

  return (
    <Box>
      <FormControl as="fieldset" isInvalid={errMsgs.length > 0 && touched}>
        <FormLabel as="legend">
          <Heading>Describe the feel</Heading>
        </FormLabel>
        <Stack spacing="5">
          <FormHelperText>Choose at least 1 and up to 3.</FormHelperText>
          <CheckboxGroup
            value={currentValue.feel}
            onChange={(feelArr: string[]) => {
              return setFormField({
                feel: feelArr
              });
            }}
          >
            <Flex
              flexDirection="row"
              wrap="wrap"
              sx={{ gap: '1rem' }}
              alignItems="flex-start"
            >
              {Object.entries(FabricAttributes.Feel).map(
                ([colName, colValues]) => (
                  <Card key={colName} title={colName}>
                    <VStack>
                      {Object.values(colValues).map((item) => (
                        <CheckboxButton
                          key={item}
                          w="100%"
                          isDisabled={
                            currentValue.feel &&
                            (currentValue.feel.length >= 3 ||
                              (currentValue.feel.length >= 2 &&
                                !!currentValue.feelOther)) &&
                            !currentValue.feel.includes(item)
                          }
                          value={item}
                        >
                          {item}
                        </CheckboxButton>
                      ))}
                    </VStack>
                  </Card>
                )
              )}
            </Flex>
          </CheckboxGroup>
          <OtherTextInput
            onChange={(e) => onTextChange(e.target.value)}
            isDisabled={currentValue.feel && currentValue.feel.length >= 3}
            value={currentValue.feelOther ?? ''}
          />
        </Stack>
        <ErrorMessage errMsgs={errMsgs} />
      </FormControl>
    </Box>
  );
};

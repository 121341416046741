import React from 'react';
import { Card } from '.';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Heading
} from '@chakra-ui/react';

interface FormFieldTemplateProps {
  title: string;
  helperText?: string;
}

/* 
  Bizarre behaviour with switch input; toggles label if FormLabel as="legend"
  Link to GitHub issue: https://github.com/chakra-ui/chakra-ui/issues/3206
*/
export const FormFieldTemplate: React.FC<FormFieldTemplateProps> = ({
  title,
  helperText,
  children
}) => {
  return (
    <FormControl as="fieldset" w={0}>
      <FormLabel as={title === 'Drape' ? 'div' : 'legend'}>
        <Heading>{title}</Heading>
      </FormLabel>
      <Card>{children}</Card>
      <FormHelperText>{helperText ?? `Choose one.`}</FormHelperText>
    </FormControl>
  );
};

import {
  FibreType,
  NaturalFibres,
  SemiSyntheticFibres,
  SyntheticFibres
} from './fibreTypes';
import { FabricAttributes as fa } from 'product-validator';

type KnitWeaveTypePricing = {
  [knitOrWoven in keyof typeof fa.KnitOrWoven]: {
    [knitType: string]: number;
  };
};

type priceLookupType = {
  [FibreType.Natural]: {
    [fibre in keyof typeof NaturalFibres]: KnitWeaveTypePricing;
  };
  [FibreType.SemiSynthetic]: {
    [fibre in keyof typeof SemiSyntheticFibres]: KnitWeaveTypePricing;
  };
  [FibreType.Synthetic]: {
    [fibre in keyof typeof SyntheticFibres]: KnitWeaveTypePricing;
  };
};

export const PRICE_LOOKUP: priceLookupType = {
  [FibreType.Natural]: {
    [fa.ContentSpecific.Cashmere]: {
      [fa.KnitOrWoven.Knit]: {
        [fa.Knits['French Terry']]: 18,
        [fa.Knits.Fleece]: 14,
        [fa.Knits.Jersey]: 14,
        [fa.Knits['Rib Knit']]: 12,
        [fa.Knits['Double Cloth Knit']]: 18,
        [fa.Knits.Interlock]: 14,
        [fa.Knits['Jersey Fleece']]: 18,
        [fa.Knits['Knitted Velour']]: 14,
        [fa.Knits.Sequins]: 16,
        [fa.Knits['Waffle Knit']]: 14
      },
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves['Twill Weave']]: 14,
        [fa.Weaves.Fleece]: 14,
        [fa.Weaves['Basket Weave']]: 14,
        [fa.Weaves.Beaded]: 18,
        [fa.Weaves.Corduroy]: 18,
        [fa.Weaves.Crepe]: 14,
        [fa.Weaves['Double Cloth Weave']]: 20,
        [fa.Weaves.Embroidery]: 18,
        [fa.Weaves.Felt]: 14,
        [fa.Weaves.Flanel]: 14,
        [fa.Weaves.Flanelette]: 14,
        [fa.Weaves.Gauze]: 10,
        [fa.Weaves.Herringbone]: 14,
        [fa.Weaves.Houndstooth]: 14,
        [fa.Weaves.Jacquard]: 18,
        [fa.Weaves.Lace]: 16,
        [fa.Weaves.Pinstripe]: 14,
        [fa.Weaves['Plain Weave']]: 14,
        [fa.Weaves['Rib Weave']]: 16,
        [fa.Weaves.Sequins]: 16,
        [fa.Weaves['Tapestry Weave']]: 20,
        [fa.Weaves.Tweed]: 20,
        [fa.Weaves.Velvet]: 16,
        [fa.Weaves['Waffle Weave']]: 14
      }
    },
    [fa.ContentSpecific.Cotton]: {
      [fa.KnitOrWoven.Knit]: {
        [fa.Knits['French Terry']]: 18,
        [fa.Knits.Fleece]: 12,
        [fa.Knits.Jersey]: 12,
        [fa.Knits['Rib Knit']]: 12,
        [fa.Knits['Double Cloth Knit']]: 14,
        [fa.Knits.Interfacing]: 4,
        [fa.Knits.Interlock]: 12,
        [fa.Knits['Jersey Fleece']]: 18,
        [fa.Knits['Knitted Velour']]: 12,
        [fa.Knits.Lace]: 12,
        [fa.Knits.Mesh]: 6,
        [fa.Knits.Sequins]: 12,
        [fa.Knits['Sport Mesh']]: 8,
        [fa.Knits['Terry Towel']]: 14,
        [fa.Knits.Tulle]: 6,
        [fa.Knits['Waffle Knit']]: 10
      },
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves['French Terry']]: 16,
        [fa.Weaves['Twill Weave']]: 10,
        [fa.Weaves.Denim]: 14,
        [fa.Weaves.Fleece]: 10,
        [fa.Weaves['Basket Weave']]: 10,
        [fa.Weaves.Batting]: 4,
        [fa.Weaves.Beaded]: 14,
        [fa.Weaves.Chiffon]: 12,
        [fa.Weaves.Corduroy]: 14,
        [fa.Weaves.Crepe]: 14,
        [fa.Weaves['Double Cloth Weave']]: 16,
        [fa.Weaves.Embroidery]: 16,
        [fa.Weaves.Flanel]: 12,
        [fa.Weaves.Flanelette]: 11,
        [fa.Weaves.Gauze]: 6,
        [fa.Weaves.Herringbone]: 14,
        [fa.Weaves.Interfacing]: 4,
        [fa.Weaves.Jacquard]: 14,
        [fa.Weaves.Lace]: 12,
        [fa.Weaves.Mesh]: 6,
        [fa.Weaves.Minky]: 12,
        [fa.Weaves.PUL]: 6,
        [fa.Weaves.Pinstripe]: 12,
        [fa.Weaves['Plain Weave']]: 10,
        [fa.Weaves['Rib Weave']]: 12,
        [fa.Weaves.Sateen]: 10,
        [fa.Weaves.Satin]: 12,
        [fa.Weaves.Sequins]: 12,
        [fa.Weaves['Tapestry Weave']]: 16,
        [fa.Weaves.Tweed]: 16,
        [fa.Weaves.Velvet]: 12,
        [fa.Weaves.Velveteen]: 12,
        [fa.Weaves['Waffle Weave']]: 10
      }
    },
    [fa.ContentSpecific.Hemp]: {
      [fa.KnitOrWoven.Knit]: {
        [fa.Knits['French Terry']]: 18,
        [fa.Knits.Fleece]: 12,
        [fa.Knits.Jersey]: 14,
        [fa.Knits['Rib Knit']]: 12,
        [fa.Knits['Double Cloth Knit']]: 16,
        [fa.Knits.Interlock]: 14,
        [fa.Knits['Jersey Fleece']]: 18,
        [fa.Knits['Knitted Velour']]: 14,
        [fa.Knits.Lace]: 14,
        [fa.Knits.Mesh]: 8,
        [fa.Knits.Sequins]: 12,
        [fa.Knits['Sport Mesh']]: 10,
        [fa.Knits['Terry Towel']]: 15,
        [fa.Knits.Tulle]: 6,
        [fa.Knits['Waffle Knit']]: 12
      },
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves['French Terry']]: 16,
        [fa.Weaves['Twill Weave']]: 12,
        [fa.Weaves.Denim]: 14,
        [fa.Weaves.Fleece]: 10,
        [fa.Weaves['Basket Weave']]: 12,
        [fa.Weaves.Beaded]: 16,
        [fa.Weaves.Corduroy]: 14,
        [fa.Weaves.Crepe]: 14,
        [fa.Weaves['Double Cloth Weave']]: 16,
        [fa.Weaves.Embroidery]: 16,
        [fa.Weaves.Gauze]: 7,
        [fa.Weaves.Herringbone]: 16,
        [fa.Weaves.Jacquard]: 16,
        [fa.Weaves.Lace]: 14,
        [fa.Weaves.Mesh]: 8,
        [fa.Weaves.PUL]: 8,
        [fa.Weaves.Pinstripe]: 14,
        [fa.Weaves['Plain Weave']]: 12,
        [fa.Weaves['Rib Weave']]: 14,
        [fa.Weaves.Sequins]: 12,
        [fa.Weaves['Tapestry Weave']]: 18,
        [fa.Weaves.Tweed]: 18,
        [fa.Weaves.Velvet]: 13,
        [fa.Weaves.Velveteen]: 13,
        [fa.Weaves['Waffle Weave']]: 12
      }
    },
    [fa.ContentSpecific.Linen]: {
      [fa.KnitOrWoven.Knit]: {
        [fa.Knits['French Terry']]: 18,
        [fa.Knits.Fleece]: 12,
        [fa.Knits.Jersey]: 14,
        [fa.Knits['Rib Knit']]: 12,
        [fa.Knits['Double Cloth Knit']]: 16,
        [fa.Knits.Interlock]: 14,
        [fa.Knits['Jersey Fleece']]: 18,
        [fa.Knits['Knitted Velour']]: 14,
        [fa.Knits.Lace]: 14,
        [fa.Knits.Mesh]: 8,
        [fa.Knits.Sequins]: 12,
        [fa.Knits['Sport Mesh']]: 10,
        [fa.Knits.Tulle]: 6,
        [fa.Knits['Waffle Knit']]: 12
      },
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves['French Terry']]: 16,
        [fa.Weaves['Twill Weave']]: 12,
        [fa.Weaves.Denim]: 14,
        [fa.Weaves.Fleece]: 10,
        [fa.Weaves['Basket Weave']]: 12,
        [fa.Weaves.Beaded]: 16,
        [fa.Weaves.Chiffon]: 14,
        [fa.Weaves.Corduroy]: 14,
        [fa.Weaves.Crepe]: 14,
        [fa.Weaves['Double Cloth Weave']]: 16,
        [fa.Weaves.Embroidery]: 16,
        [fa.Weaves.Gauze]: 7,
        [fa.Weaves.Herringbone]: 16,
        [fa.Weaves.Jacquard]: 16,
        [fa.Weaves.Lace]: 14,
        [fa.Weaves.Mesh]: 8,
        [fa.Weaves.PUL]: 8,
        [fa.Weaves.Pinstripe]: 14,
        [fa.Weaves['Plain Weave']]: 12,
        [fa.Weaves['Rib Weave']]: 14,
        [fa.Weaves.Sateen]: 12,
        [fa.Weaves.Satin]: 14,
        [fa.Weaves.Sequins]: 12,
        [fa.Weaves['Tapestry Weave']]: 18,
        [fa.Weaves.Tweed]: 18,
        [fa.Weaves.Tulle]: 6,
        [fa.Weaves['Waffle Weave']]: 12
      }
    },
    [fa.ContentSpecific['Organic Cotton']]: {
      [fa.KnitOrWoven.Knit]: {
        [fa.Knits['French Terry']]: 18,
        [fa.Knits.Fleece]: 12,
        [fa.Knits.Jersey]: 12,
        [fa.Knits['Rib Knit']]: 12,
        [fa.Knits['Double Cloth Knit']]: 14,
        [fa.Knits.Interlock]: 12,
        [fa.Knits['Jersey Fleece']]: 18,
        [fa.Knits['Knitted Velour']]: 12,
        [fa.Knits.Lace]: 12,
        [fa.Knits.Mesh]: 6,
        [fa.Knits.Sequins]: 12,
        [fa.Knits['Sport Mesh']]: 8,
        [fa.Knits['Terry Towel']]: 15,
        [fa.Knits.Tulle]: 6,
        [fa.Knits['Waffle Knit']]: 10
      },
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves['French Terry']]: 16,
        [fa.Weaves['Twill Weave']]: 10,
        [fa.Weaves.Denim]: 14,
        [fa.Weaves.Fleece]: 10,
        [fa.Weaves['Basket Weave']]: 10,
        [fa.Weaves.Beaded]: 14,
        [fa.Weaves.Chiffon]: 12,
        [fa.Weaves.Corduroy]: 14,
        [fa.Weaves.Crepe]: 14,
        [fa.Weaves['Double Cloth Weave']]: 16,
        [fa.Weaves.Embroidery]: 16,
        [fa.Weaves.Flanel]: 12,
        [fa.Weaves.Flanelette]: 11,
        [fa.Weaves.Gauze]: 7,
        [fa.Weaves.Herringbone]: 14,
        [fa.Weaves.Jacquard]: 14,
        [fa.Weaves.Lace]: 12,
        [fa.Weaves.Mesh]: 6,
        [fa.Weaves.Minky]: 13,
        [fa.Weaves.PUL]: 6,
        [fa.Weaves.Pinstripe]: 12,
        [fa.Weaves['Plain Weave']]: 10,
        [fa.Weaves['Rib Weave']]: 12,
        [fa.Weaves.Sateen]: 10,
        [fa.Weaves.Satin]: 12,
        [fa.Weaves.Sequins]: 12,
        [fa.Weaves['Tapestry Weave']]: 16,
        [fa.Weaves.Tweed]: 16,
        [fa.Weaves.Velvet]: 13,
        [fa.Weaves.Velveteen]: 13,
        [fa.Weaves['Waffle Weave']]: 10
      }
    },
    [fa.ContentSpecific.Silk]: {
      [fa.KnitOrWoven.Knit]: {
        [fa.Knits.Jersey]: 14,
        [fa.Knits['Knitted Velour']]: 16,
        [fa.Knits.Lace]: 14,
        [fa.Knits.Mesh]: 10,
        [fa.Knits.Sequins]: 14,
        [fa.Knits.Tulle]: 8
      },
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves['Twill Weave']]: 12,
        [fa.Weaves['Basket Weave']]: 12,
        [fa.Weaves.Beaded]: 16,
        [fa.Weaves.Chiffon]: 14,
        [fa.Weaves.Crepe]: 14,
        [fa.Weaves['Crushed Velvet']]: 14,
        [fa.Weaves['Double Cloth Weave']]: 18,
        [fa.Weaves.Embroidery]: 16,
        [fa.Weaves.Gauze]: 12,
        [fa.Weaves.Herringbone]: 16,
        [fa.Weaves.Houndstooth]: 16,
        [fa.Weaves.Jacquard]: 16,
        [fa.Weaves.Lace]: 14,
        [fa.Weaves.Mesh]: 10,
        [fa.Weaves.Noil]: 22,
        [fa.Weaves.Organza]: 14,
        [fa.Weaves.Pinstripe]: 14,
        [fa.Weaves['Plain Weave']]: 12,
        [fa.Weaves['Rib Weave']]: 14,
        [fa.Weaves.Sateen]: 12,
        [fa.Weaves.Satin]: 16,
        [fa.Weaves.Sequins]: 14,
        [fa.Weaves['Tapestry Weave']]: 20,
        [fa.Weaves.Tweed]: 20,
        [fa.Weaves.Tulle]: 8,
        [fa.Weaves.Velvet]: 14,
        [fa.Weaves.Velveteen]: 15,
        [fa.Weaves['Waffle Weave']]: 12
      }
    },
    [fa.ContentSpecific.Wool]: {
      [fa.KnitOrWoven.Knit]: {
        [fa.Knits['French Terry']]: 18,
        [fa.Knits.Fleece]: 14,
        [fa.Knits.Jersey]: 14,
        [fa.Knits['Rib Knit']]: 12,
        [fa.Knits['Double Cloth Knit']]: 18,
        [fa.Knits.Interfacing]: 4,
        [fa.Knits.Interlock]: 14,
        [fa.Knits['Jersey Fleece']]: 18,
        [fa.Knits['Knitted Velour']]: 14,
        [fa.Knits.Sequins]: 16,
        [fa.Knits['Sport Mesh']]: 10,
        [fa.Knits['Waffle Knit']]: 14
      },
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves['Twill Weave']]: 14,
        [fa.Weaves.Fleece]: 14,
        [fa.Weaves['Basket Weave']]: 12,
        [fa.Weaves.Beaded]: 16,
        [fa.Weaves.Corduroy]: 18,
        [fa.Weaves.Crepe]: 14,
        [fa.Weaves['Double Cloth Weave']]: 20,
        [fa.Weaves.Embroidery]: 16,
        [fa.Weaves.Felt]: 14,
        [fa.Weaves.Gauze]: 10,
        [fa.Weaves.Herringbone]: 14,
        [fa.Weaves.Interfacing]: 4,
        [fa.Weaves.Jacquard]: 16,
        [fa.Weaves.Noil]: 18,
        [fa.Weaves.Pinstripe]: 14,
        [fa.Weaves['Plain Weave']]: 12,
        [fa.Weaves['Rib Weave']]: 16,
        [fa.Weaves.Sequins]: 14,
        [fa.Weaves['Tapestry Weave']]: 20,
        [fa.Weaves.Tweed]: 20,
        [fa.Weaves.Velvet]: 16,
        [fa.Weaves['Waffle Weave']]: 14
      }
    },
    [fa.ContentGeneral.Natural]: {
      [fa.KnitOrWoven.Knit]: {
        [fa.Knits['Double Cloth Knit']]: 14,
        [fa.Knits.Fleece]: 14,
        [fa.Knits['French Terry']]: 14,
        [fa.Knits['Terry Towel']]: 14,
        [fa.Knits['Knitted Velour']]: 14,
        [fa.Knits.Sequins]: 12,
        [fa.Knits['Faux Fur']]: 12,
        [fa.Knits.Lace]: 12,
        [fa.Knits.Mesh]: 10,
        [fa.Knits['Sport Mesh']]: 10,
        [fa.Knits.Tulle]: 10,
        [fa.Knits.Jersey]: 12,
        [fa.Knits['Waffle Knit']]: 12,
        [fa.Knits['Rib Knit']]: 12,
        [fa.Knits.Interlock]: 12,
        [fa.Knits.Microfibre]: 12
      },
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves['Plain Weave']]: 10,
        [fa.Weaves.Flanel]: 10,
        [fa.Weaves.Flanelette]: 10,
        [fa.Weaves['Rib Weave']]: 10,
        [fa.Weaves['Waffle Weave']]: 10,
        [fa.Weaves['Twill Weave']]: 10,
        [fa.Weaves['Basket Weave']]: 10,
        [fa.Weaves.Herringbone]: 10,
        [fa.Weaves.Houndstooth]: 10,
        [fa.Weaves.Pinstripe]: 10,
        [fa.Weaves.Noil]: 10,
        [fa.Weaves.Beaded]: 14,
        [fa.Weaves.Embroidery]: 14,
        [fa.Weaves.Sequins]: 14,
        [fa.Weaves.Jacquard]: 14,
        [fa.Weaves['Tapestry Weave']]: 14,
        [fa.Weaves['Crushed Velvet']]: 12,
        [fa.Weaves.Velveteen]: 12,
        [fa.Weaves.Velvet]: 12,
        [fa.Weaves.Corduroy]: 12,
        [fa.Weaves.Felt]: 12,
        [fa.Weaves.Minky]: 12,
        [fa.Weaves['Double Cloth Weave']]: 12,
        [fa.Weaves.Fleece]: 12,
        [fa.Weaves['French Terry']]: 12,
        [fa.Weaves.PUL]: 12,
        [fa.Weaves.Softshell]: 12,
        [fa.Weaves['Faux Fur']]: 16,
        [fa.Weaves['Faux Suede']]: 16,
        [fa.Weaves.Denim]: 16,
        [fa.Weaves.Batting]: 6,
        [fa.Weaves.Interfacing]: 6,
        [fa.Weaves.Chiffon]: 10,
        [fa.Weaves.Crepe]: 10,
        [fa.Weaves.Gauze]: 10,
        [fa.Weaves.Mesh]: 10,
        [fa.Weaves.Organza]: 10,
        [fa.Weaves.Tulle]: 10,
        [fa.Weaves.Taffeta]: 10,
        [fa.Weaves.Lace]: 10,
        [fa.Weaves.Satin]: 10,
        [fa.Weaves.Sateen]: 10
      }
    },
    [fa.ContentGeneral['Natural Blend']]: {
      [fa.KnitOrWoven.Knit]: {
        [fa.Knits['Double Cloth Knit']]: 12,
        [fa.Knits.Fleece]: 12,
        [fa.Knits['French Terry']]: 12,
        [fa.Knits['Terry Towel']]: 12,
        [fa.Knits['Knitted Velour']]: 12,
        [fa.Knits.Sequins]: 10,
        [fa.Knits['Faux Fur']]: 10,
        [fa.Knits.Lace]: 10,
        [fa.Knits.Mesh]: 8,
        [fa.Knits['Sport Mesh']]: 8,
        [fa.Knits.Tulle]: 8,
        [fa.Knits.Jersey]: 10,
        [fa.Knits['Waffle Knit']]: 10,
        [fa.Knits['Rib Knit']]: 10,
        [fa.Knits.Interlock]: 10,
        [fa.Knits.Microfibre]: 10
      },
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves['Plain Weave']]: 9,
        [fa.Weaves.Flanel]: 9,
        [fa.Weaves.Flanelette]: 9,
        [fa.Weaves['Rib Weave']]: 9,
        [fa.Weaves['Waffle Weave']]: 9,
        [fa.Weaves['Twill Weave']]: 9,
        [fa.Weaves['Basket Weave']]: 9,
        [fa.Weaves.Herringbone]: 9,
        [fa.Weaves.Houndstooth]: 9,
        [fa.Weaves.Pinstripe]: 9,
        [fa.Weaves.Noil]: 9,
        [fa.Weaves.Beaded]: 13,
        [fa.Weaves.Embroidery]: 13,
        [fa.Weaves.Sequins]: 13,
        [fa.Weaves.Jacquard]: 13,
        [fa.Weaves['Tapestry Weave']]: 13,
        [fa.Weaves['Crushed Velvet']]: 10,
        [fa.Weaves.Velveteen]: 10,
        [fa.Weaves.Velvet]: 10,
        [fa.Weaves.Corduroy]: 10,
        [fa.Weaves.Felt]: 10,
        [fa.Weaves.Minky]: 10,
        [fa.Weaves['Double Cloth Weave']]: 10,
        [fa.Weaves.Fleece]: 10,
        [fa.Weaves['French Terry']]: 10,
        [fa.Weaves.PUL]: 10,
        [fa.Weaves.Softshell]: 10,
        [fa.Weaves['Faux Fur']]: 14,
        [fa.Weaves['Faux Suede']]: 14,
        [fa.Weaves.Denim]: 14,
        [fa.Weaves.Batting]: 5,
        [fa.Weaves.Interfacing]: 5,
        [fa.Weaves.Chiffon]: 9,
        [fa.Weaves.Crepe]: 9,
        [fa.Weaves.Gauze]: 9,
        [fa.Weaves.Mesh]: 9,
        [fa.Weaves.Organza]: 9,
        [fa.Weaves.Tulle]: 9,
        [fa.Weaves.Taffeta]: 9,
        [fa.Weaves.Lace]: 9,
        [fa.Weaves.Satin]: 9,
        [fa.Weaves.Sateen]: 9
      }
    }
  },
  [FibreType.SemiSynthetic]: {
    [fa.ContentSpecific.Lyocell]: {
      [fa.KnitOrWoven.Knit]: {
        [fa.Knits['French Terry']]: 18,
        [fa.Knits.Fleece]: 12,
        [fa.Knits.Jersey]: 14,
        [fa.Knits['Rib Knit']]: 12,
        [fa.Knits['Double Cloth Knit']]: 16,
        [fa.Knits.Interlock]: 14,
        [fa.Knits['Jersey Fleece']]: 18,
        [fa.Knits['Knitted Velour']]: 14,
        [fa.Knits.Lace]: 14,
        [fa.Knits.Mesh]: 8,
        [fa.Knits.Sequins]: 12,
        [fa.Knits['Sport Mesh']]: 8,
        [fa.Knits['Terry Towel']]: 12,
        [fa.Knits.Tulle]: 6,
        [fa.Knits['Waffle Knit']]: 12
      },
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves['Twill Weave']]: 12,
        [fa.Weaves.Denim]: 16,
        [fa.Weaves.Fleece]: 12,
        [fa.Weaves['Basket Weave']]: 12,
        [fa.Weaves.Beaded]: 16,
        [fa.Weaves.Chiffon]: 14,
        [fa.Weaves.Corduroy]: 16,
        [fa.Weaves.Crepe]: 14,
        [fa.Weaves['Double Cloth Weave']]: 16,
        [fa.Weaves.Embroidery]: 16,
        [fa.Weaves.Flanel]: 14,
        [fa.Weaves.Flanelette]: 14,
        [fa.Weaves.Gauze]: 8,
        [fa.Weaves.Herringbone]: 16,
        [fa.Weaves.Houndstooth]: 16,
        [fa.Weaves.Jacquard]: 16,
        [fa.Weaves.Lace]: 14,
        [fa.Weaves.Mesh]: 8,
        [fa.Weaves.Pinstripe]: 14,
        [fa.Weaves['Plain Weave']]: 12,
        [fa.Weaves['Rib Weave']]: 14,
        [fa.Weaves.Sateen]: 12,
        [fa.Weaves.Satin]: 14,
        [fa.Weaves.Sequins]: 12,
        [fa.Weaves['Tapestry Weave']]: 18,
        [fa.Weaves.Tweed]: 18,
        [fa.Weaves.Tulle]: 6,
        [fa.Weaves['Waffle Weave']]: 12
      }
    },
    [fa.ContentSpecific.Modal]: {
      [fa.KnitOrWoven.Knit]: {
        [fa.Knits['French Terry']]: 18,
        [fa.Knits.Fleece]: 12,
        [fa.Knits.Jersey]: 14,
        [fa.Knits['Rib Knit']]: 12,
        [fa.Knits['Double Cloth Knit']]: 16,
        [fa.Knits.Interlock]: 14,
        [fa.Knits['Jersey Fleece']]: 18,
        [fa.Knits['Knitted Velour']]: 14,
        [fa.Knits.Lace]: 14,
        [fa.Knits.Mesh]: 8,
        [fa.Knits.Sequins]: 12,
        [fa.Knits['Sport Mesh']]: 8,
        [fa.Knits['Terry Towel']]: 12,
        [fa.Knits.Tulle]: 6,
        [fa.Knits['Waffle Knit']]: 12
      },
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves['Twill Weave']]: 12,
        [fa.Weaves.Denim]: 16,
        [fa.Weaves.Fleece]: 12,
        [fa.Weaves['Basket Weave']]: 12,
        [fa.Weaves.Beaded]: 16,
        [fa.Weaves.Chiffon]: 14,
        [fa.Weaves.Corduroy]: 16,
        [fa.Weaves.Crepe]: 14,
        [fa.Weaves['Double Cloth Weave']]: 16,
        [fa.Weaves.Embroidery]: 16,
        [fa.Weaves.Flanel]: 14,
        [fa.Weaves.Flanelette]: 14,
        [fa.Weaves.Gauze]: 8,
        [fa.Weaves.Herringbone]: 16,
        [fa.Weaves.Houndstooth]: 16,
        [fa.Weaves.Jacquard]: 16,
        [fa.Weaves.Lace]: 14,
        [fa.Weaves.Mesh]: 8,
        [fa.Weaves.Pinstripe]: 14,
        [fa.Weaves['Plain Weave']]: 12,
        [fa.Weaves['Rib Weave']]: 14,
        [fa.Weaves.Sateen]: 12,
        [fa.Weaves.Satin]: 14,
        [fa.Weaves.Sequins]: 12,
        [fa.Weaves['Tapestry Weave']]: 18,
        [fa.Weaves.Tweed]: 18,
        [fa.Weaves.Tulle]: 6,
        [fa.Weaves['Waffle Weave']]: 12
      }
    },
    [fa.ContentSpecific.Rayon]: {
      [fa.KnitOrWoven.Knit]: {
        [fa.Knits['French Terry']]: 16,
        [fa.Knits.Fleece]: 10,
        [fa.Knits.Jersey]: 12,
        [fa.Knits['Rib Knit']]: 12,
        [fa.Knits['Double Cloth Knit']]: 14,
        [fa.Knits.Interlock]: 12,
        [fa.Knits['Jersey Fleece']]: 16,
        [fa.Knits['Knitted Velour']]: 12,
        [fa.Knits.Lace]: 10,
        [fa.Knits.Mesh]: 6,
        [fa.Knits.Sequins]: 10,
        [fa.Knits['Sport Mesh']]: 8,
        [fa.Knits['Terry Towel']]: 12,
        [fa.Knits.Tulle]: 6,
        [fa.Knits['Waffle Knit']]: 12
      },
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves['Twill Weave']]: 10,
        [fa.Weaves.Denim]: 14,
        [fa.Weaves.Fleece]: 10,
        [fa.Weaves['Basket Weave']]: 10,
        [fa.Weaves.Beaded]: 12,
        [fa.Weaves.Chiffon]: 10,
        [fa.Weaves.Corduroy]: 14,
        [fa.Weaves.Crepe]: 12,
        [fa.Weaves['Double Cloth Weave']]: 16,
        [fa.Weaves.Embroidery]: 14,
        [fa.Weaves.Flanel]: 12,
        [fa.Weaves.Flanelette]: 12,
        [fa.Weaves.Gauze]: 8,
        [fa.Weaves.Herringbone]: 12,
        [fa.Weaves.Houndstooth]: 14,
        [fa.Weaves.Jacquard]: 12,
        [fa.Weaves.Lace]: 10,
        [fa.Weaves.Mesh]: 6,
        [fa.Weaves.Pinstripe]: 12,
        [fa.Weaves['Plain Weave']]: 10,
        [fa.Weaves['Rib Weave']]: 12,
        [fa.Weaves.Sateen]: 10,
        [fa.Weaves.Satin]: 10,
        [fa.Weaves.Sequins]: 12,
        [fa.Weaves['Tapestry Weave']]: 16,
        [fa.Weaves.Tweed]: 16,
        [fa.Weaves.Tulle]: 6,
        [fa.Weaves['Waffle Weave']]: 12
      }
    },
    [fa.ContentSpecific['Rayon From Bamboo']]: {
      [fa.KnitOrWoven.Knit]: {
        [fa.Knits['French Terry']]: 18,
        [fa.Knits.Fleece]: 16,
        [fa.Knits.Jersey]: 14,
        [fa.Knits['Rib Knit']]: 12,
        [fa.Knits['Double Cloth Knit']]: 16,
        [fa.Knits.Interlock]: 14,
        [fa.Knits['Jersey Fleece']]: 16,
        [fa.Knits['Knitted Velour']]: 14,
        [fa.Knits.Lace]: 14,
        [fa.Knits.Mesh]: 8,
        [fa.Knits.Sequins]: 12,
        [fa.Knits['Sport Mesh']]: 8,
        [fa.Knits['Terry Towel']]: 12,
        [fa.Knits.Tulle]: 6,
        [fa.Knits['Waffle Knit']]: 12
      },
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves['Twill Weave']]: 12,
        [fa.Weaves.Denim]: 16,
        [fa.Weaves.Fleece]: 12,
        [fa.Weaves['Basket Weave']]: 12,
        [fa.Weaves.Beaded]: 16,
        [fa.Weaves.Chiffon]: 14,
        [fa.Weaves.Corduroy]: 16,
        [fa.Weaves.Crepe]: 14,
        [fa.Weaves['Double Cloth Weave']]: 16,
        [fa.Weaves.Embroidery]: 16,
        [fa.Weaves.Flanel]: 14,
        [fa.Weaves.Flanelette]: 14,
        [fa.Weaves.Gauze]: 8,
        [fa.Weaves.Herringbone]: 16,
        [fa.Weaves.Houndstooth]: 16,
        [fa.Weaves.Jacquard]: 16,
        [fa.Weaves.Lace]: 14,
        [fa.Weaves.Mesh]: 8,
        [fa.Weaves.Pinstripe]: 14,
        [fa.Weaves['Plain Weave']]: 12,
        [fa.Weaves['Rib Weave']]: 14,
        [fa.Weaves.Sateen]: 12,
        [fa.Weaves.Satin]: 14,
        [fa.Weaves.Sequins]: 12,
        [fa.Weaves['Tapestry Weave']]: 18,
        [fa.Weaves.Tweed]: 18,
        [fa.Weaves.Tulle]: 6,
        [fa.Weaves['Waffle Weave']]: 12
      }
    },
    [fa.ContentSpecific.Tencel]: {
      [fa.KnitOrWoven.Knit]: {
        [fa.Knits['French Terry']]: 18,
        [fa.Knits.Fleece]: 12,
        [fa.Knits.Jersey]: 14,
        [fa.Knits['Rib Knit']]: 12,
        [fa.Knits['Double Cloth Knit']]: 16,
        [fa.Knits.Interlock]: 14,
        [fa.Knits['Jersey Fleece']]: 18,
        [fa.Knits['Knitted Velour']]: 14,
        [fa.Knits.Lace]: 14,
        [fa.Knits.Mesh]: 8,
        [fa.Knits.Sequins]: 12,
        [fa.Knits['Sport Mesh']]: 8,
        [fa.Knits['Terry Towel']]: 12,
        [fa.Knits.Tulle]: 6,
        [fa.Knits['Waffle Knit']]: 12
      },
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves['Twill Weave']]: 12,
        [fa.Weaves.Denim]: 16,
        [fa.Weaves.Fleece]: 12,
        [fa.Weaves['Basket Weave']]: 12,
        [fa.Weaves.Beaded]: 16,
        [fa.Weaves.Chiffon]: 14,
        [fa.Weaves.Corduroy]: 16,
        [fa.Weaves.Crepe]: 14,
        [fa.Weaves['Double Cloth Weave']]: 16,
        [fa.Weaves.Embroidery]: 16,
        [fa.Weaves.Flanel]: 14,
        [fa.Weaves.Flanelette]: 14,
        [fa.Weaves.Gauze]: 8,
        [fa.Weaves.Herringbone]: 16,
        [fa.Weaves.Houndstooth]: 16,
        [fa.Weaves.Jacquard]: 16,
        [fa.Weaves.Lace]: 14,
        [fa.Weaves.Mesh]: 8,
        [fa.Weaves.Pinstripe]: 14,
        [fa.Weaves['Plain Weave']]: 12,
        [fa.Weaves['Rib Weave']]: 14,
        [fa.Weaves.Sateen]: 12,
        [fa.Weaves.Satin]: 14,
        [fa.Weaves.Sequins]: 12,
        [fa.Weaves['Tapestry Weave']]: 18,
        [fa.Weaves.Tweed]: 18,
        [fa.Weaves.Tulle]: 6,
        [fa.Weaves['Waffle Weave']]: 12
      }
    }
  },
  [FibreType.Synthetic]: {
    [fa.ContentSpecific.Acetate]: {
      [fa.KnitOrWoven.Knit]: {},
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves['Basket Weave']]: 6,
        [fa.Weaves.Beaded]: 6,
        [fa.Weaves.Embroidery]: 6,
        [fa.Weaves.Organza]: 6,
        [fa.Weaves.PUL]: 6,
        [fa.Weaves['Plain Weave']]: 4,
        [fa.Weaves.Sateen]: 6,
        [fa.Weaves.Satin]: 6,
        [fa.Weaves.Sequins]: 6,
        [fa.Weaves.Taffeta]: 6
      }
    },
    [fa.ContentSpecific.Acrylic]: {
      [fa.KnitOrWoven.Knit]: {
        [fa.Knits['French Terry']]: 10,
        [fa.Knits.Fleece]: 8,
        [fa.Knits.Jersey]: 8,
        [fa.Knits['Rib Knit']]: 10,
        [fa.Knits['Double Cloth Knit']]: 10,
        [fa.Knits['Faux Fur']]: 12,
        [fa.Knits.Interlock]: 10,
        [fa.Knits['Jersey Fleece']]: 10,
        [fa.Knits['Knitted Velour']]: 10,
        [fa.Knits.Lace]: 8,
        [fa.Knits.Mesh]: 4,
        [fa.Knits.Microfibre]: 8,
        [fa.Knits.Sequins]: 8,
        [fa.Knits['Sport Mesh']]: 8,
        [fa.Knits['Terry Towel']]: 10,
        [fa.Knits.Tulle]: 4,
        [fa.Knits['Waffle Knit']]: 10
      },
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves['French Terry']]: 10,
        [fa.Weaves['Twill Weave']]: 8,
        [fa.Weaves.Denim]: 12,
        [fa.Weaves.Fleece]: 8,
        [fa.Weaves['Basket Weave']]: 8,
        [fa.Weaves.Beaded]: 10,
        [fa.Weaves.Chiffon]: 10,
        [fa.Weaves.Corduroy]: 12,
        [fa.Weaves.Crepe]: 10,
        [fa.Weaves['Double Cloth Weave']]: 12,
        [fa.Weaves.Embroidery]: 12,
        [fa.Weaves['Faux Fur']]: 12,
        [fa.Weaves.Felt]: 10,
        [fa.Weaves.Flanel]: 9,
        [fa.Weaves.Flanelette]: 10,
        [fa.Weaves.Herringbone]: 10,
        [fa.Weaves.Houndstooth]: 10,
        [fa.Weaves.Jacquard]: 8,
        [fa.Weaves.Lace]: 6,
        [fa.Weaves.Mesh]: 8,
        [fa.Weaves.Organza]: 10,
        [fa.Weaves.PUL]: 10,
        [fa.Weaves.Pinstripe]: 6,
        [fa.Weaves['Plain Weave']]: 8,
        [fa.Weaves['Rib Weave']]: 8,
        [fa.Weaves.Sateen]: 8,
        [fa.Weaves.Satin]: 10,
        [fa.Weaves.Sequins]: 8,
        [fa.Weaves.Taffeta]: 12,
        [fa.Weaves['Tapestry Weave']]: 12,
        [fa.Weaves.Tweed]: 4,
        [fa.Weaves.Tulle]: 10,
        [fa.Weaves['Waffle Weave']]: 10
      }
    },
    [fa.ContentSpecific.Nylon]: {
      [fa.KnitOrWoven.Knit]: {
        [fa.Knits['French Terry']]: 10,
        [fa.Knits.Fleece]: 10,
        [fa.Knits.Jersey]: 8,
        [fa.Knits['Rib Knit']]: 8,
        [fa.Knits['Double Cloth Knit']]: 10,
        [fa.Knits.Interfacing]: 2,
        [fa.Knits.Interlock]: 4,
        [fa.Knits['Jersey Fleece']]: 10,
        [fa.Knits['Knitted Velour']]: 8,
        [fa.Knits.Lace]: 8,
        [fa.Knits.Mesh]: 10,
        [fa.Knits.Sequins]: 8,
        [fa.Knits['Sport Mesh']]: 4,
        [fa.Knits.Tulle]: 10,
        [fa.Knits['Waffle Knit']]: 10
      },
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves['French Terry']]: 10,
        [fa.Weaves['Twill Weave']]: 10,
        [fa.Weaves.Denim]: 12,
        [fa.Weaves.Fleece]: 8,
        [fa.Weaves['Basket Weave']]: 8,
        [fa.Weaves.Beaded]: 10,
        [fa.Weaves.Chiffon]: 10,
        [fa.Weaves.Corduroy]: 12,
        [fa.Weaves.Crepe]: 10,
        [fa.Weaves['Double Cloth Weave']]: 12,
        [fa.Weaves.Embroidery]: 12,
        [fa.Weaves.Flanel]: 10,
        [fa.Weaves.Flanelette]: 9,
        [fa.Weaves.Herringbone]: 10,
        [fa.Weaves.Houndstooth]: 10,
        [fa.Weaves.Interfacing]: 2,
        [fa.Weaves.Jacquard]: 10,
        [fa.Weaves.Lace]: 8,
        [fa.Weaves.Mesh]: 6,
        [fa.Weaves.Organza]: 8,
        [fa.Weaves.PUL]: 6,
        [fa.Weaves.Pinstripe]: 10,
        [fa.Weaves['Plain Weave']]: 8,
        [fa.Weaves['Rib Weave']]: 10,
        [fa.Weaves.Sateen]: 8,
        [fa.Weaves.Satin]: 8,
        [fa.Weaves.Sequins]: 10,
        [fa.Weaves.Softshell]: 12,
        [fa.Weaves.Taffeta]: 8,
        [fa.Weaves['Tapestry Weave']]: 12,
        [fa.Weaves.Tweed]: 12,
        [fa.Weaves.Tulle]: 4,

        [fa.Weaves['Waffle Weave']]: 10
      }
    },
    [fa.ContentSpecific.PU]: {
      [fa.KnitOrWoven.Knit]: {
        [fa.Knits['Pleather Cotton Backing']]: 12,
        [fa.Knits['Pleather Polyester Backing']]: 10
      },
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves['Pleather Cotton Backing']]: 12,
        [fa.Weaves['Pleather Polyester Backing']]: 10
      }
    },
    [fa.ContentSpecific.PVC]: {
      [fa.KnitOrWoven.Knit]: {},
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves.Vinyl]: 12
      }
    },
    [fa.ContentSpecific.Polyester]: {
      [fa.KnitOrWoven.Knit]: {
        [fa.Knits['French Terry']]: 10,
        [fa.Knits.Fleece]: 8,
        [fa.Knits.Jersey]: 8,
        [fa.Knits['Rib Knit']]: 10,
        [fa.Knits['Double Cloth Knit']]: 10,
        [fa.Knits.Interfacing]: 2,
        [fa.Knits.Interlock]: 10,
        [fa.Knits['Jersey Fleece']]: 10,
        [fa.Knits['Knitted Velour']]: 10,
        [fa.Knits.Lace]: 8,
        [fa.Knits.Mesh]: 4,
        [fa.Knits.Microfibre]: 8,
        [fa.Knits.Sequins]: 8,
        [fa.Knits['Sport Mesh']]: 8,
        [fa.Knits['Terry Towel']]: 10,
        [fa.Knits.Tulle]: 4,
        [fa.Knits['Waffle Knit']]: 10
      },
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves['French Terry']]: 10,
        [fa.Weaves['Twill Weave']]: 10,
        [fa.Weaves.Denim]: 12,
        [fa.Weaves.Fleece]: 8,
        [fa.Weaves['Basket Weave']]: 8,
        [fa.Weaves.Batting]: 3,
        [fa.Weaves.Beaded]: 10,
        [fa.Weaves.Chiffon]: 10,
        [fa.Weaves.Corduroy]: 12,
        [fa.Weaves.Crepe]: 10,
        [fa.Weaves['Double Cloth Weave']]: 12,
        [fa.Weaves.Embroidery]: 12,
        [fa.Weaves['Faux Suede']]: 10,
        [fa.Weaves.Flanel]: 10,
        [fa.Weaves.Flanelette]: 9,
        [fa.Weaves.Gauze]: 4,
        [fa.Weaves.Herringbone]: 10,
        [fa.Weaves.Houndstooth]: 10,
        [fa.Weaves.Interfacing]: 2,
        [fa.Weaves.Jacquard]: 10,
        [fa.Weaves.Lace]: 8,
        [fa.Weaves.Minky]: 12,
        [fa.Weaves.Mesh]: 6,
        [fa.Weaves.Organza]: 8,
        [fa.Weaves.PUL]: 6,
        [fa.Weaves.Pinstripe]: 10,
        [fa.Weaves['Plain Weave']]: 8,
        [fa.Weaves['Rib Weave']]: 10,
        [fa.Weaves.Sateen]: 8,
        [fa.Weaves.Satin]: 8,
        [fa.Weaves.Sequins]: 10,
        [fa.Weaves.Softshell]: 12,
        [fa.Weaves.Taffeta]: 8,
        [fa.Weaves['Tapestry Weave']]: 12,
        [fa.Weaves.Tweed]: 12,
        [fa.Weaves.Tulle]: 4,
        [fa.Weaves.Velvet]: 10,
        [fa.Weaves.Velveteen]: 11,

        [fa.Weaves['Waffle Weave']]: 10
      }
    },
    [fa.ContentGeneral.Synthetic]: {
      [fa.KnitOrWoven.Knit]: {
        [fa.Knits['Double Cloth Knit']]: 10,
        [fa.Knits.Fleece]: 10,
        [fa.Knits['French Terry']]: 10,
        [fa.Knits['Terry Towel']]: 10,
        [fa.Knits['Knitted Velour']]: 10,
        [fa.Knits.Sequins]: 8,
        [fa.Knits['Faux Fur']]: 8,
        [fa.Knits.Lace]: 8,
        [fa.Knits.Mesh]: 6,
        [fa.Knits['Sport Mesh']]: 6,
        [fa.Knits.Tulle]: 6,
        [fa.Knits.Jersey]: 8,
        [fa.Knits['Waffle Knit']]: 8,
        [fa.Knits['Rib Knit']]: 8,
        [fa.Knits.Interlock]: 8,
        [fa.Knits.Microfibre]: 8
      },
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves['Plain Weave']]: 8,
        [fa.Weaves.Flanel]: 8,
        [fa.Weaves.Flanelette]: 8,
        [fa.Weaves['Rib Weave']]: 8,
        [fa.Weaves['Waffle Weave']]: 8,
        [fa.Weaves['Twill Weave']]: 8,

        [fa.Weaves['Basket Weave']]: 8,
        [fa.Weaves.Herringbone]: 8,
        [fa.Weaves.Houndstooth]: 8,
        [fa.Weaves.Pinstripe]: 8,
        [fa.Weaves.Noil]: 8,
        [fa.Weaves.Beaded]: 12,
        [fa.Weaves.Embroidery]: 12,
        [fa.Weaves.Sequins]: 12,
        [fa.Weaves.Jacquard]: 12,
        [fa.Weaves['Tapestry Weave']]: 12,
        [fa.Weaves['Crushed Velvet']]: 8,
        [fa.Weaves.Velveteen]: 8,
        [fa.Weaves.Velvet]: 8,
        [fa.Weaves.Corduroy]: 8,
        [fa.Weaves.Felt]: 8,
        [fa.Weaves.Minky]: 8,
        [fa.Weaves['Double Cloth Weave']]: 8,
        [fa.Weaves.Fleece]: 8,
        [fa.Weaves['French Terry']]: 8,
        [fa.Weaves.PUL]: 8,
        [fa.Weaves.Softshell]: 8,
        [fa.Weaves['Faux Fur']]: 12,
        [fa.Weaves['Faux Suede']]: 12,
        [fa.Weaves.Denim]: 12,
        [fa.Weaves.Batting]: 4,
        [fa.Weaves.Interfacing]: 4,
        [fa.Weaves.Chiffon]: 8,
        [fa.Weaves.Crepe]: 8,
        [fa.Weaves.Gauze]: 8,
        [fa.Weaves.Mesh]: 8,
        [fa.Weaves.Organza]: 8,
        [fa.Weaves.Tulle]: 8,
        [fa.Weaves.Taffeta]: 8,
        [fa.Weaves.Lace]: 8,
        [fa.Weaves.Satin]: 8,
        [fa.Weaves.Sateen]: 8
      }
    },
    [fa.ContentGeneral['Synthetic Blend']]: {
      [fa.KnitOrWoven.Knit]: {
        [fa.Knits['Double Cloth Knit']]: 10,
        [fa.Knits.Fleece]: 10,
        [fa.Knits['French Terry']]: 10,
        [fa.Knits['Terry Towel']]: 10,
        [fa.Knits['Knitted Velour']]: 10,
        [fa.Knits.Sequins]: 8,
        [fa.Knits['Faux Fur']]: 8,
        [fa.Knits.Lace]: 8,
        [fa.Knits.Mesh]: 6,
        [fa.Knits['Sport Mesh']]: 6,
        [fa.Knits.Tulle]: 6,
        [fa.Knits.Jersey]: 8,
        [fa.Knits['Waffle Knit']]: 8,
        [fa.Knits['Rib Knit']]: 8,
        [fa.Knits.Interlock]: 8,
        [fa.Knits.Microfibre]: 8
      },
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves['Plain Weave']]: 8,
        [fa.Weaves.Flanel]: 8,
        [fa.Weaves.Flanelette]: 8,
        [fa.Weaves['Rib Weave']]: 8,
        [fa.Weaves['Waffle Weave']]: 8,
        [fa.Weaves['Twill Weave']]: 8,

        [fa.Weaves['Basket Weave']]: 8,
        [fa.Weaves.Herringbone]: 8,
        [fa.Weaves.Houndstooth]: 8,
        [fa.Weaves.Pinstripe]: 8,
        [fa.Weaves.Noil]: 8,
        [fa.Weaves.Beaded]: 12,
        [fa.Weaves.Embroidery]: 12,
        [fa.Weaves.Sequins]: 12,
        [fa.Weaves.Jacquard]: 12,
        [fa.Weaves['Tapestry Weave']]: 12,
        [fa.Weaves['Crushed Velvet']]: 8,
        [fa.Weaves.Velveteen]: 8,
        [fa.Weaves.Velvet]: 8,
        [fa.Weaves.Corduroy]: 8,
        [fa.Weaves.Felt]: 8,
        [fa.Weaves.Minky]: 8,
        [fa.Weaves['Double Cloth Weave']]: 8,
        [fa.Weaves.Fleece]: 8,
        [fa.Weaves['French Terry']]: 8,
        [fa.Weaves.PUL]: 8,
        [fa.Weaves.Softshell]: 8,
        [fa.Weaves['Faux Fur']]: 12,
        [fa.Weaves['Faux Suede']]: 12,
        [fa.Weaves.Denim]: 12,
        [fa.Weaves.Batting]: 4,
        [fa.Weaves.Interfacing]: 4,
        [fa.Weaves.Chiffon]: 8,
        [fa.Weaves.Crepe]: 8,
        [fa.Weaves.Gauze]: 8,
        [fa.Weaves.Mesh]: 8,
        [fa.Weaves.Organza]: 8,
        [fa.Weaves.Tulle]: 8,
        [fa.Weaves.Taffeta]: 8,
        [fa.Weaves.Lace]: 8,
        [fa.Weaves.Satin]: 8,
        [fa.Weaves.Sateen]: 8
      }
    },
    [fa.ContentGeneral.Other]: {
      [fa.KnitOrWoven.Knit]: {
        [fa.Knits['Double Cloth Knit']]: 12,
        [fa.Knits.Fleece]: 12,
        [fa.Knits['French Terry']]: 12,
        [fa.Knits['Terry Towel']]: 12,
        [fa.Knits['Knitted Velour']]: 12,
        [fa.Knits.Sequins]: 10,
        [fa.Knits['Faux Fur']]: 10,
        [fa.Knits.Lace]: 10,
        [fa.Knits.Mesh]: 8,
        [fa.Knits['Sport Mesh']]: 8,
        [fa.Knits.Tulle]: 8,
        [fa.Knits.Jersey]: 10,
        [fa.Knits['Waffle Knit']]: 10,
        [fa.Knits['Rib Knit']]: 10,
        [fa.Knits.Interlock]: 10,
        [fa.Knits.Microfibre]: 10
      },
      [fa.KnitOrWoven.Woven]: {
        [fa.Weaves['Plain Weave']]: 9,
        [fa.Weaves.Flanel]: 9,
        [fa.Weaves.Flanelette]: 9,
        [fa.Weaves['Rib Weave']]: 9,
        [fa.Weaves['Waffle Weave']]: 9,
        [fa.Weaves['Twill Weave']]: 9,
        [fa.Weaves['Basket Weave']]: 9,
        [fa.Weaves.Herringbone]: 9,
        [fa.Weaves.Houndstooth]: 9,
        [fa.Weaves.Pinstripe]: 9,
        [fa.Weaves.Noil]: 9,
        [fa.Weaves.Beaded]: 13,
        [fa.Weaves.Embroidery]: 13,
        [fa.Weaves.Sequins]: 13,
        [fa.Weaves.Jacquard]: 13,
        [fa.Weaves['Tapestry Weave']]: 13,
        [fa.Weaves['Crushed Velvet']]: 10,
        [fa.Weaves.Velveteen]: 10,
        [fa.Weaves.Velvet]: 10,
        [fa.Weaves.Corduroy]: 10,
        [fa.Weaves.Felt]: 10,
        [fa.Weaves.Minky]: 10,
        [fa.Weaves['Double Cloth Weave']]: 10,
        [fa.Weaves.Fleece]: 10,
        [fa.Weaves['French Terry']]: 10,
        [fa.Weaves.PUL]: 10,
        [fa.Weaves.Softshell]: 10,
        [fa.Weaves['Faux Fur']]: 14,
        [fa.Weaves['Faux Suede']]: 14,
        [fa.Weaves.Denim]: 14,
        [fa.Weaves.Batting]: 5,
        [fa.Weaves.Interfacing]: 5,
        [fa.Weaves.Chiffon]: 9,
        [fa.Weaves.Crepe]: 9,
        [fa.Weaves.Gauze]: 9,
        [fa.Weaves.Mesh]: 9,
        [fa.Weaves.Organza]: 9,
        [fa.Weaves.Tulle]: 9,
        [fa.Weaves.Taffeta]: 9,
        [fa.Weaves.Lace]: 9,
        [fa.Weaves.Satin]: 9,
        [fa.Weaves.Sateen]: 9
      }
    }
  }
};

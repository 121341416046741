import { MergedProduct } from '../../../types/formTypes';
import { ButtonProductAttributes as ba } from 'product-validator';

const PRICE_INDIV_A = [0.15, 0.25, 0.5, 0.75];
const PRICE_INDIV_B = [0.25, 0.5, 0.75, 1.5];
const PRICE_INDIV_C = [0.75, 1, 1.5, 2];

const PRICE_2PACK_UNIQUE_A = [2, 3, 5, 7];
const PRICE_2PACK_UNIQUE_B = [3, 4, 6, 8];

const PRICE_4PACK_UNIQUE_A = [4, 6, 10, 14];
const PRICE_4PACK_UNIQUE_B = [6, 8, 12, 16];

const getButtonProductPrice = (
  material: string,
  widthMM: number,
  uniqueShape: boolean,
  packSize?: number
) => {
  let price = 0,
    discount = 0,
    widthIdx = 0;

  let indivTable: number[];
  let unique2PackTable: number[];
  let unique4PackTable: number[];
  let largeWidth2Pack: number;
  let largeWidth4Pack: number;

  switch (material) {
    case ba.Material.Plastic:
    case ba.Material.Wood:
    case ba.Material.Paper:
    default:
      indivTable = PRICE_INDIV_A;
      unique2PackTable = PRICE_2PACK_UNIQUE_A;
      unique4PackTable = PRICE_4PACK_UNIQUE_A;
      largeWidth2Pack = 4;
      largeWidth4Pack = 8;
      break;
    case ba.Material['Fabric Covered']:
    case ba.Material['Leather Covered']:
      indivTable = PRICE_INDIV_B;
      unique2PackTable = PRICE_2PACK_UNIQUE_A;
      unique4PackTable = PRICE_4PACK_UNIQUE_A;
      largeWidth2Pack = 5;
      largeWidth4Pack = 10;
      break;
    case ba.Material.Metal:
    case ba.Material.Brass:
    case ba.Material.Shell:
      indivTable = PRICE_INDIV_C;
      unique2PackTable = PRICE_2PACK_UNIQUE_B;
      unique4PackTable = PRICE_4PACK_UNIQUE_B;
      largeWidth2Pack = 6;
      largeWidth4Pack = 12;
      break;
  }

  if (widthMM <= 15) widthIdx = 0;
  else if (widthMM <= 30) widthIdx = 1;
  else if (widthMM <= 49) widthIdx = 2;
  else widthIdx = 3;

  if (packSize) {
    if (packSize < 50) discount = 0.65;
    else discount = 0.6;

    if (packSize === 2 && uniqueShape) price = unique2PackTable[widthIdx];
    else if (packSize === 4 && uniqueShape) price = unique4PackTable[widthIdx];
    else if (packSize === 2 && widthMM >= 50) price = largeWidth2Pack;
    else if (packSize === 4 && widthMM >= 50) price = largeWidth4Pack;
    else if (packSize && uniqueShape)
      price = packSize * (indivTable[widthIdx] * 2);
    else price = discount * (packSize * indivTable[widthIdx]);
  } else {
    price = indivTable[widthIdx];
    if (uniqueShape) price *= 2;
  }

  // Round to nearest 5 cents to avoid precision issues
  price = Math.ceil(price * 20) / 20;

  return price;
};

export const buttonProductPriceSuggestion = (
  sellingUnit: ba.SellingUnit,
  material: string,
  widthMM: number,
  uniqueShape: boolean,
  packSize?: number
) => {
  let price = 0.0;
  if (sellingUnit === ba.SellingUnit.Individual)
    price = getButtonProductPrice(material, widthMM, uniqueShape);
  else price = getButtonProductPrice(material, widthMM, uniqueShape, packSize);

  return price;
};

export const buttonProductPriceSuggestionFromForm = (
  buttonProduct: MergedProduct
) => {
  if (buttonProduct.sellingUnit) {
    let widthMM = Number(buttonProduct.widthMM);
    let packSize = Number(buttonProduct.packSize);

    return buttonProductPriceSuggestion(
      buttonProduct.sellingUnit as ba.SellingUnit,
      buttonProduct.material ?? '',
      isNaN(widthMM) ? 0 : widthMM,
      buttonProduct.uniqueShape ?? false,
      isNaN(packSize) ? undefined : packSize
    );
  }

  return undefined;
};

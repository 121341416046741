import { Button } from '@chakra-ui/react';
import React from 'react';
import { ErrorMessage } from '../../general';
import { isTouched } from '../../../utils/validationUtils';
import { AppFieldComponent } from '../../../types/appFieldTypes';
import Dropzone, { FileWithPath, DropzoneState } from 'react-dropzone';
import { Heading, Box, VStack, Text, FormControl } from '@chakra-ui/react';
import { ImageGallery } from './ImageGallery';

const MAX_IMGS = 7;
const RESPONSIVE_W = ['18rem'];

export const ImageUploadField: AppFieldComponent = ({
  images,
  setImages,
  imagesErrors
}) => {
  const isImagesTouched = isTouched(images);
  const isMaxImgs = images && images.length >= MAX_IMGS ? true : false;

  let errorMessages =
    isImagesTouched && !!imagesErrors ? [imagesErrors] : undefined;
  if (isMaxImgs) errorMessages = ['Maximum of 7 images can be uploaded'];

  const handleDrop = (acceptedFiles: FileWithPath[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const binaryStr = reader.result as string;
        if (setImages) {
          setImages((prevImages) => {
            if (prevImages) return [...prevImages, binaryStr];
            else {
              return [binaryStr];
            }
          });
        }
      };
      reader.readAsDataURL(file);
    });
  };
  return (
    <Box>
      <VStack w="100%" spacing="1em">
        <Heading>Image Upload</Heading>
        <VStack justifyContent="space-between">
          <Dropzone onDrop={handleDrop} accept="image/*">
            {({ getRootProps, getInputProps, inputRef }: DropzoneState) => (
              <VStack
                width={RESPONSIVE_W}
                height="16rem"
                border="0.2rem"
                borderStyle="dashed"
                borderColor="grey"
                bgColor="purple.50"
                alignItems="center"
                justifyContent="center"
                {...getRootProps()}
              >
                <input ref={inputRef} {...getInputProps()} />
                <Text textColor="grey" textAlign="center">
                  Drag 'n' drop images here
                </Text>
                <Button
                  size="lg"
                  w="10rem"
                  h="4rem"
                  bgColor="purple.500"
                  textColor="white"
                  textAlign="center"
                >
                  Select images
                </Button>
              </VStack>
            )}
          </Dropzone>
        </VStack>
        <ImageGallery images={images} setImages={setImages} />
        <FormControl isInvalid={!!errorMessages || isMaxImgs}>
          <ErrorMessage errMsgs={errorMessages} />
        </FormControl>
      </VStack>
    </Box>
  );
};

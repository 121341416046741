import React from 'react';
import {
  Flex,
  Box,
  Heading,
  Text,
  Table,
  Tbody,
  Tr,
  Td,
  Spinner
} from '@chakra-ui/react';
import { SimpleProduct } from '../../types/rollToBundleTypes';

interface AutoCompleteProps {
  products?: SimpleProduct[];
  setMainProduct: (product: SimpleProduct) => void;
}

export const AutoComplete: React.FC<AutoCompleteProps> = ({
  products,
  setMainProduct
}) => {
  const productList = products?.map((product) => (
    <Tr
      onClick={setMainProduct.bind(null, product)}
      _hover={{ bg: 'gray.50' }}
      key={product.id}
      m={1}
    >
      <Td>
        <Heading size="sm">{product.title}</Heading>
        <Text>{product.sku}</Text>
      </Td>
    </Tr>
  ));

  const noProductsMsg = (
    <Tr>
      <Td>
        <Text textColor="red.500" fontSize="sm">
          No roll products found with that SKU.
        </Text>
      </Td>
    </Tr>
  );

  return (
    <Box
      maxW="25rem"
      border="1px"
      borderColor="purple.200"
      borderRadius="0.375rem"
      maxH="30vh"
      overflowY="auto"
    >
      {productList ? (
        <Table>
          <Tbody m={1}>
            {productList.length > 0 ? productList : noProductsMsg}
          </Tbody>
        </Table>
      ) : (
        <Flex justifyContent="center">
          <Spinner m={1} />
        </Flex>
      )}
    </Box>
  );
};

import {
  FormControl,
  FormLabel,
  FormHelperText,
  VStack,
  Heading,
  Box,
  CheckboxGroup
} from '@chakra-ui/react';
import React from 'react';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import {
  OtherTextInput,
  ErrorMessage,
  ImageRadioGroup,
  ImageRadioButton,
  WrappedInputGroup,
  CheckboxButton
} from '../../../general';
import {
  getNestedValidationError,
  isTouched
} from '../../../../utils/validationUtils';
import { ElasticAttributes as ea, ProductTypes } from 'product-validator';
import * as IMAGES from '../../../../images/elastics';

const elasticSrc: { [k in keyof typeof ea.ElasticType]: string } = {
  Braided: IMAGES.braided,
  Buttonhole: IMAGES.buttonhole,
  Clear: IMAGES.clear,
  Cord: IMAGES.cord,
  Drawcord: IMAGES.drawcord,
  Foldover: IMAGES.foldover,
  Knitted: IMAGES.knit,
  Lingerie: IMAGES.lingerie,
  'Plush-Back': IMAGES.plushback,
  'Ribbed Non-Roll': IMAGES.ribbed_non_roll,
  Swim: IMAGES.swim,
  Woven: IMAGES.woven,
  'Pre-Sewn': IMAGES.presewn
};

export const ElasticTypeField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  const elasticTypeErrMsgs = getNestedValidationError(
    'elasticType',
    validationErrors
  );
  const elasticTypeTouched = isTouched(
    currentValue.elasticType,
    currentValue.elasticTypeOther
  );

  var elasticTypeOptions = Object.values(ea.ElasticType);

  // filtering out pre-sewn type when selling unit is not pack or by meter
  if (
    currentValue.productType === ProductTypes.Elastic &&
    (currentValue.sellingUnit === ea.SellingUnit['By Meter'] ||
      currentValue.sellingUnit === ea.SellingUnit.Roll)
  ) {
    elasticTypeOptions = elasticTypeOptions.filter(
      (option) => option !== ea.ElasticType['Pre-Sewn']
    );
  }

  const preSewnTypeErrMsgs = getNestedValidationError(
    'preSewnType',
    validationErrors
  );

  const preSewnTypeTouched = isTouched(currentValue.preSewnType);

  const preSewnTypeOptions = Object.keys(ea.PreSewnType);

  const preSewnType = (
    <FormControl
      as="fieldset"
      isInvalid={preSewnTypeErrMsgs.length > 0 && preSewnTypeTouched}
    >
      <FormLabel as="legend">
        <Heading>Type of Pre-Sewn Elastic</Heading>
      </FormLabel>
      <VStack spacing="3" maxW="36.2rem" alignItems="left">
        <CheckboxGroup
          value={currentValue.preSewnType}
          onChange={(preSewnType: string[]) => {
            return setFormField({
              preSewnType: preSewnType
            });
          }}
        >
          <WrappedInputGroup maxW="43rem">
            {preSewnTypeOptions.map((value) => (
              <CheckboxButton key={value} value={value}>
                {value}
              </CheckboxButton>
            ))}
          </WrappedInputGroup>
        </CheckboxGroup>
        <FormHelperText>Choose all that apply</FormHelperText>
        <ErrorMessage errMsgs={preSewnTypeErrMsgs} />
      </VStack>
    </FormControl>
  );

  return (
    <Box>
      <FormControl
        as="fieldset"
        isInvalid={elasticTypeErrMsgs.length > 0 && elasticTypeTouched}
      >
        <FormLabel as="legend">
          <Heading>Type</Heading>
        </FormLabel>
        <VStack spacing="3" maxW="36.2rem" alignItems="left">
          <ImageRadioGroup
            name="Elastic Type"
            value={currentValue.elasticType}
            onChange={(v) => {
              setFormField({ elasticType: v as ea.ElasticType });
            }}
          >
            {elasticTypeOptions.map((v) => (
              <ImageRadioButton
                value={v}
                key={v}
                image={elasticSrc[v as ea.ElasticType]}
                isDisabled={!!currentValue.elasticTypeOther}
              />
            ))}
          </ImageRadioGroup>
          <OtherTextInput
            onChange={(e) => {
              setFormField({
                elasticTypeOther: e.target.value
              });
            }}
            value={currentValue.elasticTypeOther ?? ''}
          />
          <FormHelperText>Select one or enter in another.</FormHelperText>
          <ErrorMessage errMsgs={elasticTypeErrMsgs} />
        </VStack>
      </FormControl>
      {currentValue.elasticType === ea.ElasticType['Pre-Sewn'] &&
        (currentValue.sellingUnit === ea.SellingUnit.Pack ||
          currentValue.sellingUnit === ea.SellingUnit.Bundle) &&
        preSewnType}
    </Box>
  );
};

import {
  FormControl,
  FormLabel,
  FormHelperText,
  Heading,
  Box
} from '@chakra-ui/react';
import React from 'react';
import { FabricAttributes } from 'product-validator';
import { ButtonRadioGroup } from '../../../general/ButtonRadio';
import { AppFieldComponent } from '../../../../types/appFieldTypes';

export const BundleOrRollField: AppFieldComponent = ({
  setFormField,
  currentValue,
  toNextPage
}) => {
  return (
    <Box>
      <FormControl as="fieldset">
        <FormLabel as="legend" mb="1.5rem">
          <Heading>What would you like to enter?</Heading>
        </FormLabel>
        <ButtonRadioGroup
          name="Dimension Type"
          value={currentValue.bundleOrRoll}
          options={Object.values(FabricAttributes.BundleOrRoll)}
          onChange={(v) => {
            setFormField({
              bundleOrRoll: v as FabricAttributes.BundleOrRoll
            });
            toNextPage({ navOnNextFormUpdate: true });
          }}
        />
        <FormHelperText>Choose one.</FormHelperText>
      </FormControl>
    </Box>
  );
};

import {
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  RadioGroup,
  VStack
} from '@chakra-ui/react';
import React from 'react';
import {
  getNestedValidationError,
  isTouched
} from '../../../utils/validationUtils';
import {
  ErrorMessage,
  NumberInput,
  WrappedInputGroup,
  ButtonRadioGroup
} from '../../general';
import { IndexedAppFieldComponentProps } from '../../../types/appFieldTypes';
import { RadioButton } from '../../general/ButtonRadio';
import {
  ProductTypes,
  BiasTapeAttributes as bta,
  TrimAttributes as tra,
  ElasticAttributes as ea,
  OtherSupplyAttributes as osa
} from 'product-validator';
import update from 'immutability-helper';

enum LengthUnit {
  M = 'M'
}

const LENGTH_OPTIONS = {
  [LengthUnit.M]: {
    UnitLong: 'meters',
    Radios: ['1', '2', '3', '4', '5', '10', '20', '25', '30']
  }
};

export const LengthMixedField: React.FC<IndexedAppFieldComponentProps> = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage,
  idx = 0
}) => {
  let lengthRadios: string[] = [];
  let lengthErr: string[] = [];
  let lengthTouched = false;
  let lengthUnit: LengthUnit;

  switch (currentValue.productType) {
    case ProductTypes.Elastic:
    case ProductTypes['Bias Tape']: {
      lengthUnit = LengthUnit.M;
      lengthRadios =
        currentValue.sellingUnit !== 'Roll'
          ? LENGTH_OPTIONS.M.Radios.filter((v) => parseInt(v) <= 10)
          : LENGTH_OPTIONS.M.Radios.filter((v) => parseInt(v) >= 10);
      lengthErr = getNestedValidationError(`lengthM[${idx}]`, validationErrors);
      lengthTouched = isTouched(
        currentValue.lengthM[idx],
        currentValue.lengthRadio[idx],
        currentValue.lengthMOther[idx]
      );
      break;
    }
    case ProductTypes.Trim:
      let trimLengthRadios = ['1.5', '2', '2.5'];
      lengthUnit = LengthUnit.M;
      lengthRadios =
        currentValue.sellingUnit === 'Roll'
          ? LENGTH_OPTIONS.M.Radios.filter((v) => parseInt(v) >= 10)
          : currentValue.sellingUnit === 'Pack'
          ? trimLengthRadios
          : LENGTH_OPTIONS.M.Radios.filter((v) => parseInt(v) <= 10);
      lengthErr = getNestedValidationError(`lengthM[${idx}]`, validationErrors);
      lengthTouched = isTouched(
        currentValue.lengthM[idx],
        currentValue.lengthRadio[idx],
        currentValue.lengthMOther[idx]
      );
      break;
    case ProductTypes['Other Supply']:
      lengthUnit = LengthUnit.M;
      lengthRadios = LENGTH_OPTIONS.M.Radios.filter((v) => parseInt(v) >= 10);
      lengthErr = getNestedValidationError(`lengthM[${idx}]`, validationErrors);
      lengthTouched = isTouched(
        currentValue.lengthM[idx],
        currentValue.lengthRadio[idx],
        currentValue.lengthMOther[idx]
      );
      break;

    default:
      lengthUnit = LengthUnit.M;
  }

  const numberInputProps = {
    size: 'lg',
    stackProps: { w: '80%' },
    w: '80%'
  };

  const biasTapeLengthType = (
    <ButtonRadioGroup
      name="Bias Tape Length Type"
      value={currentValue.biasTapeLengthType}
      options={Object.values(bta.KnownOrEstimated)}
      buttonProps={{ minW: '8rem' }}
      onChange={(v) =>
        setFormField({ biasTapeLengthType: v as bta.KnownOrEstimated })
      }
    />
  );

  const trimLengthType = (
    <ButtonRadioGroup
      name="Trim Length Type"
      value={currentValue.trimLengthType}
      options={Object.values(tra.KnownOrEstimated)}
      buttonProps={{ minW: '8rem' }}
      onChange={(v) =>
        setFormField({ trimLengthType: v as tra.KnownOrEstimated })
      }
    />
  );

  const elasticLengthType = (
    <ButtonRadioGroup
      name="Elastic Length Type"
      value={currentValue.elasticLengthType}
      options={Object.values(ea.KnownOrEstimated)}
      buttonProps={{ minW: '8rem' }}
      onChange={(v) =>
        setFormField({ elasticLengthType: v as ea.KnownOrEstimated })
      }
    />
  );

  const otherSupplyLengthType = (
    <ButtonRadioGroup
      name="Other Supply Length Type"
      value={currentValue.otherSupplyLengthType}
      options={Object.values(osa.KnownOrEstimated)}
      buttonProps={{ minW: '8rem' }}
      onChange={(v) =>
        setFormField({ otherSupplyLengthType: v as osa.KnownOrEstimated })
      }
    />
  );

  return (
    <FormControl
      as="fieldset"
      isInvalid={lengthErr.length > 0 && lengthTouched}
    >
      <FormLabel as="legend">
        {((currentValue.productType === 'Bias Tape' ||
          currentValue.productType === 'Elastic' ||
          currentValue.productType === 'Trim') &&
          currentValue.sellingUnit === 'Roll') ||
        (currentValue.productType === 'Other Supply' &&
          currentValue.sellingUnit === 'By Meter') ? (
          <Heading>
            What's the length of the roll in{' '}
            {LENGTH_OPTIONS[lengthUnit].UnitLong}
          </Heading>
        ) : currentValue.sellingUnit === 'Pack' ? (
          <Heading>
            What's the minimum length in {LENGTH_OPTIONS[lengthUnit].UnitLong}?
          </Heading>
        ) : (
          <Heading>
            What's the length in {LENGTH_OPTIONS[lengthUnit].UnitLong}?
          </Heading>
        )}
      </FormLabel>
      <VStack flexDir="column" alignItems="flex-start" spacing="3">
        {currentValue.productType === ProductTypes['Bias Tape'] &&
          currentValue.sellingUnit === bta.SellingUnit['Roll'] &&
          biasTapeLengthType}
        {currentValue.productType === ProductTypes.Trim &&
          currentValue.sellingUnit === tra.SellingUnit['Roll'] &&
          trimLengthType}
        {currentValue.productType === ProductTypes.Elastic &&
          currentValue.sellingUnit === tra.SellingUnit['Roll'] &&
          elasticLengthType}
        {currentValue.productType === ProductTypes['Other Supply'] &&
          currentValue.sellingUnit === osa.SellingUnit['By Meter'] &&
          otherSupplyLengthType}
        <FormLabel>Specify a length</FormLabel>
        <RadioGroup
          value={currentValue.lengthRadio[idx]}
          onChange={(v) =>
            setFormField((oldForm) =>
              update(oldForm, {
                lengthRadio: {
                  [idx]: { $set: v.toString() }
                }
              })
            )
          }
        >
          <WrappedInputGroup numColumns={3} sx={{ gap: '0.25rem' }}>
            {lengthRadios.map((option) => (
              <RadioButton
                key={option}
                value={option.toString()}
                isDisabled={!!currentValue.lengthMOther?.[idx]}
                minW="7rem"
              >
                {`${option}M`}
              </RadioButton>
            ))}
          </WrappedInputGroup>
        </RadioGroup>

        <NumberInput
          id="lengthMOther"
          onChange={(s) => {
            setFormField((oldForm) =>
              update(oldForm, {
                lengthMOther: {
                  [idx]: { $set: s }
                }
              })
            );
          }}
          defaultValue={currentValue.lengthMOther[idx]}
          text={`length (${lengthUnit})`}
          {...numberInputProps}
        />
      </VStack>
      <ErrorMessage errMsgs={lengthErr} />
      <FormHelperText>Type in a value</FormHelperText>
    </FormControl>
  );
};

import React, { useEffect } from 'react';
import {
  Button,
  Alert,
  AlertIcon,
  AlertStatus,
  AlertTitle,
  AlertDescription,
  Box,
  useToast,
  UseToastOptions,
  Flex
} from '@chakra-ui/react';
import { Overlay } from './NewProductOverlay';
import { FormFieldState } from '../../types';

export enum UploadStatus {
  FORM_IS_INVALID,
  READY_TO_UPLOAD,
  UPLOADING,
  UPLOADING_MEDIA,
  UPLOAD_SUCCESS,
  UPLOAD_FAILED,
  UPLOAD_PARTIALLY_FAILED
}

interface UploadButtonConsoleProps {
  currentValue: FormFieldState | undefined;
  uploadStatus: UploadStatus;
  skusMessage: string | undefined;
  errorMessage?: string;
  startNewProduct: () => void;
  startNewSupply: () => void;
  printLabel: () => void;
  submitProductForm: () => void;
}

export const UploadButtonConsole: React.FC<UploadButtonConsoleProps> = ({
  currentValue,
  uploadStatus,
  skusMessage,
  errorMessage,
  startNewProduct,
  startNewSupply,
  printLabel,
  submitProductForm
}) => {
  const messageToast = useToast();
  const [isOverlayOpen, setIsOverlayOpen] = React.useState(false);
  const onOverlayClose = () => setIsOverlayOpen(false);
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  useEffect(() => {
    let message;
    let status: UseToastOptions['status'] | undefined;
    if (uploadStatus === UploadStatus.UPLOAD_SUCCESS) {
      message = 'Success!';
      status = 'success';
    } else if (uploadStatus === UploadStatus.UPLOAD_FAILED) {
      message = errorMessage || 'No reason given.';
      status = 'error';
    }

    if (message)
      messageToast({
        title: message,
        description: message,
        status: status,
        position: 'top-right',
        duration: 9000,
        isClosable: true
      });
  }, [errorMessage, messageToast, uploadStatus]);

  let buttons;
  let msg;

  let txt;
  let status: AlertStatus = 'info';

  switch (uploadStatus) {
    case UploadStatus.UPLOAD_SUCCESS:
      status = 'success';
      txt = (
        <Box flex="1">
          <AlertTitle>Upload successful!</AlertTitle>
          <AlertDescription>Product SKU(s): {skusMessage}</AlertDescription>
        </Box>
      );
      buttons = (
        <>
          <Button size="lg" onClick={printLabel}>
            Print Product Label
          </Button>
          <Button
            size="lg"
            onClick={startNewProduct /*() => setIsOverlayOpen(true)*/}
          >
            Start New Product
          </Button>
          {currentValue?.productType === 'Fabric' ? (
            <></>
          ) : (
            <Button
              size="lg"
              onClick={startNewSupply /*() => setIsOverlayOpen(true)*/}
            >
              Start New Supply
            </Button>
          )}
        </>
      );
      break;
    case UploadStatus.FORM_IS_INVALID:
      status = 'warning';
      txt = 'Fix all errors to upload.';
      break;
    case UploadStatus.READY_TO_UPLOAD:
      status = 'info';
      txt = 'Ready to upload.';
      break;
    case UploadStatus.UPLOADING:
      status = 'info';
      txt = '...Sending product to Shopify.';
      break;
    case UploadStatus.UPLOADING_MEDIA:
      status = 'info';
      txt = '...Sending images to Shopify.';
      break;
    case UploadStatus.UPLOAD_FAILED:
      status = 'error';
      txt = (
        <>
          {'Upload Failed.'}
          <br /> {errorMessage || 'No reason given.'}
        </>
      );
      break;
    case UploadStatus.UPLOAD_PARTIALLY_FAILED:
      status = 'warning';
      txt = (
        <>
          {'Product was uploaded, but images were not attached.'}
          <br /> {errorMessage || 'No reason given.'}
        </>
      );
      buttons = (
        <>
          <Button onClick={submitProductForm} size="lg" colorScheme="red">
            Retry Image Upload
          </Button>
          <Button size="lg" onClick={printLabel}>
            Print Label
          </Button>
          <Button
            size="lg"
            onClick={startNewProduct /*() => setIsOverlayOpen(true)*/}
          >
            Start New Product
          </Button>
          <Button
            size="lg"
            onClick={startNewSupply /*() => setIsOverlayOpen(true)*/}
          >
            Start New Supply
          </Button>
        </>
      );

      break;
  }
  msg = (
    <Alert w="auto" status={status} textAlign="center">
      <AlertIcon />
      {txt}
    </Alert>
  );
  if (!buttons) {
    const failed = uploadStatus === UploadStatus.UPLOAD_FAILED;
    buttons = (
      <Button
        onClick={submitProductForm}
        size="lg"
        disabled={uploadStatus === UploadStatus.FORM_IS_INVALID}
        isLoading={
          uploadStatus === UploadStatus.UPLOADING ||
          uploadStatus === UploadStatus.UPLOADING_MEDIA
        }
        loadingText="Uploading"
        colorScheme={failed ? 'red' : 'orange'}
      >
        {failed ? 'Retry' : 'Upload'}
      </Button>
    );
  }
  return (
    <>
      {msg}
      <Flex
        dir="row"
        mb="3rem"
        maxW="100%"
        wrap="wrap"
        justifyContent="center"
        sx={{ gap: '0.25rem' }}
        px="1"
      >
        {buttons}
      </Flex>
      <Overlay
        currentValue={currentValue}
        cancelRef={cancelRef}
        isOverlayOpen={isOverlayOpen}
        onOverlayClose={onOverlayClose}
        startNewProduct={startNewProduct}
        startNewSupply={startNewSupply}
      />
    </>
  );
};

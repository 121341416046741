import {
  FormControl,
  FormLabel,
  Heading,
  RadioGroup,
  VStack
} from '@chakra-ui/react';
import React from 'react';
import {
  getNestedValidationError,
  isTouched
} from '../../../../utils/validationUtils';
import { NumberInput, WrappedInputGroup } from '../../../general';
import { IndexedAppFieldComponentProps } from '../../../../types/appFieldTypes';
import { RadioButton } from '../../../general/ButtonRadio';
import { ProductTypes } from 'product-validator';
import update from 'immutability-helper';

enum WeightUnit {
  LB = 'LB'
}

const WEIGHT_OPTIONS = {
  [WeightUnit.LB]: {
    UnitLong: 'pounds',
    Radios: ['1', '2', '3']
  }
};

export const PoundsInUnitField: React.FC<IndexedAppFieldComponentProps> = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage,
  idx = 0
}) => {
  let weightRadios: string[] = [];
  let weightErr: string[] = [];
  let weightTouched = false;
  let weightUnit: WeightUnit;

  switch (currentValue.productType) {
    case ProductTypes['Other Supply']: {
      weightUnit = WeightUnit.LB;
      weightRadios = WEIGHT_OPTIONS.LB.Radios;
      weightErr = getNestedValidationError(`weightLB`, validationErrors);
      weightTouched = isTouched(
        currentValue.weightLB,
        currentValue.weightRadio
      );
      break;
    }
    default:
      weightUnit = WeightUnit.LB;
  }
  const numberInputProps = {
    size: 'lg',
    stackProps: { w: '80%' },
    w: '80%'
  };
  return (
    <FormControl
      as="fieldset"
      isInvalid={weightErr.length > 0 && weightTouched}
    >
      <FormLabel as="legend">
        <Heading>How Many Pounds in a Unit?</Heading>
      </FormLabel>
      <VStack flexDir="column" alignItems="flex-start" spacing="3">
        <RadioGroup
          value={currentValue.weightRadio}
          onChange={(v) =>
            setFormField((oldForm) =>
              update(oldForm, {
                weightRadio: {
                  $set: v.toString()
                }
              })
            )
          }
        >
          <WrappedInputGroup numColumns={3} sx={{ gap: '0.25rem' }}>
            {weightRadios.map((option) => (
              <RadioButton
                key={option}
                value={option.toString()}
                isDisabled={!!currentValue.weightLB?.[idx]}
                minW="7rem"
              >
                {`${option}LB`}
              </RadioButton>
            ))}
          </WrappedInputGroup>
        </RadioGroup>

        <NumberInput
          id="weightLB"
          onChange={(s) => {
            setFormField((oldForm) =>
              update(oldForm, {
                weightLB: {
                  $set: s
                }
              })
            );
          }}
          defaultValue={currentValue.weightLB}
          text={`Pounds (${weightUnit})`}
          {...numberInputProps}
        />
      </VStack>
    </FormControl>
  );
};

import { FormControl, VStack } from '@chakra-ui/react';
import React from 'react';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { Divider, YesNoQuestion } from '../../../general';
import { WidthField } from '../WidthField';
import { ButtonProductAttributes as ba } from 'product-validator';

export const ButtonSizeField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  return (
    <FormControl as="fieldset">
      <VStack spacing="8" alignItems="left">
        <WidthField
          currentValue={currentValue}
          setFormField={setFormField}
          validationErrors={validationErrors}
          toNextPage={toNextPage}
        />
        <Divider />
        <YesNoQuestion
          name="uniqueShape"
          question={`Does the button have a unique shape?`}
          value={currentValue.uniqueShape ? 'Yes' : 'No'}
          onChange={(v) => {
            if (v === 'Yes') {
              setFormField({ uniqueShape: true });
              if (currentValue.sellingUnit === ba.SellingUnit['Pack'])
                setFormField({ packSize: '2' });
            }
            if (v === 'No') setFormField({ uniqueShape: false });
          }}
        />
      </VStack>
    </FormControl>
  );
};

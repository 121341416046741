import { MergedProduct } from '../../../types/formTypes';
import { ElasticAttributes as ea } from 'product-validator';

export const elasticPriceSuggestion = (
  sellingUnit: ea.SellingUnit,
  widthIn: number,
  elasticType?: string,
  preSewnType?: string[],
  imperfections?: string[],
  lengthM?: number,
  packSize?: number
): number => {
  let price = 0;

  switch (elasticType) {
    case ea.ElasticType.Knitted:
    case ea.ElasticType.Cord:
    case ea.ElasticType.Clear:
      price = 0.25;
      break;
    case ea.ElasticType.Woven:
    case ea.ElasticType.Lingerie:
    case ea.ElasticType.Buttonhole:
    case ea.ElasticType['Plush-Back']:
    case ea.ElasticType['Ribbed Non-Roll']:
      price = 0.5;
      break;
    case ea.ElasticType.Braided:
    case ea.ElasticType.Foldover:
    case ea.ElasticType.Drawcord:
    default:
      price = 0.75;
      break;
    case ea.ElasticType.Swim:
      price = 1;
      break;
  }

  if (widthIn <= 1) price += 0.25;
  else if (widthIn <= 2) price += 0.5;
  else if (widthIn <= 3.5) price += 0.75;
  else price += 1;

  if (
    elasticType === ea.ElasticType.Braided ||
    elasticType === ea.ElasticType.Foldover
  )
    price -= 0.25;

  if (sellingUnit === ea.SellingUnit.Bundle && lengthM) price *= lengthM;
  if (sellingUnit === ea.SellingUnit.Roll && lengthM) price *= lengthM * 0.85;
  if (sellingUnit === ea.SellingUnit['Pack'] && lengthM && packSize) {
    if (lengthM < 0.5) {
      price = 0.1 * packSize;
    } else if (lengthM >= 0.5 && lengthM <= 0.75) {
      price = 0.2 * packSize;
    } else {
      price = price * lengthM * packSize * 0.8;
    }
  }

  // Defining pre-sewn elastic prices
  if (
    sellingUnit === ea.SellingUnit.Bundle &&
    lengthM &&
    preSewnType?.includes(ea.PreSewnType.Waistband)
  ) {
    price = 0.6 * lengthM;
  } else if (
    sellingUnit === ea.SellingUnit.Bundle &&
    lengthM &&
    (preSewnType?.includes(ea.PreSewnType.Cuff) ||
      preSewnType?.includes(ea.PreSewnType.Collar))
  ) {
    price = 0.45 * lengthM;
  }

  if (
    elasticType === ea.ElasticType['Pre-Sewn'] &&
    preSewnType &&
    packSize &&
    preSewnType?.length > 0 &&
    preSewnType.includes(ea.PreSewnType.Waistband)
  ) {
    price = 0.6 * packSize;
  } else if (
    elasticType === ea.ElasticType['Pre-Sewn'] &&
    preSewnType &&
    packSize &&
    preSewnType?.length > 0 &&
    (preSewnType.includes(ea.PreSewnType.Cuff) ||
      preSewnType.includes(ea.PreSewnType.Collar))
  ) {
    price = 0.45 * packSize;
  }

  if (imperfections && imperfections.length > 0) price *= 0.85;

  price = Math.ceil(price * 20) / 20;

  return price;
};

export const elasticPriceSuggestionFromForm = (elastic: MergedProduct) => {
  if (elastic.sellingUnit) {
    let lengthM = Number(elastic.lengthM);
    let widthIn = Number(elastic.widthIn);
    let packSize = Number(elastic.packSize);

    return elasticPriceSuggestion(
      elastic.sellingUnit as ea.SellingUnit,
      isNaN(widthIn) ? 1 : widthIn,
      elastic.elasticType,
      elastic.preSewnType,
      elastic.imperfections?.[0],
      isNaN(lengthM) ? undefined : lengthM,
      isNaN(packSize) ? undefined : packSize
    );
  }

  return undefined;
};

import { ProductTypes } from 'product-validator';
import React, { useEffect, useState } from 'react';
import { FormFieldState } from '../types/formTypes';
import { fillAutoFields } from './formUtils';

/**
 * Ensure that the stored form is valid and return saved form
 */
const getStoredForm = (
  localStorageEntry: string | null,
  currentForm: FormFieldState
): FormFieldState | undefined => {
  try {
    if (!localStorageEntry) return undefined;
    const storedForm = JSON.parse(localStorageEntry) as FormFieldState;

    // if the format of the stored form is wrong, this should throw
    fillAutoFields(storedForm, { ...storedForm });

    // handle case where url is manually changed between /product_entry and /supplies
    if (
      (currentForm.productType === ProductTypes.Fabric ||
        storedForm.productType === ProductTypes.Fabric) &&
      storedForm.productType !== currentForm.productType
    )
      return undefined;

    return storedForm;
  } catch (e) {
    return undefined;
  }
};

/**
 * This hook stores the form in local storage. This will allow the state to persist across refresh
 * @param initialForm the initial state of the form
 * @param localStoreKey the key to store the state in
 * @returns useState controls
 */
export const useRefreshPersistantState = (
  initialForm: FormFieldState,
  localStoreKey: string
): [FormFieldState, React.Dispatch<React.SetStateAction<FormFieldState>>] => {
  const [state, setState] = useState(() => {
    const localStorageForm = localStorage.getItem(localStoreKey);
    const form = getStoredForm(localStorageForm, initialForm);
    if (form) return form;
    return initialForm;
  });

  useEffect(() => {
    localStorage.setItem(localStoreKey, JSON.stringify(state));
    return () => localStorage.removeItem(localStoreKey);
  }, [localStoreKey, state]);

  return [state, setState];
};

import { Box, BoxProps, Center, Heading } from '@chakra-ui/react';
import React from 'react';

interface CardProps extends BoxProps {
  title?: string;
  children: React.ReactNode;
}
export const Card = (props: CardProps) => {
  let title;
  if (props.title)
    title = (
      <Center paddingTop="1">
        <Heading color="purple.300" as="h6" size="xs">
          {props.title.toUpperCase()}
        </Heading>
      </Center>
    );
  return (
    <Box
      borderWidth="2px"
      borderRadius="lg"
      borderColor="purple.100"
      overflow="hidden"
      maxWidth="max-content"
      {...props}
    >
      {title ?? ''}
      <Box p="3">{props.children}</Box>
    </Box>
  );
};

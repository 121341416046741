import React from 'react';
import { FormControl, FormLabel, VStack, Heading } from '@chakra-ui/react';
import { ImageRadioGroup, ImageRadioButton } from '../../../general';
import { ZipperAttributes as za } from 'product-validator';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import * as IMAGES from '../../../../images/zippers';

const zipperSrc: { [k in keyof typeof za.Closure]: string } = {
  Separating: IMAGES.separating,
  'Two-Way Separating': IMAGES.two_way_separating,
  'Closed End': IMAGES.closed_end
};

export const ClosureField: AppFieldComponent = ({
  currentValue,
  setFormField,
  toNextPage
}) => {
  return (
    <VStack spacing="6" alignItems="left">
      <FormControl as="fieldset">
        <FormLabel as="legend">
          <Heading>Closure</Heading>
        </FormLabel>
        <VStack spacing="3" alignItems="left" maxW="25rem">
          <ImageRadioGroup
            name="closure"
            value={currentValue.closure}
            onChange={(v) => {
              setFormField({ closure: v as za.Closure });
            }}
          >
            {Object.values(za.Closure).map((v) => (
              <ImageRadioButton
                value={v}
                key={v}
                image={zipperSrc[v as za.Closure]}
                minWidth="13.6rem"
              />
            ))}
          </ImageRadioGroup>
        </VStack>
      </FormControl>
    </VStack>
  );
};

import {
  FabricAttributes as fa,
  ZipperAttributes as za,
  RibbingAttributes as ra,
  ButtonProductAttributes as ba,
  ThreadAttributes as ta,
  TrimAttributes as tra,
  OtherSupplyAttributes as osa,
  isFieldVarianted,
  ProductTypes,
  FabProduct
} from 'product-validator';
import { FormFieldState } from '../../../../types';
import { getContent } from '../../../../utils/utils';

const ZIPPER_PAGE_TEXT = {
  [za.SellingUnit.Individual]: {
    title: 'Pick a price',
    sideText: '$ for one zipper'
  },
  [za.SellingUnit['Pack']]: {
    title: 'Pick a price per pack of zippers',
    sideText: '$ for each pack'
  },
  [za.SellingUnit['Tape By The Meter']]: {
    title: 'Pick a price per meter',
    sideText: '$ per meter'
  },
  [za.SellingUnit.Hardware]: {
    title: 'Pick a price',
    sideText: '$ per hardware'
  }
};

const RIBBING_PAGE_TEXT = {
  [ra.SellingUnit.Individual]: {
    title: 'Pick a price',
    sideText: '$ per ribbing'
  },
  [ra.SellingUnit['Pack']]: {
    title: 'Pick a price per pack of ribbing',
    sideText: '$ for each pack'
  },
  [ra.SellingUnit['By Meter']]: {
    title: 'Pick a price per meter',
    sideText: '$ per meter'
  }
};

const getHelperText = (
  relevantFields: (keyof FabProduct)[],
  product?: FabProduct
) => {
  const isRollVariants =
    product?.bundleOrRoll === fa.BundleOrRoll.Roll &&
    product?.variantedFields &&
    product?.variantedFields.length > 0;

  if (!product) return '';
  const output = [];
  if (isRollVariants) {
    for (let field of relevantFields) {
      const value = product[field];
      if (!value?.toString()) continue;

      switch (field) {
        case 'content':
          output.push(getContent(product.content));
          break;
        case 'widthCM':
          output.push(`${Object.values(value)[0]}CM (Width)`);
          break;
        case 'lengthM':
          output.push(`${Object.values(value)[0]}M (Length)`);
          break;
        case 'imperfections':
          output.push(`${Object.values(value)[0]} (Imperfections)`);
          break;
      }
    }
  } else {
    for (let field of relevantFields) {
      const value = product[field];
      if (!value?.toString()) continue;

      switch (field) {
        case 'content':
          output.push(getContent(product.content));
          break;
        case 'widthCM':
          output.push(`${value}CM (Width)`);
          break;
        case 'lengthM':
          output.push(`${value}M (Length)`);
          break;
        case 'imperfections':
          output.push(`${value} (Imperfections)`);
          break;
      }
    }
  }

  return output.filter((v) => !!v).join(' | ');
};

export const getPageText = (
  form: Readonly<FormFieldState>,
  product?: FabProduct
) => {
  let title = 'Pick a price';
  let sideText = '$';
  let relevantFields: (keyof FabProduct)[] = [];

  switch (form.productType) {
    case ProductTypes.Fabric: {
      title = 'Pick a price for the bundle';
      sideText = '$ for the bundle';
      relevantFields = ['content', 'widthCM', 'lengthM', 'imperfections'];
      if (
        form.bundleOrRoll === fa.BundleOrRoll.Roll ||
        isFieldVarianted('price', form.variantedFields)
      ) {
        title = 'Pick a price per meter';
        sideText = '$ per meter';
        relevantFields = ['content', 'widthCM', 'imperfections'];
      }
      break;
    }
    case ProductTypes.Zippers: {
      const sellingUnit = form.sellingUnit;
      if (sellingUnit && sellingUnit in ZIPPER_PAGE_TEXT) {
        title = ZIPPER_PAGE_TEXT[sellingUnit as za.SellingUnit].title;
        sideText = ZIPPER_PAGE_TEXT[sellingUnit as za.SellingUnit].sideText;
      }
      if (sellingUnit === za.SellingUnit.Hardware) {
        if (form.zipperHardwareSU === za.ZipperHardwareSU.Pack) {
          title = 'Pick a price';
          sideText = '$ for each pack';
        } else if (form.zipperHardwareType === za.ZipperHardwareType.Ender) {
          title = 'Pick a price';
          sideText = '$ for one ender';
        } else if (form.zipperHardwareType === za.ZipperHardwareType.Pull) {
          title = 'Pick a price';
          sideText = '$ for one pull';
        } else if (form.zipperHardwareType === za.ZipperHardwareType.Slider) {
          title = 'Pick a price';
          sideText = '$ for one slider';
        } else if (form.zipperHardwareType === za.ZipperHardwareType.Stopper) {
          title = 'Pick a price';
          sideText = '$ for one stopper';
        }
      }
      break;
    }
    case ProductTypes.Ribbing: {
      const sellingUnit = form.sellingUnit;
      if (sellingUnit && sellingUnit in RIBBING_PAGE_TEXT) {
        title = RIBBING_PAGE_TEXT[sellingUnit as ra.SellingUnit].title;
        sideText = RIBBING_PAGE_TEXT[sellingUnit as ra.SellingUnit].sideText;
      }
      break;
    }
    case ProductTypes['Sewing Patterns']: {
      title = 'Pick a price';
      sideText = '$ for the pattern';
      break;
    }
    case ProductTypes.Buttons: {
      title = 'Pick a price';
      sideText = '$ per button';
      if (form.sellingUnit === ba.SellingUnit['Pack']) {
        title = 'Pick a price per pack of buttons';
        sideText = '$ for each pack';
      }
      break;
    }
    case ProductTypes.Thread: {
      title = 'Pick a price';
      sideText = '$ per thread';
      if (form.sellingUnit === ta.SellingUnit['Pack']) {
        title = 'Pick a price per pack of thread';
        sideText = '$ for each pack';
      }
      break;
    }
    case ProductTypes.Trim: {
      title = 'Pick a price';
      if (form.sellingUnit === tra.SellingUnit['By Meter']) {
        sideText = '$ per meter';
      } else if (form.sellingUnit === tra.SellingUnit['Roll']) {
        sideText = '$ per roll';
      } else if (form.sellingUnit === tra.SellingUnit.Bundle) {
        sideText = '$ per bundle';
      }
      break;
    }
    case ProductTypes.Elastic: {
      title = 'Pick a price';
      if (form.sellingUnit === tra.SellingUnit['By Meter']) {
        title = 'Pick a price per meter';
        sideText = '$ per meter';
      } else if (form.sellingUnit === tra.SellingUnit.Bundle) {
        sideText = '$ per bundle';
      }
      break;
    }
    case ProductTypes['Other Supply']: {
      title = 'Price';
      if (form.sellingUnit === osa.SellingUnit['By Meter']) {
        sideText = '$ per meter';
      } else if (form.sellingUnit === osa.SellingUnit['By Weight']) {
        sideText = `$ per ${product?.weightLB ? product.weightLB : ''} pound`;
      } else if (form.sellingUnit === osa.SellingUnit.Individual) {
        sideText = '$ per piece';
      } else if (form.sellingUnit === osa.SellingUnit.Pack) {
        sideText = '$ per pack';
      }
      break;
    }
  }
  const helperText = getHelperText(relevantFields, product);
  return { title, sideText, helperText };
};

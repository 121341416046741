import {
  ButtonProductAttributes as ba,
  ThreadAttributes as ta,
  TrimAttributes as tra,
  OtherSupplyAttributes as osa
} from 'product-validator';

export const getButtonMaterialOptions = (
  buttonType?: string
): ba.Material[] => {
  if (!buttonType) return Object.values(ba.Material);
  switch (buttonType) {
    case ba.ButtonType['Sew-on Snap']:
    case ba.ButtonType.Grommet:
      return [ba.Material.Metal, ba.Material.Brass];
    case ba.ButtonType['Spring Snap']:
      return [
        ba.Material.Metal,
        ba.Material['Leather Covered'],
        ba.Material['Fabric Covered'],
        ba.Material.Brass
      ];
    case ba.ButtonType.Toggle:
      return [
        ba.Material.Metal,
        ba.Material.Plastic,
        ba.Material.Wood,
        ba.Material.Shell,
        ba.Material.Brass
      ];
    case ba.ButtonType['2 Hole']:
    case ba.ButtonType['4 Hole']:
    case ba.ButtonType.Shank:
    default:
      return Object.values(ba.Material);
  }
};

export const getThreadMaterialOptions = (threadType?: string): ta.Content[] => {
  if (!threadType) return Object.values(ta.Content);
  switch (threadType) {
    case ta.ThreadType.Embroidery:
      return [
        ta.Content.Polyester,
        ta.Content.Cotton,
        ta.Content.Nylon,
        ta.Content.Silk,
        ta.Content.Rayon,
        ta.Content['Poly/Cotton Blend']
      ];
    case ta.ThreadType['Embroidery Floss']:
      return [
        ta.Content.Polyester,
        ta.Content.Cotton,
        ta.Content['Poly/Cotton Blend']
      ];
    case ta.ThreadType.Elastic:
      return [ta.Content.Elastane, ta.Content['Poly/Rubber'], ta.Content.PU];
    case ta.ThreadType.Upholstery:
      return [
        ta.Content.Polyester,
        ta.Content.Cotton,
        ta.Content.Nylon,
        ta.Content.Silk
      ];
    case ta.ThreadType.Metallic:
      return [ta.Content.Synthetic, ta.Content['Poly/Metal foil']];
    case ta.ThreadType.Invisible:
      return [ta.Content.Nylon, ta.Content.Rayon];
    case ta.ThreadType.Silk:
      return [ta.Content.Silk];
    case ta.ThreadType.Unknown:
      return [
        ta.Content.Polyester,
        ta.Content.Cotton,
        ta.Content.Nylon,
        ta.Content.Silk,
        ta.Content.Rayon,
        ta.Content['Poly/Cotton Blend'],
        ta.Content.Acrylic,
        ta.Content.Wool
      ];
    case ta.ThreadType['All Purpose']:
      return [
        ta.Content.Polyester,
        ta.Content.Cotton,
        ta.Content.Nylon,
        ta.Content['Poly/Cotton Blend'],
        ta.Content.Acrylic,
        ta.Content.Wool
      ];
    case ta.ThreadType.Yarn:
      return [ta.Content.Polyester, ta.Content.Cotton];
    case ta.ThreadType['Wooly Nylon']:
      return [ta.Content.Nylon];
    default:
      return Object.values(ta.Content);
  }
};

export const getTrimQualityOptions = (trimType?: string): string[] => {
  const baseOptions = [
    tra.Qualities.Metallic,
    tra.Qualities.Velvet,
    tra.Qualities['Latex Grip'],
    tra.Qualities.Vintage,
    tra.Qualities.Glitter,
    tra.Qualities.Sequins,
    tra.Qualities.Rhinestones,
    tra.Qualities.Beaded,
    tra.Qualities.Cording,
    tra.Qualities.Embroidered,
    tra.Qualities.Stretchy
  ];
  if (!trimType) return Object.values(tra.Qualities);
  switch (trimType) {
    case tra.TrimType.Lace:
      return baseOptions.concat([tra.Qualities['Hand Made']]);
    case tra.TrimType['Faux Fur']:
      return baseOptions.concat([
        tra.Qualities['Short Hair'],
        tra.Qualities['Medium Hair'],
        tra.Qualities['Long Hair']
      ]);
    case tra.TrimType.Tape:
      return baseOptions.concat([
        tra.Qualities.Grommet,
        tra.Qualities.Snap,
        tra.Qualities['Hook and Eye']
      ]);
    default:
      return baseOptions;
  }
};

export const getTrimWeaveOptions = (trimType?: string): tra.Weave[] => {
  if (!trimType) return Object.values(tra.Weave);
  switch (trimType) {
    case tra.TrimType.Ribbon:
      return [tra.Weave.Satin];
    case tra.TrimType.Lace:
      return [tra.Weave.Bobbin, tra.Weave.Crochet, tra.Weave.Mesh];
    case tra.TrimType.Tape:
      return [tra.Weave.Twill, tra.Weave.Braided, tra.Weave.Looped];
    case tra.TrimType.Cording:
      return [tra.Weave.Braided, tra.Weave.Rope];
    case tra.TrimType.Piping:
      return [tra.Weave.Rope, tra.Weave['Fabric Covered']];
    case tra.TrimType.Webbing:
    case tra.TrimType['Faux Fur']:
    case tra.TrimType.Braided:
    case tra.TrimType.Ricrac:
    default:
      return Object.values(tra.Weave);
  }
};

export const getTrimUseOptions = (trimType?: string): tra.Uses[] => {
  if (!trimType) return Object.values(tra.Uses);
  const baseOptions = [
    tra.Uses['Home Decor'],
    tra.Uses.Accessories,
    tra.Uses.Apparel
  ];

  if (
    !(trimType in tra.TrimType) ||
    trimType === tra.TrimType.Webbing ||
    trimType === tra.TrimType.Tape
  )
    return baseOptions.concat(tra.Uses.Belting, tra.Uses.Strapping);

  return baseOptions;
};

export const getOtherSupplySellingUnitOptions = (
  otherSupplyType: string
): osa.SellingUnit[] => {
  if (!otherSupplyType) return Object.values(osa.SellingUnit);
  switch (otherSupplyType) {
    case osa.otherSupplyType.Velcro:
      return [
        osa.SellingUnit.Individual,
        osa.SellingUnit.Pack,
        osa.SellingUnit['By Meter']
      ];
    case osa.otherSupplyType.Quilting:
      return [osa.SellingUnit.Individual, osa.SellingUnit.Pack];
    case osa.otherSupplyType['Sewing Tool']:
      return [osa.SellingUnit.Individual];
    case osa.otherSupplyType.Feathers:
      return [osa.SellingUnit.Individual, osa.SellingUnit.Pack];
    case osa.otherSupplyType.Sequins:
      return [osa.SellingUnit.Pack];
    case osa.otherSupplyType.Beads:
      return [osa.SellingUnit.Individual, osa.SellingUnit.Pack];
    case osa.otherSupplyType.Felt:
      return [osa.SellingUnit.Individual, osa.SellingUnit.Pack];
    case osa.otherSupplyType.Yarn:
      return [osa.SellingUnit.Individual, osa.SellingUnit.Pack];
    case osa.otherSupplyType['Costume Jewelry']:
      return [osa.SellingUnit.Individual, osa.SellingUnit.Pack];
    case osa.otherSupplyType['Bridal Wear']:
      return [osa.SellingUnit.Individual, osa.SellingUnit.Pack];
    case osa.otherSupplyType.Clothing:
      return [osa.SellingUnit.Individual, osa.SellingUnit.Pack];
    case osa.otherSupplyType.Books:
      return [osa.SellingUnit.Individual];
    case osa.otherSupplyType.Tools:
      return [osa.SellingUnit.Individual, osa.SellingUnit.Pack];
    default:
      return Object.values(osa.SellingUnit);
  }
};

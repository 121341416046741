import React, { useEffect } from 'react';
import { useHistory, Route, RouteProps } from 'react-router-dom';
import { checkAuth, authErrorMsg } from '../../utils/authSession';

export const ProtectedRoute = (props: RouteProps) => {
  const errors = authErrorMsg();
  const history = useHistory();

  useEffect(() => {
    if (!checkAuth()) {
      history.push({
        pathname: '/login',
        state: { errorMsg: errors }
      });
    }
  }, [errors, history]);

  return <Route {...props} />;
};

import React from 'react';
import {
  FormControl,
  FormHelperText,
  Textarea
  //Heading
} from '@chakra-ui/react';
import { ErrorMessage, YesNoQuestion } from '../../../general';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { YesNo } from '../../../../types/formTypes';
import {
  getNestedValidationError,
  isTouched
} from '../../../../utils/validationUtils';

export const OtherSupplyAdditionalInfoField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  const errMsgs = getNestedValidationError('additionalInfo', validationErrors);
  const touched = isTouched(currentValue.additionalInfo);

  // return (
  //   <FormControl as="fieldset" isInvalid={errMsgs.length > 0 && touched}>
  //     <Heading>Additional Information</Heading>
  //     <FormHelperText>Optional</FormHelperText>
  //     <FormHelperText></FormHelperText>
  //     <Textarea
  //       w="100%"
  //       minH="160px"
  //       name="additionalInfo"
  //       value={currentValue.additionalInfo}
  //       onChange={(e) => setFormField({ additionalInfo: e.target.value })}
  //       borderColor="gray"
  //     />
  //     <FormHelperText>Use complete sentences.</FormHelperText>
  //     <ErrorMessage errMsgs={errMsgs} />
  //   </FormControl>
  // );
  return (
    <YesNoQuestion
      name="additionalInfoExists"
      question="Would you like to include any additional information?"
      value={currentValue.additionalInfoExists as YesNo}
      onChange={(v: string) => setFormField({ additionalInfoExists: v })}
    >
      <FormControl as="fieldset" isInvalid={errMsgs.length > 0 && touched}>
        <Textarea
          w="70%"
          minH="160px"
          name="additionalInfo"
          value={currentValue.additionalInfo}
          onChange={(e) => setFormField({ additionalInfo: e.target.value })}
          borderColor="gray"
        />
        <ErrorMessage errMsgs={errMsgs} />
        <FormHelperText>Use complete sentences.</FormHelperText>
      </FormControl>
    </YesNoQuestion>
  );
};

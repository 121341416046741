import React from 'react';
import { FormControl } from '@chakra-ui/react';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { StretchField } from '../StretchField';
import { SupplyContentField } from '../SupplyContentField';

export const ContentStretchField: AppFieldComponent = (props) => {
  return (
    <FormControl as="fieldset">
      <SupplyContentField {...props} />
      <StretchField {...props} />
    </FormControl>
  );
};

import {
  FormControl,
  FormLabel,
  FormHelperText,
  Heading,
  VStack
} from '@chakra-ui/react';
import React from 'react';
import { ButtonRadioGroup } from '../../../general/ButtonRadio';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { FabricAttributes as fa } from 'product-validator';
import update from 'immutability-helper';

export const HasVariantsField: AppFieldComponent = ({
  setFormField,
  currentValue,
  toNextPage
}) => {
  const hasVariants =
    currentValue.variantedFields && currentValue.variantedFields.length > 0;
  const isRollVariants = currentValue.bundleOrRoll === fa.BundleOrRoll.Roll;
  return (
    <VStack>
      <FormControl as="fieldset">
        <FormLabel as="legend" mb="1.5rem">
          {isRollVariants ? (
            <Heading>Are there bundles of the same fabric?</Heading>
          ) : (
            <Heading>Does the fabric have dimension variants?</Heading>
          )}
        </FormLabel>
        <ButtonRadioGroup
          name="HasVariants"
          value={hasVariants ? 'Yes' : 'No'}
          options={['Yes', 'No']}
          onChange={(v) => {
            setFormField((oldForm) => {
              if (v === 'Yes')
                return update(oldForm, {
                  variantedFields: {
                    $set: [
                      'lengthRadio',
                      'lengthM',
                      'lengthMOther',
                      'widthCM',
                      'widthOther',
                      'widthRadio',
                      'imperfectionOther',
                      'imperfections',
                      'price',
                      'quantity',
                      'quantityOther',
                      'quantityRadio'
                    ]
                  }
                });
              else
                return update(oldForm, {
                  variantedFields: {
                    $set: []
                  }
                });
            });
            toNextPage({ navOnNextFormUpdate: true });
          }}
        />
        <FormHelperText>Choose one.</FormHelperText>
      </FormControl>
    </VStack>
  );
};

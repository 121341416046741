import { Box, RadioGroup, Image } from '@chakra-ui/react';
import React from 'react';
import { WrappedInputGroup } from './WrappedInputGroup';
import { LooksDescriptor, RadioButton } from '.';

interface ImageRadioButtonProps {
  value: string;
  image: string;
  isDisabled?: boolean;
  minWidth?: string;
}

export const ImageRadioButton = ({
  value,
  image,
  isDisabled,
  minWidth
}: ImageRadioButtonProps) => {
  const buttonProps = {
    h: '7rem',
    maxW: '15rem',
    //minW: '10rem',
    // Two different minW with a custom option to try to take into account image buttons with
    // long text such zipper closure type 'Two-Way Separating'
    minW: minWidth ? minWidth : '11.45rem',
    paddingTop: '4px'
  };
  return (
    <RadioButton value={value} isDisabled={isDisabled} {...buttonProps}>
      {/* Added a condition so that Other Supplies button on the supply type selection says Everything Else instead of Other Supply */}
      <LooksDescriptor
        text={
          value === 'Other Supply'
            ? (value = 'Everything Else')
            : (value as string)
        }
        isVertical={true}
      >
        <Image src={image} fit="fill" boxSize="100%" backgroundColor="black" />
      </LooksDescriptor>
    </RadioButton>
  );
};

interface ImageRadioGroupProps {
  name: string;
  value?: string;
  onChange?: (e: string) => void;
  children: React.ReactNode;
}

export const ImageRadioGroup = (props: ImageRadioGroupProps) => {
  return (
    <Box>
      <RadioGroup value={props.value} onChange={props.onChange}>
        <WrappedInputGroup maxW="43rem" sx={{ gap: '0.75rem' }}>
          {props.children}
        </WrappedInputGroup>
      </RadioGroup>
    </Box>
  );
};

import {
  Button,
  ButtonProps,
  UseCheckboxReturn,
  UseRadioReturn
} from '@chakra-ui/react';
import React from 'react';

type useReturn = UseCheckboxReturn & UseRadioReturn;
interface InputButtonProps
  extends Pick<useReturn, 'getCheckboxProps' | 'getInputProps'>,
    ButtonProps {
  children: React.ReactNode;
}

export const InputButton = (props: InputButtonProps) => {
  const { getInputProps, getCheckboxProps, children, ...rest } = props;
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Button
      as="label"
      size="lg"
      minW="10rem"
      maxW="10rem"
      {...checkbox}
      borderWidth="1px"
      borderColor="purple.200"
      _checked={{
        bgColor: 'purple.500',
        color: 'white',
        borderColor: 'purple.500'
      }}
      {...rest}
    >
      <input {...input} />
      {children}
    </Button>
  );
};

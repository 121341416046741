import {
  FabricAttributes as fa,
  ZipperAttributes as za,
  RibbingAttributes as ra,
  //ButtonProductAttributes as ba,
  //ThreadAttributes as ta,
  TrimAttributes as tra,
  ElasticAttributes as ea,
  BiasTapeAttributes as bta,
  OtherSupplyAttributes as osa,
  ProductTypes,
  isFieldVarianted
} from 'product-validator';
import { FormFieldState } from '../../../types/formTypes';

interface FieldRowEntryOptions {
  valueKey: string;
  appFieldKeys: string[];
  isVariantedFn?(currentValue: FormFieldState): boolean;
  showRowFn?(currentValue: FormFieldState): boolean;
}

export const FieldRowEntries: { [key: string]: FieldRowEntryOptions } = {
  Supplies: {
    valueKey: 'productType',
    appFieldKeys: ['Supplies']
  },
  'Selling Unit': {
    valueKey: 'sellingUnit',
    appFieldKeys: ['Selling Unit']
  },
  Content: {
    valueKey: 'material',
    appFieldKeys: ['Material and Stretch', 'Material + Type']
  },
  Material: {
    valueKey: 'material',
    appFieldKeys: [
      'Material',
      'Button Material',
      'Thread Material',
      'Bias Tape Material',
      'Material and Stretch'
    ]
  },
  'Zipper Type': {
    valueKey: 'zipperType',
    appFieldKeys: ['Zipper Type + Closure', 'Zipper Type']
  },
  'Hardware Material': {
    valueKey: 'zipperHardwareMaterial',
    appFieldKeys: ['Hardware Material']
  },
  'Hardware Type': {
    valueKey: 'zipperHardwareType',
    appFieldKeys: ['Hardware Type']
  },
  'Hardware Unit': {
    valueKey: 'zipperHardwareSU',
    appFieldKeys: ['Hardware Unit']
  },
  Closure: {
    valueKey: 'closure',
    appFieldKeys: ['Zipper Type + Closure']
  },
  'Bundle or Roll': {
    valueKey: 'bundleOrRoll',
    appFieldKeys: ['Bundle or Roll']
  },
  Images: {
    valueKey: '',
    appFieldKeys: ['Camera']
  },
  Size: {
    valueKey: 'size',
    appFieldKeys: ['Dimensions'],
    showRowFn: (currentValue: FormFieldState): boolean => {
      return (
        currentValue.productType === ProductTypes.Ribbing &&
        currentValue.sellingUnit !== ra.SellingUnit['By Meter']
      );
    }
  },
  'Supply Type': {
    valueKey: 'otherSupplyType',
    appFieldKeys: ['Supply Type']
  },
  'Product Size': {
    valueKey: 'osSize',
    appFieldKeys: ['Weight', 'Weight + Size']
  },
  'Weight (G)': {
    valueKey: 'weightG',
    appFieldKeys: ['Weight', 'Weight + Size']
  },
  'Weight (LB)': {
    valueKey: 'weightLB',
    appFieldKeys: ['Weight', 'Weight + Size']
  },
  'Width (cm)': {
    valueKey: 'widthCM',
    appFieldKeys: ['Dimensions', 'Variant Dimensions', 'Dimensions + Quantity'],
    isVariantedFn: (currentValue: FormFieldState) => {
      return isFieldVarianted('widthCM', currentValue.variantedFields);
    }
  },
  'Length (cm)': {
    valueKey: 'lengthCM',
    appFieldKeys: [
      'Dimensions',
      'Dimensions + Quantity',
      'Other Supply Dimensions'
    ],
    showRowFn: (currentValue: FormFieldState): boolean => {
      return (
        (currentValue.productType === ProductTypes.Ribbing ||
          currentValue.productType === ProductTypes['Other Supply']) &&
        currentValue.sellingUnit !== ra.SellingUnit['By Meter']
      );
    }
  },
  'Length (M)': {
    valueKey: 'lengthM',
    appFieldKeys: ['Dimensions', 'Variant Dimensions', 'Dimensions + Quantity'],
    showRowFn: (currentValue: FormFieldState): boolean => {
      return (
        currentValue.productType === ProductTypes.Fabric ||
        (currentValue.productType === ProductTypes.Trim &&
          currentValue.sellingUnit !== tra.SellingUnit['By Meter']) ||
        (currentValue.productType === ProductTypes.Elastic &&
          currentValue.sellingUnit !== ea.SellingUnit['By Meter']) ||
        (currentValue.productType === ProductTypes['Bias Tape'] &&
          currentValue.sellingUnit !== bta.SellingUnit['By Meter']) ||
        (currentValue.productType === ProductTypes['Other Supply'] &&
          currentValue.sellingUnit === osa.SellingUnit['By Meter'])
      );
    },
    isVariantedFn: (currentValue: FormFieldState) => {
      return isFieldVarianted('lengthM', currentValue.variantedFields);
    }
  },
  'Height (cm)': {
    valueKey: 'heightCM',
    appFieldKeys: ['Other Supply Dimensions']
  },
  'Dimension Description': {
    valueKey: 'dimensionDescription',
    appFieldKeys: ['Dimensions']
  },
  'Knit or Woven': {
    valueKey: 'knitOrWoven',
    appFieldKeys: ['Knit or Woven']
  },
  'Fabric Content': {
    valueKey: 'content',
    appFieldKeys: ['Fiber Content']
  },
  Quality: {
    valueKey: 'quality',
    appFieldKeys: [
      'Brand, Imperfections, and Quality',
      'Imperfections + Quality',
      'Quality',
      'Brand + Additional Info'
    ]
  },
  Stretch: {
    valueKey: 'stretch',
    appFieldKeys: ['Stretch', 'Material and Stretch']
  },
  'Width Stretch': {
    valueKey: 'widthStretch',
    appFieldKeys: ['Stretch Percentage']
  },
  'Length Stretch': {
    valueKey: 'lengthStretch',
    appFieldKeys: ['Stretch Percentage']
  },
  Opacity: {
    valueKey: 'opacity',
    appFieldKeys: ['Opacity, Weight, and Drape']
  },
  Weight: {
    valueKey: 'weight',
    appFieldKeys: ['Opacity, Weight, and Drape', 'Weight', 'Weight + Size']
  },
  Drape: {
    valueKey: 'drape',
    appFieldKeys: ['Opacity, Weight, and Drape']
  },
  Feel: {
    valueKey: 'feel',
    appFieldKeys: ['Feel']
  },
  Colours: {
    valueKey: 'colour',
    appFieldKeys: ['Colours']
  },
  'Colour Tone': {
    valueKey: 'colourTone',
    appFieldKeys: ['Colours']
  },
  'Button Opaqueness': {
    valueKey: 'buttonOpaqueness',
    appFieldKeys: ['Colours']
  },
  Brand: {
    valueKey: 'brand',
    appFieldKeys: [
      'Brand, Imperfections, and Quality',
      'Sewing Pattern Brand Number',
      'Brand + Additional Info',
      'Bias Tape Brand',
      'Thread Type'
    ]
  },
  Pattern: {
    valueKey: 'pattern',
    appFieldKeys: ['Pattern + Imperfections', 'Pattern']
  },
  Imperfections: {
    valueKey: 'imperfections',
    appFieldKeys: [
      'Brand, Imperfections, and Quality',
      'Imperfections + Quality',
      'Qualities + Imperfections',
      'Pattern + Imperfections',
      'Variant Dimensions',
      'Uses + Imperfections',
      'Imperfections'
    ],
    isVariantedFn: (currentValue: FormFieldState) => {
      return isFieldVarianted('imperfections', currentValue.variantedFields);
    }
  },
  'Knit or Weave Type': {
    valueKey: 'knitWeaveType',
    appFieldKeys: ['Knit or Weave Type']
  },
  Length: {
    valueKey: 'lengthIn',
    appFieldKeys: ['Zipper Dimensions'],
    showRowFn: (currentValue: FormFieldState): boolean => {
      return (
        currentValue.sellingUnit === za.SellingUnit.Individual ||
        currentValue.sellingUnit === za.SellingUnit['Pack']
      );
    }
  },
  'Teeth Size': {
    valueKey: 'widthMM',
    appFieldKeys: ['Zipper Dimensions']
  },
  Uses: {
    valueKey: 'uses',
    appFieldKeys: ['Common Uses', 'Uses + Imperfections']
  },
  'Ribbing Use': {
    valueKey: 'use',
    appFieldKeys: ['Ribbing Common Use']
  },
  'Pack Size Type': {
    valueKey: 'packSizeType',
    appFieldKeys: ['Quantity']
  },
  'Pack Size': {
    valueKey: 'packSize',
    appFieldKeys: ['Quantity'],
    showRowFn: (currentValue: FormFieldState): boolean => {
      return (
        currentValue.sellingUnit === 'Pack'
        //currentValue.sellingUnit === za.SellingUnit['Pack'] ||
        // currentValue.sellingUnit === ra.SellingUnit['Pack'] ||
        // currentValue.sellingUnit === ba.SellingUnit['Pack']
        // currentValue.sellingUnit === ta.SellingUnit['Pack'] ||
        // currentValue.sellingUnit === ea.SellingUnit['Pack']
      );
    }
  },
  Quantity: {
    valueKey: 'quantity',
    appFieldKeys: [
      'Quantity',
      'Dimensions',
      'Variant Dimensions',
      'Dimensions + Quantity'
    ],
    showRowFn: (currentValue: FormFieldState): boolean => {
      return (
        currentValue.bundleOrRoll === fa.BundleOrRoll.Bundle ||
        currentValue.productType !== ProductTypes.Fabric
      );
    },
    isVariantedFn: (currentValue: FormFieldState) => {
      return isFieldVarianted('quantity', currentValue.variantedFields);
    }
  },
  'Sewing Pattern Number': {
    valueKey: 'patternNumber',
    appFieldKeys: ['Sewing Pattern Brand Number']
  },
  'Sewing Pattern Type': {
    valueKey: 'patternType',
    appFieldKeys: ['Sewing Pattern Type']
  },
  Qualities: {
    valueKey: 'qualities',
    appFieldKeys: [
      'Sewing Pattern Type',
      'Qualities',
      'Qualities + Imperfections',
      'Trim Qualities'
    ]
  },
  Condition: {
    valueKey: 'condition',
    appFieldKeys: ['Sewing Pattern Type']
  },
  Price: {
    valueKey: 'price',
    appFieldKeys: ['Price', 'Variant Price'],
    isVariantedFn: (currentValue: FormFieldState) => {
      return isFieldVarianted('price', currentValue.variantedFields);
    }
  },
  'Additional Info': {
    valueKey: 'additionalInfo',
    appFieldKeys: [
      'Additional Information',
      'Brand + Additional Info',
      'Additional Info'
    ]
  },
  Name: {
    valueKey: 'name',
    appFieldKeys: ['Name']
  },
  Unboxing: {
    valueKey: 'unboxingDate',
    appFieldKeys: ['Name']
  },
  'Media Tag': {
    valueKey: 'mediaTag',
    appFieldKeys: ['Name']
  },
  'Width (mm)': {
    valueKey: 'widthMM',
    appFieldKeys: ['Button Size']
  },
  'Unique Shape': {
    valueKey: 'uniqueShape',
    appFieldKeys: ['Button Size']
  },
  'Button Type': {
    valueKey: 'buttonType',
    appFieldKeys: ['Button Type']
  },
  Design: {
    valueKey: 'design',
    appFieldKeys: ['Button Design', 'Design + Shape']
  },
  'Thread Type': {
    valueKey: 'threadType',
    appFieldKeys: ['Thread Type']
  },
  'Thread on Spool': {
    valueKey: 'threadOnSpool',
    appFieldKeys: ['Thread Size']
  },
  'Spool Size': {
    valueKey: 'spoolSize',
    appFieldKeys: ['Thread Size']
  },
  'Industrial Weight': {
    valueKey: 'indusWeight',
    appFieldKeys: ['Thread Size']
  },
  'Width (in)': {
    valueKey: 'widthIn',
    appFieldKeys: ['Dimensions', 'Dimensions + Quantity']
  },
  'Length Known or Estimated': {
    valueKey: 'trimLengthType',
    appFieldKeys: ['Dimensions', 'Dimensions + Quantity']
  },
  'Bias Tape Length Known or Estimated': {
    valueKey: 'biasTapeLengthType',
    appFieldKeys: ['Dimensions + Quantity']
  },
  'Elastic Length Known or Estimated': {
    valueKey: 'elasticLengthType',
    appFieldKeys: ['Dimensions + Quantity', 'Dimensions'],
    showRowFn: (currentValue: FormFieldState): boolean => {
      return currentValue.sellingUnit === ea.SellingUnit.Roll;
    }
  },
  'Length Type': {
    valueKey: 'otherSupplyLengthType',
    appFieldKeys: ['Dimensions']
  },
  'Trim Type': {
    valueKey: 'trimType',
    appFieldKeys: ['Type + Weave']
  },
  'Trim Weave': {
    valueKey: 'weaveType',
    appFieldKeys: ['Type + Weave']
  },
  'Trim Shape': {
    valueKey: 'shape',
    appFieldKeys: ['Design + Shape']
  },
  'Trim Content': {
    valueKey: 'trimContent',
    appFieldKeys: ['Trim Content']
  },
  'Elastic Type': {
    valueKey: 'elasticType',
    appFieldKeys: ['Elastic Type']
  },
  'Bias Tape Type': {
    valueKey: 'biasTapeType',
    appFieldKeys: ['Material + Type']
  },
  'Bias Tape Brand': {
    valueKey: 'brand',
    appFieldKeys: ['Brand + Additional Info']
  },
  'Bias Tape Material': {
    valueKey: 'material',
    appFieldKeys: ['Material + Type']
  }
};

const fabricFieldRowOrder: (keyof typeof FieldRowEntries)[] = [
  'Bundle or Roll',
  'Images',
  'Width (cm)',
  'Length (M)',
  'Knit or Woven',
  'Fabric Content',
  'Knit or Weave Type',
  'Quality',
  'Stretch',
  'Width Stretch',
  'Length Stretch',
  'Opacity',
  'Weight',
  'Drape',
  'Feel',
  'Colours',
  'Colour Tone',
  'Pattern',
  'Imperfections',
  'Uses',
  'Price',
  'Media Tag',
  'Unboxing',
  'Quantity',
  'Name'
];

const zipperFieldRowOrder: (keyof typeof FieldRowEntries)[] = [
  'Supplies',
  'Selling Unit',
  'Material',
  'Hardware Type',
  'Hardware Material',
  'Hardware Unit',
  'Zipper Type',
  'Closure',
  'Images',
  'Quality',
  'Colours',
  'Colour Tone',
  'Brand',
  'Imperfections',
  'Length',
  'Teeth Size',
  'Uses',
  'Pack Size',
  'Quantity',
  'Price',
  'Additional Info'
];

const ribbingFieldRowOrder: (keyof typeof FieldRowEntries)[] = [
  'Supplies',
  'Selling Unit',
  'Material',
  'Images',
  'Size',
  'Width (cm)',
  'Length (cm)',
  'Stretch',
  'Colours',
  'Colour Tone',
  'Pattern',
  'Imperfections',
  'Ribbing Use',
  'Pack Size',
  'Quantity',
  'Price'
];

const sewingPatternFieldRowOrder: (keyof typeof FieldRowEntries)[] = [
  'Supplies',
  'Images',
  'Sewing Pattern Type',
  'Qualities',
  'Condition',
  'Brand',
  'Sewing Pattern Number',
  'Quantity',
  'Price'
];

const buttonFieldRowOrder: (keyof typeof FieldRowEntries)[] = [
  'Supplies',
  'Selling Unit',
  'Images',
  'Width (mm)',
  'Unique Shape',
  'Quantity',
  'Pack Size',
  'Button Type',
  'Material',
  'Colours',
  'Colour Tone',
  'Button Opaqueness',
  'Design',
  'Uses',
  'Price'
];

const threadFieldRowOrder: (keyof typeof FieldRowEntries)[] = [
  'Supplies',
  'Selling Unit',
  'Images',
  'Thread Type',
  'Material',
  'Colours',
  'Colour Tone',
  'Thread on Spool',
  'Spool Size',
  'Industrial Weight',
  'Brand',
  'Quantity',
  'Pack Size',
  'Imperfections',
  'Uses',
  'Price'
];

const trimFieldRowOrder: (keyof typeof FieldRowEntries)[] = [
  'Supplies',
  'Selling Unit',
  'Images',
  'Width (in)',
  'Length (M)',
  'Length Known or Estimated',
  'Quantity',
  'Trim Type',
  'Trim Weave',
  'Trim Shape',
  'Qualities',
  'Trim Content',
  'Design',
  'Colours',
  'Colour Tone',
  'Imperfections',
  'Uses',
  'Price'
];

const elasticFieldRowOrder: (keyof typeof FieldRowEntries)[] = [
  'Supplies',
  'Selling Unit',
  'Images',
  'Width (in)',
  'Length (M)',
  'Elastic Length Known or Estimated',
  'Quantity',
  'Pack Size',
  'Elastic Type',
  'Qualities',
  'Colours',
  'Colour Tone',
  'Imperfections',
  'Price'
];

const biasTapeFieldRowOrder: (keyof typeof FieldRowEntries)[] = [
  'Supplies',
  'Selling Unit',
  'Images',
  'Width (in)',
  'Length (M)',
  'Bias Tape Length Known or Estimated',
  'Quantity',
  'Bias Tape Material',
  'Bias Tape Type',
  'Qualities',
  'Bias Tape Brand',
  'Colours',
  'Colour Tone',
  'Imperfections',
  'Price',
  'Additional Info'
];

const otherSupplyFieldRowOrder: (keyof typeof FieldRowEntries)[] = [
  'Supplies',
  'Supply Type',
  'Selling Unit',
  'Name',
  'Width (cm)',
  'Length (cm)',
  'Length Type',
  'Length (M)',
  'Height (cm)',
  'Dimension Description',
  'Weight',
  'Product Size',
  'Weight (LB)',
  'Weight (G)',
  'Pack Size Type',
  'Pack Size',
  'Quantity',
  'Imperfections',
  'Additional Info',
  'Colours',
  'Colour Tone',
  'Price'
];

export const getFieldRowEntries = (
  productType: ProductTypes
): (keyof typeof FieldRowEntries)[] => {
  switch (productType) {
    case ProductTypes.Fabric:
      return fabricFieldRowOrder;
    case ProductTypes.Zippers:
      return zipperFieldRowOrder;
    case ProductTypes.Ribbing:
      return ribbingFieldRowOrder;
    case ProductTypes['Sewing Patterns']:
      return sewingPatternFieldRowOrder;
    case ProductTypes.Buttons:
      return buttonFieldRowOrder;
    case ProductTypes.Thread:
      return threadFieldRowOrder;
    case ProductTypes.Trim:
      return trimFieldRowOrder;
    case ProductTypes.Elastic:
      return elasticFieldRowOrder;
    case ProductTypes['Bias Tape']:
      return biasTapeFieldRowOrder;
    case ProductTypes['Other Supply']:
      return otherSupplyFieldRowOrder;
    default:
      return Object.keys(FieldRowEntries);
  }
};

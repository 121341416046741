import React from 'react';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button
} from '@chakra-ui/react';
import { FormFieldState } from '../../types';

type PropsType = {
  currentValue: FormFieldState | undefined;
  cancelRef: React.RefObject<HTMLButtonElement>;
  isOverlayOpen: boolean;
  onOverlayClose: () => void;
  startNewProduct: () => void;
  startNewSupply: () => void;
};
export const Overlay = (props: PropsType) => {
  return (
    <AlertDialog
      leastDestructiveRef={props.cancelRef}
      isOpen={props.isOverlayOpen}
      onClose={props.onOverlayClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Start new Product
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure?You can't undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={props.cancelRef} onClick={props.onOverlayClose}>
              Cancel
            </Button>
            {props.currentValue?.productType === 'Fabric' ? (
              <Button
                colorScheme="orange"
                onClick={props.startNewProduct}
                ml={3}
              >
                New Product
              </Button>
            ) : (
              <>
                <Button
                  colorScheme="orange"
                  onClick={props.startNewProduct}
                  ml={3}
                >
                  New Product
                </Button>
                <Button
                  colorScheme="orange"
                  onClick={props.startNewSupply}
                  ml={3}
                >
                  New Supply
                </Button>
              </>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

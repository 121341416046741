import {
  FormControl,
  FormLabel,
  FormHelperText,
  VStack,
  Heading
} from '@chakra-ui/react';
import React from 'react';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import {
  OtherTextInput,
  ErrorMessage,
  ImageRadioGroup,
  ImageRadioButton
} from '../../../general';
import {
  getNestedValidationError,
  isTouched
} from '../../../../utils/validationUtils';
import { BiasTapeAttributes as bta } from 'product-validator';
import * as IMAGES from '../../../../images/biasTapes';

const biasTapeSrc: { [k in keyof typeof bta.biasTapeType]: string } = {
  Blanket: IMAGES.blanket,
  Continuous: IMAGES.continuous,
  'Double Fold': IMAGES.double_fold,
  Fusible: IMAGES.fusible,
  'Single Fold': IMAGES.single_fold
};

export const BiasTapeTypeField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  const biasTapeTypeErrMsgs = getNestedValidationError(
    'biasTapeType',
    validationErrors
  );
  const biasTapeTypeTouched = isTouched(
    currentValue.biasTapeType,
    currentValue.biasTapeTypeOther
  );

  return (
    <FormControl
      as="fieldset"
      isInvalid={biasTapeTypeErrMsgs.length > 0 && biasTapeTypeTouched}
    >
      <FormLabel as="legend">
        <Heading>Type</Heading>
      </FormLabel>
      <VStack spacing="4" maxW="36.2rem" alignItems="left">
        <ImageRadioGroup
          name="Bias Tape Type"
          value={currentValue.biasTapeType}
          onChange={(v) => {
            setFormField({ biasTapeType: v as bta.biasTapeType });
          }}
        >
          {Object.values(bta.biasTapeType).map((v) => (
            <ImageRadioButton
              value={v}
              key={v}
              image={biasTapeSrc[v as bta.biasTapeType]}
              isDisabled={!!currentValue.biasTapeTypeOther}
            />
          ))}
        </ImageRadioGroup>
        <OtherTextInput
          onChange={(e) => {
            setFormField({
              biasTapeTypeOther: e.target.value
            });
          }}
          value={currentValue.elasticTypeOther ?? ''}
        />
        <FormHelperText>Select one or enter in another</FormHelperText>
        <ErrorMessage errMsgs={biasTapeTypeErrMsgs} />
      </VStack>
    </FormControl>
  );
};

import React from 'react';
import { FormLabel, Heading } from '@chakra-ui/react';
import { ButtonRadioGroup } from '../../../general';
import { BiasTapeAttributes as bta } from 'product-validator';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { FormControl } from '@chakra-ui/form-control';

export const BiasTapeMaterialField: AppFieldComponent = ({
  currentValue,
  setFormField,
  toNextPage
}) => {
  return (
    <FormControl as="fieldset">
      <FormLabel as="legend" mb="0.5rem">
        <Heading>Material</Heading>
      </FormLabel>

      <ButtonRadioGroup
        name="Material"
        options={Object.values(bta.Material)}
        value={currentValue.material}
        onChange={(v) => {
          setFormField({ material: v as bta.Material });
          //toNextPage({ navOnNextFormUpdate: true });
        }}
      />
    </FormControl>
    // <FormFieldTemplate title="Material">

    // </FormFieldTemplate>
  );
};

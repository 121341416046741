import {
  FormControl,
  FormLabel,
  FormHelperText,
  Stack,
  HStack,
  Box
} from '@chakra-ui/react';
import { FabricAttributes, KnownContent } from 'product-validator';
import React from 'react';
import {
  getNestedValidationError,
  isTouched
} from '../../../../utils/validationUtils';
import {
  WrappedInputGroup,
  NumberInput,
  Card,
  OtherTextInput
} from '../../../general';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { ErrorMessage } from '../../../general/ErrorMessage';

interface NumberBankProps {
  options: string[];
  onChange: (v: KnownContent[]) => void;
  values?: KnownContent[];
}
const NumberBank = (props: NumberBankProps) => {
  const valueMap: { [k: string]: number } = {};
  props.values?.forEach((v) => (valueMap[v.content] = v.amount));

  const updateContent = (
    updatedContentName: string,
    newAmountString: string
  ) => {
    const newAmount = parseFloat(newAmountString);
    if (isNaN(newAmount)) delete valueMap[updatedContentName];
    else valueMap[updatedContentName] = newAmount;

    const newContents: KnownContent[] = [];
    for (const contentName in valueMap) {
      if (valueMap[contentName] > 0)
        newContents.push({
          content: contentName,
          amount: valueMap[contentName]
        });
    }
    // Sort the array to get the primary and secondary contents
    newContents.sort((a, b) => b.amount - a.amount);
    props.onChange(newContents);
  };

  return (
    <Card>
      <WrappedInputGroup maxW="42.75rem">
        {props.options.map((contentName, idx) => (
          <Box key={idx} flexBasis="14rem">
            <NumberInput
              id={contentName}
              max={100}
              value={valueMap[contentName]}
              onChange={(v) => updateContent(contentName, v)}
              text={'% ' + contentName}
            />
          </Box>
        ))}
      </WrappedInputGroup>
    </Card>
  );
};

enum OtherKey {
  Amount = 'Amount',
  Content = 'Content'
}

interface ContentOtherProps {
  id: string;
  onChange: (v: number | string, k: OtherKey) => void;
  value?: KnownContent;
}
const ContentOther = (props: ContentOtherProps) => {
  const numberInputProps = {
    w: '4rem',
    size: 'lg'
  };

  return (
    <>
      <HStack>
        <NumberInput
          id={`OtherNum${props.id}`}
          step={5}
          max={100}
          value={props.value?.amount || ''}
          onChange={(v) => props.onChange(v, OtherKey.Amount)}
          text={'%'}
          {...numberInputProps}
        />
        <OtherTextInput
          id={`OtherText${props.id}`}
          placeholder="Other Material"
          value={props.value?.content || ''}
          onChange={(e) => props.onChange(e.target.value, OtherKey.Content)}
        />
      </HStack>
    </>
  );
};

export const KnownContentField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors
}) => {
  const errMsgs: string[] = [];

  errMsgs.push(...getNestedValidationError('content', validationErrors));
  const touched = isTouched(
    currentValue.content?.knownContent,
    currentValue.content?.knownContentOther
  );

  const updateKnownContentOther = (
    idx: number,
    val: number | string,
    key: OtherKey
  ) => {
    setFormField((oldForm) => {
      let otherContentFields: KnownContent[] =
        oldForm.content?.knownContentOther ?? [];
      let amount = undefined;
      let content = undefined;

      if (otherContentFields[idx]) {
        amount = otherContentFields[idx].amount;
        content = otherContentFields[idx].content;
      }

      if (key === OtherKey.Amount)
        amount = isNaN(val as number) ? amount : (val as number);
      else content = val as string;

      otherContentFields[idx] = {
        amount: amount,
        content: content
      } as KnownContent;

      let newForm = {
        ...oldForm,
        content: {
          ...currentValue.content,
          contentType: FabricAttributes.ContentType.Known,
          knownContentOther: otherContentFields
        }
      };

      return newForm;
    });
  };

  return (
    <FormControl as="fieldset" isInvalid={errMsgs.length > 0 && touched}>
      <FormLabel as="legend">Fill in quantities of known content</FormLabel>
      <Stack spacing="3">
        <NumberBank
          options={Object.values(FabricAttributes.ContentSpecific)}
          onChange={(n) => {
            return setFormField({
              content: {
                ...currentValue.content,
                contentType: FabricAttributes.ContentType.Known,
                knownContent: n
              }
            });
          }}
          values={currentValue.content?.knownContent}
        />
        <ContentOther
          id="0"
          value={currentValue.content?.knownContentOther?.[0]}
          onChange={(v, k) => updateKnownContentOther(0, v, k)}
        />
        <ContentOther
          id="1"
          value={currentValue.content?.knownContentOther?.[1]}
          onChange={(v, k) => updateKnownContentOther(1, v, k)}
        />
      </Stack>
      <ErrorMessage errMsgs={errMsgs} />
      <FormHelperText>Fill in quantities that sum to 100.</FormHelperText>
    </FormControl>
  );
};

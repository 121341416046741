import React from 'react';
import { ProductTypes } from 'product-validator';
import { AppFieldComponent } from '../../../types/appFieldTypes';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Heading,
  Box
} from '@chakra-ui/react';
import {
  //ButtonRadioGroup,
  ImageRadioButton,
  ImageRadioGroup
} from '../../general';
import * as IMAGES from '../../../images/supplies';

//const unavailableSupplies = [ProductTypes.Lace];

export enum SupplyTypes {
  Zippers = 'Zippers',
  Buttons = 'Buttons',
  Elastic = 'Elastic',
  'Sewing Patterns' = 'Sewing Patterns',
  Thread = 'Thread',
  Trim = 'Trim',
  Ribbing = 'Ribbing',
  'Bias Tape' = 'Bias Tape',
  'Other Supply' = 'Other Supply'
}

// Remove Fabric from the supply array
const SUPPLIES: string[] = Object.values(ProductTypes);
SUPPLIES.splice(0, 1);

const suppliesSrc: { [k in keyof typeof SupplyTypes]: string } = {
  'Bias Tape': IMAGES.bias_tape,
  Buttons: IMAGES.button,
  Elastic: IMAGES.elastic,
  Ribbing: IMAGES.ribbing,
  'Sewing Patterns': IMAGES.sewing_pattern,
  Thread: IMAGES.thread,
  Trim: IMAGES.trim,
  Zippers: IMAGES.zipper,
  'Other Supply': IMAGES.everything_else
};

export const SupplyTypeField: AppFieldComponent = ({
  setFormField,
  currentValue,
  toNextPage
}) => {
  return (
    <FormControl as="fieldset">
      <FormLabel as="legend" mb="1.5rem">
        <Heading>Supplies</Heading>
      </FormLabel>
      <Box>
        {/* <ButtonRadioGroup
          name="Dimension Type"
          value={currentValue.productType}
          options={SUPPLIES}
          onChange={(v) => {
            setFormField({ productType: v as ProductTypes });
            toNextPage({ navOnNextFormUpdate: true });
          }}
        /> */}
        <ImageRadioGroup
          name="Dimension Type"
          value={currentValue.productType}
          onChange={(v) => {
            setFormField({ productType: v as ProductTypes });
            toNextPage({ navOnNextFormUpdate: true });
          }}
        >
          {Object.values(SupplyTypes).map((v) => (
            <ImageRadioButton
              value={v}
              key={v}
              image={suppliesSrc[v as SupplyTypes]}
            />
          ))}
        </ImageRadioGroup>
      </Box>
      <FormHelperText>Choose one.</FormHelperText>
    </FormControl>
  );
};

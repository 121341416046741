import React from 'react';
import { ThreadAttributes as ta } from 'product-validator';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { FormControl, FormHelperText, Stack } from '@chakra-ui/react';
import { YesNo } from '../../../../types/formTypes';
import {
  Card,
  ButtonRadioGroup,
  OtherTextInput,
  ErrorMessage,
  YesNoQuestion
} from '../../../general';
import {
  getNestedValidationError,
  isTouched
} from '../../../../utils/validationUtils';

export const ThreadBrandField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  const errMsgs = getNestedValidationError('brand', validationErrors);
  const touched = isTouched(currentValue.brand, currentValue.brandOther);
  return (
    <YesNoQuestion
      name="brand"
      value={currentValue.brandExists as YesNo}
      question={'Would you like to enter a brand?'}
      onChange={(v: YesNo) => {
        setFormField({ brandExists: v });
        if (v === 'No') setFormField({ brand: undefined });
      }}
    >
      <FormControl as="fieldset" isInvalid={errMsgs.length > 0 && touched}>
        <Stack spacing={3}>
          <Card title="Brand" maxW="12rem">
            <ButtonRadioGroup
              name="brand"
              isDisabled={!!currentValue.brandOther}
              value={currentValue.brand}
              options={Object.values(ta.Brand)}
              onChange={(v: string) => setFormField({ brand: v })}
            />
          </Card>
          <OtherTextInput
            value={currentValue.brandOther ?? ''}
            onChange={(e) => setFormField({ brandOther: e.target.value })}
          />
        </Stack>
        <ErrorMessage errMsgs={errMsgs} />
        <FormHelperText>Choose one or type in a value.</FormHelperText>
      </FormControl>
    </YesNoQuestion>
  );
};

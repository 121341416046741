import { FormControl, VStack, Heading, FormHelperText } from '@chakra-ui/react';
import React from 'react';
import { ButtonRadioGroup } from '../general';

export enum SupplyInventorySewingCategories {
  Velcro = 'Velcro',
  'Sewing Tool' = 'Sewing Tool',
  Storage = 'Storage',
  Interfacing = 'Interfacing',
  Other = 'Other'
}

interface SewingCategoryProps {
  inventorySupplyObject: any;
  setInventorySupplyObject: (inventorySupplyObject: object) => void;
}

export const SewingCategory: React.FC<SewingCategoryProps> = ({
  inventorySupplyObject,
  setInventorySupplyObject
}) => {
  return (
    <FormControl as="fieldset">
      <VStack spacing="8" alignItems="left">
        <Heading>Sewing Category</Heading>
        <ButtonRadioGroup
          name="sewingCategory"
          options={Object.values(SupplyInventorySewingCategories)}
          value={inventorySupplyObject.sewingCategory}
          buttonProps={{ minW: '12rem' }}
          isDisabled={
            inventorySupplyObject.everythingElseCategory === 'Sewing' || null
              ? false
              : true
          }
          onChange={(v) => {
            setInventorySupplyObject({
              ...inventorySupplyObject,
              sewingCategory: v
            });
          }}
        />
        {inventorySupplyObject.everythingElseCategory === 'Sewing' || null ? (
          <></>
        ) : (
          <FormHelperText style={{ color: 'red' }}>
            Change Category to Sewing to select a Sewing Category Option
          </FormHelperText>
        )}
        <FormHelperText>Choose One</FormHelperText>
      </VStack>
    </FormControl>
  );
};

import { FormControl, VStack, Heading, FormHelperText } from '@chakra-ui/react';
import React from 'react';
import { OtherTextInput } from '../general';
import { CheckboxButton } from '../general/ButtonCheckbox';

interface StartOrUpdateBoxProps {
  inventorySupplyObject: any;
  setInventorySupplyObject: (inventorySuppliesObject: object) => void;
}

export const StartOrUpdateBox: React.FC<StartOrUpdateBoxProps> = ({
  inventorySupplyObject,
  setInventorySupplyObject
}) => {
  return (
    <FormControl as="fieldset">
      <VStack spacing="8" alignItems="center">
        <Heading>Start New Box</Heading>
        <CheckboxButton
          value={inventorySupplyObject.isNewBox}
          onChange={() => {
            if (inventorySupplyObject.isNewBox === 'no') {
              const updatedInventorySupplyObject = {
                ...inventorySupplyObject,
                isNewBox: 'yes'
              };
              setInventorySupplyObject(updatedInventorySupplyObject);
            } else if (inventorySupplyObject.isNewBox === 'yes') {
              const updatedInventorySupplyObject = {
                ...inventorySupplyObject,
                isNewBox: 'no'
              };
              setInventorySupplyObject(updatedInventorySupplyObject);
            }
          }}
          isDisabled={inventorySupplyObject.boxID !== '' ? true : false}
        >
          New Box?
        </CheckboxButton>
        <Heading>Or</Heading>
        <Heading>Update Box ID</Heading>
        {inventorySupplyObject.isNewBox === 'yes' ? (
          <FormHelperText style={{ color: 'red' }}>
            To update Box ID, unselect "New Box" button
          </FormHelperText>
        ) : (
          <></>
        )}
        <OtherTextInput
          placeholder="Box ID"
          value={inventorySupplyObject.boxID}
          isDisabled={inventorySupplyObject.isNewBox === 'yes'}
          onChange={(e) => {
            const updatedInventorySupplyObject = {
              ...inventorySupplyObject,
              boxID: e.target.value.toUpperCase()
            };
            setInventorySupplyObject(updatedInventorySupplyObject);
          }}
        />
      </VStack>
      <FormHelperText>Type in a value</FormHelperText>
    </FormControl>
  );
};

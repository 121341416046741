import { FormControl, FormHelperText, VStack } from '@chakra-ui/react';
import React from 'react';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import {
  ImageRadioGroup,
  ImageRadioButton,
  OtherTextInput,
  ErrorMessage,
  YesNoQuestion
} from '../../../general';
import { YesNo } from '../../../../types/formTypes';
import {
  getNestedValidationError,
  isTouched
} from '../../../../utils/validationUtils';
import { TrimAttributes as tra } from 'product-validator';
import * as DESIGN_IMAGES from '../../../../images/patterns';
import * as SHAPE_IMAGES from '../../../../images/trim/shape';

const patternSrc: { [k in keyof typeof tra.Design]: string } = {
  'Polka Dots': DESIGN_IMAGES.polka_dots,
  Stripes: DESIGN_IMAGES.stripes,
  Floral: DESIGN_IMAGES.floral,
  Abstract: DESIGN_IMAGES.abstract,
  Paisley: DESIGN_IMAGES.paisley
};

const trimSrc: { [k in keyof typeof tra.Shape]: string } = {
  Fringe: SHAPE_IMAGES.fringe_shape,
  'Pom Poms': SHAPE_IMAGES.pompom_shape,
  Ruffles: SHAPE_IMAGES.ruffle_shape,
  Scallops: SHAPE_IMAGES.scallop_shape,
  Tassels: SHAPE_IMAGES.tassel_shape
};

export const DesignShapeField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  const designErrMsgs = getNestedValidationError('design', validationErrors);
  const designTouched = isTouched(
    currentValue.design,
    currentValue.designOther
  );

  const TrimDesign = (
    <FormControl
      as="fieldset"
      isInvalid={designErrMsgs.length > 0 && designTouched}
    >
      <VStack spacing="3" maxW="36.2rem" alignItems="left">
        <ImageRadioGroup
          name="Trim Design"
          value={currentValue.design}
          onChange={(v) => {
            setFormField({ design: v as tra.Design });
          }}
        >
          {Object.values(tra.Design).map((v) => (
            <ImageRadioButton
              value={v}
              key={v}
              image={patternSrc[v as tra.Design]}
              isDisabled={!!currentValue.designOther}
            />
          ))}
        </ImageRadioGroup>
        <OtherTextInput
          onChange={(e) => {
            setFormField({
              designOther: e.target.value
            });
          }}
          value={currentValue.designOther ?? ''}
        />
        <FormHelperText>Select one or enter in another.</FormHelperText>
        <ErrorMessage errMsgs={designErrMsgs} />
      </VStack>
    </FormControl>
  );

  const shapeErrMsgs = getNestedValidationError('shape', validationErrors);
  const shapeTouched = isTouched(currentValue.shape, currentValue.shapeOther);

  const TrimShape = (
    <FormControl
      as="fieldset"
      isInvalid={shapeErrMsgs.length > 0 && shapeTouched}
    >
      <VStack spacing="3" maxW="36.2rem" alignItems="left">
        <ImageRadioGroup
          name="Trim Shape"
          value={currentValue.shape}
          onChange={(v) => {
            setFormField({ shape: v as tra.Shape });
          }}
        >
          {Object.values(tra.Shape).map((v) => (
            <ImageRadioButton
              value={v}
              key={v}
              image={trimSrc[v as tra.Shape]}
              isDisabled={!!currentValue.shapeOther}
            />
          ))}
        </ImageRadioGroup>
        <OtherTextInput
          onChange={(e) => {
            setFormField({
              shapeOther: e.target.value
            });
          }}
          value={currentValue.shapeOther ?? ''}
        />
        <FormHelperText>Select one or enter in another.</FormHelperText>
        <ErrorMessage errMsgs={shapeErrMsgs} />
      </VStack>
    </FormControl>
  );

  return (
    <VStack>
      <YesNoQuestion
        name="designExists"
        question="Does the trim have a design?"
        value={currentValue.designExists as YesNo}
        onChange={(v: string) => {
          setFormField({ designExists: v });
          if (v === 'No')
            setFormField({ design: undefined, designOther: undefined });
        }}
      >
        {TrimDesign}
      </YesNoQuestion>
      <YesNoQuestion
        name="shapeExists"
        question="Does the trim have a shape?"
        value={currentValue.shapeExists as YesNo}
        onChange={(v) => {
          setFormField({ shapeExists: v });
          if (v === 'No')
            setFormField({ shape: undefined, shapeOther: undefined });
        }}
      >
        {TrimShape}
      </YesNoQuestion>
    </VStack>
  );
};

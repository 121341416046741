import {
  FormControl,
  FormLabel,
  FormHelperText,
  Heading,
  Box
} from '@chakra-ui/react';
import React from 'react';
import { ZipperAttributes as za } from 'product-validator';
import { ButtonRadioGroup } from '../../../general/ButtonRadio';
import { AppFieldComponent } from '../../../../types/appFieldTypes';

export const ZipperHardwareSellingUnitField: AppFieldComponent = ({
  setFormField,
  currentValue,
  toNextPage
}) => {
  return (
    <Box maxW="30rem">
      <FormControl as="fieldset">
        <FormLabel as="legend" mb="1.5rem">
          <Heading>Type</Heading>
        </FormLabel>
        <ButtonRadioGroup
          name="Zipper Hardware Selling Unit"
          value={currentValue.zipperHardwareSU}
          options={Object.values(za.ZipperHardwareSU)}
          onChange={(v) => {
            setFormField({
              zipperHardwareSU: v as za.ZipperHardwareSU
            });
            toNextPage({ navOnNextFormUpdate: true });
          }}
        />
        <FormHelperText>Choose one.</FormHelperText>
      </FormControl>
    </Box>
  );
};

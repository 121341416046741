import {
  FormControl,
  FormLabel,
  FormHelperText,
  VStack,
  Heading,
  CheckboxGroup
} from '@chakra-ui/react';
import React from 'react';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import {
  WrappedInputGroup,
  CheckboxButton,
  OtherTextInput,
  ErrorMessage
} from '../../../general';
import {
  getNestedValidationError,
  isTouched
} from '../../../../utils/validationUtils';
import { TrimAttributes as tra } from 'product-validator';

export const TrimContentField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  const trimContentErrMsgs = getNestedValidationError(
    'trimContent',
    validationErrors
  );
  const trimContentTouched = isTouched(
    currentValue.trimContent,
    currentValue.trimContentOther
  );

  return (
    <FormControl
      as="fieldset"
      isInvalid={trimContentErrMsgs.length > 0 && trimContentTouched}
    >
      <FormLabel as="legend">
        <Heading>Trim Content</Heading>
      </FormLabel>
      <VStack spacing="3" alignItems="left" maxW="40rem">
        <FormHelperText>Select up to 2</FormHelperText>
        <CheckboxGroup
          value={currentValue.trimContent}
          onChange={(contentArr: string[]) => {
            return setFormField({
              trimContent: contentArr
            });
          }}
        >
          <WrappedInputGroup maxW="43rem">
            {Object.values(tra.Content).map((value) => (
              <CheckboxButton
                key={value}
                fontSize={`${
                  value === tra.Content['Poly/Spandex Blend'] ? 1 : 1.125
                }em`}
                isDisabled={
                  currentValue.trimContent &&
                  (currentValue.trimContent.length >= 2 ||
                    (currentValue.trimContent.length >= 1 &&
                      !!currentValue.trimContentOther)) &&
                  !currentValue.trimContent.includes(value)
                }
                value={value}
              >
                {value}
              </CheckboxButton>
            ))}
          </WrappedInputGroup>
        </CheckboxGroup>
        <OtherTextInput
          onChange={(e) => setFormField({ trimContentOther: e.target.value })}
          isDisabled={
            currentValue.trimContent && currentValue.trimContent.length >= 2
          }
          value={currentValue.trimContentOther ?? ''}
        />
        <FormHelperText>Select one or enter in another.</FormHelperText>
        <ErrorMessage errMsgs={trimContentErrMsgs} />
      </VStack>
    </FormControl>
  );
};

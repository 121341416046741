import {
  CheckboxGroup,
  FormControl,
  FormHelperText,
  Stack
} from '@chakra-ui/react';
import React from 'react';
import { ProductTypes } from 'product-validator';
import { IndexedAppFieldComponentProps } from '../../../types/appFieldTypes';
import {
  getNestedValidationError,
  isTouched
} from '../../../utils/validationUtils';
import {
  Card,
  CheckboxButton,
  WrappedInputGroup,
  OtherTextInput,
  ErrorMessage
} from '../../general';
import update from 'immutability-helper';
import { fieldOptions } from '../../../types/fieldOptionsTypes';

export const ImperfectionField: React.FC<IndexedAppFieldComponentProps> = ({
  setFormField,
  currentValue,
  validationErrors,
  idx = 0
}) => {
  const imperfectionErrMsgs = getNestedValidationError(
    'imperfections',
    validationErrors
  );
  const imperfectionTouched = isTouched(
    currentValue.imperfections,
    currentValue.imperfectionOther
  );

  var options =
    fieldOptions[currentValue?.productType ?? ProductTypes.Fabric]
      .imperfections;

  if (
    (currentValue.productType === 'Bias Tape' ||
      currentValue.productType === 'Elastic') &&
    currentValue.sellingUnit !== 'Roll'
  ) {
    options = options.filter((option) => option !== 'Partially Unrolled');
  } else if (currentValue.sellingUnit === 'Hardware') {
    options = options.filter(
      (option) => option === 'Discolouration' || option === 'Sticky'
    );
  } else if (currentValue.sellingUnit === 'Tape By The Meter') {
    options = options.filter(
      (option) => option !== 'Sticky Slider' && option !== 'Sticky'
    );
  }
  return (
    <FormControl
      as="fieldset"
      isInvalid={imperfectionErrMsgs.length > 0 && imperfectionTouched}
    >
      <Stack spacing="3">
        <Card title="Imperfections">
          <CheckboxGroup
            value={currentValue.imperfections[idx]}
            onChange={(imperfections: string[]) =>
              setFormField((oldForm) =>
                update(oldForm, {
                  imperfections: { [idx]: { $set: imperfections } }
                })
              )
            }
          >
            <WrappedInputGroup numColumns={3}>
              {options.map((value) => (
                <CheckboxButton key={value} value={value}>
                  {value}
                </CheckboxButton>
              ))}
            </WrappedInputGroup>
          </CheckboxGroup>
        </Card>
        <OtherTextInput
          onChange={(e) =>
            setFormField((oldForm) =>
              update(oldForm, {
                imperfectionOther: { [idx]: { $set: e.target.value } }
              })
            )
          }
          value={currentValue.imperfectionOther[idx] ?? ''}
        />
      </Stack>
      <ErrorMessage errMsgs={imperfectionErrMsgs} />
      <FormHelperText>Choose all that apply or enter other.</FormHelperText>
    </FormControl>
  );
};

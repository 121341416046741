import {
  FormControl,
  FormLabel,
  FormHelperText,
  VStack,
  Heading,
  Box,
  CheckboxGroup
} from '@chakra-ui/react';
import React from 'react';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import {
  ImageRadioGroup,
  ImageRadioButton,
  OtherTextInput,
  ErrorMessage,
  WrappedInputGroup,
  CheckboxButton
} from '../../../general';
import {
  getNestedValidationError,
  isTouched
} from '../../../../utils/validationUtils';
import { TrimAttributes as tra } from 'product-validator';
import * as IMAGES from '../../../../images/trim/type';
import { getTrimWeaveOptions } from '../../../../utils/supplyUtils';

const trimSrc: { [k in keyof typeof tra.TrimType]: string } = {
  Ribbon: IMAGES.ribbon_type,
  Lace: IMAGES.lace_type,
  Tape: IMAGES.tape_type,
  Cording: IMAGES.cording_type,
  Webbing: IMAGES.webbing_type,
  Piping: IMAGES.piping_type,
  'Faux Fur': IMAGES.faux_fur_type,
  Braided: IMAGES.braided_type,
  Ricrac: IMAGES.ricrac_type
};

export const TrimTypeWeaveField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  const trimTypeErrMsgs = getNestedValidationError(
    'trimType',
    validationErrors
  );
  const trimTypeTouched = isTouched(
    currentValue.trimType,
    currentValue.trimTypeOther
  );

  const weaveTypeErrMsgs = getNestedValidationError(
    'weaveType',
    validationErrors
  );
  const weaveTypeTouched = isTouched(
    currentValue.weaveType,
    currentValue.weaveTypeOther
  );

  const weaveTypeOptions = getTrimWeaveOptions(currentValue.trimType);

  const WeaveType = (
    <FormControl
      as="fieldset"
      isInvalid={weaveTypeErrMsgs.length > 0 && weaveTypeTouched}
    >
      <FormLabel as="legend">
        <Heading>Type of Weave</Heading>
        <FormHelperText>Optional</FormHelperText>
      </FormLabel>
      <VStack spacing="3" maxW="36.2rem" alignItems="left">
        <CheckboxGroup
          value={currentValue.weaveType}
          onChange={(weaveType: string[]) => {
            return setFormField({
              weaveType: weaveType
            });
          }}
        >
          <WrappedInputGroup maxW="43rem">
            {weaveTypeOptions.map((value) => (
              <CheckboxButton
                key={value}
                isDisabled={!!currentValue.weaveTypeOther}
                value={value}
              >
                {value}
              </CheckboxButton>
            ))}
          </WrappedInputGroup>
        </CheckboxGroup>

        <OtherTextInput
          onChange={(e) => {
            setFormField({
              weaveTypeOther: e.target.value
            });
          }}
          value={currentValue.weaveTypeOther ?? ''}
        />
        <FormHelperText>Select one or enter in another.</FormHelperText>
        <ErrorMessage errMsgs={weaveTypeErrMsgs} />

        {/* <ButtonRadioGroup
          name="Trim Weave"
          value={currentValue.weaveType}
          options={getTrimWeaveOptions(currentValue.trimType)}
          buttonProps={{ maxW: '10rem' }}
          onChange={(v) => {
            setFormField({ weaveType: v as tra.Weave });
          }}
          isDisabled={!!currentValue.weaveTypeOther}
        />
        <OtherTextInput
          onChange={(e) => {
            setFormField({
              weaveTypeOther: e.target.value
            });
          }}
          value={currentValue.weaveTypeOther ?? ''}
        />
        <FormHelperText>Select one or enter in another.</FormHelperText>
        <ErrorMessage errMsgs={weaveTypeErrMsgs} /> */}
      </VStack>
    </FormControl>
  );

  return (
    <Box>
      <FormControl
        as="fieldset"
        isInvalid={trimTypeErrMsgs.length > 0 && trimTypeTouched}
      >
        <FormLabel as="legend">
          <Heading>Type</Heading>
        </FormLabel>
        <VStack spacing="3" maxW="36.2rem" alignItems="left">
          <ImageRadioGroup
            name="Trim Type"
            value={currentValue.trimType}
            onChange={(v) => {
              setFormField({ trimType: v as tra.TrimType });
            }}
          >
            {Object.values(tra.TrimType).map((v) => (
              <ImageRadioButton
                value={v}
                key={v}
                image={trimSrc[v as tra.TrimType]}
                isDisabled={!!currentValue.trimTypeOther}
              />
            ))}
          </ImageRadioGroup>
          <OtherTextInput
            onChange={(e) => {
              setFormField({
                trimTypeOther: e.target.value
              });
            }}
            value={currentValue.trimTypeOther ?? ''}
          />
          <FormHelperText>Select one or enter in another.</FormHelperText>
          <ErrorMessage errMsgs={trimTypeErrMsgs} />
        </VStack>
      </FormControl>
      {currentValue.trimType &&
        currentValue.trimType !== tra.TrimType.Webbing &&
        currentValue.trimType !== tra.TrimType['Faux Fur'] &&
        currentValue.trimType !== tra.TrimType.Braided &&
        currentValue.trimType !== tra.TrimType.Ricrac &&
        WeaveType}
    </Box>
  );
};

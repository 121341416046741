import { FormControl, FormHelperText, VStack } from '@chakra-ui/react';
import React from 'react';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { YesNo } from '../../../../types/formTypes';
import {
  YesNoQuestion,
  OtherTextInput,
  ErrorMessage,
  Card,
  ImageRadioGroup,
  ImageRadioButton
} from '../../../general';
import {
  getNestedValidationError,
  isTouched
} from '../../../../utils/validationUtils';
import { ButtonProductAttributes as ba } from 'product-validator';
import * as IMAGES from '../../../../images/patterns';

const patternSrc: { [k in keyof typeof ba.Design]: string } = {
  Engraved: IMAGES.engraved,
  'Polka Dots': IMAGES.bpolka_dot,
  Stripes: IMAGES.bstripes,
  Floral: IMAGES.bfloral,
  Abstract: IMAGES.babstract,
  Paisley: IMAGES.bpaisley,
  Sparkles: IMAGES.bsparkles,
  Marbled: IMAGES.bmarbled,
  Embossed: IMAGES.bembossed
};

export const ButtonDesignField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  const buttonDesignErrMsgs = getNestedValidationError(
    'design',
    validationErrors
  );

  const buttonDesignTouched = isTouched(
    currentValue.design,
    currentValue.designOther
  );

  return (
    <FormControl
      as="fieldset"
      isInvalid={buttonDesignErrMsgs.length > 0 && buttonDesignTouched}
    >
      <YesNoQuestion
        name="design"
        question={`Does the button have a design?`}
        value={currentValue.designExists as YesNo}
        onChange={(v) => {
          setFormField({ designExists: v });
          if (v === 'No') setFormField({ design: undefined });
        }}
      >
        <VStack spacing="8" alignItems="left">
          <Card title="DESIGN" maxW="45rem">
            <ImageRadioGroup
              name="Button Design"
              value={currentValue.design}
              onChange={(v) => {
                setFormField({ design: v as ba.Design });
              }}
            >
              {Object.values(ba.Design).map((v) => (
                <ImageRadioButton
                  value={v}
                  key={v}
                  image={patternSrc[v as ba.Design]}
                  isDisabled={!!currentValue.designOther}
                />
              ))}
            </ImageRadioGroup>
          </Card>
          <OtherTextInput
            onChange={(e) =>
              setFormField({
                designOther: e.target.value
              })
            }
            value={currentValue.designOther ?? ''}
          />
        </VStack>

        <ErrorMessage errMsgs={buttonDesignErrMsgs} />
        <FormHelperText>Choose one or type in a value.</FormHelperText>
      </YesNoQuestion>
    </FormControl>
  );
};

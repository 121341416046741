import {
  Fabric,
  getFabricContent,
  ProductTypes,
  FabricAttributes as fa
} from 'product-validator';

export const getIn = (obj: any, path: string) => {
  let curr = obj;
  for (const k of path.split('.')) {
    if (curr && curr[k]) curr = curr[k];
    else {
      curr = undefined;
      break;
    }
  }
  return curr;
};

export const getValidImage = (images?: string[], index = 0) => {
  return images && images.length > 0 ? images[index] : '';
};

/** simple util to convert product types to be used in field questions */
export const strigifyProductType = (productType?: ProductTypes) => {
  if (!productType) return 'product';

  switch (productType) {
    case ProductTypes.Zippers:
      return 'zipper';
    default:
      return productType.toLowerCase();
  }
};

export const getContent = (content?: Fabric['content']) => {
  try {
    if (content) return getFabricContent(content);
  } catch (err) {
    return undefined;
  }
};

/*
  THESE SAME CONSTANTS ARE FOUND IN THE SERVER utils.ts FILE
  IF NEED TO CHANGE THEN REMEMBER TO CHANGE THEM IN BOTH PLACES
*/
export const ECO_TAGS: readonly string[] = [
  fa.ContentSpecific['Rayon From Bamboo'],
  fa.ContentSpecific.Hemp,
  fa.ContentSpecific.Linen,
  fa.ContentSpecific.Modal,
  fa.ContentSpecific.Tencel,
  fa.ContentSpecific.Lyocell,
  fa.ContentSpecific['Organic Cotton']
];

export const SPECIALTY_FABRICS: readonly string[] = [
  fa.ContentSpecific.Wool,
  fa.ContentSpecific.Silk,
  fa.ContentSpecific.Cashmere
];

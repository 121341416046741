import React, { useRef } from 'react';
import { Button, Heading, Image, Box } from '@chakra-ui/react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';

interface PropTypes {
  id: string;
  index: number;
  imgSrc: string;
  mainImgIdx?: number;
  viewImage?: (index: number) => void;
  moveImage: (dragIndex: number, hoverIndex: number) => void;
  deleteHandler: (index: number) => void;
}

interface DragItem {
  id: string;
  index: number;
  type: string;
  name: string;
}

// https://react-dnd.github.io/react-dnd/examples/sortable/simple
export const ImageGridItem: React.FC<PropTypes> = ({
  id,
  index,
  imgSrc,
  mainImgIdx,
  viewImage,
  moveImage,
  deleteHandler
}) => {
  const ref = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, drop] = useDrop({
    accept: 'card',
    drop(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      moveImage(dragIndex, hoverIndex);

      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: 'card',
      id: id,
      index: index,
      name: `Image ${index + 1}`
    } as DragItem,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0.5 : 1;
  drag(drop(ref));
  return (
    <Box
      padding="0"
      opacity={opacity}
      ref={ref}
      height="100%"
      key={index}
      align="right"
    >
      <Button
        top="1em"
        left="1em"
        aria-label="Delete image"
        size="sm"
        onClick={deleteHandler.bind(null, index)}
        colorScheme="red"
        borderRadius={25}
      >
        X
      </Button>

      <Box w={['200px', '220px']} h={['150px', '180px']}>
        <Image
          onClick={viewImage?.bind(null, index)}
          boxShadow={'lg'}
          border={index === mainImgIdx ? '0.3em' : '0'}
          borderStyle="solid"
          borderColor="blue.300"
          maxW={['200px', '220px']}
          maxH={['150px', '180px']}
          src={imgSrc}
        />
      </Box>
      <Heading marginTop="0.3em" textAlign="left" size="md">{`Image ${
        index + 1
      }`}</Heading>
    </Box>
  );
};

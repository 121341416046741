import {
  ButtonProps,
  useCheckbox,
  useCheckboxGroupContext
} from '@chakra-ui/react';
import React from 'react';
import { InputButton } from './InputButton';

interface CheckboxButtonProps extends ButtonProps {
  value: string;
  isDisabled?: boolean;
  children: React.ReactNode;
}

export const CheckboxButton = ({
  value,
  isDisabled,
  ...rest
}: CheckboxButtonProps) => {
  const group = useCheckboxGroupContext();
  const checkboxProps = useCheckbox({
    value: value,
    onChange: group?.onChange,
    isChecked: group && group?.value?.includes(value),
    isDisabled: isDisabled
  });
  return InputButton({
    getCheckboxProps: checkboxProps.getCheckboxProps,
    getInputProps: checkboxProps.getInputProps,
    ...rest
  });
};

import React from 'react';
import { FormState } from '../../FormStateContainer';
import { FormFieldState } from '../../../../types/formTypes';
import { Card, SwitchInput } from '../../../general';
import { ButtonProductAttributes as ba } from 'product-validator';

export interface ButtonOpaquenessFieldComponentProps {
  setFormField: FormState['setFormField'];
  currentValue: FormFieldState;
}

export const ButtonOpaquenessField: React.FC<ButtonOpaquenessFieldComponentProps> = ({
  setFormField,
  currentValue
}) => {
  return (
    <Card title="Optional" maxW="12rem">
      <SwitchInput
        label="Translucent"
        isChecked={
          currentValue.buttonOpaqueness
            ? currentValue.buttonOpaqueness === ba.ButtonOpaqueness.Translucent
            : false
        }
        onChange={() => {
          setFormField((oldForm) => {
            const newForm = { ...oldForm };
            if (oldForm.buttonOpaqueness === ba.ButtonOpaqueness.Translucent)
              newForm.buttonOpaqueness = '';
            else newForm.buttonOpaqueness = ba.ButtonOpaqueness.Translucent;
            return newForm;
          });
        }}
      />
      <SwitchInput
        label="Transparent"
        isChecked={
          currentValue.buttonOpaqueness
            ? currentValue.buttonOpaqueness === ba.ButtonOpaqueness.Transparent
            : false
        }
        onChange={() => {
          setFormField((oldForm) => {
            const newForm = { ...oldForm };
            if (oldForm.buttonOpaqueness === ba.ButtonOpaqueness.Transparent)
              newForm.buttonOpaqueness = '';
            else newForm.buttonOpaqueness = ba.ButtonOpaqueness.Transparent;
            return newForm;
          });
        }}
      />
    </Card>
  );
};

import {
  FormControl,
  FormLabel,
  FormHelperText,
  VStack,
  Heading
} from '@chakra-ui/react';
import React from 'react';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import {
  ButtonRadioGroup,
  OtherTextInput,
  ErrorMessage
} from '../../../general';
import {
  getNestedValidationError,
  isTouched
} from '../../../../utils/validationUtils';
import { ThreadAttributes as ta } from 'product-validator';

export const ThreadTypeField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  const threadTypeErrMsgs = getNestedValidationError(
    'threadType',
    validationErrors
  );

  const threadTypeTouched = isTouched(
    currentValue.threadType,
    currentValue.threadTypeOther
  );

  return (
    <FormControl
      as="fieldset"
      isInvalid={threadTypeErrMsgs.length > 0 && threadTypeTouched}
    >
      <FormLabel as="legend">
        <Heading>Type</Heading>
      </FormLabel>
      <VStack spacing="3" alignItems="left">
        <ButtonRadioGroup
          name="Thread Type"
          value={currentValue.threadType}
          options={Object.values(ta.ThreadType)}
          isDisabled={!!currentValue.threadTypeOther}
          onChange={(v) => setFormField({ threadType: v as ta.ThreadType })}
        />
        <OtherTextInput
          onChange={(e) => {
            setFormField({
              threadTypeOther: e.target.value
            });
          }}
          value={currentValue.threadTypeOther ?? ''}
        />
        <FormHelperText>Select one or enter in another.</FormHelperText>
        <ErrorMessage errMsgs={threadTypeErrMsgs} />
      </VStack>
    </FormControl>
  );
};

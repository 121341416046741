import React from 'react';
import {
  Link as RouterLink,
  useRouteMatch,
  useHistory
} from 'react-router-dom';
import {
  Box,
  Center,
  Divider,
  Heading,
  Link,
  Stack,
  VStack,
  Button,
  useToast,
  HStack
} from '@chakra-ui/react';
import { authLogout } from '../../api/auth';
import { clearAuth } from '../../utils/authSession';

interface SideBarProps {
  links: EntryLinkProps[];
}

interface EntryLinkProps {
  label: string;
  to: string;
  disabled?: boolean;
}

const EntryLink: React.FC<EntryLinkProps> = ({
  label,
  to,
  disabled = false
}) => {
  const match = useRouteMatch({ path: to, exact: true });
  if (disabled) return null;

  const selectedProps = match
    ? {
        bgColor: 'purple.200',
        borderLeftWidth: '0.25rem',
        borderLeftColor: 'purple.500'
      }
    : undefined;
  return (
    <Box w="100%" borderRadius="md" padding="2" {...selectedProps}>
      <Heading as="h6" size="xs">
        <Link as={RouterLink} to={to}>
          {label}
        </Link>
      </Heading>
    </Box>
  );
};

export const SideBar: React.FC<SideBarProps> = ({ links }) => {
  const history = useHistory();
  const messageToast = useToast();

  const logoutHandler = async () => {
    const response = await authLogout();
    if (response.status === 200) {
      clearAuth();
      history.push('/login');
    } else {
      messageToast({
        title: 'An error occured.',
        description: 'Unable to Logout',
        status: 'error',
        position: 'top-right',
        duration: 9000,
        isClosable: true
      });
    }
  };

  return (
    <VStack
      alignItems="left"
      minH="100%"
      bg="purple.50"
      overflow="auto"
      justifyContent="start"
    >
      <Center>
        <Heading paddingTop="2" size="lg">
          FABCYCLE
        </Heading>
      </Center>
      <Center>
        <Divider
          w="80%"
          borderBottomWidth="0.1rem"
          borderBottomColor="purple.200"
        />
      </Center>
      <Stack px="4" spacing="1">
        {links.map((link) => (
          <EntryLink {...link} key={link.label} />
        ))}
      </Stack>
      <HStack flexGrow={1} alignItems="flex-end" p="2">
        <Button colorScheme="purple" onClick={logoutHandler}>
          Sign Out
        </Button>
        <Link as={RouterLink} to="/">
          <Button>Home</Button>
        </Link>
      </HStack>
    </VStack>
  );
};

import { FormControl, Heading, VStack, FormHelperText } from '@chakra-ui/react';
import React from 'react';
import { ButtonRadioGroup } from '../general';

export enum EverythingElseCategories {
  Sewing = 'Sewing',
  Craft = 'Craft',
  Other = 'Other'
}

interface EverythingElseCategoryProps {
  inventorySupplyObject: any;
  setInventorySupplyObject: (inventorySupplyObject: object) => void;
}

export const EverythingElseCategory: React.FC<EverythingElseCategoryProps> = ({
  inventorySupplyObject,
  setInventorySupplyObject
}) => {
  return (
    <FormControl as="fieldset">
      <VStack spacing="8" alignItems="center">
        <Heading>Everything Else Category</Heading>
        <ButtonRadioGroup
          name="category"
          isVertical={true}
          options={Object.values(EverythingElseCategories)}
          value={inventorySupplyObject.everythingElseCategory}
          isDisabled={
            inventorySupplyObject.supplyType !== 'Everything Else'
              ? true
              : false
          }
          onChange={(v) => {
            setInventorySupplyObject({
              ...inventorySupplyObject,
              everythingElseCategory: v
            });
          }}
        />
        {inventorySupplyObject.supplyType !== 'Everything Else' ? (
          <FormHelperText style={{ color: 'red' }}>
            Change Supply Type to Everything Else to select a Category
          </FormHelperText>
        ) : (
          <></>
        )}
      </VStack>
    </FormControl>
  );
};

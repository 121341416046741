import { MergedProduct } from '../../../../types/formTypes';
import { TrimAttributes as tra } from 'product-validator';
import { TRIM_PRICE_LOOKUP } from './trimLookup';

const getTrimPrice = (trimType: string, qualities?: string[]): number => {
  let price = 0;
  let trimIndex = trimType as tra.TrimType | 'Other';
  if (!(trimType in tra.TrimType)) trimIndex = 'Other';

  if (qualities && qualities.length > 0) {
    let qualityIndex: tra.Qualities | 'Other' | 'None';
    let lookUp = 0;
    qualities.forEach((quality) => {
      qualityIndex = quality as tra.Qualities | 'Other' | 'None';
      if (!(quality in TRIM_PRICE_LOOKUP[trimIndex])) qualityIndex = 'Other';

      lookUp = TRIM_PRICE_LOOKUP[trimIndex][qualityIndex] ?? 0;
      price = lookUp > price ? lookUp : price;
    });
    if (qualities.length > 1) price += (qualities.length - 1) * 0.1;
  } else price = TRIM_PRICE_LOOKUP[trimIndex]['None'] ?? 0;

  return price;
};

export const trimPriceSuggestion = (
  sellingUnit: tra.SellingUnit,
  widthIn: number,
  trimType?: string,
  qualities?: string[],
  content?: string[],
  shape?: string,
  weave?: string,
  imperfections?: string[],
  lengthM?: number,
  packSize?: number
): number => {
  let price = 0;

  if (trimType && qualities) price = getTrimPrice(trimType, qualities);

  if (content?.includes(tra.Content.Leather)) price += 0.5;
  if (content?.includes(tra.Content.Silk)) price += 0.5;

  if (shape === tra.Shape.Fringe || shape === tra.Shape.Tassels) price += 0.5;

  if (weave === tra.Weave.Crochet) price += 0.5;
  else if (weave === tra.Weave.Braided || weave === tra.Weave.Looped)
    price += 0.2;

  if (widthIn > 6) price += 2;
  else if (widthIn > 4) price += 1;
  else if (widthIn > 2) price += 0.5;
  else if (widthIn > 1) price += 0.25;

  if (lengthM) {
    if (sellingUnit !== tra.SellingUnit['By Meter']) price *= lengthM;
    if (sellingUnit === tra.SellingUnit['Roll']) {
      if (lengthM >= 30) price *= 0.6;
      else if (lengthM >= 11) price *= 0.7;
      else price *= 0.8;
    }
    if (sellingUnit === tra.SellingUnit.Pack) {
      if (packSize) price = price * packSize * 0.9;
    }
  }

  if (imperfections && imperfections.length > 0) price *= 0.8;

  // Round to nearest 5 cents
  price = Math.ceil(price * 20) / 20;

  return price;
};

export const trimPriceSuggestionFromForm = (trim: MergedProduct) => {
  if (trim.sellingUnit) {
    let lengthM = Number(trim.lengthM);
    let widthIn = Number(trim.widthIn);
    let packSize = Number(trim.packSize);

    return trimPriceSuggestion(
      trim.sellingUnit as tra.SellingUnit,
      isNaN(widthIn) ? 1 : widthIn,
      trim.trimType,
      trim.qualities,
      trim.trimContent,
      trim.shape,
      trim.weaveType?.[0],
      trim.imperfections?.[0],
      isNaN(lengthM) ? undefined : lengthM,
      isNaN(packSize) ? undefined : packSize
    );
  }

  return undefined;
};

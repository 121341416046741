import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import React, { useCallback, useEffect, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

export type toNextPage = (params?: {
  goBackwards?: boolean;
  navOnNextFormUpdate?: boolean;
}) => void;

type linkGenerator = (
  currentPath: string
) => { prevPath: string; nextPath: string };

interface NextFieldNavigatorProps {
  children: ((toNextPage: toNextPage) => JSX.Element) | React.ReactNode;
  linkGenerator: linkGenerator;
}

export const NextFieldNavigator: React.FC<NextFieldNavigatorProps> = ({
  linkGenerator,
  children
}) => {
  const match = useRouteMatch();
  const history = useHistory();
  const navOnNextUpdateRef = useRef<boolean>(false);

  const toNextPage: toNextPage = useCallback(
    (params) => {
      const goBackwards = params?.goBackwards ?? false;
      const navOnNextFormUpdate = params?.navOnNextFormUpdate ?? false;

      // if navOnNextFormUpdate, then wait till form updates before navigating
      if (navOnNextFormUpdate) {
        navOnNextUpdateRef.current = true;
        return;
      }

      const splitPath = match.path.split('/');
      const nav = linkGenerator(splitPath[splitPath.length - 1]);

      if (goBackwards && nav.prevPath !== '') history.push(nav.prevPath);
      else if (!goBackwards && nav.nextPath !== '') history.push(nav.nextPath);
    },
    [history, match.path, linkGenerator]
  );

  useEffect(() => {
    if (navOnNextUpdateRef.current) {
      toNextPage();
      navOnNextUpdateRef.current = false;
    }
  }, [toNextPage]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') toNextPage();
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [toNextPage]);

  return (
    <Box>
      {children instanceof Function ? children(toNextPage) : children}
      <HStack pb="3rem" alignItems="flex-end">
        <Button
          marginTop="6"
          leftIcon={<ArrowBackIcon />}
          colorScheme="orange"
          onClick={() => toNextPage({ goBackwards: true })}
        />

        <Button
          marginTop="6"
          rightIcon={<ArrowForwardIcon />}
          colorScheme="orange"
          onClick={() => toNextPage()}
        >
          Next
        </Button>
        <Text fontSize="xs">Or press enter</Text>
      </HStack>
    </Box>
  );
};

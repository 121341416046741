import React from 'react';
import { ProductTypes, OtherSupplyAttributes as osa } from 'product-validator';
import { AppFieldComponent } from '../../../types/appFieldTypes';
import {
  FormControl,
  FormHelperText,
  Stack,
  FormLabel,
  Heading
} from '@chakra-ui/react';
import { ButtonRadioGroup, OtherTextInput, ErrorMessage } from '../../general';
import {
  getNestedValidationError,
  isTouched
} from '../../../utils/validationUtils';
import { formToProduct } from '../../../utils/formUtils';

export const PackSizeField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  const errMsgs: string[] = getNestedValidationError(
    'packSize',
    validationErrors
  );
  const touched = isTouched(currentValue.packSize, currentValue.packSizeOther);

  const getWidth = (): number => {
    const product = formToProduct(currentValue);
    return parseInt(product.widthMM ?? '');
  };

  let packSizeOptions: string[] = [];
  switch (currentValue.productType) {
    case ProductTypes.Zippers:
      packSizeOptions = ['5', '10'];
      break;
    case ProductTypes.Ribbing:
      packSizeOptions = ['4', '8'];
      break;
    case ProductTypes.Buttons:
      packSizeOptions = ['2', '4', '25', '50'];
      if (currentValue.uniqueShape) packSizeOptions = ['2', '4'];
      else if (!currentValue.uniqueShape && getWidth() < 50)
        packSizeOptions.splice(packSizeOptions.indexOf('2'), 2);
      break;
    case ProductTypes.Thread:
      packSizeOptions = ['2', '4'];
      break;
    case ProductTypes.Trim:
      packSizeOptions = ['2', '4', '5'];
      break;
    case ProductTypes.Elastic:
      packSizeOptions = ['2', '4', '5', '8', '10'];
      break;
    case ProductTypes['Other Supply']:
      packSizeOptions = ['5', '10', '20'];
      break;
  }

  const packSizeType = (
    <ButtonRadioGroup
      name="Pack Size Type"
      value={currentValue.packSizeType}
      options={Object.values(osa.KnownOrEstimated)}
      buttonProps={{ minW: '8rem' }}
      onChange={(v) =>
        setFormField({ packSizeType: v as osa.KnownOrEstimated })
      }
    />
  );

  return (
    <FormControl as="fieldset" isInvalid={errMsgs.length > 0 && touched}>
      <FormLabel as="legend">
        {currentValue.productType === ProductTypes['Other Supply'] &&
        currentValue.sellingUnit === osa.SellingUnit.Pack ? (
          <Heading>How many pieces are in the pack?</Heading>
        ) : (
          <Heading>Pack Size</Heading>
        )}
      </FormLabel>
      <Stack spacing={3} maxW="30rem">
        {currentValue.productType === ProductTypes['Other Supply'] &&
          currentValue.sellingUnit === osa.SellingUnit.Pack &&
          packSizeType}
        <ButtonRadioGroup
          name="packSize"
          value={currentValue.packSize}
          options={packSizeOptions}
          isDisabled={!!currentValue.packSizeOther}
          onChange={(v) => setFormField({ packSize: v })}
        >
          {(v) => `${v} Pack`}
        </ButtonRadioGroup>
        {/* {!currentValue.uniqueShape && (
          <OtherTextInput
            onChange={(e) =>
              setFormField({
                packSizeOther: e.target.value
              })
            }
            value={currentValue.packSizeOther ?? ''}
          />
        )} */}

        <OtherTextInput
          onChange={(e) =>
            setFormField({
              packSizeOther: e.target.value
            })
          }
          value={currentValue.packSizeOther ?? ''}
        />

        <ErrorMessage errMsgs={errMsgs} />
      </Stack>
      {currentValue.uniqueShape ? (
        <FormHelperText>
          Unique shapes are only available in packs of two.
        </FormHelperText>
      ) : (
        <FormHelperText>Choose one or type in a value.</FormHelperText>
      )}
    </FormControl>
  );
};

import {
  FormControl,
  FormLabel,
  Heading,
  FormHelperText,
  Flex,
  RadioGroup,
  VStack
} from '@chakra-ui/react';
import { FabricAttributes } from 'product-validator';
import React from 'react';
import { Divider, RadioButton } from '../../../general';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { GeneralContentField } from './GeneralContentField';
import { KnownContentField } from './KnownContentField';
import { SpecificContentField } from './SpecificContentField';

const HELP_TEXT = [
  'Add primary + secondary fibre',
  'Add synthetic or natural',
  'Add percentage of fibres'
];

export const ContentField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  let subform;
  const subprops = {
    setFormField,
    currentValue,
    validationErrors,
    toNextPage
  };
  switch (currentValue.content?.contentType) {
    case FabricAttributes.ContentType.EstimatedGeneral: {
      subform = <GeneralContentField {...subprops} />;
      break;
    }
    case FabricAttributes.ContentType.EstimatedSpecific: {
      subform = <SpecificContentField {...subprops} />;
      break;
    }
    case FabricAttributes.ContentType.Known: {
      subform = <KnownContentField {...subprops} />;
      break;
    }
    default: {
      subform = undefined;
      break;
    }
  }

  return (
    <Flex direction="column">
      <FormControl as="fieldset">
        <FormLabel as="legend" textAlign="center">
          <Heading>What is the fabric content?</Heading>
        </FormLabel>
        <RadioGroup
          value={currentValue.content?.contentType}
          onChange={(v) => {
            return setFormField({
              content: {
                ...currentValue.content,
                contentType: v as FabricAttributes.ContentType
              }
            });
          }}
        >
          <Flex wrap="wrap" justifyContent="center" sx={{ gap: '1rem' }}>
            {Object.values(FabricAttributes.ContentType).map((value, index) => (
              <VStack key={value}>
                <RadioButton value={value} w="12rem">
                  {value}
                </RadioButton>
                <FormHelperText textAlign="center">
                  {HELP_TEXT[index]}
                </FormHelperText>
              </VStack>
            ))}
          </Flex>
        </RadioGroup>
        {subform ? (
          <>
            <Divider />
            {subform}
          </>
        ) : undefined}
      </FormControl>
    </Flex>
  );
};

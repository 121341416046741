import {
  FormControl,
  FormLabel,
  FormHelperText,
  VStack,
  Heading,
  CheckboxGroup
} from '@chakra-ui/react';
import React from 'react';
import { AppFieldComponent } from '../../../types/appFieldTypes';
import {
  WrappedInputGroup,
  CheckboxButton,
  OtherTextInput,
  ErrorMessage
} from '../../general';
import {
  getNestedValidationError,
  isTouched
} from '../../../utils/validationUtils';
import { getTrimQualityOptions } from '../../../utils/supplyUtils';
import {
  ProductTypes,
  ElasticAttributes as ea,
  BiasTapeAttributes as bta
} from 'product-validator';

export const QualitiesField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  const qualitiesErrMsgs = getNestedValidationError(
    'qualities',
    validationErrors
  );
  const qualitiesTouched = isTouched(
    currentValue.qualities,
    currentValue.qualityOther
  );

  var qualityOptions =
    currentValue.productType === ProductTypes.Trim
      ? getTrimQualityOptions(currentValue.trimType)
      : currentValue.productType === ProductTypes.Elastic
      ? Object.values(ea.Qualities)
      : Object.values(bta.Qualities);

  if (
    currentValue.productType === ProductTypes['Bias Tape'] &&
    currentValue.sellingUnit !== bta.SellingUnit.Package
  ) {
    qualityOptions = qualityOptions.filter(
      (option) => option !== bta.Qualities['Extra Wide']
    );
  }
  return (
    <FormControl
      as="fieldset"
      isInvalid={qualitiesErrMsgs.length > 0 && qualitiesTouched}
    >
      <FormLabel as="legend">
        <Heading>Qualities</Heading>
      </FormLabel>
      <VStack spacing="3" alignItems="left" maxW="40rem">
        <FormHelperText>Optional, select up to 3</FormHelperText>
        <CheckboxGroup
          value={currentValue.qualities}
          onChange={(qualityArr: string[]) => {
            return setFormField({
              qualities: qualityArr
            });
          }}
        >
          <WrappedInputGroup maxW="43rem">
            {qualityOptions.map((value) => (
              <CheckboxButton
                key={value}
                isDisabled={
                  currentValue.qualities &&
                  (currentValue.qualities.length >= 3 ||
                    (currentValue.qualities.length >= 2 &&
                      !!currentValue.qualityOther)) &&
                  !currentValue.qualities.includes(value)
                }
                value={value}
              >
                {value}
              </CheckboxButton>
            ))}
          </WrappedInputGroup>
        </CheckboxGroup>
        <OtherTextInput
          onChange={(e) => setFormField({ qualityOther: e.target.value })}
          isDisabled={
            currentValue.qualities && currentValue.qualities.length >= 3
          }
          value={currentValue.qualityOther ?? ''}
        />
        {currentValue.productType === 'Bias Tape' ? (
          <FormHelperText>Select or enter in another.</FormHelperText>
        ) : (
          <FormHelperText>Select one or enter in another.</FormHelperText>
        )}

        <ErrorMessage errMsgs={qualitiesErrMsgs} />
      </VStack>
    </FormControl>
  );
};

import {
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  VStack
} from '@chakra-ui/react';
import React from 'react';
import { ProductTypes } from 'product-validator';
import {
  ButtonRadioGroup,
  //FormFieldTemplate,
  SwitchInput
  //Card
} from '../../../general';
import { OtherSupplyAttributes as osa } from 'product-validator';
import { AppFieldComponent } from '../../../../types/appFieldTypes';

export const OtherSupplyWeight: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  const bulkySwitch = (
    <SwitchInput
      paddingTop="3"
      label="bulky?"
      isChecked={currentValue.bulky ?? false}
      onChange={() => {
        setFormField((oldForm) => {
          const newForm = { ...oldForm };
          newForm.bulky = !(newForm.bulky ?? false);
          return newForm;
        });
      }}
    />
  );

  return (
    <Flex
      flexDirection="row"
      wrap="wrap"
      gridGap="1rem"
      alignItems="flex-start"
    >
      <FormControl as="fieldset">
        <FormLabel as="legend">
          <Heading>How does the product feel?</Heading>
          {currentValue.productType === ProductTypes['Other Supply'] ? (
            <FormHelperText>(Optional)</FormHelperText>
          ) : (
            <></>
          )}
        </FormLabel>
        <VStack flexDir="column" alignItems="flex-start" spacing="3">
          <ButtonRadioGroup
            name="Weight"
            value={currentValue?.weight}
            isVertical={false}
            options={Object.values(osa.Weight)}
            onChange={(v) => setFormField({ weight: v as osa.Weight })}
          />
          {bulkySwitch}
        </VStack>
      </FormControl>

      {/* <FormFieldTemplate title="Weight">
        <>
          <ButtonRadioGroup
            name="Weight"
            value={currentValue?.weight}
            isVertical={true}
            options={Object.values(osa.Weight)}
            onChange={(v) => setFormField({ weight: v as osa.Weight })}
          />
          {bulkySwitch}
        </>
      </FormFieldTemplate> */}
    </Flex>
  );
};

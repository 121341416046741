import {
  Text,
  Stack,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  TableCellProps,
  Checkbox,
  TableColumnHeaderProps,
  CheckboxProps,
  FormLabel,
  FormHelperText,
  Heading
} from '@chakra-ui/react';
import { getNumVariants, isFieldVarianted } from 'product-validator';
import React from 'react';
import { Divider } from '../../general/Divider';
import { FormVariantableField } from '../../../types/formTypes';
import { formToProduct } from '../../../utils/formUtils';
import { AppFieldComponentProps } from '../../../types/appFieldTypes';
import { TableColumnField } from './VariantDimensionsField';
import { VariantQuantity } from './VariantQuantity';
import update from 'immutability-helper';

const NONE = '-';

type rowData = {
  [k in TableColumnField]: string;
};

const TableCell: React.FC<{ onClick?: () => void }> = (props) => {
  const tdProps: TableCellProps = { textAlign: 'center' };
  if (props.onClick) {
    tdProps['_hover'] = { bg: 'gray.50' };
    tdProps['onClick'] = props.onClick;
  }
  return <Td {...tdProps}>{props.children}</Td>;
};

const TableHead: React.FC<
  {
    onCheckBoxClick?: () => void;
    defaultChecked?: CheckboxProps['defaultChecked'];
  } & TableColumnHeaderProps
> = ({ onCheckBoxClick, defaultChecked, children, ...rest }) => {
  return (
    <Th fontSize="1xl" textAlign="center" {...rest}>
      <Text>{children}</Text>
      <Checkbox
        visibility={onCheckBoxClick ? 'visible' : 'hidden'}
        size="sm"
        defaultChecked={defaultChecked}
        onChange={onCheckBoxClick}
      >
        <Text fontWeight="thin" textTransform="none">
          apply all
        </Text>
      </Checkbox>
    </Th>
  );
};

interface VariantsTableProps
  extends Pick<AppFieldComponentProps, 'currentValue' | 'setFormField'> {
  removeRow: (idx: number) => void;
  clickCell: (cellField: TableColumnField, cellRowIdx: number) => void;
  applyAllClick: (
    column: Extract<TableColumnField, 'widthCM' | 'imperfections' | 'quantity'>
  ) => void;
}

export const VariantsRollTable: React.FC<VariantsTableProps> = ({
  currentValue,
  setFormField,
  removeRow,
  clickCell,
  applyAllClick
}) => {
  const mergedForm = formToProduct(currentValue);
  const rows: rowData[] = [];
  let roll: rowData[] = [];
  let bundles: rowData[] = [];
  const numVariants = getNumVariants(currentValue) ?? 0;
  for (let idx = 0; idx < numVariants; idx++) {
    let lengthM = mergedForm.lengthM?.[idx];
    if (!lengthM) lengthM = NONE;

    let widthCM = mergedForm.widthCM?.[0];
    if (isFieldVarianted('widthCM', currentValue.variantedFields))
      widthCM = mergedForm.widthCM?.[idx];
    if (!widthCM) widthCM = NONE;

    let imperfections = mergedForm.imperfections?.[0]?.[0];
    if (isFieldVarianted('imperfections', currentValue.variantedFields))
      imperfections = mergedForm.imperfections?.[idx]?.[0];
    if (!imperfections) imperfections = NONE;

    let quantity = mergedForm.quantity?.[0];
    if (isFieldVarianted('quantity', currentValue.variantedFields))
      quantity = mergedForm.quantity?.[idx];
    if (!quantity) quantity = NONE;

    rows.push({
      lengthM: lengthM,
      widthCM: widthCM,
      imperfections: imperfections,
      quantity: quantity
    });

    roll = rows.slice(0, 1);
    bundles = rows.slice(1);
  }

  return (
    <Stack>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th fontSize="1x1" textAlign="center">
              Length (M)
            </Th>
            <Th fontSize="1x1" textAlign="center">
              Width (CM)
            </Th>
            <Th fontSize="1x1" textAlign="center">
              Imperfections
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {roll.map((v, idx) => (
            <Tr key={idx}>
              <TableCell>{v.lengthM}</TableCell>
              <TableCell>{v.widthCM}</TableCell>
              <TableCell onClick={() => clickCell('imperfections', idx)}>
                {v.imperfections}
              </TableCell>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Divider />
      <FormLabel>
        <Heading>Bundle variants</Heading>
        <FormHelperText>Click on values to edit.</FormHelperText>
      </FormLabel>
      <Table variant="simple">
        <Thead>
          <Tr>
            <TableHead>#</TableHead>
            <TableHead>Length (M)</TableHead>
            <TableHead
              onCheckBoxClick={() => applyAllClick('widthCM')}
              defaultChecked={
                !isFieldVarianted('widthCM', currentValue.variantedFields)
              }
            >
              Width (CM)
            </TableHead>
            <TableHead
              onCheckBoxClick={() => applyAllClick('quantity')}
              defaultChecked={
                !isFieldVarianted('quantity', currentValue.variantedFields)
              }
            >
              Quantity
            </TableHead>
            <TableHead
              onCheckBoxClick={() => applyAllClick('imperfections')}
              defaultChecked={
                !isFieldVarianted<FormVariantableField>(
                  'imperfections',
                  currentValue.variantedFields
                )
              }
            >
              Imperfections
            </TableHead>
            <TableHead />
          </Tr>
        </Thead>
        <Tbody>
          {/*The bundles start from the second element */}
          {bundles.map((v, idx) => (
            <Tr key={idx + 1}>
              <TableCell>{idx + 1}</TableCell>
              <TableCell onClick={() => clickCell('lengthM', idx + 1)}>
                {v.lengthM}
              </TableCell>
              <TableCell onClick={() => clickCell('widthCM', idx + 1)}>
                {v.widthCM}
              </TableCell>
              <TableCell onClick={() => clickCell('quantity', idx + 1)}>
                <VariantQuantity
                  quantityText={v.quantity}
                  quantityOther={currentValue.quantityOther[idx + 1]}
                  onChange={(val) => {
                    setFormField((oldForm) => {
                      return update(oldForm, {
                        quantityOther: { [idx + 1]: { $set: val } }
                      });
                    });
                  }}
                />
              </TableCell>

              <TableCell onClick={() => clickCell('imperfections', idx + 1)}>
                {v.imperfections}
              </TableCell>
              <TableCell>
                <Button onClick={() => removeRow(idx + 1)}>X</Button>
              </TableCell>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Stack>
  );
};

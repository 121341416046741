import { VStack } from '@chakra-ui/react';
import React from 'react';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { OtherSupplyAttributes as osa } from 'product-validator';
import { WidthField } from '../WidthField';
import { LengthField } from '../LengthField';
import { LengthMixedField } from '../LengthMixedField';
import { HeightField } from '../HeightField';
import { OtherSupplyDimensionDescriptionField } from './OtherSupplyDimensionDescriptionField';

const RESPONSIVE_WIDTH = { md: '25rem', lg: '36rem' };

export const OtherSupplyDimensionsField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  return (
    <VStack spacing="4" alignItems="left" minW={RESPONSIVE_WIDTH}>
      <WidthField
        currentValue={currentValue}
        setFormField={setFormField}
        validationErrors={validationErrors}
        toNextPage={toNextPage}
      />
      {currentValue.sellingUnit === osa.SellingUnit['By Meter'] ? (
        <LengthMixedField
          currentValue={currentValue}
          setFormField={setFormField}
          validationErrors={validationErrors}
          toNextPage={toNextPage}
        />
      ) : (
        <LengthField
          currentValue={currentValue}
          setFormField={setFormField}
          validationErrors={validationErrors}
          toNextPage={toNextPage}
        />
      )}

      {currentValue.sellingUnit !== osa.SellingUnit['By Meter'] ? (
        <HeightField
          currentValue={currentValue}
          setFormField={setFormField}
          validationErrors={validationErrors}
          toNextPage={toNextPage}
        />
      ) : (
        <></>
      )}
      {currentValue.sellingUnit === osa.SellingUnit['By Weight'] ? (
        <OtherSupplyDimensionDescriptionField
          currentValue={currentValue}
          setFormField={setFormField}
          validationErrors={validationErrors}
          toNextPage={toNextPage}
        />
      ) : (
        <></>
      )}
    </VStack>
  );
};

import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Text
} from '@chakra-ui/react';
import React from 'react';
import { Card } from '../../general/Card';
import { NumberInput } from '../../general/NumberInput';
import { WrappedInputGroup } from '../../general/WrappedInputGroup';
import { AppFieldComponent } from '../../../types/appFieldTypes';
import { PriceField } from '../fields/PriceField';
import update from 'immutability-helper';
import { Divider } from '../../general/Divider';
import { getNestedValidationError } from '../../../utils/validationUtils';
import { VariantableValue } from 'product-validator';
import { ProductTypes, FabricAttributes as fa } from 'product-validator';

const getVariantText = (
  idx: number,
  widthCM?: VariantableValue<number>,
  lengthM?: VariantableValue<number>
): string => {
  let text = `Variant ${idx + 1}`;
  if (!widthCM || !lengthM) return text;
  if (widthCM.length > 1)
    text = `${text} (${widthCM[idx]}CM x ${lengthM[idx]}M):`;
  else text = `${text} (${lengthM[idx]}M):`;
  return text;
};

const getRollVariantsText = (
  idx: number,
  widthCM?: VariantableValue<number>,
  lengthM?: VariantableValue<number>
): string => {
  let text = `Bundle ${idx}`;
  if (!widthCM || !lengthM) return text;
  if (widthCM.length > 1)
    text = `${text} (${widthCM[idx]}CM x ${lengthM[idx]}M):`;
  else text = `${text} (${lengthM[idx]}M):`;
  return text;
};

export const VariantPricingField: AppFieldComponent = (props) => {
  const isRollVariants =
    props.currentValue.productType === ProductTypes.Fabric &&
    props.currentValue.bundleOrRoll === fa.BundleOrRoll.Roll;

  const priceErr = getNestedValidationError('price', props.validationErrors);
  const missingFields = getNestedValidationError(
    ['bundleOrRoll', 'knitOrWoven', 'weight', 'content', 'lengthM', 'uses'],
    props.validationErrors
  );

  let prices = props.currentValue.price;
  let bundlePricesOnly = props.currentValue.price.slice(1);

  return (
    <Box>
      <PriceField {...props} />
      <Divider />
      <FormControl as="fieldset" isInvalid={priceErr.length > 0}>
        <FormLabel as="legend">
          <Heading>Calculated variant prices</Heading>
          <FormHelperText>
            click on fields to adjust pricing for individual variants
          </FormHelperText>
        </FormLabel>
        {missingFields.length > 0 ? (
          <Text>Can't calculate variant prices.</Text>
        ) : (
          <Card>
            <WrappedInputGroup direction="column">
              {isRollVariants
                ? bundlePricesOnly.map((p, idx) => (
                    <Box key={`v${idx}`} maxW="24.6rem">
                      <NumberInput
                        id={`v${idx}`}
                        stackProps={{ justifyContent: 'space-between' }}
                        text={getRollVariantsText(
                          idx + 1,
                          props.product?.widthCM as VariantableValue<number>,
                          props.product?.lengthM as VariantableValue<number>
                        )}
                        textOnLeft
                        value={p ?? undefined}
                        onChange={(v) => {
                          props.setFormField((oldForm) => {
                            return update(oldForm, {
                              price: { [idx]: { $set: v } }
                            });
                          });
                        }}
                      />
                    </Box>
                  ))
                : prices.map((p, idx) => (
                    <Box key={`v${idx}`} maxW="24.6rem">
                      <NumberInput
                        id={`v${idx}`}
                        stackProps={{ justifyContent: 'space-between' }}
                        text={getVariantText(
                          idx,
                          props.product?.widthCM as VariantableValue<number>,
                          props.product?.lengthM as VariantableValue<number>
                        )}
                        textOnLeft
                        value={p ?? undefined}
                        onChange={(v) => {
                          props.setFormField((oldForm) => {
                            return update(oldForm, {
                              price: { [idx]: { $set: v } }
                            });
                          });
                        }}
                      />
                    </Box>
                  ))}
            </WrappedInputGroup>
          </Card>
        )}
      </FormControl>
    </Box>
  );
};

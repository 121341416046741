import React from 'react';
import { Button, Center, Heading, VStack } from '@chakra-ui/react';
import { WrappedInputGroup } from '../components/general';
import { Link } from 'react-router-dom';

export const Home = () => {
  return (
    <Center w="100%" h="100%">
      <VStack>
        <Heading>Home</Heading>{' '}
        <WrappedInputGroup justifyContent="center">
          <Link to={'/product_entry/bundle_or_roll'}>
            <Button>Go To Fabric Entry</Button>
          </Link>
          <Link to={'/supplies/supply_type'}>
            <Button>Go To Supplies Entry</Button>
          </Link>
          <Link to={'/roll_to_bundle/search'}>
            <Button>Convert A Roll Into A Bundle</Button>
          </Link>
          <Link to={'/inventory_supplies/start_or_update'}>
            <Button>Inventory Supplies Entry</Button>
          </Link>
        </WrappedInputGroup>
      </VStack>
    </Center>
  );
};

import React from 'react';
import { ZipperTypeField } from './ZipperTypeField';
import { ClosureField } from './ClosureField';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { Box } from '@chakra-ui/react';

export const ZipperTypeClosureField: AppFieldComponent = (props) => {
  return (
    <Box>
      <ZipperTypeField {...props} />
      <ClosureField {...props} />
    </Box>
  );
};

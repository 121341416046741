import { VStack } from '@chakra-ui/react';
import React from 'react';
import { AppFieldComponent } from '../../../../types/appFieldTypes';
import { QuantityField } from '../QuantityField';

const RESPONSIVE_WIDTH = { md: '25rem', lg: '36rem' };

export const OtherSupplyQuantityField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  return (
    <VStack spacing="4" alignItems="left" minW={RESPONSIVE_WIDTH}>
      <QuantityField
        currentValue={currentValue}
        setFormField={setFormField}
        validationErrors={validationErrors}
        toNextPage={toNextPage}
      />
    </VStack>
  );
};

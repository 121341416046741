import React from 'react';
import { FormControl, Heading, VStack, FormHelperText } from '@chakra-ui/react';
import { ButtonRadioGroup } from '../general';

export enum TrimTypes {
  Fringe = 'Fringe',
  Ribbon = 'Ribbon',
  Lace = 'Lace',
  Tape = 'Tape',
  Cording = 'Cording',
  Webbing = 'Webbing',
  Piping = 'Piping',
  'Faux Fur' = 'Faux Fur',
  Braided = 'Braided',
  Ricrac = 'Ricrac'
}

interface InventorySupplyTrimTypeProps {
  inventorySupplyObject: any;
  setInventorySupplyObject: (inventorySupplyObject: object) => void;
}

export const TrimType: React.FC<InventorySupplyTrimTypeProps> = ({
  inventorySupplyObject,
  setInventorySupplyObject
}) => {
  return (
    <FormControl as="fieldset">
      <VStack spacing="8" alignItems="left">
        <Heading>Trim Type</Heading>
        <ButtonRadioGroup
          name="Inventory Supply Trim Type"
          options={Object.values(TrimTypes)}
          value={inventorySupplyObject.trimType}
          buttonProps={{ minW: '10rem' }}
          isDisabled={
            inventorySupplyObject.supplyType === 'Trim' ? false : true
          }
          onChange={(v) => {
            setInventorySupplyObject({ ...inventorySupplyObject, trimType: v });
          }}
        />
        <FormHelperText>Choose one</FormHelperText>
        {inventorySupplyObject.supplyType === 'Trim' ? (
          <></>
        ) : (
          <FormHelperText style={{ color: 'red' }}>
            Change Supply Type to Trim to select a Trim Type
          </FormHelperText>
        )}
      </VStack>
    </FormControl>
  );
};

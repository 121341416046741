import {
  ProductTypes,
  FabricAttributes as fa,
  ZipperAttributes as za,
  RibbingAttributes as ra,
  ButtonProductAttributes as ba,
  ThreadAttributes as ta,
  TrimAttributes as tra,
  ElasticAttributes as ea,
  BiasTapeAttributes as bta,
  OtherSupplyAttributes as osa
} from 'product-validator';

export type NestedObject = { [key: string]: { [key: string]: string } };

type fieldOptionsType = {
  [key in string]: {
    imperfections: string[];
    quality: string[] | NestedObject;
    commonUses: string[] | NestedObject;
    lengthMsg: string;
    lengthUnit: string;
    priceRequiredFields: string[];
    pattern: string[];
  };
};

export const fieldOptions: fieldOptionsType = {
  [ProductTypes.Fabric]: {
    imperfections: Object.values(fa.Imperfections),
    quality: fa.Quality,
    commonUses: fa.UsesUntagged,
    lengthMsg: 'meters',
    lengthUnit: 'length (M)',
    priceRequiredFields: [
      'bundleOrRoll',
      'knitOrWoven',
      'weight',
      'content',
      'lengthM',
      'uses'
    ],
    pattern: Object.values(fa.Pattern)
  },
  [ProductTypes.Zippers]: {
    imperfections: Object.values(za.Imperfections),
    quality: Object.values(za.Quality),
    commonUses: Object.values(za.Uses),
    lengthMsg: 'inches',
    lengthUnit: '"',
    priceRequiredFields: [
      'productType',
      'sellingUnit',
      'zipperType',
      'material',
      'lengthIn'
    ],
    pattern: []
  },
  [ProductTypes.Ribbing]: {
    imperfections: Object.values(ra.Imperfections),
    quality: {},
    commonUses: Object.values(ra.Uses),
    lengthMsg: 'centimeters',
    lengthUnit: 'length (cm)',
    priceRequiredFields: [
      'productType',
      'sellingUnit',
      'material',
      'lengthCM',
      'widthCM',
      'use'
    ],
    pattern: Object.values(ra.Pattern)
  },
  [ProductTypes['Other Supply']]: {
    imperfections: Object.values(osa.Imperfections),
    quality: {},
    commonUses: [],
    lengthMsg: 'centimeters',
    lengthUnit: 'length (cm)',
    priceRequiredFields: ['productType', 'sellingUnit', 'lengthCM', 'widthCM'],
    pattern: []
  },
  [ProductTypes['Sewing Patterns']]: {
    imperfections: [],
    quality: {},
    commonUses: [],
    lengthMsg: '',
    lengthUnit: '',
    priceRequiredFields: [],
    pattern: []
  },
  [ProductTypes.Buttons]: {
    imperfections: [],
    quality: {},
    commonUses: Object.values(ba.Uses),
    lengthMsg: '',
    lengthUnit: '',
    priceRequiredFields: [
      'productType',
      'sellingUnit',
      'widthMM',
      'material',
      'uniqueShape'
    ],
    pattern: []
  },
  [ProductTypes.Thread]: {
    imperfections: Object.values(ta.Imperfections),
    quality: {},
    commonUses: Object.values(ta.Uses),
    lengthMsg: '',
    lengthUnit: '',
    priceRequiredFields: [
      'productType',
      'sellingUnit',
      'threadType',
      'threadContent',
      'spoolSize',
      'indusWeight'
    ],
    pattern: []
  },
  [ProductTypes.Trim]: {
    imperfections: Object.values(tra.Imperfections),
    quality: {},
    commonUses: [],
    lengthMsg: 'meters',
    lengthUnit: 'length (M)',
    priceRequiredFields: [
      'productType',
      'sellingUnit',
      'widthIn',
      'lengthM',
      'trimType',
      'weaveType',
      'qualities',
      'shape',
      'trimContent',
      'imperfections'
    ],
    pattern: []
  },
  [ProductTypes.Elastic]: {
    imperfections: Object.values(ea.Imperfections),
    quality: {},
    commonUses: [],
    lengthMsg: 'meters',
    lengthUnit: 'length (M)',
    priceRequiredFields: [
      'productType',
      'sellingUnit',
      'widthIn',
      'lengthM',
      'elasticType',
      'imperfections'
    ],
    pattern: []
  },
  [ProductTypes['Bias Tape']]: {
    imperfections: Object.values(bta.Imperfections),
    quality: Object.values(bta.Qualities),
    commonUses: [],
    lengthMsg: 'meters',
    lengthUnit: 'length (M)',
    priceRequiredFields: [
      'productType',
      'sellingUnit',
      'widthIn',
      'lengthM',
      'material',
      'biasTapeType',
      'imperfections'
    ],
    pattern: []
  }
};

import { Box, FormErrorMessage } from '@chakra-ui/react';
import React from 'react';

export const ErrorMessage = ({ errMsgs }: { errMsgs?: string[] }) => {
  return (
    <Box h="1rem" marginTop="2">
      <FormErrorMessage m="0">{errMsgs ? errMsgs[0] : ''}</FormErrorMessage>
    </Box>
  );
};

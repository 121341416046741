import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './wrapped-date-picker.css';

interface DatePickerProps {
  date?: Date;
  onChange: (date: Date) => void;
}

/* 
 * For react-datepicker to display corrected, its .css must be imported.
 * Another .css file is imported to give it ChakraUI styling.
 * This component wraps the react-datepicker so that all three necessary 
   imports (DatePicker, base .css, and custom .css) can be imported as one.
*/
export const WrappedDatePicker: React.FC<DatePickerProps> = ({
  date,
  onChange
}) => {
  return <DatePicker selected={date} onChange={onChange}></DatePicker>;
};

import { Table, Thead, Tr, Th, Tbody, Td, Link } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { rollToBundleRoutes } from '../../pages/RollToBundle';

interface RollTableProps {
  name?: string;
  sku?: string;
  skuErrors?: string;
  length?: string;
  lengthErrors?: string;
}

export const BundleTable: React.FC<RollTableProps> = ({
  name,
  sku,
  skuErrors,
  length,
  lengthErrors
}) => {
  return (
    <Table size="lg" variant="simple" m="4">
      <Thead>
        <Tr>
          <Th>Field</Th>
          <Th>Value</Th>
          <Th>Errors</Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td>Name</Td>
          <Td>{name ?? '-'}</Td>
          <Td>-</Td>
        </Tr>
        <Tr backgroundColor={skuErrors && 'orange.100'}>
          <Td>
            <Link as={RouterLink} to={rollToBundleRoutes.search.to}>
              SKU
            </Link>
          </Td>
          <Td>{sku ?? '-'}</Td>
          <Td>{skuErrors}</Td>
        </Tr>
        <Tr backgroundColor={lengthErrors && 'orange.100'}>
          <Td>
            <Link as={RouterLink} to={rollToBundleRoutes.updateLength.to}>
              Length
            </Link>
          </Td>
          <Td>{length ?? '-'}</Td>
          <Td>{lengthErrors}</Td>
        </Tr>
      </Tbody>
    </Table>
  );
};

import axios from 'axios';
import { setAuth, clearAuth } from '../utils/authSession';

export const authLogin = async (email: string, password: string) => {
  try {
    const userInfo = {
      user_email: email,
      user_pass: password
    };

    const response = await axios.post('/auth/login', userInfo);
    setAuth(response.data?.expiry);

    return response;
  } catch (err) {
    return err.response;
  }
};

export const authLogout = async () => {
  try {
    const response = await axios.get('/auth/logout');
    clearAuth();
    return response;
  } catch (err) {
    return err.response;
  }
};

import {
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  VStack
} from '@chakra-ui/react';
import {
  FabricAttributes,
  ProductTypes,
  TrimAttributes as tra,
  BiasTapeAttributes as bta
} from 'product-validator';
import React from 'react';
import {
  getNestedValidationError,
  isTouched
} from '../../../utils/validationUtils';
import { ButtonRadioGroup, ErrorMessage, NumberInput } from '../../general';
import { IndexedAppFieldComponentProps } from '../../../types/appFieldTypes';
import { fieldOptions } from '../../../types/fieldOptionsTypes';
import update from 'immutability-helper';

export const LengthField: React.FC<IndexedAppFieldComponentProps> = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage,
  idx = 0
}) => {
  let otherField = '';
  let lengthErrParam = '';
  let lengthIndexed = false;
  let lengthMsg = '';
  let sideText = '';
  let lengthTouched = false;

  switch (currentValue.productType) {
    case ProductTypes.Fabric:
    case ProductTypes.Trim:
    case ProductTypes.Elastic:
    case ProductTypes['Bias Tape']:
      lengthTouched = isTouched(currentValue.lengthMOther[idx]);
      otherField = 'lengthMOther';
      lengthErrParam = `lengthM[${idx}]`;
      lengthIndexed = true;
      if (currentValue.bundleOrRoll === FabricAttributes.BundleOrRoll.Roll)
        lengthMsg = 'Estimate the length of the roll in meters';
      else if (currentValue.sellingUnit === bta.SellingUnit.Roll)
        lengthMsg = "What's the length of the roll in meters?";
      else lengthMsg = "What's the length in meters?";
      sideText = fieldOptions[currentValue.productType].lengthUnit;
      break;
    case ProductTypes.Zippers:
      lengthTouched = isTouched(currentValue.lengthInOther);
      otherField = 'lengthInOther';
      lengthErrParam = 'lengthIn';
      lengthMsg = `What's the length in ${
        fieldOptions[currentValue.productType].lengthMsg
      }?`;
      sideText = fieldOptions[currentValue.productType].lengthUnit;
      break;
    case ProductTypes.Ribbing:
      lengthTouched = isTouched(currentValue.lengthCM);
      otherField = 'lengthCM';
      lengthErrParam = 'lengthCM';
      lengthMsg = `What's the length in ${
        fieldOptions[currentValue.productType].lengthMsg
      }?`;
      sideText = fieldOptions[currentValue.productType].lengthUnit;
      break;
    case ProductTypes['Other Supply']:
      lengthTouched = isTouched(currentValue.lengthCM);
      otherField = 'lengthCM';
      lengthErrParam = 'lengthCM';
      lengthMsg = `What's the length in ${
        fieldOptions[currentValue.productType].lengthMsg
      }?`;
      sideText = fieldOptions[currentValue.productType].lengthUnit;
      break;
  }

  const lengthErr = getNestedValidationError(lengthErrParam, validationErrors);

  const getCurrentInput = (): string => {
    if (lengthIndexed) return currentValue.lengthMOther[idx] ?? '';
    else if (currentValue.productType === ProductTypes.Zippers)
      return currentValue.lengthInOther ?? '';
    else if (currentValue.productType === ProductTypes.Ribbing)
      return (currentValue.lengthCM ?? '').toString();
    else if (currentValue.productType === ProductTypes['Other Supply'])
      return (currentValue.lengthCM ?? '').toString();
    return '';
  };

  const trimLengthType = (
    <ButtonRadioGroup
      name="Trim Length Type"
      value={currentValue.trimLengthType}
      options={Object.values(tra.KnownOrEstimated)}
      buttonProps={{ minW: '8rem' }}
      onChange={(v) =>
        setFormField({ trimLengthType: v as tra.KnownOrEstimated })
      }
    />
  );

  const numberInputProps = {
    size: 'lg',
    stackProps: { w: '80%' },
    w: '80%'
  };
  return (
    <FormControl
      as="fieldset"
      isInvalid={lengthErr.length > 0 && lengthTouched}
    >
      <FormLabel as="legend">
        <Heading>{lengthMsg}</Heading>
        {currentValue.productType === ProductTypes['Other Supply'] ? (
          <FormHelperText>(Optional)</FormHelperText>
        ) : (
          <></>
        )}
      </FormLabel>
      <VStack spacing="3" alignItems="left">
        {currentValue.productType === ProductTypes.Trim &&
          currentValue.sellingUnit === tra.SellingUnit['Roll'] &&
          trimLengthType}
        <NumberInput
          id="lengthM"
          onChange={(s) => {
            setFormField((oldForm) => {
              if (lengthIndexed)
                return update(oldForm, {
                  [otherField]: { [idx]: { $set: s } }
                });
              else return update(oldForm, { [otherField]: { $set: s } });
            });
          }}
          value={getCurrentInput()}
          text={sideText}
          {...numberInputProps}
        />
      </VStack>
      <ErrorMessage errMsgs={lengthErr} />
      <FormHelperText>Enter numbers.</FormHelperText>
    </FormControl>
  );
};

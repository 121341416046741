import {
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  RadioGroup,
  VStack
} from '@chakra-ui/react';
import React from 'react';
import {
  getNestedValidationError,
  isTouched
} from '../../../utils/validationUtils';
import {
  Divider,
  ErrorMessage,
  NumberInput,
  RadioButton,
  WrappedInputGroup
} from '../../general';
import { IndexedAppFieldComponentProps } from '../../../types/appFieldTypes';
import update from 'immutability-helper';
import { PackSizeField } from './PackSizeField';
import {
  ProductTypes,
  ZipperAttributes as za,
  RibbingAttributes as ra,
  //ButtonProductAttributes as ba,
  //ThreadAttributes as ta,
  TrimAttributes as tra,
  ElasticAttributes as ea,
  BiasTapeAttributes as bta,
  OtherSupplyAttributes as osa
} from 'product-validator';

const QUANTITY_OPTIONS = {
  Radios: ['1', '5', '10']
};

export const QuantityField: React.FC<IndexedAppFieldComponentProps> = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage,
  idx = 0
}) => {
  const subProps = {
    setFormField,
    currentValue,
    validationErrors,
    toNextPage
  };
  const quantityErr = getNestedValidationError('quantity', validationErrors);
  const quantityTouched = isTouched(currentValue.quantityOther[idx]);

  let quantityRadios: string[] = [];

  const showQuantityRadios =
    currentValue.productType === ProductTypes.Buttons ||
    currentValue.productType === ProductTypes['Other Supply'];

  const showPackSize =
    currentValue.sellingUnit === 'Pack' ||
    (currentValue.sellingUnit === 'Hardware' &&
      currentValue.zipperHardwareSU === 'Pack');

  const numberInputProps = {
    size: 'lg',
    stackProps: { w: '80%' },
    w: '80%'
  };

  let title = 'Enter Quantity';
  let sideText = '';
  if (
    currentValue.sellingUnit === za.SellingUnit['Tape By The Meter'] ||
    (currentValue.productType === ProductTypes.Ribbing &&
      currentValue.sellingUnit === ra.SellingUnit['By Meter'])
  ) {
    title = 'Estimate the length of roll in meters';
    sideText = 'length (M)';
  } else if (
    currentValue.productType === ProductTypes.Trim &&
    currentValue.sellingUnit === tra.SellingUnit['By Meter']
  )
    title = 'Enter quantity of meters on the roll';
  else if (currentValue.sellingUnit === tra.SellingUnit['Roll'])
    title = 'Quantity of Rolls';
  else if (
    currentValue.productType === ProductTypes.Elastic &&
    currentValue.sellingUnit === ea.SellingUnit['By Meter']
  )
    title = 'Enter the quantity of meters';
  else if (
    (currentValue.productType === ProductTypes.Elastic ||
      currentValue.productType === ProductTypes.Thread ||
      currentValue.productType === ProductTypes.Ribbing) &&
    currentValue.sellingUnit === ea.SellingUnit['Pack']
  ) {
    title = 'Enter the quantity of packs';
  } else if (
    currentValue.productType === ProductTypes.Elastic &&
    currentValue.sellingUnit === ea.SellingUnit.Bundle
  ) {
    title = 'Enter the quantity of bundles';
  } else if (
    currentValue.productType === ProductTypes['Bias Tape'] &&
    currentValue.sellingUnit === bta.SellingUnit.Package
  )
    title = 'Quantity of Packages';
  else if (
    currentValue.productType === ProductTypes['Bias Tape'] &&
    currentValue.sellingUnit === bta.SellingUnit['By Meter']
  )
    title = 'Enter the quantity of meters';
  else if (
    currentValue.productType === ProductTypes['Other Supply'] &&
    currentValue.sellingUnit === osa.SellingUnit.Pack
  )
    title = 'Quantity of Packs';
  else if (
    currentValue.productType === ProductTypes['Other Supply'] &&
    currentValue.sellingUnit === osa.SellingUnit['By Weight']
  )
    title = 'Quantity of weight units';

  switch (currentValue.productType) {
    case ProductTypes.Buttons:
      quantityRadios = QUANTITY_OPTIONS.Radios;
      break;
    case ProductTypes['Other Supply']:
      quantityRadios = QUANTITY_OPTIONS.Radios;
      break;
  }

  return (
    <>
      {showPackSize && (
        <>
          <PackSizeField {...subProps} />
          <Divider />
        </>
      )}
      <FormControl
        as="fieldset"
        isInvalid={quantityErr.length > 0 && quantityTouched}
      >
        <FormLabel as="legend">
          <Heading>{title}</Heading>
        </FormLabel>
        <VStack flexDir="column" alignItems="flex-start" spacing="3">
          {showQuantityRadios ? (
            <RadioGroup
              value={currentValue.quantityRadio[idx]}
              onChange={(v) =>
                setFormField((oldForm) =>
                  update(oldForm, {
                    quantityRadio: {
                      [idx]: { $set: v.toString() }
                    }
                  })
                )
              }
            >
              <WrappedInputGroup numColumns={4} sx={{ gap: '0.25rem' }}>
                {quantityRadios.map((option) => (
                  <RadioButton
                    key={option}
                    value={option.toString()}
                    isDisabled={!!currentValue.quantityOther?.[idx]}
                    minW="7rem"
                  >
                    {`${option}`}
                  </RadioButton>
                ))}
              </WrappedInputGroup>
            </RadioGroup>
          ) : (
            <></>
          )}
          <NumberInput
            id="quantity"
            onChange={(s) => {
              setFormField((oldForm) =>
                update(oldForm, {
                  quantityOther: { [idx]: { $set: s } }
                })
              );
            }}
            defaultValue={currentValue.quantityOther[idx]}
            text={sideText}
            {...numberInputProps}
            width={'27rem'}
          />
        </VStack>

        <ErrorMessage errMsgs={quantityErr} />
        <FormHelperText>Enter numbers.</FormHelperText>
      </FormControl>
    </>
  );
};

import {
  FormControl,
  FormLabel,
  FormHelperText,
  VStack,
  Heading,
  CheckboxGroup
} from '@chakra-ui/react';
import React from 'react';
import { AppFieldComponent } from '../../../types/appFieldTypes';
import {
  ButtonRadioGroup,
  OtherTextInput,
  ErrorMessage,
  WrappedInputGroup,
  CheckboxButton
} from '../../general';
import {
  getNestedValidationError,
  isTouched
} from '../../../utils/validationUtils';
import {
  ProductTypes,
  RibbingAttributes as ra,
  ButtonProductAttributes as ba,
  ThreadAttributes as ta,
  BiasTapeAttributes as bta
} from 'product-validator';
import {
  getButtonMaterialOptions,
  getThreadMaterialOptions
} from '../../../utils/supplyUtils';

export const SupplyContentField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors,
  toNextPage
}) => {
  const materialErrMsgs = getNestedValidationError(
    'material',
    validationErrors
  );

  const materialTouched = isTouched(
    currentValue.material,
    currentValue.materialOther
  );

  let materialOptions:
    | ra.Material[]
    | ba.Material[]
    | ta.Content[]
    | bta.Material[] = [];

  if (currentValue.productType === ProductTypes.Ribbing) {
    materialOptions = Object.values(ra.Material);
  } else if (currentValue.productType === ProductTypes.Buttons) {
    materialOptions = getButtonMaterialOptions(currentValue.buttonType);
  } else if (currentValue.productType === ProductTypes.Thread) {
    materialOptions = getThreadMaterialOptions(
      currentValue.threadTypeOther && currentValue.threadTypeOther !== ''
        ? ''
        : currentValue.threadType
    );
  } else if (currentValue.productType === ProductTypes['Bias Tape']) {
    materialOptions = Object.values(bta.Material);
  }

  return (
    <FormControl
      as="fieldset"
      isInvalid={materialErrMsgs.length > 0 && materialTouched}
    >
      <FormLabel as="legend">
        <Heading>Material</Heading>
      </FormLabel>
      <VStack spacing="3" alignItems="left">
        {
          // A button can have multiple materials Ex: Metal/Plastic so checkbox buttons need to be used
          currentValue.productType === ProductTypes.Buttons ? (
            <CheckboxGroup
              value={currentValue.materials}
              onChange={(materialArr: string[]) => {
                return setFormField({
                  materials: materialArr
                });
              }}
            >
              <WrappedInputGroup maxW="30.50rem">
                {(materialOptions as Array<ba.Material>).map((value) => (
                  <CheckboxButton
                    key={value}
                    value={value}
                    isDisabled={
                      currentValue.materials &&
                      (currentValue.materials.length >= 2 ||
                        (currentValue.materials.length >= 1 &&
                          !!currentValue.materialOther)) &&
                      !currentValue.materials.includes(value)
                    }
                  >
                    {value}
                  </CheckboxButton>
                ))}
              </WrappedInputGroup>
            </CheckboxGroup>
          ) : (
            <ButtonRadioGroup
              name="Material"
              value={currentValue.material}
              options={materialOptions}
              isDisabled={!!currentValue.materialOther}
              onChange={(v) => {
                setFormField({
                  material: v as
                    | ra.Material
                    | ba.Material
                    | ta.Content
                    | bta.Material
                });
              }}
            />
          )
        }
        <OtherTextInput
          onChange={(e) => {
            setFormField({
              materialOther: e.target.value
            });
          }}
          value={currentValue.materialOther ?? ''}
        />
        <FormHelperText>Select one or enter in another.</FormHelperText>
        <ErrorMessage errMsgs={materialErrMsgs} />
      </VStack>
    </FormControl>
  );
};

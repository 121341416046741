import React from 'react';
import { ButtonRadioGroup, FormFieldTemplate } from '../../../general';
import { ZipperAttributes as za } from 'product-validator';
import { AppFieldComponent } from '../../../../types/appFieldTypes';

export const ZipperTypeField: AppFieldComponent = ({
  currentValue,
  setFormField,
  toNextPage
}) => {
  return (
    <FormFieldTemplate title="Type">
      <ButtonRadioGroup
        name="zipperType"
        isVertical={true}
        options={Object.values(za.ZipperType)}
        value={currentValue.zipperType}
        onChange={(v) => {
          setFormField({ zipperType: v as za.ZipperType });
          if (currentValue.sellingUnit === za.SellingUnit['Tape By The Meter'])
            toNextPage({ navOnNextFormUpdate: true });
          else if (
            (currentValue.sellingUnit === za.SellingUnit.Individual ||
              currentValue.sellingUnit === za.SellingUnit['Pack']) &&
            currentValue.closure
          )
            toNextPage({ navOnNextFormUpdate: true });
        }}
      />
    </FormFieldTemplate>
  );
};

import {
  FormControl,
  FormLabel,
  FormHelperText,
  Heading,
  VStack
} from '@chakra-ui/react';
import React from 'react';
import { FabricAttributes } from 'product-validator';
import { ButtonRadioGroup } from '../../../general/ButtonRadio';
import { AppFieldComponent } from '../../../../types/appFieldTypes';

export const KnitOrWovenField: AppFieldComponent = ({
  setFormField,
  currentValue,
  toNextPage
}) => {
  return (
    <VStack>
      <FormControl as="fieldset">
        <FormLabel as="legend" mb="1.5rem">
          <Heading>Is the primary fabric a knit or woven?</Heading>
        </FormLabel>
        <ButtonRadioGroup
          name="knitOrWoven"
          value={currentValue?.knitOrWoven}
          options={Object.values(FabricAttributes.KnitOrWoven)}
          onChange={(v) => {
            setFormField({ knitOrWoven: v as FabricAttributes.KnitOrWoven });
            toNextPage({ navOnNextFormUpdate: true });
          }}
        />
        <FormHelperText>Choose one.</FormHelperText>
      </FormControl>
    </VStack>
  );
};

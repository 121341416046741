import { FabricAttributes as fa } from 'product-validator';
// 11 conditions that only hold depending on the fabric, weave or knit
// 11 conditions that will hold no matter what
// Only one quality can be selected

// 8 qualities that will alter the price if conditions is met
const SPECIAL_QUALITIES = [
  fa.Quality.Performance.RawSilk,
  fa.Quality.Performance.VirginWool,
  fa.Quality.Aesthetic.ShortHair,
  fa.Quality.Aesthetic.LongHair,
  fa.Quality.Aesthetic.QuiltedTop,
  fa.Quality.Performance.SewIn
];

type QualityMap<T> = {
  [key: string]: T;
};

// 19 Different qualities that affect price
const QUALITY_PRICE: QualityMap<number> = {
  [fa.Quality.Performance.Waterproof]: 2,
  [fa.Quality.Performance.WaterResistant]: 2,
  [fa.Quality.Performance.Chitosante]: 2,
  [fa.Quality.Aesthetic.Glitter]: 2,
  [fa.Quality.Performance.FireRetardent]: 2,
  [fa.Quality.Performance.Waxed]: 2,
  [fa.Quality.Aesthetic.Crinkle]: 1,
  [fa.Quality.Performance.RawSilk]: 2,
  [fa.Quality.Performance.VirginWool]: 2,
  [fa.Quality.Aesthetic.UnbleachedOrUndyed]: -1,
  [fa.Quality.Performance.Goretex]: 2,
  [fa.Quality.Performance.DWR]: 2,
  [fa.Quality.Aesthetic.Brushed]: 1,
  [fa.Quality.Aesthetic.ShortHair]: -2,
  [fa.Quality.Aesthetic.MediumHair]: 0,
  [fa.Quality.Aesthetic.LongHair]: 2,
  [fa.Quality.Aesthetic.QuiltedTop]: 6,
  [fa.Quality.Performance.SewIn]: 1,
  [fa.Quality.Performance.IronOn]: 0,
  [fa.Quality.Aesthetic.Tubular]: 0
};

/**
 * Return whether a special quality condition will hold
 */
const qualityCondition = (
  quality: string,
  primaryType = '',
  secondaryType = '',
  knitOrWoven: fa.KnitOrWoven,
  knitWeaveType = ''
): boolean => {
  switch (quality) {
    case fa.Quality.Performance.RawSilk:
      return (
        primaryType === fa.ContentSpecific.Silk && secondaryType === undefined
      );
    case fa.Quality.Performance.VirginWool:
      return (
        primaryType === fa.ContentSpecific.Wool && secondaryType === undefined
      );
    case fa.Quality.Aesthetic.ShortHair:
      return (
        knitWeaveType === fa.KnitWeaveType.Knit.Heavy['Faux Fur'] ||
        knitWeaveType === fa.KnitWeaveType.Woven.Heavy['Faux Fur']
      );
    case fa.Quality.Aesthetic.LongHair:
      return (
        knitWeaveType === fa.KnitWeaveType.Knit.Heavy['Faux Fur'] ||
        knitWeaveType === fa.KnitWeaveType.Woven.Heavy['Faux Fur']
      );
    case fa.Quality.Aesthetic.QuiltedTop:
      return (
        knitOrWoven === fa.KnitOrWoven.Woven &&
        knitWeaveType === fa.KnitWeaveType.Woven.Light.Batting
      );
    case fa.Quality.Performance.SewIn:
      return (
        knitWeaveType === fa.KnitWeaveType.Woven.Light.Interfacing ||
        knitWeaveType === fa.KnitWeaveType.Knit.Light.Interfacing
      );
    default:
      return false;
  }
};

/**
 * Given an existing quality, return the price alteration
 */
export const qualityPrice = (
  quality: string,
  primaryType: string,
  secondaryType: string | undefined,
  knitOrWoven: fa.KnitOrWoven,
  knitWeaveType: string
): number => {
  if (SPECIAL_QUALITIES.includes(quality)) {
    return qualityCondition(
      quality,
      primaryType,
      secondaryType,
      knitOrWoven,
      knitWeaveType
    )
      ? QUALITY_PRICE[quality]
      : 0;
  } else {
    // Unable to index obj, quality was entered via a "Other" field
    return QUALITY_PRICE[quality] ?? 0;
  }
};

import {
  FormControl,
  FormControlProps,
  FormLabel,
  Switch,
  SwitchProps
} from '@chakra-ui/react';
import React from 'react';

interface SwitchInputProps extends FormControlProps {
  label: string;
  isChecked: boolean;
  isDisabled?: boolean;
  onChange: SwitchProps['onChange'];
}

export const SwitchInput = ({
  label,
  isChecked,
  isDisabled = false,
  onChange,
  ...rest
}: SwitchInputProps) => {
  return (
    <FormControl
      w="auto"
      display="flex"
      flexDir="row"
      justifyContent="end"
      isDisabled={isDisabled}
      {...rest}
    >
      <FormLabel>{label}</FormLabel>
      <Switch
        size="lg"
        colorScheme="purple"
        isDisabled={isDisabled}
        isChecked={isChecked}
        onChange={onChange}
      />
    </FormControl>
  );
};

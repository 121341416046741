import React from 'react';
import { ButtonRadioGroup, FormFieldTemplate } from '../../../general';
import { ZipperAttributes as za } from 'product-validator';
import { AppFieldComponent } from '../../../../types/appFieldTypes';

export const ZipperHardwareMaterialField: AppFieldComponent = ({
  currentValue,
  setFormField,
  toNextPage
}) => {
  var pullMaterialOptions = Object.values(za.ZipperHardwareMaterial);

  var materialOptions = pullMaterialOptions.filter(
    (option) => option !== 'Fabric'
  );

  return (
    <FormFieldTemplate title="Material">
      <ButtonRadioGroup
        name="Zipper Hardware Material"
        isVertical={true}
        options={
          currentValue.zipperHardwareType === za.ZipperHardwareType.Pull
            ? pullMaterialOptions
            : materialOptions
        }
        value={currentValue.zipperHardwareMaterial}
        onChange={(v) => {
          setFormField({
            zipperHardwareMaterial: v as za.ZipperHardwareMaterial
          });
          toNextPage({ navOnNextFormUpdate: true });
        }}
      />
    </FormFieldTemplate>
  );
};

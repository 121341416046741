import React from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';
import { ButtonRadioGroup } from '../general';
import { YesNo } from '../../types/formTypes';

interface YesNoQuestionProps {
  name: string;
  value?: YesNo;
  onChange: (v: YesNo) => void;
  question: string;
}

export const YesNoQuestion: React.FC<YesNoQuestionProps> = ({
  name,
  value,
  onChange,
  question,
  children
}) => {
  return (
    <Box>
      <Flex
        maxW="55rem"
        my="1em"
        dir="row"
        gridGap="2em"
        wrap="wrap"
        justifyContent="space-between"
      >
        <Heading size="md">{question}</Heading>
        <ButtonRadioGroup
          name={name}
          buttonProps={{ w: '7em' }}
          value={value ?? 'No'}
          options={['Yes', 'No']}
          onChange={(v) => onChange(v as YesNo)}
        />
      </Flex>
      {value === 'Yes' && children}
    </Box>
  );
};

import {
  FormControl,
  FormLabel,
  FormHelperText,
  Heading,
  Box,
  Stack,
  CheckboxGroup,
  HStack
} from '@chakra-ui/react';
import React from 'react';
import { FabricAttributes } from 'product-validator';
import { AppFieldComponent } from '../../../types/appFieldTypes';
import {
  getNestedValidationError,
  isTouched
} from '../../../utils/validationUtils';
import {
  LooksDescriptor,
  OtherTextInput,
  WrappedInputGroup,
  ErrorMessage,
  Card,
  CheckboxButton
} from '../../general';
import { ColourToneField } from './ColourToneField';
import { ButtonOpaquenessField } from './ButtonProductFields/ButtonOpaquenessField';

const colourIconValue: {
  [colour in keyof Omit<typeof FabricAttributes.Colour, 'Multi'>]: string;
} = {
  Beige: 'yellow.600',
  Black: 'black',
  Blue: 'blue.500',
  Brown: 'yellow.900',
  Green: 'green.700',
  Grey: 'gray.500',
  Purple: 'purple.500',
  White: 'white',
  Red: 'red.500',
  Orange: 'orange.500',
  Yellow: 'yellow.300',
  Pink: 'pink.300',
  Navy: 'blue.900',
  Cream: 'yellow.50',
  Gold: 'yellow.400',
  Silver: 'gray.50',
  Burgundy: 'red.800'
};

const ColourButtonText = (props: { value: string }) => {
  let colorProps = {};
  if (props.value === FabricAttributes.Colour.Multi)
    colorProps = {
      backgroundImage: 'linear-gradient(red, yellow, green, blue)'
    };
  else if (props.value === FabricAttributes.Colour.Gold)
    colorProps = {
      backgroundImage:
        'linear-gradient(rgba(219, 177, 0, 8), rgba(255, 215, 46, 8), rgba(255, 238, 166, 8))'
    };
  else if (props.value === FabricAttributes.Colour.Silver)
    colorProps = {
      backgroundImage: 'linear-gradient(gray, white, gray)'
    };
  else
    colorProps = {
      bgColor: colourIconValue[props.value as keyof typeof colourIconValue]
    };

  return <LooksDescriptor text={props.value} {...colorProps} />;
};

export const ColourField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors
}) => {
  const onTextChange = async (text: string) => {
    setFormField({ colourOther: text });
  };

  const errMsgs = getNestedValidationError('colour', validationErrors);
  const touched = isTouched(currentValue.colour, currentValue.colourOther);

  return (
    <Box w="100%" h="100%">
      <FormControl as="fieldset" isInvalid={errMsgs.length > 0 && touched}>
        <FormLabel as="legend">
          <Heading>What colour is the product?</Heading>
          <FormHelperText>
            Choose up to two colours.
            <br />
            If the product has more than two colours, choose the primary colour
            and multi.
          </FormHelperText>
        </FormLabel>
        <Stack spacing="3">
          <Card title="Colours">
            <CheckboxGroup
              value={currentValue.colour}
              onChange={(colourArr: string[]) => {
                return setFormField({
                  colour: colourArr
                });
              }}
            >
              <WrappedInputGroup numColumns={4}>
                {Object.values(FabricAttributes.Colour).map((value) => (
                  <CheckboxButton
                    key={value}
                    isDisabled={
                      currentValue.colour &&
                      (currentValue.colour.length >= 2 ||
                        (currentValue.colour.length >= 1 &&
                          !!currentValue.colourOther)) &&
                      !currentValue.colour.includes(value)
                    }
                    value={value}
                  >
                    <ColourButtonText value={value} />
                  </CheckboxButton>
                ))}
              </WrappedInputGroup>
            </CheckboxGroup>
          </Card>
          <HStack>
            <ColourToneField
              setFormField={setFormField}
              currentValue={currentValue}
            />
            {currentValue.productType === 'Buttons' ? (
              <ButtonOpaquenessField
                setFormField={setFormField}
                currentValue={currentValue}
              />
            ) : (
              <></>
            )}
          </HStack>

          <OtherTextInput
            onChange={(e) => onTextChange(e.target.value)}
            isDisabled={currentValue.colour && currentValue.colour.length >= 2}
            value={currentValue.colourOther ?? ''}
          />
        </Stack>
        <ErrorMessage errMsgs={errMsgs} />
        <FormHelperText>Choose all that apply or enter other.</FormHelperText>
      </FormControl>
    </Box>
  );
};

import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Heading,
  Text,
  Box
} from '@chakra-ui/react';
import React from 'react';
import {
  getNestedValidationError,
  isTouched
} from '../../../utils/validationUtils';
import { OtherTextInput } from '../../general/OtherTextInput';
import { AppFieldComponent } from '../../../types/appFieldTypes';
import { ProductTypes } from 'product-validator';
import { MediaTagField } from './FabricFields/MediaTagField';

export const NameField: AppFieldComponent = ({
  setFormField,
  currentValue,
  validationErrors
}) => {
  const nameErr: string[] = getNestedValidationError('name', validationErrors);
  const nameTouched = isTouched(currentValue.name);

  return (
    <Box>
      <FormControl as="fieldset" isInvalid={nameErr.length > 0 && nameTouched}>
        <FormLabel as="legend">
          {currentValue.productType === ProductTypes['Other Supply'] ? (
            <Heading>Add a name</Heading>
          ) : (
            <Heading>Name</Heading>
          )}
          {currentValue.productType === ProductTypes['Other Supply'] ? (
            <>
              <FormHelperText>
                Don't include the name of the supply type
              </FormHelperText>
              <Text size="sm">(optional)</Text>
            </>
          ) : (
            ''
          )}
          {currentValue.productType === ProductTypes['Other Supply'] ? (
            <></>
          ) : (
            <>Name this fabric something fun!</>
          )}
        </FormLabel>
        <OtherTextInput
          onChange={(e) => setFormField({ name: e.target.value as string })}
          colorScheme="purple.500"
          placeholder="Enter a name"
          value={currentValue.name ?? ''}
        />
        <FormErrorMessage>{nameErr[0] ?? ''}</FormErrorMessage>
        {currentValue.productType === ProductTypes['Other Supply'] ? (
          <></>
        ) : (
          <FormHelperText>Enter a name.</FormHelperText>
        )}
        <MediaTagField
          setFormField={setFormField}
          currentValue={currentValue}
        />
      </FormControl>
    </Box>
  );
};
